import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import closeIcon from "../../assets/icons/close-icon.svg";

export default function ResponsiveDialog({ open, handleClose, data }) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						textAlign: "center",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							width: "30rem",
						}}
					>
						<Typography
							variant="h6"
							gutterBottom
							component="p"
							sx={{ fontWeight: 700, textAlign: "center" }}
						>
							{data?.accountName}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="p"
							sx={{ fontWeight: 700, textAlign: "center" }}
						>
							{data?.bankName}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="p"
							sx={{ fontWeight: 700, textAlign: "center" }}
						>
							{data?.accountNumber}
						</Typography>
					</Box>
				</DialogContent>
				<DialogActions sx={{ justifyContent: "center" }}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							marginTop: "1rem",
						}}
					>
						<Stack direction="row" spacing={2} sx={{ alignSelf: "center" }}>
							<Button
								sx={{
									paddingLeft: "3rem",
									paddingRight: "3rem",
								}}
								color="success"
								variant="contained"
								onClick={() => {
									handleClose();
								}}
							>
								close
							</Button>
						</Stack>
					</Box>
				</DialogActions>
			</Dialog>
		</div>
	);
}

import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
	Box,
	Typography,
	styled,
} from "../../../components/common/muiComponents";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Coop/WalletBalancesTable";
import { fetchCoopWalletBalances } from "../../../api/admin/dashboard";
import {
	downloadExcel,
	numberFormatter,
} from "../../../utils/helpers/functions";

const SummaryFilterContainer = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: ".5rem",
	alignItems: "center",
});

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const CoopTransactionsLog = () => {
	const [searchTerm, setSearchTerm] = useState("");

	const { region, user } = useSelector((state) => state.auth);

	const {
		data: reportData = [],
		isLoading: reportLoading,
		isError: reportError,
		error: reportErrorMessage,
	} = useQuery(
		[`fetch-coop-wallet-balances`, region?.region],
		async () =>
			fetchCoopWalletBalances({
				region: region?.region,
				roleID: user?.role,
				username: user?.username,
			}),
		{
			select: (data) => data?.data,
			staleTime: 4 * 60 * 1000,
			enabled: !!region?.region,
		}
	);

	useEffect(() => {
		if (reportError) {
			if (reportErrorMessage.response) {
				toast.error(reportErrorMessage.response.data?.message);
			} else if (reportErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", reportErrorMessage.message);
			}
		}
	}, [reportError, reportErrorMessage]);

	const displayed = useMemo(() => {
		if (reportData.length) {
			let filtered = [...reportData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item?.username
							?.toLowerCase()
							?.includes(searchTerm?.toLowerCase()) ||
						`${item?.Balance}`
							?.toLowerCase()
							?.includes(searchTerm?.toLowerCase())
					);
				});
			}
			return filtered;
		}
		return [];
	}, [reportData, searchTerm]);

	const totalAmount = useMemo(() => {
		return displayed.reduce((acc, item) => acc + Number(item.Balance), 0);
	}, [displayed]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Wallet Balances"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search report"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<SummaryFilterContainer>
					<Typography variant="h6">{`Total Amount: ${numberFormatter(
						totalAmount
					)}`}</Typography>
				</SummaryFilterContainer>
				<Table data={displayed} loading={reportLoading} />
			</Box>
		</Box>
	);
};

export default CoopTransactionsLog;

import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Box } from "../../../components/common/muiComponents";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/ProductInterestTable";
import { fetchInterest } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const FetchInterest = () => {
	const [searchTerm, setSearchTerm] = useState("");

	const { region, user } = useSelector((state) => state.auth);

	const {
		data: fetchInterestData = [],
		isLoading: fetchInterestLoading,
		isError: fetchInterestError,
		error: fetchInterestErrorMessage,
	} = useQuery(
		["fetch-interest", region.region],
		async () =>
			fetchInterest({
				region: region.region,
				bizFrom: user?.bizFrom,
				username: user?.username,
			}),
		{
			select: (data) => data?.data,
		}
	);

	useEffect(() => {
		if (fetchInterestError) {
			if (fetchInterestErrorMessage.response) {
				toast.error(fetchInterestErrorMessage.response.data?.message);
			} else if (fetchInterestErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", fetchInterestErrorMessage.message);
			}
		}
	}, [fetchInterestError, fetchInterestErrorMessage]);

	const displayed = useMemo(() => {
		if (searchTerm) {
			return fetchInterestData.filter((item) => {
				return item.productName
					?.toLowerCase()
					.includes(searchTerm.toLowerCase());
			});
		}
		return fetchInterestData;
	}, [fetchInterestData, searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Product Interest"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search report"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table data={displayed} loading={fetchInterestLoading} />
			</Box>
		</Box>
	);
};

export default FetchInterest;

import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/common/DiscountsClaimedTable";
import ViewDiscountClaimedModal from "../../../components/common/ViewDiscountClaimedModal";
import { getDiscountsClaimed } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const DiscountsClaimed = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);

	const { region } = useSelector((state) => state.auth);

	const {
		data: discountsClaimedData = [],
		isLoading: discountsClaimedLoading,
		isError: discountsClaimedError,
		error: discountsClaimedErrorMessage,
	} = useQuery(
		["get-admin-discountsClaimed", region.region],
		async () => getDiscountsClaimed(region.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (discountsClaimedError) {
			if (discountsClaimedErrorMessage.response) {
				toast.error(discountsClaimedErrorMessage.response.data?.message);
			} else if (discountsClaimedErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", discountsClaimedErrorMessage.message);
			}
		}
	}, [discountsClaimedError, discountsClaimedErrorMessage]);

	useEffect(() => {
		if (discountsClaimedData.length && !discountsClaimedLoading) {
			setDisplayed([...discountsClaimedData]);
			setAllData([...discountsClaimedData]);
		}
	}, [discountsClaimedData, discountsClaimedLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.username?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.partnerName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.attendant?.toLowerCase()?.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="discount claimed"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search discounts claimed"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table
					data={displayed}
					loading={discountsClaimedLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</Box>
			<ViewDiscountClaimedModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Discount Claimed Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default DiscountsClaimed;

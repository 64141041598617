import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import { numberFormatter } from "../../utils/helpers/functions";

export default function StickyHeadTable({
	loading,
	data,
	setSelectedItem,
	handleView,
}) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const firstPageElement = page * rowsPerPage + 1;

	useEffect(() => {
		if (firstPageElement > data?.length) {
			setPage(0);
		}
	}, [data, firstPageElement]);

	const columns = [
		{ id: "S/N", label: "S/N", minWidth: 10 },
		{ id: "username", label: "Username", minWidth: 80 },
		// { id: "fullName", label: "FullName", minWidth: 170 },
		// { id: "emailAddress", label: "Email Address", minWidth: 120 },
		// { id: "phoneNumber", label: "Phone Number", minWidth: 80 },
		{
			id: "InitiationTranRef",
			label: "Initiation Transaction Reference",
			minWidth: 80,
		},
		// { id: "dealID", label: "Deal ID", minWidth: 80 },
		// { id: "pointsUsed", label: "Points Used", minWidth: 80 },
		// { id: "transactionRef", label: "Transaction Reference", minWidth: 170 },
		// {
		// 	id: "shippingFee",
		// 	label: `Shipping Fee (${currencySymbolMap[region.region]})`,
		// 	minWidth: 80,
		// },
		// {
		// 	id: "retailPrice",
		// 	label: `Retail Price (${currencySymbolMap[region.region]})`,
		// 	minWidth: 80,
		// },
		{
			id: "amount",
			label: `Amount`,
			minWidth: 80,
		},
		{
			id: "accountNumber",
			label: "Account Number",
			minWidth: 80,
		},
		// { id: "address", label: "Address", minWidth: 80 },
		{ id: "actualAmount", label: "Actual Amount", minWidth: 80 },
		{ id: "transactionRef", label: "Transaction Reference", minWidth: 80 },
		{ id: "status", label: "Status", minWidth: 80 },
		// { id: "approvalDate", label: "Approval Date", minWidth: 80 },
		{ id: "action", label: "Action", minWidth: 80 },
	];

	return (
		<Paper
			sx={{
				width: "100%",
				overflow: "hidden",
				marginBottom: "3rem",
			}}
		>
			<TableContainer sx={{ maxHeight: 440 }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
									sx={{
										backgroundColor: "primary.main",
										color: "#fff",
									}}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{!loading &&
							data
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((item, index) => (
									<TableRow key={item.id} hover role="checkbox" tabIndex={-1}>
										<TableCell>{page * rowsPerPage + index + 1}</TableCell>
										<TableCell>{item.username || ""}</TableCell>
										{/* <TableCell>{item.fullName || ""}</TableCell> */}
										<TableCell>{item.InitiationTranRef || ""}</TableCell>
										<TableCell>{numberFormatter(item.amount) || ""}</TableCell>
										<TableCell>{item.accountNumber || ""}</TableCell>
										<TableCell>
											{numberFormatter(item.actualAmount) || ""}
										</TableCell>
										<TableCell>{item.transactionRef || ""}</TableCell>
										<TableCell>{item.status || ""}</TableCell>
										<TableCell>
											<IconButton
												onClick={(event) => {
													setSelectedItem(item);
													handleClick(event);
												}}
											>
												<MoreHorizIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
					</TableBody>
				</Table>
			</TableContainer>
			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						marginTop: "2rem",
					}}
				>
					<CircularProgress size="2rem" />
				</Box>
			)}
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={data.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Menu
				id="handle-activate-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "handle-activate-button",
				}}
			>
				<MenuItem
					onClick={() => {
						handleClose();
						handleView();
					}}
				>
					<VisibilityOutlinedIcon sx={{ mr: "0.5rem", fontSize: "1rem" }} />
					<Typography variant="body2">View</Typography>
				</MenuItem>
			</Menu>
		</Paper>
	);
}

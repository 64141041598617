import TextField from "@mui/material/TextField";

const NumberTextField = ({
	id,
	label,
	name,
	value,
	onChange,
	variant = "outlined",
	error = null,
	helperText = null,
}) => {
	return (
		<TextField
			id={id}
			label={label}
			variant={variant}
			value={value}
			type="number"
			inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
			name={name}
			onChange={onChange}
			fullWidth
			error={error}
			helperText={helperText}
			sx={{
				"input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
					WebkitAppearance: "none",
					margin: 0,
				},
				"input[type=number]": {
					MozAppearance: "textfield",
				},
			}}
		/>
	);
};

export default NumberTextField;

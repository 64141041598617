import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { logout as adminLogout } from "../../slices/authSlice";

import { ROLES, routesByRole } from "../../utils/constants";

const MobileNavLinks = ({
	// handleProfileClick,
	isAuthenticated,
	role,
	isDesktopCountryOpen,
	setDesktopCountryAnchorEl,
	logo,
	toggleFlag,
	handleMobileMenuClose,
}) => {
	const dispatch = useDispatch();

	const routes = routesByRole[role];

	return (
		<div style={{ textAlign: "right" }}>
			{role?.toLowerCase() !== ROLES.ADMIN && (
				<>
					{/* <MenuItem>
						<Button
							variant="text"
							sx={{ color: "#594E4E", textTransform: "none", mr: "0.5rem" }}
							component={Link}
							to="/how-it-works"
						>
							How it works
						</Button>
					</MenuItem> */}

					{/* <MenuItem>
						<Button
							variant="text"
							sx={{ color: "#594E4E", textTransform: "none", mr: "0.5rem" }}
							component={Link}
							to="/test"
						>
							Advertise
						</Button>
					</MenuItem> */}
				</>
			)}

			{isAuthenticated && role?.toLowerCase() === ROLES.MEMBER && (
				<Button
					variant="text"
					sx={{ color: "#594E4E", textTransform: "none", mr: "0.5rem" }}
					component={Link}
					to="/my deals"
				>
					My Deals
				</Button>
			)}

			{!isAuthenticated && (
				<>
					{/* <MenuItem>
						<Button
							variant="text"
							sx={{ color: "#594E4E", textTransform: "none", mr: "0.5rem" }}
							component={Link}
							to="/test"
						>
							Subscriptions
						</Button>
					</MenuItem> */}
					<MenuItem>
						<Button
							variant="text"
							sx={{ color: "#594E4E", textTransform: "none", mr: "0.5rem" }}
							component={Link}
							to="/"
							onClick={handleMobileMenuClose}
						>
							Login
						</Button>
					</MenuItem>
					{/* <MenuItem>
						<Button
							variant="outlined"
							sx={{
								textTransform: "capitalize",
								whiteSpace: "no-wrap",
							}}
						>
							Sign Up
						</Button>
					</MenuItem> */}
				</>
			)}

			{isAuthenticated && (
				<>
					{/* <MenuItem onClick={handleProfileClick}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="primary-search-account-menu"
							aria-haspopup="true"
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Box sx={{ display: "flex" }}>
								<Typography variant="body2" component="div">
									Profile
								</Typography>
								<ArrowDropDownIcon sx={{ mt: "-0.2rem" }} />
							</Box>
							<Typography
								variant="subtitle1"
								sx={{ color: "#E6771F" }}
								component="div"
							>
								points
							</Typography>
						</Box>
					</MenuItem> */}
					<>
						{routes?.map((route) => (
							<MenuItem component={Link} to={route.link} key={route.link}>
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									{route.name}
								</Typography>
							</MenuItem>
						))}
						<MenuItem onClick={() => dispatch(adminLogout())}>
							<Typography
								variant="body2"
								component="div"
								sx={{ textTransform: "capitalize" }}
							>
								logout
							</Typography>
						</MenuItem>
					</>
					{/* {role === ROLES.ADMIN && (
						<>
							<MenuItem component={Link} to="/dashboard">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									dashboard
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/members">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									Members
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/partners">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									partners
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/suggested-partners">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									Suggested Partners
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/products">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									products
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/live-deals">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									Live Deals
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/deals-won">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									Deals Won
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/checkout">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									checkout
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/delivery">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									delivery
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/banks">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									banks
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/verify-account">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									verify account
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/discount-claimed">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									discount claimed
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/failed withdrawals">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									failed withdrawals
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/mailing-list">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									mailing list
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/subscriptions">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									subscriptions
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/wallet-report">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									wallet report
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/activity-log">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									activity log
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/flutter-log">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									flutter log
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/paystack-log">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									paystack log
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/providus-log">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									providus log
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/notifications">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									notifications
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/settings/subscription-plans">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									subscription plans
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/settings/user-roles">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									user roles
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/settings/admin-users">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									admin users
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/create-admin">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									create-admin
								</Typography>
							</MenuItem>
							<MenuItem component={Link} to="/settings/change-password">
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									Change Password
								</Typography>
							</MenuItem>
							<MenuItem onClick={() => dispatch(adminLogout())}>
								<Typography
									variant="body2"
									component="div"
									sx={{ textTransform: "capitalize" }}
								>
									logout
								</Typography>
							</MenuItem>
						</>
					)} */}
				</>
			)}
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					mt: "1rem",
				}}
			>
				<Box
					sx={{ height: "2.3rem", width: "2.3rem" }}
					id="country-selector-mobile"
					aria-controls={
						isDesktopCountryOpen ? "desktop-country-menu" : undefined
					}
					aria-haspopup="true"
					aria-expanded={isDesktopCountryOpen ? "true" : undefined}
					onClick={(event) => {
						if (toggleFlag) {
							setDesktopCountryAnchorEl(event.currentTarget);
						}
					}}
				>
					<img
						src={logo}
						alt="country flag"
						height="100%"
						width="100%"
						style={{ objectFit: "cover", borderRadius: "50%" }}
					/>
				</Box>
			</Box>
		</div>
	);
};

export default MobileNavLinks;

import { createContext } from "react";
import { io } from "socket.io-client";

import config from "../config";

const { socketUrl } = config().secrets;

export const socket = io.connect(socketUrl, {
	transports: ["websocket", "polling", "flashsocket"],
});

export const SocketContext = createContext();

const SocketProvider = (props) => {
	return (
		<SocketContext.Provider value={socket}>
			{props.children}
		</SocketContext.Provider>
	);
};

export default SocketProvider;

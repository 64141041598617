import { useState, useEffect } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Box,
	CircularProgress,
	IconButton,
	Typography,
	Menu,
	MenuItem,
	CheckIcon,
} from "../common/muiComponents";

export default function StickyHeadTable({
	loading,
	data,
	setSelectedItem,
	handleActivatePartner,
	handleDeactivatePartner,
	handleView,
}) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const firstPageElement = page * rowsPerPage + 1;

	useEffect(() => {
		if (firstPageElement > data?.length) {
			setPage(0);
		}
	}, [data, firstPageElement]);

	const columns = [
		{ id: "S/N", label: "S/N", minWidth: 10 },
		{ id: "partnerLogo", label: "Logo", minWidth: 80 },
		{ id: "partnerName", label: "Name", minWidth: 170 },
		{ id: "emailAddress", label: "Email Address", minWidth: 80 },
		{ id: "phoneNumber", label: "Phone Number", minWidth: 80 },
		{ id: "status", label: "Status", minWidth: 80 },
		{ id: "action", label: "Action", minWidth: 80 },
	];

	return (
		<Paper
			sx={{
				width: "100%",
				overflow: "hidden",
				marginBottom: "3rem",
			}}
		>
			<TableContainer sx={{ maxHeight: 440 }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
									sx={{
										backgroundColor: "primary.main",
										color: "#fff",
									}}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{!loading &&
							data
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((item, index) => (
									<TableRow key={item.id} hover role="checkbox" tabIndex={-1}>
										<TableCell>{page * rowsPerPage + index + 1}</TableCell>
										<TableCell>
											<Box sx={{ height: "3rem", width: "3rem" }}>
												<img
													src={item?.partnerLogo}
													alt={item?.partnerName}
													height="100%"
													width="100%"
												/>
											</Box>
										</TableCell>
										<TableCell>{item.partnerName || ""}</TableCell>
										<TableCell>{item.emailAddress || ""}</TableCell>
										<TableCell>{item.phoneNumber || ""}</TableCell>
										<TableCell
											sx={{
												color: Number(item.status) === 1 ? "#00C96C" : "#f00",
											}}
										>
											{Number(item.status) === 1 ? "Active" : "Inactive" || ""}
										</TableCell>
										<TableCell>
											<IconButton
												onClick={(event) => {
													setSelectedItem(item);
													handleClick(event);
												}}
											>
												<MoreHorizIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
					</TableBody>
				</Table>
			</TableContainer>
			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						marginTop: "2rem",
					}}
				>
					<CircularProgress size="2rem" />
				</Box>
			)}
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={data.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Menu
				id="handle-activate-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "handle-activate-button",
				}}
			>
				<MenuItem
					onClick={() => {
						handleClose();
						handleView();
					}}
				>
					<VisibilityOutlinedIcon sx={{ mr: "0.5rem", fontSize: "1rem" }} />
					<Typography variant="body2">View</Typography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleActivatePartner();
						handleClose();
					}}
					sx={{ color: "#00C96C" }}
				>
					<CheckIcon sx={{ mr: "0.5rem", fontSize: "1rem" }} />
					<Typography variant="body2">Activate</Typography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleDeactivatePartner();
						handleClose();
					}}
					sx={{ color: "#f00" }}
				>
					<CloseIcon sx={{ mr: "0.5rem", fontSize: "1rem" }} />
					<Typography variant="body2">Deactivate</Typography>
				</MenuItem>
			</Menu>
		</Paper>
	);
}

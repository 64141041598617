import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { dateFormatter } from "../../utils/helpers/functions";
import closeIcon from "../../assets/icons/close-icon.svg";
import logo from "../../assets/logos/smart-deals-logo.svg";

const columns = [
	{ id: "Record Info", label: "Record Info", minWidth: 80 },
	{ id: "Details", label: "Details", minWidth: 80 },
];

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	data,
}) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
						maxWidth: "60rem",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box sx={{ height: "5.25rem" }}>
							<img
								src={logo}
								height="100%"
								width="auto"
								alt="Cardinalstone logo"
							/>
						</Box>
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						textAlign: "center",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "2rem",
							pb: "2rem",
							width: "30rem",
						}}
					>
						<Box sx={{ mb: "1rem", width: "100%", display: "flex" }}>
							<Paper sx={{ width: "100%", overflow: "hidden" }}>
								<TableContainer sx={{ maxHeight: 440 }}>
									<Table stickyHeader aria-label="sticky table">
										<TableHead>
											<TableRow>
												{columns.map((column) => (
													<TableCell
														key={column.id}
														style={{
															minWidth: column.minWidth,
															backgroundColor: "#e41f26",
															color: "#fff",
														}}
													>
														{column.label}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Event Image 1</TableCell>
												<TableCell>
													<Box sx={{ height: "13rem", width: "13rem" }}>
														<img
															src={data?.image1}
															alt=""
															height="100%"
															width="auto"
														/>
													</Box>
												</TableCell>
											</TableRow>
											{data?.image2 && (
												<TableRow hover role="checkbox" tabIndex={-1}>
													<TableCell>Event Image 2</TableCell>
													<TableCell>
														<Box sx={{ height: "13rem", width: "13rem" }}>
															<img
																src={data?.image2}
																alt=""
																height="100%"
																width="auto"
															/>
														</Box>
													</TableCell>
												</TableRow>
											)}
											{data?.image3 && (
												<TableRow hover role="checkbox" tabIndex={-1}>
													<TableCell>Event Image 3</TableCell>
													<TableCell>
														<Box sx={{ height: "13rem", width: "13rem" }}>
															<img
																src={data?.image3}
																alt=""
																height="100%"
																width="auto"
															/>
														</Box>
													</TableCell>
												</TableRow>
											)}
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Partner Name</TableCell>
												<TableCell>{data?.partnerName || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Event Name</TableCell>
												<TableCell>{data?.eventName || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Event Venue</TableCell>
												<TableCell>{data?.venue || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Status</TableCell>
												<TableCell>
													{Number(data?.status) === 1 ? "Active" : "Inactive"}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Event Date</TableCell>
												<TableCell>
													{dateFormatter(data?.eventDate) || ""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Event Class</TableCell>
												<TableCell>{data?.eventClass || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Event Type</TableCell>
												<TableCell>{data?.eventType || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Slogan</TableCell>
												<TableCell>{data?.discount || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Username</TableCell>
												<TableCell>{data?.username || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Email Address</TableCell>
												<TableCell>{data?.emailAddress || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Phone Number</TableCell>
												<TableCell>{data?.phoneNumber}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Platform</TableCell>
												<TableCell>{data?.platform}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>App version</TableCell>
												<TableCell>{data?.version}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Address</TableCell>
												<TableCell>{data?.address}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Country</TableCell>
												<TableCell>{data?.country}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>State</TableCell>
												<TableCell>{data?.state}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>City</TableCell>
												<TableCell>{data?.city}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Passcode</TableCell>
												{/* eslint-disable-next-line no-underscore-dangle */}
												<TableCell>{data?.passcode_}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Date Processed</TableCell>
												<TableCell>{data?.processDate}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>processDate</TableCell>
												<TableCell>{data?.processDate}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>ProcessedBy</TableCell>
												<TableCell>{data?.processedBy}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Date Created</TableCell>
												<TableCell>{data?.createDate}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</Box>
						<Stack
							direction="row"
							spacing={2}
							sx={{
								marginBottom: "1rem",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								sx={{
									paddingLeft: "3rem",
									paddingRight: "3rem",
								}}
								variant="contained"
								onClick={() => {
									handleClose();
								}}
							>
								close
							</Button>
						</Stack>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

import { useContext, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from "@mui/icons-material/Circle";

import ListSubheader from "@mui/material/ListSubheader";

import { SocketContext } from "../../context/chatSocket";
import { drawerWidth } from "../../utils/constants";

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: 0,
	[theme.breakpoints.up("sm")]: {
		width: 0,
	},
	// width: `calc(${theme.spacing(7)} + 1px)`,
	// [theme.breakpoints.up("sm")]: {
	// 	width: `calc(${theme.spacing(8)} + 1px)`,
	// },
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	paddingBottom: "10rem",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const drawerContainerStyle = {
	display: "flex",
	flexDirection: "column",
	// justifyContent: "space-between",
	height: "100%",
};

const chatTypesConntainerStyle = {
	flex: 1,
	// border: "1px solid red",
	overflow: "auto",
};

const subheaderStyle = {
	fontWeight: "bold",
	fontSie: "1rem",
	borderTop: "2px solid #000",
	borderBottom: "2px solid #000",
};

// eslint-disable-next-line no-unused-vars
const SupportChatDrawer = ({ open, setOpen }) => {
	// const [innerWidth, setInnerWidth] = useState(window.innerWidth);

	const { user } = useSelector((state) => state.auth);
	const {
		socket,
		clientId,
		activeQueue,
		supportQueue,
		transferQueue,
		currentRoom,
		setCurrentRoom,
		setIsChatEnded,
	} = useContext(SocketContext);

	// useEffect(() => {
	// 	let isMounted = true;

	// 	function handleResize() {
	// 		setInnerWidth(() => window.innerWidth);
	// 		if (innerWidth < 960 && isMounted) {
	// 			setOpen(false);
	// 		} else {
	// 			setOpen(true);
	// 		}
	// 	}

	// 	window.addEventListener("resize", handleResize);

	// 	return () => {
	// 		window.addEventListener("resize", handleResize);
	// 		isMounted = false;
	// 	};
	// });

	// useEffect(() => {
	// 	let isMounted = true;

	// 	if (innerWidth < 960 && isMounted) {
	// 		setOpen(false);
	// 	}

	// 	return () => {
	// 		isMounted = false;
	// 	};
	// }, [innerWidth]);

	const handleJoinRoom = (client) => {
		if (client.available !== 1) {
			toast.success("This user is currently offline");
			return;
		}
		const payload = {
			method: "join_room",
			clientId,
			fullName: user.fullName,
			emailAddress: user.emailAddress,
			username: user?.username,
			room: client?.room,
			accountType: "ADMIN",
		};

		setCurrentRoom(client?.room);
		setIsChatEnded(false);
		socket.emit("join_room", payload);
	};

	return (
		<Drawer
			variant="permanent"
			open={open}
			// sx={{
			// 	display: {
			// 		xs: "none",
			// 		md: "block",
			// 	},
			// }}
		>
			<Toolbar />
			<Divider />

			<Box sx={drawerContainerStyle}>
				<Box sx={chatTypesConntainerStyle}>
					<List
						component="nav"
						aria-labelledby="support-queue-list-subheader"
						subheader={
							<ListSubheader
								sx={subheaderStyle}
								component="div"
								id="support-queue-list-subheader"
							>
								Active Queue
							</ListSubheader>
						}
					>
						{activeQueue?.map((item) => (
							<Fragment key={item.id}>
								<ListItem
									disablePadding
									key={item.id}
									onClick={() => handleJoinRoom(item)}
								>
									<ListItemButton
										selected={
											item?.room?.toLowerCase() ===
												currentRoom?.toLowerCase() && item?.available === 1
										}
										sx={{
											"&.Mui-selected": {
												backgroundColor: "#002564 !important",
												color: "#fff !important",
											},
										}}
									>
										<ListItemIcon sx={{ minWidth: "fit-content", mr: ".5rem" }}>
											<CircleIcon
												sx={{
													color: `${
														item?.available === 1 ? "green" : "primary.main"
													}`,
													fontSize: ".8rem",
												}}
											/>
										</ListItemIcon>
										<ListItemText primary={item?.customer} />
									</ListItemButton>
								</ListItem>
								<Divider />
							</Fragment>
						))}
					</List>
				</Box>
				<Divider />
				<Box sx={chatTypesConntainerStyle}>
					<List
						component="nav"
						aria-labelledby="support-queue-list-subheader"
						subheader={
							<ListSubheader
								sx={subheaderStyle}
								component="div"
								id="support-queue-list-subheader"
							>
								Support Queue
							</ListSubheader>
						}
					>
						{supportQueue?.map((item) => (
							<Fragment key={item.id}>
								<ListItem
									disablePadding
									key={item.id}
									onClick={() => handleJoinRoom(item)}
								>
									<ListItemButton>
										<ListItemIcon sx={{ minWidth: "fit-content", mr: ".5rem" }}>
											<CircleIcon
												sx={{
													color: `${
														item?.available === 1 ? "green" : "primary.main"
													}`,
													fontSize: ".8rem",
												}}
											/>
										</ListItemIcon>
										<ListItemText primary={item?.customer} />
									</ListItemButton>
								</ListItem>
								<Divider />
							</Fragment>
						))}
					</List>
				</Box>
				<Divider />
				<Box sx={chatTypesConntainerStyle}>
					<List
						component="nav"
						aria-labelledby="support-queue-list-subheader"
						subheader={
							<ListSubheader
								sx={subheaderStyle}
								component="div"
								id="support-queue-list-subheader"
							>
								Transfer Queue
							</ListSubheader>
						}
					>
						{transferQueue?.map((item) => (
							<Fragment key={item.id}>
								<ListItem disablePadding onClick={() => handleJoinRoom(item)}>
									<ListItemButton>
										<ListItemIcon sx={{ minWidth: "fit-content", mr: ".5rem" }}>
											<CircleIcon
												sx={{
													color: `${
														item?.available === 1 ? "green" : "primary.main"
													}`,
													fontSize: ".8rem",
												}}
											/>
										</ListItemIcon>
										<ListItemText primary={item?.customer} />
									</ListItemButton>
								</ListItem>
								<Divider />
							</Fragment>
						))}
					</List>
				</Box>
			</Box>
		</Drawer>
	);
};

export default SupportChatDrawer;

/* eslint-disable */
import { useState } from "react";

import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	CircularProgress,
	TablePagination,
	VisibilityIcon,
	IconButton,
} from "../common/muiComponents";
import { formatNumberToDecimalPlace } from "../../utils/helpers/functions";

const tableCellStyle = {
	// fontSize: "0.6875rem",
	// lineHeight: "1.25rem",
	// letterSpacing: "0.03125rem",
};

const columns = [
	{ id: "symbol", label: "Symbol", minWidth: 100 },
	{
		id: "pclose",
		label: "Prev Close (₦)",
		minWidth: 100,
	},
	{
		id: "open",
		label: "Open",
		minWidth: 100,
	},
	{
		id: "high",
		label: "High (₦)",
		minWidth: 100,
	},
	{
		id: "low",
		label: "Low (₦)",
		minWidth: 100,
	},
	{
		id: "change",
		label: "Change (₦)",
		minWidth: 100,
	},
	{
		id: "volume",
		label: "Volume",
		minWidth: 100,
	},
	{
		id: "Action",
		label: "Action",
		minWidth: 100,
	},
];

const CorporateResultsTable = ({
	loading,
	data,
	selectedRow,
	setSelectedRow,
	handleStockSelect,
	handleViewDetails,
}) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<Paper
			sx={{
				width: "100%",
				overflow: "hidden",
				marginBottom: "3rem",
			}}
		>
			<TableContainer
				sx={{
					maxHeight: 440,
					minHeight: 440,
				}}
			>
				<Table
					stickyHeader
					aria-label="sticky table"
					size="small"
					sx={{
						scrollbarWidth: "none",
						msOverflowStyle: "none",
						"&::-webkit-scrollbar": {
							display: "none",
						},
					}}
				>
					<TableHead>
						<TableRow>
							{columns
								?.filter((col) => col.id === "symbol")
								.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
										sx={{
											backgroundColor: "primary.main",
											color: "#fff",
											position: "sticky",
											left: 0,
											zIndex: "3 !important",
											boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
										}}
									>
										{column.label}
									</TableCell>
								))}
							{columns
								?.filter((col) => col.id !== "symbol")
								.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
										sx={{
											backgroundColor: "primary.main",
											color: "#fff",
										}}
									>
										{column.label}
									</TableCell>
								))}
						</TableRow>
					</TableHead>
					<TableBody
						sx={{
							scrollbarWidth: "none",
							msOverflowStyle: "none",
							"&::-webkit-scrollbar": {
								display: "none",
							},
						}}
					>
						{!loading &&
							data
								?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								?.map((item, index) => (
									<TableRow
										key={index}
										hover
										role="checkbox"
										tabIndex={-1}
										onClick={() => {
											handleStockSelect(item);
											setSelectedRow(item);
										}}
										selected={item?.name === selectedRow?.name}
										sx={{ cursor: "pointer" }}
									>
										<TableCell
											sx={{
												...tableCellStyle,
												position: "sticky",
												left: 0,
												zIndex: "2 !important",
												background:
													item?.name === selectedRow?.name
														? "#EBC2C4"
														: "white",
												boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
											}}
										>
											{item?.symbol}
										</TableCell>
										<TableCell sx={tableCellStyle}>
											{formatNumberToDecimalPlace(Number(item?.pclose), 4)}
										</TableCell>
										<TableCell sx={tableCellStyle}>
											{formatNumberToDecimalPlace(Number(item?.open), 4)}
										</TableCell>
										<TableCell sx={tableCellStyle}>
											{formatNumberToDecimalPlace(Number(item?.high), 4)}
										</TableCell>
										<TableCell sx={tableCellStyle}>
											{formatNumberToDecimalPlace(Number(item?.low), 4)}
										</TableCell>
										<TableCell sx={tableCellStyle}>
											{formatNumberToDecimalPlace(Number(item?.change), 4)}
										</TableCell>
										<TableCell sx={tableCellStyle}>
											{formatNumberToDecimalPlace(Number(item?.volume), 4)}
										</TableCell>
										<TableCell>
											<IconButton
												onClick={(evt) => {
													evt.stopPropagation();
													setSelectedRow(item);
													handleViewDetails();
												}}
											>
												<VisibilityIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
					</TableBody>
				</Table>
			</TableContainer>
			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						marginTop: "2rem",
						marginBottom: "2rem",
					}}
				>
					<CircularProgress size="2rem" />
				</Box>
			)}
			{!loading && data?.length === 0 && (
				<Box sx={{ textAlign: "center", marginTop: "2rem" }}>No data</Box>
			)}
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={data.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
};

export default CorporateResultsTable;

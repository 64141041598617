import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/AdvertsTable";
import ViewAdvertModal from "../../../components/common/ViewAdvertModal";
import ApproveModal from "../../../components/common/ApproveModal";
import RejectModal from "../../../components/common/RejectModal";
import DeleteModal from "../../../components/common/Deletemodal";
import {
	fetchAdvertImages,
	approveAdvertImage,
	rejectAdvertImage,
	deleteAdvertImage,
} from "../../../api/admin/dashboard";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const Adverts = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [approveModalOpen, setApproveModalOpen] = useState(false);
	const [rejectModalOpen, setRejectModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [comment, setComment] = useState("");
	const [pendingAdverts, setPendingAdverts] = useState([]);
	const [rejectedAdverts, setRejectedAdverts] = useState([]);
	const [paidAdverts, setPaidAdverts] = useState([]);
	const [approvedUnpaidAdverts, setApprovedUnpaidAdverts] = useState([]);
	const [currentTab, setCurrentTab] = useState(0);
	const [expiredAdverts, setExpiredAdverts] = useState([]);

	const { user, region } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: advertsData = [],
		isLoading: advertsLoading,
		isError: advertsError,
		error: advertsErrorMessage,
	} = useQuery(
		["fetch-adverts", region?.region],
		async () => fetchAdvertImages(region?.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (advertsError) {
			if (advertsErrorMessage.response) {
				toast.error(advertsErrorMessage.response.data?.message);
			} else if (advertsErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", advertsErrorMessage.message);
			}
		}
	}, [advertsError, advertsErrorMessage]);

	useEffect(() => {
		if (advertsData.length && !advertsLoading) {
			if (currentTab === 0) {
				if (searchTerm) {
					const paid = advertsData.filter(
						(item) =>
							Number(item.status) === 1 &&
							Number(item.paid) === 1 &&
							item.username.toLowerCase().includes(searchTerm.toLowerCase())
					);
					setPaidAdverts(paid);
					return;
				}
				const paid = advertsData.filter(
					(item) => Number(item.status) === 1 && Number(item.paid) === 1
				);
				setPaidAdverts(paid);
			}
			if (currentTab === 1) {
				if (searchTerm) {
					const approvedUnpaid = advertsData.filter(
						(item) =>
							Number(item.status) === 1 &&
							Number(item.paid) === 0 &&
							item.username.toLowerCase().includes(searchTerm.toLowerCase())
					);
					setApprovedUnpaidAdverts(approvedUnpaid);
					return;
				}
				const approvedUnpaid = advertsData.filter(
					(item) => Number(item.status) === 1 && Number(item.paid) === 0
				);
				setApprovedUnpaidAdverts(approvedUnpaid);
			}
			if (currentTab === 2) {
				if (searchTerm) {
					const pending = advertsData.filter(
						(item) =>
							Number(item.status) === 0 &&
							item.username.toLowerCase().includes(searchTerm.toLowerCase())
					);
					setPendingAdverts(pending);
					return;
				}
				const pending = advertsData.filter((item) => Number(item.status) === 0);
				setPendingAdverts(pending);
			}
			if (currentTab === 3) {
				if (searchTerm) {
					const rejected = advertsData.filter(
						(item) =>
							Number(item.status) === 2 &&
							item.username.toLowerCase().includes(searchTerm.toLowerCase())
					);
					setRejectedAdverts(rejected);
					return;
				}
				const rejected = advertsData.filter(
					(item) => Number(item.status) === 2
				);
				setRejectedAdverts(rejected);
			}
			if (currentTab === 4) {
				if (searchTerm) {
					const expired = advertsData.filter(
						(item) => Number(item.status) === 3
					);
					setExpiredAdverts(expired);
					return;
				}
				const expired = advertsData.filter((item) => Number(item.status) === 3);
				setExpiredAdverts(expired);
			}
		}
	}, [searchTerm, advertsData, currentTab, advertsLoading]);

	const { mutate: approveAdvert, isLoading: approveAdvertLoading } =
		useMutation("approve-advert", approveAdvertImage, {
			onSuccess: (dataSuccess) => {
				setApproveModalOpen(false);
				setSelectedItem({});
				setComment("");
				toast.success(dataSuccess?.message);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("fetch-adverts");
			},
		});

	const handleApprove = async () => {
		const payload = {
			customerID: selectedItem.customerID,
			ID: selectedItem.id,
			username: user.username,
			region: region?.region,
			admin: user.fullName,
			comment,
			emailAddress: selectedItem?.emailAddress,
			fullName: selectedItem.fullName || "NA",
			title: selectedItem.title || "NA",
			advertID: selectedItem.advertID,
		};
		await approveAdvert(payload);
	};

	const { mutate: rejectAdvert, isLoading: rejectAdvertLoading } = useMutation(
		"reject-advert",
		rejectAdvertImage,
		{
			onSuccess: (dataSuccess) => {
				setRejectModalOpen(false);
				setSelectedItem({});
				setComment("");
				toast.success(dataSuccess?.message);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("fetch-adverts");
			},
		}
	);

	const handleReject = async () => {
		const payload = {
			customerID: selectedItem.customerID,
			ID: selectedItem.id,
			username: user.username,
			region: region?.region,
			admin: user.fullName,
			comment,
			emailAddress: selectedItem?.emailAddress,
			fullName: selectedItem.fullName || "NA",
			title: selectedItem.title || "NA",
			advertID: selectedItem.advertID,
		};

		await rejectAdvert(payload);
	};

	const { mutate: deleteAdvert, isLoading: deleteAdvertLoading } = useMutation(
		"delete-advert",
		deleteAdvertImage,
		{
			onSuccess: (dataSuccess) => {
				setDeleteModalOpen(false);
				setSelectedItem({});
				toast.success(dataSuccess?.message);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("fetch-adverts");
			},
		}
	);

	const handleDelete = async () => {
		const payload = {
			customerID: selectedItem.customerID,
			ID: selectedItem.id,
			username: user.username,
			region: region?.region,
			admin: user.fullName,
			position: selectedItem.position,
		};
		await deleteAdvert(payload);
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="adverts"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					label="Search username"
				/>
			</Box>

			<Box sx={{ width: "100%", mb: "1rem" }}>
				<Box>
					<Tabs
						value={currentTab}
						onChange={(event, newValue) => {
							setCurrentTab(newValue);
						}}
						aria-label="advert tabs"
						variant="scrollable"
						scrollButtons
						allowScrollButtonsMobile
					>
						<Tab label="Active Adverts" {...a11yProps(0)} />
						<Tab label="Unpaid Adverts" {...a11yProps(1)} />
						<Tab label="Pending Adverts" {...a11yProps(2)} />
						<Tab label="Rejected Adverts" {...a11yProps(3)} />
						<Tab label="Expired Adverts" {...a11yProps(4)} />
					</Tabs>
				</Box>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				{currentTab === 0 && (
					<Table
						data={paidAdverts}
						loading={advertsLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setViewDetailsModalOpen(true)}
						disableOtherActions
						currentTab={currentTab}
					/>
				)}
				{currentTab === 1 && (
					<Table
						data={approvedUnpaidAdverts}
						loading={advertsLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setViewDetailsModalOpen(true)}
						disableOtherActions
						currentTab={currentTab}
					/>
				)}
				{currentTab === 2 && (
					<Table
						data={pendingAdverts}
						loading={advertsLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setViewDetailsModalOpen(true)}
						handleApprove={() => setApproveModalOpen(true)}
						handleReject={() => setRejectModalOpen(true)}
						handleDelete={() => setDeleteModalOpen(true)}
						disableOtherActions
						currentTab={currentTab}
					/>
				)}
				{currentTab === 3 && (
					<Table
						data={rejectedAdverts}
						loading={advertsLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setViewDetailsModalOpen(true)}
						disableOtherActions
						currentTab={currentTab}
					/>
				)}
				{currentTab === 4 && (
					<Table
						data={expiredAdverts}
						loading={advertsLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setViewDetailsModalOpen(true)}
						disableOtherActions
						currentTab={currentTab}
					/>
				)}
				<ViewAdvertModal
					open={viewDetailsModalOpen}
					handleClose={() => {
						setViewDetailsModalOpen(false);
						setSelectedItem({});
					}}
					title="View Advert Details"
					data={selectedItem}
				/>
				<ApproveModal
					open={approveModalOpen}
					handleClose={() => setApproveModalOpen(false)}
					title="Approve Advert"
					subtitle=""
					handleApprove={handleApprove}
					loading={approveAdvertLoading}
					comment={comment}
					setComment={setComment}
				/>
				<RejectModal
					open={rejectModalOpen}
					handleClose={() => setRejectModalOpen(false)}
					title="Reject Advert"
					subtitle=""
					handleReject={handleReject}
					loading={rejectAdvertLoading}
					comment={comment}
					setComment={setComment}
				/>
				<DeleteModal
					open={deleteModalOpen}
					handleClose={() => setDeleteModalOpen(false)}
					title="Delete Advert"
					subtitle=""
					handleDelete={handleDelete}
					loading={deleteAdvertLoading}
				/>
			</Box>
		</Box>
	);
};

export default Adverts;

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import closeIcon from "../../assets/icons/close-icon.svg";
import logo from "../../assets/logos/smart-deals-logo.svg";

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	data,
}) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
						maxWidth: "60rem",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box sx={{ height: "5.25rem" }}>
							<img
								src={logo}
								height="100%"
								width="auto"
								alt="Cardinalstone logo"
							/>
						</Box>
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						textAlign: "center",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "2rem",
							pb: "2rem",
							width: "30rem",
						}}
					>
						<Box
							sx={{
								mb: "1rem",
								width: "100%",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<Box sx={{ mb: "1rem" }}>
								<Typography
									variant="h6"
									component="p"
									sx={{ textAlign: "center" }}
									gutterBottom
								>
									Message 1
								</Typography>
								<Typography
									variant="body1"
									component="p"
									sx={{ textAlign: "center" }}
								>
									{data.message1}
								</Typography>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<Typography
									variant="h6"
									component="p"
									sx={{ textAlign: "center" }}
									gutterBottom
								>
									Message 2
								</Typography>
								<Typography
									variant="body1"
									component="p"
									sx={{ textAlign: "center" }}
								>
									{data.message2}
								</Typography>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<Typography
									variant="h6"
									component="p"
									sx={{ textAlign: "center" }}
									gutterBottom
								>
									Message 3
								</Typography>
								<Typography
									variant="body1"
									component="p"
									sx={{ textAlign: "center" }}
								>
									{data.message3}
								</Typography>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<Typography
									variant="h6"
									component="p"
									sx={{ textAlign: "center" }}
									gutterBottom
								>
									Message 4
								</Typography>
								<Typography
									variant="body1"
									component="p"
									sx={{ textAlign: "center" }}
								>
									{data.message4}
								</Typography>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<Typography
									variant="h6"
									component="p"
									sx={{ textAlign: "center" }}
									gutterBottom
								>
									Message 5
								</Typography>
								<Typography
									variant="body1"
									component="p"
									sx={{ textAlign: "center" }}
								>
									{data.message5}
								</Typography>
							</Box>
						</Box>
						<Stack
							direction="row"
							spacing={2}
							sx={{
								marginBottom: "1rem",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								sx={{
									paddingLeft: "3rem",
									paddingRight: "3rem",
								}}
								variant="contained"
								onClick={() => {
									handleClose();
								}}
							>
								close
							</Button>
						</Stack>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

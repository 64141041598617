import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/ContactUsTable";
import ContactUsModal from "../../../components/common/ContactUsModal";
import { downloadExcel } from "../../../utils/helpers/functions";
import { getContactUs } from "../../../api/admin/dashboard";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const ContactUs = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [openViewDetailsModal, setOpenViewDetailsModal] = useState(false);

	const { region } = useSelector((state) => state.auth);

	const {
		data: contatcUsData = [],
		isLoading: contatcUsLoading,
		isError: contatcUsError,
		error: contatcUsErrorMessage,
	} = useQuery(
		["get-contact-us", region.region],
		async () => getContactUs(region.region),
		{
			select: (data) => data?.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (contatcUsError) {
			if (contatcUsErrorMessage.response) {
				toast.error(contatcUsErrorMessage.response.data?.message);
			} else if (contatcUsErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", contatcUsErrorMessage.message);
			}
		}
	}, [contatcUsError, contatcUsErrorMessage]);

	useEffect(() => {
		if (contatcUsData.length && !contatcUsLoading) {
			setDisplayed(contatcUsData);
			setAllData(contatcUsData);
		}
	}, [contatcUsData, contatcUsLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.emailAddress?.toLowerCase().includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="contact us"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Contact Us List"
				/>
			</Box>

			<Box>
				<Table
					data={displayed}
					loading={contatcUsLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setOpenViewDetailsModal(true)}
				/>
			</Box>

			<ContactUsModal
				open={openViewDetailsModal}
				handleClose={() => setOpenViewDetailsModal(false)}
				data={selectedItem}
			/>
		</Box>
	);
};

export default ContactUs;

import { Link } from "react-router-dom";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import POPPERLINKS from "../../constants/PopperLinks";

const popperItemStyles = {
	textDecoration: "none",
	color: "#696969",
	fontSize: {
		md: "0.8rem",
		xs: "0.7rem",
	},
};

const LifeStylePopper = ({ isTabsOpen, tabsAnchorEl, setTabsAnchorEl }) => {
	return (
		<Popper
			open={isTabsOpen}
			anchorEl={tabsAnchorEl}
			id="menu-list-grow"
			onMouseLeave={() => setTabsAnchorEl(null)}
			onClose={() => setTabsAnchorEl(null)}
			onTouchEnd={() => setTabsAnchorEl(null)}
			sx={{
				paddingTop: "0.45rem",
				display: "flex",
				justifyContent: "center",
				hieght: "100%",
				overflowY: "scroll",
			}}
		>
			<Paper
				elevation={1}
				sx={{
					width: "80%",
					padding: {
						md: "1rem 2rem",
						xs: "1rem 1rem 8rem 1rem",
						maxHeight: "100vh",
						overflow: "auto",
					},
				}}
			>
				<Box sx={{ flexGrow: 1 }}>
					<Grid
						container
						spacing={{ md: 10, xs: 5 }}
						sx={{ fontFamily: "Montserrat" }}
					>
						{/* First Grid */}
						<Grid item xs={12} md={4}>
							<Grid
								container
								sx={{
									mb: {
										xs: "1rem",
									},
								}}
							>
								<Grid
									item
									xs={12}
									sx={{
										borderBottom: "1px solid #CCCCCC",
										paddingBottom: "0.5rem",
										mb: "0.5rem",
										mr: "1rem",
									}}
								>
									<Grid
										sx={{
											fontWeight: "600",
											fontSize: "0.875rem",
										}}
										item
									>
										HEALTH &amp; BEAUTY
									</Grid>
								</Grid>
								{POPPERLINKS.lifestyle.sections.healthBeauty.map(
									(item, index) => (
										<Grid
											item
											xs={12}
											sx={popperItemStyles}
											component={Link}
											to={item.link}
											key={index}
										>
											{item.label}
										</Grid>
									)
								)}
							</Grid>
							<Grid container>
								<Grid
									item
									xs={12}
									sx={{
										borderBottom: "1px solid #CCCCCC",
										paddingBottom: "0.5rem",
										mb: "0.5rem",
										mr: "1rem",
									}}
								>
									<Grid
										sx={{
											fontWeight: "600",
											fontSize: "0.875rem",
										}}
										item
									>
										VITAMINS &amp; DIETARY SUPPLEMENTS
									</Grid>
								</Grid>
								{POPPERLINKS.lifestyle.sections.vitamnins.map((item, index) => (
									<Grid
										item
										xs={12}
										sx={popperItemStyles}
										component={Link}
										to={item.link}
										key={index}
									>
										{item.label}
									</Grid>
								))}
							</Grid>
						</Grid>
						{/* Second Grid */}
						<Grid item xs={12} md={4}>
							<Grid
								container
								sx={{
									mb: {
										xs: "1rem",
									},
								}}
							>
								<Grid
									item
									xs={12}
									sx={{
										borderBottom: "1px solid #CCCCCC",
										paddingBottom: "0.5rem",
										mb: "0.5rem",
										mr: "1rem",
									}}
								>
									<Grid
										sx={{
											fontWeight: "600",
											fontSize: "0.875rem",
										}}
										item
									>
										BEAUTY &amp; PERSONAL CARE
									</Grid>
								</Grid>
								{POPPERLINKS.lifestyle.sections.beautyCare.map(
									(item, index) => (
										<Grid
											item
											xs={12}
											sx={popperItemStyles}
											component={Link}
											to={item.link}
											key={index}
										>
											{item.label}
										</Grid>
									)
								)}
							</Grid>
							<Grid container>
								<Grid
									item
									xs={12}
									sx={{
										borderBottom: "1px solid #CCCCCC",
										paddingBottom: "0.5rem",
										mb: "0.5rem",
										mr: "1rem",
									}}
								>
									<Grid
										sx={{
											fontWeight: "600",
											fontSize: "0.875rem",
										}}
										item
									>
										MEDICAL SUPPLIES &amp; EQUIPMENT
									</Grid>
								</Grid>
								{POPPERLINKS.lifestyle.sections.medicalEquipment.map(
									(item, index) => (
										<Grid
											item
											xs={12}
											sx={popperItemStyles}
											component={Link}
											to={item.link}
											key={index}
										>
											{item.label}
										</Grid>
									)
								)}
							</Grid>
						</Grid>
						{/* Third Grid */}
						<Grid item xs={12} md={4}>
							<Grid
								container
								sx={{
									mb: {
										xs: "1rem",
									},
								}}
							>
								<Grid
									item
									xs={12}
									sx={{
										borderBottom: "1px solid #CCCCCC",
										paddingBottom: "0.5rem",
										mb: "0.5rem",
										mr: "1rem",
									}}
								>
									<Grid
										sx={{
											fontWeight: "600",
											fontSize: "0.875rem",
										}}
										item
									>
										HEALTH CARE
									</Grid>
								</Grid>
								{POPPERLINKS.lifestyle.sections.healthCare.map(
									(item, index) => (
										<Grid
											item
											xs={12}
											sx={popperItemStyles}
											component={Link}
											to={item.link}
											key={index}
										>
											{item.label}
										</Grid>
									)
								)}
							</Grid>
							<Grid
								container
								sx={{
									mb: {
										xs: "1rem",
									},
								}}
							>
								<Grid
									item
									xs={12}
									sx={{
										borderBottom: "1px solid #CCCCCC",
										paddingBottom: "0.5rem",
										mb: "0.5rem",
										mr: "1rem",
									}}
								>
									<Grid
										sx={{
											fontWeight: "600",
											fontSize: "0.875rem",
										}}
										item
									>
										SEXUAL WELLNESS
									</Grid>
								</Grid>
								{POPPERLINKS.lifestyle.sections.sexualWellness.map(
									(item, index) => (
										<Grid
											item
											xs={12}
											sx={popperItemStyles}
											component={Link}
											to={item.link}
											key={index}
										>
											{item.label}
										</Grid>
									)
								)}
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Popper>
	);
};

export default LifeStylePopper;

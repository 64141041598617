import { useState } from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import closeIcon from "../../assets/icons/close-icon.svg";
import logo from "../../assets/logos/smart-deals-logo.svg";

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	handleReject,
	loading,
	comment,
	setComment,
}) {
	const [charCount, setCharCount] = useState(0);
	const [charError, setCharError] = useState(false);

	const handleChange = (e) => {
		const text = e.target.value;
		const charLength = text.length;
		if (charLength <= 200) {
			setCharCount(charLength);
			setComment(text);
			setCharError(false);
			return;
		}
		setCharError(true);
	};

	const handlesubmit = async (e) => {
		e.preventDefault();
		if (!comment) {
			toast.error("Please enter reason for rejection");
			return;
		}
		await handleReject(comment);
	};
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box sx={{ height: "5.25rem" }}>
							<img
								src={logo}
								height="100%"
								width="auto"
								alt="Cardinalstone logo"
							/>
						</Box>
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						textAlign: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "2rem",
						}}
					>
						<Typography
							variant="body2"
							sx={{
								fontWeight: 700,
								textTransform: "uppercase",
							}}
							gutterBottom
							component="div"
						>
							REASON FOR REJECTION
						</Typography>
						<Box
							component="form"
							sx={{
								"& .MuiTextField-root": { m: 1, width: "45ch" },
							}}
							noValidate
							autoComplete="off"
							onSubmit={handlesubmit}
						>
							<TextField
								id="outlined-multiline-static"
								label="Input reason for rejection"
								multiline
								rows={4}
								value={comment}
								onChange={handleChange}
								helperText={`Remaining characters ${200 - charCount}`}
								error={charError}
							/>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									marginTop: "1rem",
								}}
							>
								<Stack direction="row" spacing={2} sx={{ alignSelf: "center" }}>
									<Button
										sx={{
											backgroundColor: "#D8281F",
											paddingLeft: "2rem",
											paddingRight: "2rem",
										}}
										variant="contained"
										type="submit"
										disabled={loading}
									>
										{loading ? (
											<CircularProgress size="1.5rem" />
										) : (
											"confirm rejection"
										)}
									</Button>
									<Button
										sx={{
											backgroundColor: "#002564",
											paddingLeft: "3rem",
											paddingRight: "3rem",
											"&:hover": {
												backgroundColor: "#002564",
											},
										}}
										variant="contained"
										onClick={() => {
											handleClose();
										}}
									>
										cancel
									</Button>
								</Stack>
							</Box>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

import { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import {
	Box,
	TextField,
	Typography,
	InputAdornment,
	FileUploadOutlinedIcon,
} from "../../../components/common/muiComponents";
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import SelectFieldComponent from "../../../components/common/SelectFieldComponent";
import DateComponent from "../../../components/common/DateComponent";
import SubmitButton from "../../../components/common/SubmitButton";
import {
	storeNoticeImage,
	createNotice,
	getAccountType,
} from "../../../api/admin/dashboard";
import { newDateFormatter } from "../../../utils/helpers/functions";

const fields = {
	title: "",
	message: "",
	accountType: "",
	files: null,
	noticeDate: new Date(),
};

const validationSchema = yup.object({
	title: yup
		.string("Please enter the notice title")
		.required("Notice title is required"),
	message: yup
		.string("Please enter message")
		.max(200, "Message should be of maximum of 200 characters long")
		.required("Message is required"),
	files: yup
		.mixed()
		.test("fileSize", "File is too large", (value) => {
			return value && value.length > 0 && value[0].size <= 524288;
		})
		.test("fileType", "Unsupported file format", (value) => {
			return (
				value &&
				value.length > 0 &&
				["image/jpeg", "image/png", "image/jpg"].includes(value[0].type)
			);
		}),
});

const NoticeBoard = () => {
	const { user } = useSelector((state) => state.auth);

	const {
		data: accountTypeData = [],
		// isLoading: accountTypeLoading,
		isError: accountTypeError,
		error: accountTypeErrorMessage,
	} = useQuery(["get-account-type"], async () => getAccountType(), {
		select: (data) => data?.data,
		staleTime: Infinity,
	});

	useEffect(() => {
		if (accountTypeError) {
			if (accountTypeErrorMessage.response) {
				toast.error(accountTypeErrorMessage.response.data?.message);
			} else if (accountTypeErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", accountTypeErrorMessage.message);
			}
		}
	}, [accountTypeError, accountTypeErrorMessage]);

	const handleSubmit = async (values, stopSubmitLoading, resetForm) => {
		try {
			const formData = new FormData();
			formData.append("username", user?.username);
			formData.append("title", values.title);
			formData.append("files", values.files?.[0]);

			const result = await storeNoticeImage(formData);
			const payload = {
				username: user?.username,
				message: values.message,
				accountType: values.accountType,
				logo: result?.url,
				noticeDate: newDateFormatter(values.noticeDate, "yyyy-MM-dd HH:mm:ss"),
			};
			const noticeRsult = await createNotice(payload);
			toast.success(noticeRsult?.message);
			stopSubmitLoading();
			resetForm();
		} catch (err) {
			stopSubmitLoading();
			if (err.response) {
				toast.error(err.response.data.message);
			} else if (err.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", err.message);
			}
		}
	};

	const formik = useFormik({
		initialValues: fields,
		validationSchema,
		onSubmit: (values) => {
			handleSubmit(
				values,
				() => formik.setSubmitting(false),
				() => formik.resetForm()
			);
		},
	});

	return (
		<Box
			sx={{
				minHeight: "100vh",
				backgroundColor: "#fff",
				position: "relative",
			}}
		>
			<Box
				sx={{
					padding: { md: "3rem", sm: "1rem", xs: 0 },
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							width: "33rem",
							border: "1px solid #E6E6E6",
							borderRadius: "8px",
							pb: { md: "2rem", xs: "1rem" },
						}}
					>
						<Typography
							variant="h6"
							sx={{
								textAlign: "center",
								fontWeight: 700,
								textTransform: "capitalize",
								color: "#303030",
								p: { md: "2rem", xs: "1rem" },
							}}
						>
							create notice board
						</Typography>
						<Box
							component="form"
							sx={{
								p: { md: "1rem 3rem", xs: "1rem" },
								// border: "1px solid red",
							}}
							noValidate
							autoComplete="off"
							onSubmit={formik.handleSubmit}
						>
							<Box sx={{ mb: "1rem" }}>
								<TextFieldComponent
									value={formik.values.title}
									onChange={formik.handleChange}
									required
									label="Title"
									name="title"
									error={formik.touched.title && Boolean(formik.errors.title)}
									helperText={formik.touched.title && formik.errors.title}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<TextFieldComponent
									value={formik.values.message}
									onChange={formik.handleChange}
									required
									label="Message"
									name="message"
									error={
										formik.touched.message && Boolean(formik.errors.message)
									}
									helperText={formik.touched.message && formik.errors.message}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<SelectFieldComponent
									width="100%"
									values={accountTypeData}
									value={formik.values.accountType}
									onChange={formik.handleChange}
									label="Account Type"
									id="accountType"
									name="accountType"
									menuValue="code"
									displayValue="name"
									error={
										formik.touched.accountType &&
										Boolean(formik.errors.accountType)
									}
									helperText={
										formik.touched.accountType && formik.errors.accountType
									}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<DateComponent
									value={formik.noticeDate}
									onChange={(newValue) =>
										formik.setFieldValue("noticeDate", newValue)
									}
									label="Notice Date"
									id="NoticeDate"
									name="noticeDate"
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<TextField
									type="file"
									onChange={(event) => {
										formik.setFieldValue("files", event.currentTarget.files);
									}}
									label={
										formik.values.files?.[0]?.name || "Click to upload image"
									}
									id="files"
									name="files"
									required
									InputLabelProps={{
										shrink: false,
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position="start">
												<FileUploadOutlinedIcon />
											</InputAdornment>
										),
										accept: "image/*",
									}}
									// eslint-disable-next-line react/jsx-no-duplicate-props
									inputProps={{ accept: "image/*" }}
									sx={{
										input: {
											opacity: 0,
										},
										label: {
											color: "#594E4E !important",
										},
										"& .MuiOutlinedInput-notchedOutline": {
											border: "1px solid rgba(0, 0, 0, 0.23) !important",
										},
									}}
									fullWidth
									error={formik.touched.files && Boolean(formik.errors.files)}
									helperText={
										(formik.touched.files && formik.errors.files) ||
										"Image must be of format jpg, png or jpeg"
									}
								/>
							</Box>

							<SubmitButton
								text="submit"
								loading={formik.isSubmitting}
								disabled={!formik.dirty || formik.isSubmitting}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default NoticeBoard;

import { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/DelayedTransactionsTable";
import ViewDetailsModal from "../../../components/common/ViewDelayedTransactionsModal";
import DelayedTransactionsTreatmentModal from "../../../components/common/DelayedTransactionsTreatmentModal";
import {
	getDelayedTransactions,
	resolveDelayedTransaction,
	reverseDelayedTransaction,
} from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const DelayedTransactions = () => {
	const [selectedItem, setSelectedItem] = useState({});
	const [searchTerm, setSearchTerm] = useState("");
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [openResolutionModal, setOpenResolutionModal] = useState(false);
	const [openReversalModal, setOpenReversalModal] = useState(false);
	const [comment, setComment] = useState("");

	const { user, region } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: ddelayedTransactionsData = [],
		isLoading: ddelayedTransactionsLoading,
		isError: ddelayedTransactionsError,
		error: ddelayedTransactionsErrorMessage,
	} = useQuery(
		["get-admin-delayed-transactions", region?.region],
		async () =>
			getDelayedTransactions({
				region: region?.region,
				username: user?.username,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region),
		}
	);

	useEffect(() => {
		if (ddelayedTransactionsError) {
			if (ddelayedTransactionsErrorMessage.response) {
				toast.error(ddelayedTransactionsErrorMessage.response.data?.message);
			} else if (ddelayedTransactionsErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", ddelayedTransactionsErrorMessage.message);
			}
		}
	}, [ddelayedTransactionsError, ddelayedTransactionsErrorMessage]);

	const {
		mutate: resolveDelayedTransactionMutation,
		isLoading: resolveDelayedTransactionLoading,
	} = useMutation("resolve-transaction", resolveDelayedTransaction, {
		onSuccess: (dataSuccess) => {
			toast.success(dataSuccess?.message);
			setSelectedItem({});
			setOpenResolutionModal(false);
			setComment("");
		},
		onError: (errors) => {
			if (errors.response) {
				toast.error(errors.response.data.message);
			} else if (errors.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", errors.message);
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries("get-admin-delayed-transactions");
		},
	});

	const {
		mutate: reverseDelayedTransactionMutation,
		isLoading: reverseDelayedTransactionLoading,
	} = useMutation("reverse-transaction", reverseDelayedTransaction, {
		onSuccess: (dataSuccess) => {
			toast.success(dataSuccess?.message);
			setSelectedItem({});
			setOpenReversalModal(false);
			setComment("");
		},
		onError: (errors) => {
			if (errors.response) {
				toast.error(errors.response.data.message);
			} else if (errors.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", errors.message);
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries("get-admin-delayed-transactions");
		},
	});

	const handleResolveTransaction = async () => {
		const payload = {
			id: selectedItem.id,
			username: user.username,
			comment,
		};

		await resolveDelayedTransactionMutation(payload);
	};

	const handleReseverseTransaction = async () => {
		const payload = {
			id: selectedItem.id,
			username: user.username,
			comment,
		};

		await reverseDelayedTransactionMutation(payload);
	};

	const displayed = useMemo(() => {
		if (ddelayedTransactionsData.length) {
			let filteredData = [...ddelayedTransactionsData];
			if (searchTerm) {
				filteredData = filteredData.filter((partner) => {
					return (
						partner.partnerName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.username
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.emailAddress
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.phoneNumber
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredData;
		}
		return ddelayedTransactionsData;
	}, [ddelayedTransactionsData, searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
				width: "100%",
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="delayed transactions"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Transactions"
				/>
			</Box>

			<Box
				sx={{
					// width: { md: "78vw", xs: "95vw" },
					width: "100%",
					overflow: "auto",
					maxWidth: "100%",
					boxSizing: "border-box",
				}}
			>
				<Table
					data={displayed}
					loading={ddelayedTransactionsLoading}
					setSelectedItem={setSelectedItem}
					handleResolveTransaction={() => setOpenResolutionModal(true)}
					handleReseverseTransaction={() => setOpenReversalModal(true)}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</Box>
			<ViewDetailsModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Delayed Transactions Details"
				data={selectedItem}
			/>
			<DelayedTransactionsTreatmentModal
				open={openResolutionModal}
				handleClose={() => {
					setOpenResolutionModal(false);
					setComment("");
				}}
				title="Resolve Transaction"
				subtitle=""
				handleApprove={handleResolveTransaction}
				loading={resolveDelayedTransactionLoading}
				comment={comment}
				setComment={setComment}
				modalTitle="REASON FOR RESOLUTION"
				buttonText="Resolve"
			/>
			<DelayedTransactionsTreatmentModal
				open={openReversalModal}
				handleClose={() => {
					setOpenReversalModal(false);
					setComment("");
				}}
				title="Reverse Transaction"
				subtitle=""
				handleApprove={handleReseverseTransaction}
				loading={reverseDelayedTransactionLoading}
				comment={comment}
				setComment={setComment}
				modalTitle="REASON FOR REVERSAL"
				buttonText="Reverse"
			/>
		</Box>
	);
};

export default DelayedTransactions;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import {
	CircularProgress,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Box,
	Typography,
	Stack,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
} from "../common/muiComponents";
import NumberTextField from "../common/NumberTextField";
import SelectFieldComponent from "../common/SelectFieldComponent";
import { fetchNotificationGroup } from "../../api/admin/dashboard";
import closeIcon from "../../assets/icons/close-icon.svg";

const formFieldContainer = {
	mb: "1rem",
};

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	data,
	setData,
	loading,
	handleFormSubmit,
	countriesData,
}) {
	const { user } = useSelector((state) => state.auth);

	const {
		data: notificationGroupData = [],
		// isLoading: notificationGroupLoading,
		isError: notificationGroupError,
		error: notificationGroupErrorMessage,
	} = useQuery(
		["get-notification-group"],
		async () => fetchNotificationGroup({ username: user?.username }),
		{
			select: (dataRes) => dataRes?.data?.filter((item) => !!item?.referrer),
			staleTime: Infinity,
		}
	);

	useEffect(() => {
		if (notificationGroupError) {
			if (notificationGroupErrorMessage.response) {
				toast.error(notificationGroupErrorMessage.response.data?.message);
			} else if (notificationGroupErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", notificationGroupErrorMessage.message);
			}
		}
	}, [notificationGroupError, notificationGroupErrorMessage]);

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography
							variant="h6"
							component="div"
							sx={{
								fontWeight: 700,
								textTransform: "capitalize",
							}}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						textAlign: "center",
						overflowX: "hidden",
					}}
				>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { width: "25ch" },
						}}
						noValidate
						autoComplete="off"
						onSubmit={handleFormSubmit}
					>
						<Box sx={formFieldContainer}>
							<NumberTextField
								label="Deal Duration in hours"
								value={data.dealHours}
								onChange={(e) => {
									setData({
										...data,
										dealHours: e.target.value,
									});
								}}
								fullWidth
							/>
						</Box>
						<Box sx={formFieldContainer}>
							<SelectFieldComponent
								width="100%"
								values={notificationGroupData}
								value={data.group}
								onChange={(e) => {
									setData({
										...data,
										group: e.target.value,
									});
								}}
								label="Group"
								id="group"
								name="group"
								menuValue="referrer"
								displayValue="referrer"
							/>
						</Box>
						<Box sx={formFieldContainer}>
							<SelectFieldComponent
								value={data.region}
								values={countriesData}
								onChange={(e) => {
									setData({
										...data,
										region: e.target.value,
									});
								}}
								label="Choose Region"
								id="short_name"
								name="region"
								displayValue="name"
								menuValue="short_name"
							/>
						</Box>

						<Box sx={formFieldContainer}>
							<FormControl>
								<FormLabel id="promo-label">Promo</FormLabel>
								<RadioGroup
									row
									aria-labelledby="promo-label"
									name="promo"
									value={data.promo}
									onChange={(e) => {
										setData({
											...data,
											promo: e.target.value,
										});
									}}
								>
									<FormControlLabel
										value="yes"
										control={<Radio />}
										label="Yes"
									/>
									<FormControlLabel value="no" control={<Radio />} label="No" />
								</RadioGroup>
							</FormControl>
						</Box>

						<Stack
							direction="row"
							spacing={2}
							sx={{
								marginTop: "1rem",
								marginBottom: "1rem",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								sx={{
									paddingLeft: "2rem",
									paddingRight: "2rem",
									textTransform: "none",
								}}
								variant="contained"
								type="submit"
							>
								{loading ? (
									<CircularProgress size="1.5rem" sx={{ color: "#fff" }} />
								) : (
									"Activate Deal"
								)}
							</Button>
						</Stack>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

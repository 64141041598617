import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import TextFieldComponent from "../common/TextFieldComponent";
import NumberTextField from "../common/NumberTextField";
import SelectFieldComponent from "../common/SelectFieldComponent";
import closeIcon from "../../assets/icons/close-icon.svg";

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	data,
	setData,
	loading,
	handleFormSubmit,
	isEdit = false,
	countriesData,
}) {
	const handleImageChange = (e) => {
		const { name, files } = e.target;
		const reader = new FileReader();
		const file = files[0];
		if (file.size > 500000) {
			toast.error("file is too large");
			return;
		}
		if (file.type !== "image/png") {
			toast.error("Image must be of format jpg, png or jpeg");
			return;
		}
		reader.onloadend = () => {
			if (name === "files") {
				setData({
					...data,
					files: file,
				});
			}
		};

		reader.readAsDataURL(file);
	};
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography
							variant="h6"
							component="div"
							sx={{
								fontWeight: 700,
								textTransform: "capitalize",
							}}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						textAlign: "center",
						overflowX: "hidden",
					}}
				>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						noValidate
						autoComplete="off"
						onSubmit={handleFormSubmit}
					>
						{!isEdit && (
							<TextFieldComponent
								id="subscriptionClass"
								label="Name"
								variant="outlined"
								value={data.subscriptionClass}
								onChange={(e) =>
									setData({
										...data,
										subscriptionClass: e.target.value,
									})
								}
							/>
						)}
						{isEdit && (
							<TextField
								id="subscriptionClass"
								label="Name"
								variant="outlined"
								value={data.subscriptionClass}
								readOnly
							/>
						)}
						<NumberTextField
							id="amount"
							label="Amount"
							variant="outlined"
							value={data.amount}
							onChange={(e) =>
								setData({
									...data,
									amount: e.target.value,
								})
							}
						/>
						<NumberTextField
							id="subscriptionDays"
							label="Number of Days"
							variant="outlined"
							value={data.subscriptionDays}
							onChange={(e) =>
								setData({
									...data,
									subscriptionDays: e.target.value,
								})
							}
						/>
						<NumberTextField
							id="points"
							label="Number of Points"
							variant="outlined"
							value={data.points}
							onChange={(e) =>
								setData({
									...data,
									points: e.target.value,
								})
							}
						/>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Box
								sx={{
									m: 1,
									width: "25ch",
								}}
							>
								<SelectFieldComponent
									value={data.region}
									values={countriesData}
									onChange={(e) => {
										setData({
											...data,
											region: e.target.value,
										});
									}}
									label="Choose Region"
									id="short_name"
									name="region"
									displayValue="name"
									menuValue="short_name"
									required
								/>
							</Box>
						</Box>
						{!isEdit && (
							<Box sx={{ mb: "1rem" }}>
								<TextField
									type="file"
									onChange={handleImageChange}
									label={data.files.name || "Click to upload image"}
									id="files"
									name="files"
									required
									InputLabelProps={{
										shrink: false,
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position="start">
												<FileUploadOutlinedIcon />
											</InputAdornment>
										),
										accept: "application/pdf",
									}}
									// eslint-disable-next-line react/jsx-no-duplicate-props
									inputProps={{ accept: "image/png" }}
									sx={{
										input: {
											opacity: 0,
										},
										label: {
											color: "#594E4E !important",
										},
										"& .MuiOutlinedInput-notchedOutline": {
											border: "1px solid rgba(0, 0, 0, 0.23) !important",
										},
									}}
									fullWidth
									helperText="image format (png)"
								/>
							</Box>
						)}
						<Stack
							direction="row"
							spacing={2}
							sx={{
								marginTop: "1rem",
								marginBottom: "1rem",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								sx={{
									paddingLeft: "2rem",
									paddingRight: "2rem",
								}}
								variant="contained"
								type="submit"
							>
								{loading ? (
									<CircularProgress size="1.5rem" sx={{ color: "#fff" }} />
								) : (
									"save"
								)}
							</Button>
						</Stack>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

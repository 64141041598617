import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import PrivateRoute from "./PrivateRoutes";
import AdminRoutes from "./AdminRoutes";
import Logout from "../../components/common/Logout";
import OtpVerification from "../views/OtpVerification";
import NotFound from "../views/NotFoundPage/NotFoundPage";
import TermsConditions from "../views/TermsConditions";
import PrivacyPolicy from "../views/PrivacyPolicy";
import HowItWorks from "../views/HowItWorks";
import LandingPage from "../views/Admin/LandingPage";

const BaseRoute = () => (
	<Router>
		<Switch>
			<Route path="/logout" component={Logout} />
			<Route path="/otp-verification" exact component={OtpVerification} />
			<Route path="/terms-and-conditions" exact component={TermsConditions} />
			<Route path="/privacy-policy" exact component={PrivacyPolicy} />
			<Route path="/how-it-works" exact component={HowItWorks} />
			<Route path="/landing-page" component={LandingPage} />
			<Route path="/" component={AdminRoutes} />
			<Route path="*" exact component={NotFound} />
		</Switch>
	</Router>
);

export default BaseRoute;

import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Table from "../../../components/Admin/SubscriptionTable";
import SubscriptionModal from "../../../components/Admin/SubscriptionModal";
import {
	fetchSubscriptionClass,
	createSubscriptionClass,
	deleteSubscriptionClass,
	editSubscriptionClass,
} from "../../../api/admin/dashboard";
import { getCountry } from "../../../api/common/location";

const defaultData = {
	subscriptionClass: "",
	amount: "",
	subscriptionDays: "",
	points: "",
	files: "",
	region: "",
};

const SubscriptionPlans = () => {
	const [selectedItem, setSelectedItem] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [addData, setAddData] = useState(defaultData);
	const [editData, setEditData] = useState(defaultData);

	const { user, region, bizFrom, role } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: subscriptionData = [],
		isLoading: subscriptionLoading,
		isError: subscriptionError,
		error: subscriptionErrorMessage,
	} = useQuery(
		["get-admin-subscriptions", region?.region],
		async () => fetchSubscriptionClass(region?.region, bizFrom, role),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region),
		}
	);

	const {
		data: countriesData = [],
		// isLoading: countriesLoading,
		isError: fetchCountriesError,
		error: fetchCountriesErrorMessage,
	} = useQuery(["get-smartdeals-regions"], async () => getCountry(), {
		select: (data) => data.filter((country) => country.list),
		staleTime: 4 * 60 * 1000,
	});

	useEffect(() => {
		if (subscriptionError) {
			if (subscriptionErrorMessage.response) {
				toast.error(subscriptionErrorMessage.response.data?.message);
			} else if (subscriptionErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", subscriptionErrorMessage.message);
			}
		}
		if (fetchCountriesError) {
			if (fetchCountriesErrorMessage.response) {
				toast.error(fetchCountriesErrorMessage.response.data?.message);
			} else if (fetchCountriesErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", fetchCountriesErrorMessage.message);
			}
		}
	}, [
		subscriptionError,
		subscriptionErrorMessage,
		fetchCountriesError,
		fetchCountriesErrorMessage,
	]);

	const { mutate: addPlan, isLoading: addPlanLoading } = useMutation(
		"create-plan",
		createSubscriptionClass,
		{
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setOpen(false);
				setAddData(defaultData);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-subscriptions");
			},
		}
	);

	const { mutate: updatePlan, isLoading: updateLoading } = useMutation(
		"edit-role",
		editSubscriptionClass,
		{
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setOpenEdit(false);
				setEditData(defaultData);
				setSelectedItem(null);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-subscriptions");
			},
		}
	);

	const { mutate: destroyPlan } = useMutation(
		"delete-plan",
		deleteSubscriptionClass,
		{
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setSelectedItem(null);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-subscriptions");
			},
		}
	);

	const handleAddPlan = async (e) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append("admin", user.fullName);
		formData.append("subscriptionClass", addData.subscriptionClass);
		formData.append("subscriptionDays", addData.subscriptionDays);
		formData.append("points", addData.points);
		formData.append("amount", addData.amount);
		formData.append("region", addData?.region);
		formData.append("files", addData.files);
		formData.append("bizFrom", bizFrom);
		formData.append("role", role);

		await addPlan(formData);
	};

	const handleeditSubscriptionClass = async (e) => {
		e.preventDefault();
		const payload = {
			admin: user.fullName,
			region: editData?.region,
			ID: selectedItem.id,
			subscriptionClass: editData.subscriptionClass,
			subscriptionDays: editData.subscriptionDays,
			points: editData.points,
			amount: editData.amount,
			bizFrom,
			role,
		};

		await updatePlan(payload);
	};

	const handledeleteSubscriptionClass = async () => {
		const payload = {
			admin: user.fullName,
			ID: selectedItem.id,
			subscriptionClass: selectedItem.subscriptionClass,
			region: region?.region,
			bizFrom,
			role,
		};
		await destroyPlan(payload);
	};

	useEffect(() => {
		if (selectedItem) {
			setEditData({
				...editData,
				subscriptionClass: selectedItem.subscriptionClass,
				amount: selectedItem.amount,
				subscriptionDays: selectedItem.subscriptionDays,
				points: selectedItem.points,
				region: selectedItem.region || "",
			});
		}
	}, [selectedItem]);

	useEffect(() => {
		if (subscriptionData.length && !subscriptionLoading) {
			setDisplayed(subscriptionData);
			setAllData(subscriptionData);
		}
	}, [subscriptionData, subscriptionLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return item.subscriptionClass
						?.toLowerCase()
						.includes(searchTerm.toLowerCase());
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			{/* header */}
			<Box sx={{ mb: "2rem" }}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: { sm: "row", xs: "column" },
						mb: "1rem",
					}}
				>
					<Typography
						variant="h6"
						sx={{ fontWeight: 700, textTransform: "capitalize", mr: "auto" }}
						gutterBottom
					>
						Admin settings - Subscription plans
					</Typography>

					<Box
						sx={{
							display: "flex",
							// justifyContent: { xs: "flex-start", sm: "flex-end" },
							width: { xs: "100%", sm: "auto" },
						}}
					>
						<Box sx={{ ml: { md: "auto", xs: 0 }, mr: "1rem" }}>
							<TextField
								id="table-search-box"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								label="Search Plans"
								variant="outlined"
								size="small"
								sx={{
									label: {
										color: "#594E4E !important",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										borderColor: "#594E4E !important",
									},
								}}
							/>
						</Box>

						<Box sx={{ ml: { md: "auto", xs: 0 } }}>
							<Button
								variant="contained"
								color="primary"
								sx={{ textTransform: "capitalize" }}
								onClick={() => setOpen(true)}
							>
								add plan
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>

			{/* table */}
			<Box
				sx={{
					// width: { md: "78vw", xs: "95vw" },
					width: "100%",
					overflow: "auto",
					maxWidth: "100%",
					boxSizing: "border-box",
				}}
			>
				<Table
					data={displayed}
					loading={subscriptionLoading}
					setSelectedItem={setSelectedItem}
					handleEdit={() => setOpenEdit(true)}
					handleDelete={handledeleteSubscriptionClass}
				/>
			</Box>

			{/* add modal */}
			<SubscriptionModal
				open={open}
				handleClose={() => setOpen(false)}
				title="create plan"
				data={addData}
				setData={setAddData}
				loading={addPlanLoading}
				handleFormSubmit={handleAddPlan}
				countriesData={countriesData}
			/>
			{/* edit modal */}
			<SubscriptionModal
				open={openEdit}
				handleClose={() => setOpenEdit(false)}
				title="edit plan"
				data={editData}
				setData={setEditData}
				loading={updateLoading}
				handleFormSubmit={handleeditSubscriptionClass}
				countriesData={countriesData}
				isEdit
			/>
		</Box>
	);
};

export default SubscriptionPlans;

import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import TableHeader from "../../../components/Admin/TableHeader";
import CompleteTransferTab from "../../../components/CompleteTransfer/CompleteTransferTab";
import CustomTabPanel from "../../../components/common/CustomTabPanel";
import { fetchMakvidTransfer } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const CompleteTransfer = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [currentTab, setCurrentTab] = useState(0);

	const { region, user, bizFrom, role } = useSelector((state) => state.auth);

	const handleChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const {
		data: transferData = [],
		isLoading: transferLoading,
		isError: transferError,
		error: transferErrorMessage,
	} = useQuery(
		["get-makvid-transfer", region.region],
		async () =>
			fetchMakvidTransfer({
				region: region.region,
				bizFrom,
				role,
				username: user?.username,
			}),

		{
			select: (data) => data?.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (transferError) {
			if (transferErrorMessage.response) {
				toast.error(transferErrorMessage.response.data?.message);
			} else if (transferErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", transferErrorMessage.message);
			}
		}
	}, [transferError, transferErrorMessage]);

	useEffect(() => {
		if (transferData.length && !transferLoading) {
			setDisplayed(transferData);
			setAllData(transferData);
		}
	}, [transferData, transferLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						`${item.amount}`
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						item.requester?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.accountType
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.bizFrom?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.status?.toLowerCase()?.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const displayedPendingPayments = useMemo(() => {
		let filtered = [...transferData]?.filter(
			(item) => item?.status?.toLowerCase() === "pending"
		);
		if (searchTerm) {
			filtered = filtered.filter((item) => {
				return (
					`${item.amount}`?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item.requester?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.accountType?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.bizFrom?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.status?.toLowerCase()?.includes(searchTerm.toLowerCase())
				);
			});
		}
		return filtered;
	}, [transferData, searchTerm]);

	const displayedApprovedPayments = useMemo(() => {
		let filtered = [...transferData]?.filter(
			(item) => item?.status?.toLowerCase() === "approved"
		);
		if (searchTerm) {
			filtered = filtered.filter((item) => {
				return (
					`${item.amount}`?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item.requester?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.accountType?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.bizFrom?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.status?.toLowerCase()?.includes(searchTerm.toLowerCase())
				);
			});
		}
		return filtered;
	}, [transferData, searchTerm]);

	const displayedRejectedPayments = useMemo(() => {
		let filtered = [...transferData]?.filter(
			(item) => item?.status?.toLowerCase() === "rejected"
		);
		if (searchTerm) {
			filtered = filtered.filter((item) => {
				return (
					`${item.amount}`?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item.requester?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.accountType?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.bizFrom?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.status?.toLowerCase()?.includes(searchTerm.toLowerCase())
				);
			});
		}
		return filtered;
	}, [transferData, searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: ".5rem" }}>
				<TableHeader
					text="Transfer Requests"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search requests"
				/>
			</Box>

			<Box sx={{ mb: ".5rem" }}>
				<Tabs
					value={currentTab}
					onChange={handleChange}
					aria-label="transfer request tabs"
				>
					<Tab label="All" {...a11yProps(0)} />
					<Tab label="Pending" {...a11yProps(1)} />
					<Tab label="Approved" {...a11yProps(2)} />
					<Tab label="Rejected" {...a11yProps(3)} />
				</Tabs>
			</Box>

			<CustomTabPanel value={currentTab} index={0}>
				<CompleteTransferTab data={displayed} loading={transferLoading} />
			</CustomTabPanel>
			<CustomTabPanel value={currentTab} index={1}>
				<CompleteTransferTab
					data={displayedPendingPayments}
					loading={transferLoading}
				/>
			</CustomTabPanel>
			<CustomTabPanel value={currentTab} index={2}>
				<CompleteTransferTab
					data={displayedApprovedPayments}
					loading={transferLoading}
				/>
			</CustomTabPanel>
			<CustomTabPanel value={currentTab} index={3}>
				<CompleteTransferTab
					data={displayedRejectedPayments}
					loading={transferLoading}
				/>
			</CustomTabPanel>
		</Box>
	);
};

export default CompleteTransfer;

import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import TextFieldComponent from "../../../components/common/TextFieldComponent";
import SelectFieldComponent from "../../../components/common/SelectFieldComponent";
import AccountDetailsModal from "../../../components/Admin/AccountDetailsModal";
import { getBanks, resolveBankAccount } from "../../../api/admin/dashboard";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const mainStyle = {
	display: "flex",
	justifyContent: "center",
};

const initialFields = {
	accountNumber: "",
	bank: "",
};

const VerifyAccount = () => {
	const { region } = useSelector((state) => state.auth);

	const [selectedBank, setSelectedBank] = useState("");
	const [resolvedBankDetails, setResolvedBankDetails] = useState({});
	const [openConfirmAccountDetailsModal, setOpenConfirmAccountDetailsModal] =
		useState(false);

	const {
		data: banksData = [],
		// isLoading: banksLoading,
		isError: banksError,
		error: banksErrorMessage,
	} = useQuery(
		["get-banks", region.region],
		async () => getBanks(region.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (banksError) {
			if (banksErrorMessage.response) {
				toast.error(banksErrorMessage.response.data?.message);
			} else if (banksErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", banksErrorMessage.message);
			}
		}
	}, [banksError, banksErrorMessage]);

	const validationSchema = yup.object({
		accountNumber: yup
			.number("Please enter account number")
			.required("Account Number is required"),
		bank: yup
			.string("Please choose your bank")
			.required("Bank name is required"),
	});

	const formik = useFormik({
		initialValues: initialFields,
		validationSchema,
		onSubmit: (values) => {
			handleResolveBankDetails(
				values,
				() => formik.resetForm(),
				() => formik.setSubmitting(false)
			);
		},
	});

	const handleResolveBankDetails = async (
		values,
		resetForm,
		stopSubmmitting
	) => {
		try {
			const bankSelected = banksData.find((bank) => bank.ID === values.bank);
			setSelectedBank(bankSelected);
			const result = await resolveBankAccount({
				region: region?.region,
				accountNumber: values.accountNumber,
				bankCode: bankSelected?.iCode,
			});
			if (result?.data?.account_number && result?.data?.account_name) {
				toast.success(result?.message);
				setResolvedBankDetails({
					accountName: result?.data?.account_name,
					accountNumber: result?.data?.account_number,
					bank_id: result?.data?.bank_id,
					bvn: values?.bvn,
				});
				setOpenConfirmAccountDetailsModal(true);
			} else {
				toast.error(result?.message);
			}
			stopSubmmitting();
			resetForm();
		} catch (error) {
			stopSubmmitting();
			if (error.response) {
				toast.error(error.response.data?.message);
			} else if (error.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", error.message);
			}
		}
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: { sm: "row", xs: "column" },
					}}
				>
					<Typography
						variant="h6"
						sx={{ fontWeight: 700, textTransform: "capitalize", mr: "auto" }}
						gutterBottom
					>
						verify account
					</Typography>
				</Box>
			</Box>

			<Typography
				variant="h6"
				component="div"
				sx={{
					fontWeight: 700,
					textAlign: "center",
					textTransform: "capitalize",
					mb: "1rem",
				}}
			>
				enter bank details
			</Typography>

			<Box sx={mainStyle}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginBottom: "2rem",
						width: "30rem",
					}}
				>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						sx={{ width: "80%" }}
						onSubmit={formik.handleSubmit}
					>
						<Box sx={{ mb: "1rem" }}>
							<TextFieldComponent
								width="100%"
								value={formik.values.accountNumber}
								onChange={formik.handleChange}
								label="Account Number"
								id="accountNumber"
								name="accountNumber"
								required
								error={
									formik.touched.accountNumber &&
									Boolean(formik.errors.accountNumber)
								}
								helperText={
									formik.touched.accountNumber && formik.errors.accountNumber
								}
							/>
						</Box>

						<Box sx={{ mb: "1rem" }}>
							<SelectFieldComponent
								width="100%"
								values={banksData}
								value={formik.values.bank}
								onChange={formik.handleChange}
								label="Choose bank"
								id="bank"
								name="bank"
								menuValue="ID"
								displayValue="bankName"
								error={formik.touched.bank && Boolean(formik.errors.bank)}
								helperText={formik.touched.bank && formik.errors.bank}
							/>
						</Box>

						<Stack justifyContent="center">
							<Button
								variant="contained"
								color="primary"
								sx={{
									textTransform: "capitalize",
									pl: "3rem",
									pr: "3rem",
									py: ".8rem",
								}}
								type="submit"
							>
								{formik.isSubmitting ? (
									<CircularProgress size="1.5rem" sx={{ color: "#fff" }} />
								) : (
									"submit details"
								)}
							</Button>
						</Stack>
					</Box>
				</Box>
			</Box>

			<AccountDetailsModal
				open={openConfirmAccountDetailsModal}
				handleClose={() => {
					setOpenConfirmAccountDetailsModal(false);
				}}
				data={{ ...resolvedBankDetails, ...selectedBank }}
			/>
		</Box>
	);
};

export default VerifyAccount;

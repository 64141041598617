import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/AllUsersTable";
import { fetchAllUsers } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const Users = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);

	const { region, bizFrom, role, user } = useSelector((state) => state.auth);

	const {
		data: allUsersData = [],
		isLoading: allUsersLoading,
		isError: allUsersError,
		error: allUsersErrorMessage,
	} = useQuery(
		["get-all-users", region.region],
		async () =>
			fetchAllUsers({
				region: region.region,
				bizFrom,
				role,
				username: user?.username,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (allUsersError) {
			if (allUsersErrorMessage.response) {
				toast.error(allUsersErrorMessage.response.data?.message);
			} else if (allUsersErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", allUsersErrorMessage.message);
			}
		}
	}, [allUsersError, allUsersErrorMessage]);

	useEffect(() => {
		if (allUsersData.length && !allUsersLoading) {
			setDisplayed(allUsersData);
			setAllData(allUsersData);
		}
	}, [allUsersData, allUsersLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.fullName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.accountType?.toLowerCase()?.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="all users"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search all users"
				/>
			</Box>
			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table data={displayed} loading={allUsersLoading} />
			</Box>
		</Box>
	);
};

export default Users;

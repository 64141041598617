import { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
	Box,
	Button,
	Typography,
	Tooltip,
	IconButton,
	VisibilityIcon,
	styled,
} from "../../../components/common/muiComponents";
import SummaryCards from "../../../components/Admin/SummaryCards";
import LineChart from "../../../components/Admin/LineChart";
import BarChart from "../../../components/Admin/BarChart";
// import Table from "../../../components/common/DiscountsClaimedTable";
import Loader from "../../../components/common/Loader";
// import ViewDiscountClaimedModal from "../../../components/common/ViewDiscountClaimedModal";
import ChartsTable from "../../../components/Admin/ChartsTable";
import DateComponent from "../../../components/common/DateComponent";
import {
	getSummary,
	getDiscountChart,
	getSavingChart,
	// getDiscountsClaimed,
} from "../../../api/admin/dashboard";
import { newDateFormatter } from "../../../utils/helpers/functions";
import { ROLES } from "../../../utils/constants";

const SummaryFilterContainer = styled(Box)({
	display: "flex",
	justifyContent: "flex-end",
	marginBottom: ".5rem",
	gap: "1rem",
	alignItems: "center",
});

const formatDiscountData = (data) => {
	const formattedData = data.map((datum) => {
		return {
			x: datum.period,
			y: datum.total,
		};
	});
	return formattedData;
};

const changeChartView = (chartType, setChartType) => {
	if (chartType === "line") {
		setChartType("bar");
	} else if (chartType === "bar") {
		setChartType("table");
	} else if (chartType === "table") {
		setChartType("line");
	}
};

const formatLineChartData = (data) => {
	const formattedData = formatDiscountData(data);
	return [{ data: formattedData, id: "Discount Chart" }];
};

const Dashboard = () => {
	// const [selectedItem, setSelectedItem] = useState({});
	// const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [discounstChartView, setDiscountChartView] = useState("line");
	const [amountSavedChartView, setAmountSavedChartView] = useState("bar");
	const [selectedDate, setSelectedDate] = useState(null);
	const [open, setOpen] = useState(false);

	const pickerRef = useRef(null);

	const handlePickerClick = () => {
		setOpen(true);
	};
	const { region, user } = useSelector((state) => state.auth);

	const {
		data: summaryData = {},
		isLoading: summaryLoading,
		isError: summaryError,
		error: summaryErrorMessage,
	} = useQuery(
		[
			`get-admin-summary-${
				selectedDate ? newDateFormatter(selectedDate, "MMM yyyy") : ""
			}`,
			region.region,
			selectedDate,
		],
		async () =>
			getSummary({
				region: region.region,
				bizFrom: user?.bizFrom,
				accountType: user?.accountType,
				period: selectedDate ? newDateFormatter(selectedDate, "MMM yyyy") : "",
			}),
		{
			staleTime: 4 * 60 * 1000,
		}
	);

	const {
		data: discountChartData = [],
		isLoading: discountChartLoading,
		isError: discountChartError,
		error: discountChartErrorMessage,
	} = useQuery(
		["get-admin-discountChart", region.region],
		async () =>
			getDiscountChart(region.region, user?.bizFrom, user?.accountType),
		{
			select: (data) => data.chart,
			staleTime: 4 * 60 * 1000,
		}
	);

	const {
		data: savingChartData = [],
		isLoading: savingChartLoading,
		isError: savingChartError,
		error: savingChartErrorMessage,
	} = useQuery(
		["get-admin-savingChart", region.region],
		async () => getSavingChart(region.region, user?.bizFrom, user?.accountType),
		{
			select: (data) => data.chart,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (summaryError) {
			if (summaryErrorMessage.response) {
				toast.error(summaryErrorMessage.response.data?.message);
			} else if (summaryErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", summaryErrorMessage.message);
			}
		}
		if (discountChartError) {
			if (discountChartErrorMessage.response) {
				toast.error(discountChartErrorMessage.response.data?.message);
			} else if (discountChartErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", discountChartErrorMessage.message);
			}
		}
		if (savingChartError) {
			if (savingChartErrorMessage.response) {
				toast.error(savingChartErrorMessage.response.data?.message);
			} else if (savingChartErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", savingChartErrorMessage.message);
			}
		}
	}, [
		summaryError,
		summaryErrorMessage,
		discountChartError,
		discountChartErrorMessage,
		savingChartError,
		savingChartErrorMessage,
	]);

	const discountLineChartData = formatLineChartData(discountChartData);
	const amountSavedLineChartData = formatLineChartData(savingChartData);
	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
				width: "100%",
			}}
		>
			<Typography
				variant="h6"
				sx={{ fontWeight: 700, textTransform: "capitalize", mb: "1rem" }}
				component="div"
			>
				overview
			</Typography>

			{(user.role === ROLES.EXECUTIVE ||
				user.role === ROLES.CSU ||
				user.role === ROLES.FINCON) && (
				<Box sx={{ mb: "2rem" }}>
					<SummaryFilterContainer>
						<Box
							sx={{ width: "12rem", cursor: "pointer" }}
							onClick={handlePickerClick}
						>
							<Box
								onClick={(e) => {
									if (open) {
										e.stopPropagation();
									}
								}}
							>
								<DateComponent
									label="Filter by date"
									value={selectedDate}
									onChange={(newValue) => {
										setSelectedDate(newValue);
									}}
									views={["year", "month"]}
									inputProps={{
										readOnly: true,
									}}
									open={open}
									onClose={() => setOpen(false)}
									onAccept={() => setOpen(false)}
									ref={pickerRef}
								/>
							</Box>
						</Box>

						<Box>
							<Button
								variant="contained"
								sx={{ textTransform: "capitalize" }}
								onClick={() => setSelectedDate(null)}
							>
								show all
							</Button>
						</Box>
					</SummaryFilterContainer>
					<SummaryCards summary={summaryData} loading={summaryLoading} />
				</Box>
			)}

			<Box
				sx={{
					display: "flex",
					flexDirection: { md: "row", xs: "column" },
					mb: "4rem",
				}}
			>
				<Box
					sx={{
						width: { md: "55%", xs: "100%" },
						mr: { md: "1rem", xs: "0" },
						mb: { md: 0, xs: "1rem" },
					}}
				>
					<Box sx={{ height: "25rem" }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Typography
								variant="h6"
								sx={{ fontWeight: 700, mb: "1rem", p: "1rem" }}
								gutterBottom
							>
								Customers who accessed discounts
							</Typography>

							<Tooltip title="Click to change chart view">
								<IconButton
									onClick={() =>
										changeChartView(discounstChartView, setDiscountChartView)
									}
								>
									<VisibilityIcon />
								</IconButton>
							</Tooltip>
						</Box>
						{!discountChartLoading && discounstChartView === "line" && (
							<LineChart data={discountLineChartData} />
						)}
						{!discountChartLoading && discounstChartView === "bar" && (
							<BarChart data={discountChartData} />
						)}
						{!discountChartLoading && discounstChartView === "table" && (
							<ChartsTable
								data={discountChartData}
								loading={discountChartLoading}
							/>
						)}
						{discountChartLoading && <Loader size="1.5rem" />}
					</Box>
				</Box>
				<Box
					sx={{ width: { md: "45%", xs: "100%" }, mt: { md: 0, xs: "2rem" } }}
				>
					<Box sx={{ height: "25rem" }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Typography
								variant="h6"
								sx={{ fontWeight: 700, mb: "1rem", p: "1rem" }}
								gutterBottom
							>
								Amount Saved
							</Typography>

							<Tooltip title="Click to change chart view">
								<IconButton
									onClick={() =>
										changeChartView(
											amountSavedChartView,
											setAmountSavedChartView
										)
									}
								>
									<VisibilityIcon />
								</IconButton>
							</Tooltip>
						</Box>
						{!savingChartLoading && amountSavedChartView === "line" && (
							<LineChart data={amountSavedLineChartData} />
						)}
						{!savingChartLoading && amountSavedChartView === "bar" && (
							<BarChart data={savingChartData} />
						)}
						{!savingChartLoading && amountSavedChartView === "table" && (
							<ChartsTable
								data={savingChartData}
								loading={savingChartLoading}
							/>
						)}
						{savingChartLoading && <Loader size="1.5rem" />}
					</Box>
				</Box>
			</Box>

			{/* <ViewDiscountClaimedModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Discount Claimed Details"
				data={selectedItem}
			/> */}
		</Box>
	);
};

export default Dashboard;

// discountChart = period: "Jan 2022" total: 6
// savingsChart  = period: "Jan 2022" total: "5440"

import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Box, Typography } from "../../../components/common/muiComponents";
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import SubmitButton from "../../../components/common/SubmitButton";
import { virtualCardRequestStatus } from "../../../api/admin/dashboard";
import SelectFieldComponent from "../../../components/common/SelectFieldComponent";

const categories = ["dispute", "event", "request"];

const fields = {
	requestId: "",
	category: "",
};

const validationSchema = yup.object({
	requestId: yup
		.string("Please enter requestId")
		.required("Please enter request ID"),
	category: yup.string("Please enter category"),
});

const VirtualCardRequest = () => {
	const [response, setResponse] = useState("");

	const { user, region } = useSelector((state) => state.auth);

	const handleSubmit = async (values, stopSubmitLoading, resetForm) => {
		try {
			const payload = {
				username: user?.username,
				requestId: values.requestId,
				category: values.category,
				admin: user?.fullName,
				region: region?.region,
			};

			console.log({ payload });

			const result = await virtualCardRequestStatus(payload);

			console.log({ result });
			setResponse(result);
			toast.success(result?.message);
			stopSubmitLoading();
			resetForm();
		} catch (err) {
			console.log({ err });
			stopSubmitLoading();
			if (err.response) {
				toast.error(err.response?.data?.message);
			} else if (err.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", err.message);
			}
		}
	};

	const formik = useFormik({
		initialValues: fields,
		validationSchema,
		onSubmit: (values) => {
			handleSubmit(
				values,
				() => formik.setSubmitting(false),
				() => formik.resetForm()
			);
		},
	});

	return (
		<Box
			sx={{
				minHeight: "100vh",
				backgroundColor: "#fff",
				position: "relative",
			}}
		>
			<Box
				sx={{
					padding: { md: "3rem", sm: "1rem", xs: 0 },
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							width: "33rem",
							border: "1px solid #E6E6E6",
							borderRadius: "8px",
							pb: { md: "2rem", xs: "1rem" },
						}}
					>
						<Typography
							variant="h6"
							sx={{
								textAlign: "center",
								fontWeight: 700,
								textTransform: "capitalize",
								color: "#303030",
								p: { md: "2rem", xs: "1rem" },
							}}
						>
							Virtual Card Request
						</Typography>
						<Box
							component="form"
							sx={{
								p: { md: "1rem 3rem", xs: "1rem" },
								// border: "1px solid red",
							}}
							noValidate
							autoComplete="off"
							onSubmit={formik.handleSubmit}
						>
							<Box sx={{ mb: "1rem" }}>
								<TextFieldComponent
									value={formik.values.requestId}
									onChange={formik.handleChange}
									required
									label="Request ID"
									name="requestId"
									error={
										formik.touched.requestId && Boolean(formik.errors.requestId)
									}
									helperText={
										formik.touched.requestId && formik.errors.requestId
									}
								/>
							</Box>

							<Box sx={{ mb: "1rem" }}>
								<SelectFieldComponent
									width="100%"
									value={formik.values.category}
									values={categories}
									onChange={formik.handleChange}
									label="Choose Category"
									id="category"
									name="category"
									required
								/>
							</Box>

							<Box sx={{ my: "2rem" }}>
								<Typography
									variant="h6"
									gutterBottom
									component="p"
									// sx={{ fontWeight: 700, textAlign: "center" }}
								>
									Response
								</Typography>

								<Typography
									variant="body1"
									gutterBottom
									component="p"
									// sx={{ fontWeight: 700, textAlign: "center" }}
								>
									Message: {response?.data?.message}
								</Typography>

								<Typography
									variant="body1"
									gutterBottom
									component="p"
									// sx={{ fontWeight: 700, textAlign: "center" }}
								>
									Request ID: {response?.data?.requestId}
								</Typography>

								<Typography
									variant="body1"
									gutterBottom
									component="p"
									// sx={{ fontWeight: 700, textAlign: "center" }}
								>
									State: {response?.data?.state}
								</Typography>

								<Typography
									variant="body1"
									gutterBottom
									component="p"
									// sx={{ fontWeight: 700, textAlign: "center" }}
								>
									Status: {response?.data?.status}
								</Typography>
							</Box>

							<SubmitButton
								text="submit"
								loading={formik.isSubmitting}
								disabled={!formik.dirty || formik.isSubmitting}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default VirtualCardRequest;

import { ResponsiveLine } from "@nivo/line";
import { numberFormatter } from "../../utils/helpers/functions";

const MyResponsiveLine = ({ data }) => (
	<ResponsiveLine
		data={data}
		valueFormat={(v) => numberFormatter(v)}
		margin={{ top: 20, right: 30, bottom: 110, left: 40 }}
		xScale={{ type: "point" }}
		yScale={{
			type: "linear",
			min: "auto",
			max: "auto",
			stacked: true,
			reverse: false,
		}}
		// yFormat=" >-.2f"
		curve="cardinal"
		axisTop={null}
		axisRight={null}
		axisBottom={{
			orient: "bottom",
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 90,
			legend: "",
			legendOffset: 36,
			legendPosition: "middle",
		}}
		axisLeft={{
			orient: "left",
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: "",
			legendOffset: -40,
			legendPosition: "middle",
		}}
		enableGridX={false}
		pointSize={3}
		pointColor={{ theme: "background" }}
		pointBorderWidth={2}
		pointBorderColor={{ from: "serieColor" }}
		pointLabelYOffset={-12}
		useMesh
		legends={[]}
	/>
);

export default MyResponsiveLine;

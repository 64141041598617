import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/AdminUsersTable";
import { fetchAdmin } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const AdminUsers = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);

	const { region, bizFrom, role } = useSelector((state) => state.auth);

	const {
		data: adminUsersData = [],
		isLoading: adminUsersLoading,
		isError: adminUsersError,
		error: adminUsersErrorMessage,
	} = useQuery(
		["get-admin-users", region.region],
		async () => fetchAdmin(region.region, bizFrom, role),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (adminUsersError) {
			if (adminUsersErrorMessage.response) {
				toast.error(adminUsersErrorMessage.response.data?.message);
			} else if (adminUsersErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", adminUsersErrorMessage.message);
			}
		}
	}, [adminUsersError, adminUsersErrorMessage]);

	useEffect(() => {
		if (adminUsersData.length && !adminUsersLoading) {
			setDisplayed([...adminUsersData]);
			setAllData([...adminUsersData]);
		}
	}, [adminUsersData, adminUsersLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.emailAddress
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						item.phoneNumber
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						item.role?.toLowerCase().includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Admin settings - Admin users"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search User"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table data={displayed} loading={adminUsersLoading} />
			</Box>
		</Box>
	);
};

export default AdminUsers;

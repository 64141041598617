import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Table from "../../../components/Admin/UserRolesTable";
import RoleModal from "../../../components/Admin/RoleModal";
import {
	fetchRoles,
	creatRole,
	deleteRole,
	editRole,
} from "../../../api/admin/dashboard";

const defaultData = {
	role: "",
	roleID: "",
	accountType: "",
};

const AdminRoles = () => {
	const [selectedItem, setSelectedItem] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [addData, setAddData] = useState(defaultData);
	const [editData, setEditData] = useState(defaultData);

	const { user, region, bizFrom } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: userRolesData = [],
		isLoading: userRolesLoading,
		isError: userRolesError,
		error: userRolesErrorMessage,
	} = useQuery(
		["get-admin-roles", region?.region],
		async () => fetchRoles(region?.region, bizFrom),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region),
		}
	);

	useEffect(() => {
		if (userRolesError) {
			if (userRolesErrorMessage.response) {
				toast.error(userRolesErrorMessage.response.data?.message);
			} else if (userRolesErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", userRolesErrorMessage.message);
			}
		}
	}, [userRolesError, userRolesErrorMessage]);

	const { mutate: addRole, isLoading: addRoleLoading } = useMutation(
		"create-role",
		creatRole,
		{
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setOpen(false);
				setAddData(defaultData);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-roles");
			},
		}
	);

	const { mutate: updateRole, isLoading: updateRoleLoading } = useMutation(
		"edit-role",
		editRole,
		{
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setOpenEdit(false);
				setEditData(defaultData);
				setSelectedItem(null);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-roles");
			},
		}
	);

	const { mutate: destroyRole } = useMutation("delete-role", deleteRole, {
		onSuccess: (dataSuccess) => {
			toast.success(dataSuccess?.message);
			setSelectedItem(null);
		},
		onError: (errors) => {
			if (errors.response) {
				toast.error(errors.response.data.message);
			} else if (errors.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", errors.message);
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries("get-admin-roles");
		},
	});

	const handleAddRole = async (e) => {
		e.preventDefault();
		const payload = {
			admin: user.fullName,
			role: addData.role,
			roleID: addData.roleID,
			region: region?.region,
			accountType: addData.accountType,
			bizFrom,
		};

		await addRole(payload);
	};

	const handleEditRole = async (e) => {
		e.preventDefault();
		const payload = {
			admin: user.fullName,
			role: editData.role,
			roleID: editData.roleID,
			region: region?.region,
			ID: selectedItem.ID,
			accountType: editData.accountType,
			bizFrom,
		};

		await updateRole(payload);
	};

	const handleDeleteRole = async () => {
		const payload = {
			admin: user.fullName,
			role: editData.role,
			roleID: selectedItem.roleID,
			region: region?.region,
			bizFrom,
		};
		await destroyRole(payload);
	};

	useEffect(() => {
		if (selectedItem) {
			setEditData({
				...editData,
				role: selectedItem.role,
				roleID: selectedItem.roleID,
				accountType: selectedItem.accountType || "",
			});
		}
	}, [selectedItem]);

	useEffect(() => {
		if (userRolesData.length && !userRolesLoading) {
			setDisplayed(userRolesData);
			setAllData(userRolesData);
		}
	}, [userRolesData, userRolesLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.role?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.roleID?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.admin?.toLowerCase()?.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			{/* header */}
			<Box sx={{ mb: "2rem" }}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: { sm: "row", xs: "column" },
						mb: "1rem",
					}}
				>
					<Typography
						variant="h6"
						sx={{ fontWeight: 700, textTransform: "capitalize", mr: "auto" }}
						gutterBottom
					>
						Admin settings - User roles
					</Typography>

					<Box
						sx={{
							display: "flex",
							// justifyContent: { xs: "flex-start", sm: "flex-end" },
							width: { xs: "100%", sm: "auto" },
						}}
					>
						<Box sx={{ ml: { md: "auto", xs: 0 }, mr: "1rem" }}>
							<TextField
								id="table-search-box"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								label="Search Roles"
								variant="outlined"
								size="small"
								sx={{
									label: {
										color: "#594E4E !important",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										borderColor: "#594E4E !important",
									},
								}}
							/>
						</Box>

						<Box sx={{ ml: { md: "auto", xs: 0 } }}>
							<Button
								variant="contained"
								color="primary"
								sx={{ textTransform: "capitalize" }}
								onClick={() => setOpen(true)}
							>
								add role
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>

			{/* table */}
			<Box
				sx={{
					// width: { md: "78vw", xs: "95vw" },
					width: "100%",
					overflow: "auto",
					maxWidth: "100%",
					boxSizing: "border-box",
				}}
			>
				<Table
					data={displayed}
					loading={userRolesLoading}
					setSelectedItem={setSelectedItem}
					handleEdit={() => setOpenEdit(true)}
					handleDelete={handleDeleteRole}
				/>
			</Box>

			{/* add modal */}
			<RoleModal
				open={open}
				handleClose={() => setOpen(false)}
				title="create role"
				data={addData}
				setData={setAddData}
				loading={addRoleLoading}
				handleFormSubmit={handleAddRole}
			/>
			{/* edit modal */}
			<RoleModal
				open={openEdit}
				handleClose={() => setOpenEdit(false)}
				title="edit role"
				data={editData}
				setData={setEditData}
				loading={updateRoleLoading}
				handleFormSubmit={handleEditRole}
			/>
		</Box>
	);
};

export default AdminRoles;

import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
	Box,
	Tabs,
	Tab,
	TextField,
	Button,
} from "../../../components/common/muiComponents";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/PendingInflowTable";
import InfoTable from "../../../components/Admin/PendingInflowInfoTable";
import ViewDetailsModal from "../../../components/common/ViewPendingInflowModal";
import ViewDetailsInfoModal from "../../../components/common/ViewPendingInflowInfoModal";
import {
	fetchPendingInflow,
	fetchPendingInflowInfo,
} from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
	width: "100%",
};

const pendingInflowInfoInitialData = {
	accountNumber: "",
	actualAmount: "",
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const PendingInflow = () => {
	const [selectedItem, setSelectedItem] = useState({});
	const [searchTerm, setSearchTerm] = useState("");
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [pendingInflowInfoFormData, setPendingInflowInfoFormData] = useState(
		pendingInflowInfoInitialData
	);
	const [currentTab, setCurrentTab] = useState(0);
	const [selectedInfoItem, setSelectedInfoItem] = useState({});
	const [viewInfoDetailsModalOpen, setViewInfoDetailsModalOpen] =
		useState(false);

	const { user, region } = useSelector((state) => state.auth);

	const {
		data: pendingInflowData = [],
		isLoading: pendingInflowLoading,
		isError: pendingInflowError,
		error: pendingInflowErrorMessage,
	} = useQuery(
		["get-admin-pending-inflow", region?.region],
		async () =>
			fetchPendingInflow({
				region: region?.region,
				username: user?.username,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region),
		}
	);

	const {
		data: pendingInflowInfoData = [],
		isLoading: pendingInflowInfoLoading,
		isError: pendingInflowInfoError,
		error: pendingInflowInfoErrorMessage,
		refetch: pendingInflowInfoRefetch,
	} = useQuery(
		["get-admin-pending-inflow-info", region?.region],
		async () =>
			fetchPendingInflowInfo({
				region: region?.region,
				username: user?.username,
				accountNumber: pendingInflowInfoFormData?.accountNumber,
				actualAmount: pendingInflowInfoFormData?.actualAmount,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: false,
		}
	);

	useEffect(() => {
		if (pendingInflowError) {
			if (pendingInflowErrorMessage.response) {
				toast.error(pendingInflowErrorMessage.response.data?.message);
			} else if (pendingInflowErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", pendingInflowErrorMessage.message);
			}
		}
		if (pendingInflowInfoError) {
			if (pendingInflowInfoErrorMessage.response) {
				toast.error(pendingInflowInfoErrorMessage.response.data?.message);
			} else if (pendingInflowInfoErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", pendingInflowInfoErrorMessage.message);
			}
		}
	}, [
		pendingInflowError,
		pendingInflowErrorMessage,
		pendingInflowInfoError,
		pendingInflowInfoErrorMessage,
	]);

	const displayed = useMemo(() => {
		if (pendingInflowData.length) {
			let filteredData = [...pendingInflowData];
			if (searchTerm) {
				filteredData = filteredData.filter((item) => {
					return (
						item.senderAccountName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						`${item.settledAmount}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						`${item.actualAmount}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.accountNumber
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredData;
		}
		return pendingInflowData;
	}, [pendingInflowData, searchTerm]);

	const displayedPendingInflowInfo = useMemo(() => {
		if (pendingInflowInfoData.length) {
			let filteredData = [...pendingInflowInfoData];
			if (searchTerm) {
				filteredData = filteredData.filter((item) => {
					return (
						item.username?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.initiationTranRef
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.status?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						`${item.accountNumber}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						`${item.actualAmount}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredData;
		}
		return pendingInflowInfoData;
	}, [pendingInflowInfoData, searchTerm]);

	const handleInfoFormChange = (e) => {
		const { name, value } = e.target;

		setPendingInflowInfoFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleInfoFormSubmit = (e) => {
		e.preventDefault();
		if (!pendingInflowInfoFormData?.accountNumber) {
			toast.error("Account number is required");
			return;
		}
		if (!pendingInflowInfoFormData?.actualAmount) {
			toast.error("Actual amount is required");
			return;
		}
		pendingInflowInfoRefetch();
	};

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};
	return (
		<Box sx={style}>
			<Box sx={{ mb: "1rem" }}>
				<Tabs
					value={currentTab}
					onChange={(event, newValue) => {
						setCurrentTab(newValue);
					}}
					aria-label="pending inflow tabs"
				>
					<Tab label="Log" {...a11yProps(0)} />
					<Tab label="Info" {...a11yProps(1)} />
				</Tabs>
			</Box>
			{currentTab === 0 && (
				<>
					<Box sx={{ mb: "2rem" }}>
						<TableHeader
							text="pending inflow log"
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
							handleExcelExport={handleExcelExport}
							label="Search records"
						/>
					</Box>

					<Table
						data={displayed}
						loading={pendingInflowLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setViewDetailsModalOpen(true)}
					/>

					<ViewDetailsModal
						open={viewDetailsModalOpen}
						handleClose={() => {
							setViewDetailsModalOpen(false);
						}}
						title="View Pending Inflow Details"
						data={selectedItem}
						setData={setSelectedItem}
					/>
				</>
			)}
			{currentTab === 1 && (
				<>
					<Box sx={{ mb: "2rem" }}>
						<TableHeader
							text="pending inflow info"
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
							handleExcelExport={handleExcelExport}
							label="Search records"
						/>
					</Box>

					<Box
						component="form"
						sx={{
							display: "flex",
							alignItems: "center",
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						noValidate
						autoComplete="off"
						onSubmit={handleInfoFormSubmit}
					>
						<TextField
							name="accountNumber"
							label="Account Number"
							value={pendingInflowInfoFormData?.accountNumber}
							onChange={handleInfoFormChange}
						/>
						<TextField
							name="actualAmount"
							label="Actual Amount"
							value={pendingInflowInfoFormData?.actualAmount}
							onChange={handleInfoFormChange}
						/>

						{/* <Box> */}
						<Button
							variant="contained"
							type="submit"
							sx={{ textTransform: "capitalize" }}
						>
							submit
						</Button>
						{/* </Box> */}
					</Box>
					<InfoTable
						data={displayedPendingInflowInfo}
						loading={pendingInflowInfoLoading}
						setSelectedItem={setSelectedInfoItem}
						handleView={() => setViewInfoDetailsModalOpen(true)}
					/>

					<ViewDetailsInfoModal
						open={viewInfoDetailsModalOpen}
						handleClose={() => {
							setViewInfoDetailsModalOpen(false);
						}}
						title="View Pending Inflow Info Details"
						data={selectedInfoItem}
					/>
				</>
			)}
		</Box>
	);
};

export default PendingInflow;

import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/WalletBalancesTable";
import WalletBalanceSummaryCards from "../../../components/Admin/WalletBalanceSummaryCards";
import { getWalletBalances } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const WalletReport = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);

	const { region, user } = useSelector((state) => state.auth);

	const {
		data: walletReportData = [],
		isLoading: walletReportLoading,
		isError: walletReportError,
		error: walletReportErrorMessage,
	} = useQuery(
		["get-wallet-balances", region?.region],
		async () => getWalletBalances(region?.region, user?.username),
		{
			select: (data) => data?.data,
			staleTime: 4 * 60 * 1000,
			enabled: !!region?.region,
		}
	);

	useEffect(() => {
		if (walletReportError) {
			if (walletReportErrorMessage.response) {
				toast.error(walletReportErrorMessage.response.data?.message);
			} else if (walletReportErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", walletReportErrorMessage.message);
			}
		}
	}, [walletReportError, walletReportErrorMessage]);

	useEffect(() => {
		if (walletReportData?.balances?.length && !walletReportLoading) {
			setDisplayed([...walletReportData?.balances]);
			setAllData([...walletReportData?.balances]);
		}
	}, [walletReportData?.balances, walletReportLoading]);

	useEffect(() => {
		if (allData?.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						`${item.balances}`?.toLowerCase().includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Wallet Balances"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search wallet balances"
				/>
			</Box>

			<WalletBalanceSummaryCards
				loading={walletReportLoading}
				summary={{
					balance: walletReportData?.balance,
					paystack_balance: walletReportData?.paystack_balance,
					providus_balance: walletReportData?.providus_balance,
					// interswitch_balance: walletReportData?.interswitch_balance,
					webpay_balance: walletReportData?.webpay_balance,
					thepeer_balance: walletReportData?.thepeer_balance,
					baxi_balance: walletReportData?.baxi_balance,
				}}
			/>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table data={displayed} loading={walletReportLoading} />
			</Box>
		</Box>
	);
};

export default WalletReport;

import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({
	path,
	component: Component,
	render,
	roles,
	accountTypes,
	...rest
}) => {
	const { role, isAuthenticated, user } = useSelector((state) => state.auth);

	return (
		<Route
			{...rest}
			render={(props) => {
				if (
					!isAuthenticated ||
					(!roles.includes(role) &&
						!accountTypes.includes(user?.accountType?.toLowerCase()))
				)
					return (
						<Redirect
							to={{
								pathname: "/logout",
								state: { from: props.location },
							}}
						/>
					);
				return Component ? <Component {...props} /> : render(props);
			}}
		/>
	);
};

export default ProtectedRoute;

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import { numberFormatter } from "../../utils/helpers/functions";
import closeIcon from "../../assets/icons/close-icon.svg";
import logo from "../../assets/logos/smart-deals-logo.svg";

const columns = [
	{ id: "Record Info", label: "Record Info", minWidth: 80 },
	{ id: "Details", label: "Details", minWidth: 80 },
];

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	data,
}) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
						maxWidth: "60rem",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box sx={{ height: "5.25rem" }}>
							<img
								src={logo}
								height="100%"
								width="auto"
								alt="Cardinalstone logo"
							/>
						</Box>
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						textAlign: "center",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "2rem",
							pb: "2rem",
							width: "30rem",
						}}
					>
						<Box sx={{ mb: "1rem", width: "100%", display: "flex" }}>
							<Paper sx={{ width: "100%", overflow: "hidden" }}>
								<TableContainer sx={{ maxHeight: 440 }}>
									<Table stickyHeader aria-label="sticky table">
										<TableHead>
											<TableRow>
												{columns.map((column) => (
													<TableCell
														key={column.id}
														style={{
															minWidth: column.minWidth,
															backgroundColor: "#e41f26",
															color: "#fff",
														}}
													>
														{column.label}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Photo</TableCell>
												<TableCell>
													<Box sx={{ height: "13rem", width: "13rem" }}>
														<img
															src={data?.photo}
															alt={data?.username}
															height="100%"
															width="auto"
														/>
													</Box>
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Username</TableCell>
												<TableCell>{data?.username || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Title</TableCell>
												<TableCell>{data?.title || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>First Name</TableCell>
												<TableCell>{data?.firstname}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Last Name</TableCell>
												<TableCell>{data?.lastname}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Middle Name</TableCell>
												<TableCell>{data?.middlename}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Email Address</TableCell>
												<TableCell>{data?.email || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Phone Number</TableCell>
												<TableCell>{data?.phone || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Phone Number 2</TableCell>
												<TableCell>{data?.phone2 || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Account Type</TableCell>
												<TableCell>{data?.accountType || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Level of Account</TableCell>
												<TableCell>{data?.level_of_account || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Name on Card</TableCell>
												<TableCell>{data?.name_on_card || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>BVN</TableCell>
												<TableCell>{data?.bvn || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>NIN</TableCell>
												<TableCell>{data?.nin || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Birthdate</TableCell>
												<TableCell>{data?.birthdate || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Gender</TableCell>
												<TableCell>{data?.gender || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Marital Status</TableCell>
												<TableCell>{data?.marital_status || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Nationality</TableCell>
												<TableCell>{data?.nationality || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Residential Address</TableCell>
												<TableCell>{data?.residential_address || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>LGA of Origin</TableCell>
												<TableCell>{data?.lga_of_origin || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>LGA of Residence</TableCell>
												<TableCell>{data?.lga_of_residence || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>State of Origin</TableCell>
												<TableCell>{data?.state_of_origin || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>State of Residence</TableCell>
												<TableCell>{data?.state_of_residence || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Enrollment Bank</TableCell>
												<TableCell>{data?.enrollment_bank || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Enrollment Branch</TableCell>
												<TableCell>{data?.enrollment_branch || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Watch Listed</TableCell>
												<TableCell>{data?.watch_listed || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>IP Address</TableCell>
												<TableCell>{data?.ipAddress || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Registration Date</TableCell>
												<TableCell>{data?.registration_date || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Date Created</TableCell>
												<TableCell>{data?.createdDate || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Last Modified</TableCell>
												<TableCell>{data?.modifiedDate || ""}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

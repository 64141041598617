const tabs = [
	"Home",
	"Family",
	"Gadgets",
	"Fashion",
	"Lifestyle",
	"Electronics",
	"Computers",
	"Mobile Phones",
	"Accessories",
];

export default tabs;

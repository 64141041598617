import request from "../httpService";

// eslint-disable-next-line import/prefer-default-export
export async function getSummary(params) {
	if (!params.period) {
		// eslint-disable-next-line no-param-reassign
		delete params.period;
	}
	const { data } = await request.get("/api/admin/summary", {
		params,
	});
	return data;
}

export async function getDiscountChart(region) {
	const { data } = await request.get("/api/admin/discountChart", {
		params: { region },
	});
	return data;
}

export async function getSavingChart(region) {
	const { data } = await request.get("/api/admin/savingChart", {
		params: { region },
	});
	return data;
}

export async function getDiscountsClaimed(region) {
	const { data } = await request.get("/api/admin/discountHistories", {
		params: { region },
	});
	return data;
}

export async function fetchPartners(region) {
	const { data } = await request.get("/api/admin/fetchPartners_web", {
		params: { region },
	});
	return data;
}

export async function fetchSuggestedPartner(region) {
	const { data } = await request.get("/api/fetchSuggestedPartner", {
		params: { region },
	});
	return data;
}

export async function onboardSuggestedPartner(payload) {
	const url = "/api/onboardSuggestedPartner";
	const { data } = await request.post(url, payload);
	return data;
}

export async function enablePartner(payload) {
	const url = "/api/admin/enablePartner";
	const { data } = await request.post(url, payload);
	return data;
}

export async function disablePartner(payload) {
	const url = "/api/admin/disablePartner";
	const { data } = await request.post(url, payload);
	return data;
}

export async function fetchCustomers(region) {
	const { data } = await request.get("/api/admin/fetchCustomers", {
		params: { region },
	});
	return data;
}

export async function enableCustomer(payload) {
	const url = "/api/admin/enableCustomer";
	const { data } = await request.post(url, payload);
	return data;
}

export async function disableCustomer(payload) {
	const url = "/api/admin/disableCustomer";
	const { data } = await request.post(url, payload);
	return data;
}

export async function getLiveDeals(region) {
	const url = "/api/customer/liveDeals";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function getAdminDealsWon(region) {
	const url = "/api/admin/adminDealsWon";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function fetchAdmin(region) {
	const url = "/api/admin/fetchAdmin";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function fetchRoles(region) {
	const url = "/api/customer/fetchRoles";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function editRole(payload) {
	const url = "/api/admin/editRole";
	const { data } = await request.post(url, payload);
	return data;
}

export async function deleteRole(payload) {
	const url = "/api/admin/deleteRole";
	const { data } = await request.post(url, payload);
	return data;
}

export async function creatRole(payload) {
	const url = "/api/admin/creatRole";
	const { data } = await request.post(url, payload);
	return data;
}

export async function fetchSubscriptionClass(region) {
	const url = "/api/admin/fetchSubscriptionClass";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function createSubscriptionClass(payload) {
	const url = "/api/admin/createSubscriptionClass";
	const { data } = await request.post(url, payload);
	return data;
}

export async function editSubscriptionClass(payload) {
	const url = "/api/admin/editSubscriptionClass";
	const { data } = await request.post(url, payload);
	return data;
}

export async function deleteSubscriptionClass(payload) {
	const url = "/api/admin/deleteSubscriptionClass";
	const { data } = await request.post(url, payload);
	return data;
}

export async function fetchProducts(region, bizFrom) {
	const url = "/api/admin/fetchProducts";
	const { data } = await request.get(url, {
		params: { region, bizFrom },
	});
	return data;
}

export async function createProduct(payload) {
	const url = "/api/admin/createProduct";
	const { data } = await request.post(url, payload);
	return data;
}

export async function editProduct(payload) {
	const url = "/api/admin/editProduct";
	const { data } = await request.post(url, payload);
	return data;
}

export async function deleteProduct(payload) {
	const url = "/api/admin/deleteProduct";
	const { data } = await request.post(url, payload);
	return data;
}

export async function activateDeal(payload) {
	const url = "/api/admin/activateDeal";
	const { data } = await request.post(url, payload);
	return data;
}

export async function fetchProductDetails(productID, region, bizFrom) {
	const url = "/api/admin/fetchProductDetails";
	const { data } = await request.get(url, {
		params: { productID, region, bizFrom },
	});
	return data;
}

export async function getBizFrom(region) {
	const url = "/api/getBizFrom";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function getAdminCheckout(region) {
	const url = "/api/admin/adminCheckout";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function fetchUnreadAdminNotifications(region, admin) {
	const url = "/api/fetchUnreadAdminNotifications";
	const { data } = await request.get(url, {
		params: { region, admin },
	});
	return data;
}

export async function adminUpdateCheckout(payload) {
	const url = "/api/admin/adminUpdateCheckout";
	const { data } = await request.post(url, payload);
	return data;
}

export async function activityLog(region) {
	const url = "/api/activityLog";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function fetchPendingAdverts(region) {
	const url = "/api/admin/fetchPendingAdverts";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function fetchAdvertImages(region) {
	const url = "/api/admin/fetchAdminAdvertImages";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function approveAdvertImage(payload) {
	const url = "/api/admin/approveAdvertImage";
	const { data } = await request.post(url, payload);
	return data;
}

export async function rejectAdvertImage(payload) {
	const url = "/api/admin/rejectAdvertImage";
	const { data } = await request.post(url, payload);
	return data;
}

export async function deleteAdvertImage(payload) {
	const url = "/api/admin/deleteAdvertImage";
	const { data } = await request.post(url, payload);
	return data;
}

export async function kwikGetTasks(region) {
	const url = "/api/admin/kwikGetTasks";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function kwikCreateTask(payload) {
	const url = "/api/customer/kwikCreateTask";
	const { data } = await request.post(url, payload);
	return data;
}

export async function getMailingList(region) {
	const url = "/api/admin/getMailingList";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function failedWithdrawal(region, sdate, edate) {
	const url = "/api/admin/failedWithdrawal";
	const { data } = await request.get(url, {
		params: { region, sdate, edate },
	});
	return data;
}

export async function getAllWalletReport(region) {
	const url = "/api/admin/getAllWalletReport";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function getWalletBalances(region, username) {
	const url = "/api/admin/getWalletBalances";
	const { data } = await request.get(url, {
		params: { region, username },
	});
	return data;
}

export async function getAllSubscriptions(region) {
	const url = "/api/admin/getAllSubscriptions";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function getRedemptionMode(region, bizFrom, admin) {
	const url = "/api/admin/redemptionMode";
	const { data } = await request.get(url, {
		params: { region, bizFrom, admin },
	});
	return data;
}

export async function getBanks(region) {
	const url = "/api/admin/getBanks";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function resolveBankAccount({ accountNumber, region, bankCode }) {
	const url = "/api/admin/resolveBankAccount";
	const { data } = await request.get(url, {
		params: { region, accountNumber, bankCode },
	});
	return data;
}

export async function getFlutterLog(region) {
	const url = "/api/admin/getFlutterLog";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function getPaystackLog(region) {
	const url = "/api/admin/getPaystackLog";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function getProvidusLog(region) {
	const url = "/api/admin/getProvidusLog";
	const { data } = await request.get(url, {
		params: { region },
	});
	return data;
}

export async function checkUsername(payload) {
	const url = "/api/admin/checkAdminUsername";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function checkEmailAddress(payload) {
	const url = "/api/admin/checkAdminEmailAddress";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function checkPhoneNumber(payload) {
	const url = "/api/admin/checkAdminPhoneNumber";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function fetchAdminEvents(payload) {
	const url = "/api/admin/getAdminAllEvents";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function approveEvent(payload) {
	const url = "/api/admin/approveEvent";
	const { data } = await request.post(url, payload);
	return data;
}

export async function rejectEvent(payload) {
	const url = "/api/admin/rejectEvent";
	const { data } = await request.post(url, payload);
	return data;
}

export async function createAccount(payload) {
	const url = "/api/customer/new-account";
	const { data } = await request.post(url, payload);
	return data;
}

export async function getContactUs(payload) {
	const url = "/api/admin/getContactUs";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function fetchAllUsers(payload) {
	const url = "/api/admin/fetchAllUsers";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function executiveSummary(payload) {
	if (!payload?.period) {
		// eslint-disable-next-line no-param-reassign
		delete payload.period;
	}
	const url = "/api/exec/executiveSummary";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function updateAppVersion(payload) {
	const url = "/api/admin/updateAppVersion";
	const { data } = await request.post(url, payload);
	return data;
}

export async function getAppVersion(payload) {
	const url = "/api/getAppVersion";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function extendSubscriptionValidity(payload) {
	const url = "/api/admin/extendSubscriptionValidity";
	const { data } = await request.post(url, payload);
	return data;
}

export async function getSuccessfulBillPayment(payload) {
	const url = "/api/admin/getSuccessfulBillPayment";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function getPendingBillPayment(payload) {
	const url = "/api/admin/getPendingBillPayment";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function getFailedBillPayment(payload) {
	const url = "/api/admin/getFailedBillPayment";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function getActionScreen(payload) {
	const url = "/api/getActionScreen";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function pushNotification(payload) {
	const url = "/api/pushNotification";
	const { data } = await request.post(url, payload);
	return data;
}

export async function fetchMakvidTransfer(payload) {
	const url = "/api/admin/fetchMakvidTransfer";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function getAccountType(payload) {
	const url = "/api/getAccountType";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function fetchUserProfile(payload) {
	const url = "/api/admin/fetchUserProfile";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function submitMakvidTransfer(payload) {
	const url = "/api/admin/submitMakvidTransfer";
	const { data } = await request.post(url, payload);
	return data;
}

export async function approveMakvidTransfer(payload) {
	const url = "/api/admin/approveMakvidTransfer";
	const { data } = await request.post(url, payload);
	return data;
}

export async function rejectMakvidTransfer(payload) {
	const url = "/api/admin/rejectMakvidTransfer";
	const { data } = await request.post(url, payload);
	return data;
}

export async function fetchPayPartners(payload) {
	const url = "/api/admin/fetchPayPartners";
	const { data } = await request.get(url, {
		params: payload,
	});
	return data;
}

export async function setupPayPartner(payload) {
	const url = "/api/admin/setupPayPartner";
	const { data } = await request.post(url, payload);
	return data;
}

export async function editPayPartner(payload) {
	const url = "/api/admin/editPayPartner";
	const { data } = await request.post(url, payload);
	return data;
}

export async function deletePayPartner(payload) {
	const url = "/api/admin/deletePayPartner";
	const { data } = await request.post(url, payload);
	return data;
}

// partner discount
export async function fetchDiscounts(params) {
	const url = "/api/admin/fetchDiscounts";
	const { data } = await request.get(url, {
		params,
	});
	return data;
}

export async function enableDiscount(payload) {
	const url = "/api/admin/enableDiscount";
	const { data } = await request.post(url, payload);
	return data;
}

export async function disableDiscount(payload) {
	const url = "/api/admin/disableDiscount";
	const { data } = await request.post(url, payload);
	return data;
}

// delayed transactions
export async function getDelayedTransactions(params) {
	const url = "/api/admin/getDelayedTransactions";
	const { data } = await request.get(url, {
		params,
	});
	return data;
}

export async function resolveDelayedTransaction(payload) {
	const url = "/api/admin/resolveDelayedTransaction";
	const { data } = await request.post(url, payload);
	return data;
}

export async function reverseDelayedTransaction(payload) {
	const url = "/api/admin/reverseDelayedTransaction";
	const { data } = await request.post(url, payload);
	return data;
}

// Support chat
export async function fetchCustomerServiceAgent(params) {
	const url = "/api/admin/fetchCustomerServiceAgent";
	const { data } = await request.get(url, {
		params,
	});
	return data;
}

export async function fetchPendingInflow(params) {
	const url = "/api/admin/fetchPendingInflow";
	const { data } = await request.get(url, {
		params,
	});
	return data;
}

export async function fetchUserReferrals(params) {
	const url = "/api/admin/fetchUserReferrals";
	const { data } = await request.get(url, {
		params,
	});
	return data;
}

export async function userReferralSummary(params) {
	const url = "api/admin/userReferralSummary";
	const { data } = await request.get(url, {
		params,
	});
	return data;
}

export async function updatePendingInflow(payload) {
	const url = "/api/admin/updatePendingInflow";
	const { data } = await request.post(url, payload);
	return data;
}

export async function fetchPendingInflowInfo(params) {
	const url = "/api/admin/fetchPendingInflowInfo";
	const { data } = await request.get(url, { params });
	return data;
}

export async function fetchPendingProvidusNotifications(params) {
	const url = "/api/admin/fetchPendingProvidusNotifications";
	const { data } = await request.get(url, { params });
	return data;
}

export async function storeNoticeImage(payload) {
	const url = "/api/admin/storeNoticeImage";
	const { data } = await request.post(url, payload);
	return data;
}

export async function createNotice(payload) {
	const url = "/api/admin/createNotice";
	const { data } = await request.post(url, payload);
	return data;
}

export async function fetchCollabMembers(params) {
	const url = "/api/admin/fetchCollabMembers";
	const { data } = await request.get(url, { params });
	return data;
}

export async function fetchCollabSubscriptions(params) {
	if (!params.period) {
		// eslint-disable-next-line no-param-reassign
		delete params.period;
	}
	const url = "/api/admin/fetchCollabSubscriptions";
	const { data } = await request.get(url, { params });
	return data;
}

export async function baxiServiceStatusLog(params) {
	if (!params.period) {
		// eslint-disable-next-line no-param-reassign
		delete params.period;
	}
	const url = "/api/admin/baxiServiceStatusLog";
	const { data } = await request.get(url, { params });
	return data;
}

export async function fetchNotificationGroup(params) {
	const url = "/api/admin/fetchNotificationGroup";
	const { data } = await request.get(url, { params });
	return data;
}

// COOP
export async function fetchCoopMembers(params) {
	const url = "/api/admin/fetchCoopMembers";
	const { data } = await request.get(url, { params });
	return data;
}

export async function fetchCoopTransactionsLog(params) {
	const url = "/api/admin/fetchCoopTransactionsLog";
	const { data } = await request.get(url, { params });
	return data;
}

export async function fetchCoopTransactionsSummary(params) {
	const url = "/api/admin/fetchCoopTransactionsSummary";
	const { data } = await request.get(url, { params });
	return data;
}

export async function fetchCoopWalletBalances(params) {
	const url = "/api/admin/fetchCoopWalletBalances";
	const { data } = await request.get(url, { params });
	return data;
}

export async function fetchCoopSubscriptions(params) {
	const url = "/api/admin/fetchCoopSubscriptions";
	const { data } = await request.get(url, { params });
	return data;
}

export async function fetchScuml(params) {
	const url = "/api/admin/fetchScuml";
	const { data } = await request.get(url, { params });
	return data;
}

export async function fetchBVNReport(params) {
	const url = "/api/admin/fetchBVNReport";
	const { data } = await request.get(url, { params });
	return data;
}

export async function fetchBVNInfo(params) {
	const url = "/api/admin/fetchBVNInfo";
	const { data } = await request.get(url, { params });
	return data;
}

export async function processBVN(payload) {
	const url = "/api/admin/processBVN";
	const { data } = await request.post(url, payload);
	return data;
}

export async function fetchInterest(params) {
	const url = "/api/admin/fetchInterest";
	const { data } = await request.get(url, { params });
	return data;
}

export async function compareFaces(payload) {
	const url = "/api/admin/compareFaces";
	const { data } = await request.post(url, payload);
	return data;
}

export async function fundPaystackBalance(params) {
	const url = "/api/admin/fundPaystackBalance__";
	const { data } = await request.get(url, { params });
	return data;
}

export async function processStockRecommendation(params) {
	const url = "/api/admin/processStockRecommendation__";
	const { data } = await request.get(url, { params });
	return data;
}

export async function recheckBVN(payload) {
	const url = "/api/admin/recheckBVN";
	const { data } = await request.post(url, payload);
	return data;
}

export async function fetchAdminEventsManagers(params) {
	const url = "/api/admin/fetchAdminEvents";
	const { data } = await request.get(url, { params });
	return data;
}

export async function enableEventManager(payload) {
	const url = "/api/admin/enableEventManager";
	const { data } = await request.post(url, payload);
	return data;
}

export async function disableEventManager(payload) {
	const url = "/api/admin/disableEventManager";
	const { data } = await request.post(url, payload);
	return data;
}

export async function postAMC(payload) {
	const url = "/api/admin/amc";
	const { data } = await request.post(url, payload);
	return data;
}

export async function virtualCardRequestStatus(payload) {
	const url = "/api/admin/virtualCardRequestStatus";
	const { data } = await request.post(url, payload);
	return data;
}

export async function fetchVirtualCardsData(params) {
	const url = "/api/admin/virtualCards";
	const { data } = await request.get(url, { params });
	return data;
}

import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import ViewScumlReportModal from "../../../components/common/ViewScumlReportModal";
import Table from "../../../components/Admin/ScumlReportTable";
import { fetchScuml } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const ScumlReport = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);

	const { region, user } = useSelector((state) => state.auth);

	const {
		data: scumlReportData = [],
		isLoading: scumlReportLoading,
		isError: scumlReportError,
		error: scumlReportErrorMessage,
	} = useQuery(
		["fetch-scuml-report", region?.region],
		async () =>
			fetchScuml({
				regiion: region?.region,
				username: user?.username,
				roleID: user?.role,
			}),
		{
			select: (data) => data?.data,
			staleTime: 4 * 60 * 1000,
			enabled: !!region?.region,
		}
	);

	console.log({ scumlReportData });

	useEffect(() => {
		if (scumlReportError) {
			if (scumlReportErrorMessage.response) {
				toast.error(scumlReportErrorMessage.response.data?.message);
			} else if (scumlReportErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", scumlReportErrorMessage.message);
			}
		}
	}, [scumlReportError, scumlReportErrorMessage]);

	useEffect(() => {
		if (scumlReportData.length && !scumlReportLoading) {
			setDisplayed([...scumlReportData]);
			setAllData([...scumlReportData]);
		}
	}, [scumlReportData, scumlReportLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						`${item.transactionAmount}`
							?.toLowerCase()
							.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="SCUML Report"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Report"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table
					data={displayed}
					loading={scumlReportLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</Box>

			<ViewScumlReportModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View SCUML Report Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default ScumlReport;

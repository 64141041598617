import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import OtpInput from "react-otp-input";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	// width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 3,
};

const inputStyle = {
	width: "2.5rem",
	height: "2.5rem",
	margin: "0 1rem",
	fontSize: "2rem",
	borderRadius: 4,
	border: "1px solid rgba(0,0,0,0.3)",
};

const errorStyle = {
	color: "red",
	border: "1px solid red !important",
};

export default function BasicModal({
	open,
	handleClose,
	otp,
	handleChange,
	handleOtpRequest,
	handleVerifyOtp,
	loading,
	time,
}) {
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				// onBackdropClick={handleClose}
			>
				<Box sx={style}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							borderBottom: "1px solid #6F6F6F",
							mb: "1rem",
						}}
					>
						<Typography variant="h6" component="div" sx={{ fontWeight: 700 }}>
							OTP Verification
						</Typography>
					</Box>

					<Typography
						id="modal-modal-description"
						sx={{ mb: "2rem", textAlign: "center" }}
						variant="body1"
					>
						Please enter the OTP sent to your email
					</Typography>

					<Box sx={{ mb: "2rem" }}>
						<OtpInput
							inputStyle={inputStyle}
							value={otp}
							isInputNum
							onChange={handleChange}
							numInputs={6}
							shouldAutoFocus
							errorStyle={errorStyle}
							separator={<span>-</span>}
						/>
					</Box>

					<Box sx={{ display: "flex", justifyContent: "center" }}>
						{time.days + time.hours + time.minutes + time.seconds > 0 && (
							<Typography variant="body1" component="div" sx={{ mb: "2rem" }}>
								Resend code in{" "}
								<Typography variant="body1" color="secondary" component="span">
									{`(${time.minutes}:${
										time.seconds > 0
											? String(time.seconds).padStart(2, "0")
											: "00"
									})`}
								</Typography>
							</Typography>
						)}

						{time.days + time.hours + time.minutes + time.seconds <= 0 && (
							<Button
								variant="text"
								color="secondary"
								sx={{ mb: "2rem" }}
								onClick={handleOtpRequest}
							>
								resend otp
							</Button>
						)}
						{/* <Button
							variant="text"
							sx={{ textTransform: "none", color: "#000" }}
							onClick={() => handleOtpRequest()}
						>
							Resend One-Time Password
						</Button> */}
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() => handleVerifyOtp()}
							sx={{ mb: "1rem" }}
						>
							{loading ? (
								<CircularProgress sx={{ color: "#fff" }} size="1.5rem" />
							) : (
								"verify otp"
							)}
						</Button>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}

import {
	Box,
	Card,
	CardContent,
	Typography,
	CircularProgress,
} from "./muiComponents";

const cardStyles = {
	// width: 230,
	minHeight: "6.3rem",
	// color: "#fff",
	margin: "0.5rem",
	pl: { lg: "1rem", xs: 0 },
	width: { md: "19%", sm: 230, xs: "100%" },
	flexShrink: 0,
};

const cardContentStyles = {
	padding: "0.5rem",
	height: "100%",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	"&:last-child": {
		padding: "0.5rem",
	},
};

const SummaryCard = ({
	loading,
	text,
	value,
	Icon,
	isNumber = true,
	backgroundColor,
	subtext,
}) => {
	return (
		<Card
			sx={{
				...cardStyles,
				backgroundColor,
				width: {
					sm: isNumber ? 240 : 250,
					xs: "97%",
				},
			}}
		>
			<CardContent
				sx={{
					...cardContentStyles,
				}}
			>
				<Box>
					<Typography
						variant="h6"
						sx={{ width: "70%", fontWeight: 700, whiteSpace: "nowrap" }}
						component="div"
					>
						{loading ? <CircularProgress size="1rem" /> : value}
					</Typography>

					<Typography variant="body2" sx={{ textTransform: "capitalize" }}>
						{text}
					</Typography>
					{!!subtext && (
						<Typography variant="body2" sx={{ textTransform: "capitalize" }}>
							{subtext}
						</Typography>
					)}
				</Box>
				<Box sx={{ alignSelf: "start" }}>{Icon}</Box>
			</CardContent>
		</Card>
	);
};

export default SummaryCard;

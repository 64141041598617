import { createContext, useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import config from "../config";
import { ROLES } from "../utils/constants";
// import chimeAudioFile from "../assets/audio/chime.mp3";

const { chatSocketUrl } = config().secrets;

export const socket = io.connect(chatSocketUrl, {
	transports: ["websocket", "polling", "flashsocket"],
});

export const SocketContext = createContext();

const SupportSocketProvider = (props) => {
	const [activeQueue, setActiveQueue] = useState([]);
	const [supportQueue, setSupportQueue] = useState([]);
	const [transferQueue, setTransferQueue] = useState([]);
	const [clientId, setClientId] = useState("");
	const [messages, setMessages] = useState({});
	const [currentRoom, setCurrentRoom] = useState(null);
	const [isChatEnded, setIsChatEnded] = useState(true);
	const [isOtherUserTyping, setIsOtherUserTyping] = useState(false);
	const [userTypingMessage, setUserTypingMessage] = useState("");
	const [stocks, setStocks] = useState([]);
	const [stocksLoading, setStocksLoading] = useState(false);
	const [bidlevels, setBidLevels] = useState([]);
	const [offerLevels, setOfferLevels] = useState([]);
	const [trades, setTrades] = useState([]);
	const [bidOfferTradesLoading, setBidOfferTradesLoading] = useState(false);
	const [stockRecommendations, setStockRecommendations] = useState([]);
	const [stockRecommendationsLoading, setStockRecommendationsLoading] =
		useState(false);
	const [stockRecommendationsPrice, setStockRecommendationsPrice] = useState(
		[]
	);
	const [
		stockRecommendationsPriceLoading,
		setStockRecommendationsPriceLoading,
	] = useState(false);

	const { user } = useSelector((state) => state.auth);
	// const chimeAudio = new Audio(chimeAudioFile);
	const chatBoxRef = useRef();

	const fetchActiveChat = () => {
		const payload = {
			method: "active_chat",
			username: user?.username,
			accountType: ROLES.ADMIN,
		};

		socket.emit("active_chat", payload);
	};

	const supportChatTransferQueue = () => {
		const payload = {
			method: "fetch_transfer",
			username: user?.username,
			accountType: ROLES.ADMIN,
		};

		socket.emit("fetch_transfer", payload);
	};

	const updateStockPrices = () => {
		const payload = { username: user?.username, accountType: ROLES.ADMIN };
		socket.emit("stock_prices", payload);
		setStocksLoading(true);
	};

	const updateStockRecommendations = () => {
		const payload = { username: user?.username, accountType: ROLES.ADMIN };

		socket.emit("stock_recommendations", payload);
		setStockRecommendationsLoading(true);
	};

	const updateStockRecommendationsPrice = () => {
		const payload = { username: user?.username, accountType: ROLES.ADMIN };

		socket.emit("stock_recommendations_price", payload);
		setStockRecommendationsPriceLoading(true);
	};

	useEffect(() => {
		let userTyping;

		socket.on("connect", () => {
			console.log(`Connection opened on : ${socket.id}`);
		});

		socket.on("connected", (message) => {
			setClientId(socket.id);
			console.log(message);
			const response = JSON.parse(message);

			if (response.method === "connected" && response.clientId === clientId) {
				console.log(`Client id Set successfully ${clientId}`);
			}
		});

		socket.on("support_queue", (message) => {
			const response = JSON.parse(message);
			// chimeAudio.play();
			// setSupportQueue(response.queue);
			setSupportQueue((prev) => {
				try {
					response?.queue?.filter(
						(obj1) => !prev.some((obj2) => obj2.room === obj1.room)
					);
					// if (newRooms?.length) {
					// 	chimeAudio.play();
					// }
				} catch (error) {
					console.log(error);
				}
				return response.queue;
			});
		});

		socket.on("fetch_transfer", (message) => {
			const response = JSON.parse(message);
			// chimeAudio.play();
			setTransferQueue((prev) => {
				try {
					response?.queue?.filter(
						(obj1) => !prev.some((obj2) => obj2.room === obj1.room)
					);
					// if (newRooms?.length) {
					// 	chimeAudio.play();
					// }
				} catch (error) {
					console.log(error);
				}
				return response.queue;
			});
		});

		socket.on("admin_join_room", (message) => {
			const response = JSON.parse(message);
			toast.success(response.message);
			// if (response.message.includes("rejoined")) {
			// 	chimeAudio.play();
			// }

			setMessages((prev) => ({
				...prev,
				[response.room]: [
					...(prev[response.room] || []),
					{ ...response, sender: "system", chat: response.message },
				],
			}));
			if (chatBoxRef.current) {
				chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
			}
		});
		socket.on("send_message", (message) => {
			const response = JSON.parse(message);
			setMessages((prev) => ({
				...prev,
				[response.room]: [
					...(prev[response.room] || []),
					{ ...response, sender: response.room },
				],
			}));
			// chimeAudio.play();
			chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
			setIsOtherUserTyping(false);
			setUserTypingMessage("");
		});

		socket.on("active_chat", (message) => {
			const response = JSON.parse(message);
			setActiveQueue(response.queue);
		});

		socket.on("end_session", (message) => {
			const response = JSON.parse(message);
			console.log({ response });
			// chimeAudio.play();

			setMessages((prev) => ({
				...prev,
				[response.room]: [
					...(prev[response.room] || []),
					{ ...response, sender: "system", chat: response.message },
				],
			}));
			if (chatBoxRef.current) {
				chatBoxRef.current.scrollTop = chatBoxRef?.current?.scrollHeight;
			}
			setIsChatEnded(true);
		});

		socket.on("away", (message) => {
			const response = JSON.parse(message);
			// chimeAudio.play();

			setMessages((prev) => ({
				...prev,
				[response.room]: [
					...(prev[response.room] || []),
					{ ...response, sender: "system", chat: response.message },
				],
			}));
			if (chatBoxRef.current) {
				chatBoxRef.current.scrollTop = chatBoxRef?.current?.scrollHeight;
			}
			setIsChatEnded(true);
			setCurrentRoom(null);
		});

		socket.on("typing", (message) => {
			const response = JSON.parse(message);
			if (response.accountType !== ROLES.ADMIN) {
				setUserTypingMessage(response.message);
				setIsOtherUserTyping(true);
			}
			userTyping = setTimeout(() => {
				setUserTypingMessage("");
				setIsOtherUserTyping(false);
			}, 500);
		});

		socket.on("admin_transfer_chat", (message) => {
			const response = JSON.parse(message);
			// chimeAudio.play();

			setMessages((prev) => ({
				...prev,
				[response.room]: [
					...(prev[response.room] || []),
					{ ...response, sender: "system", chat: response.message },
				],
			}));
		});

		socket.on("stock_prices", (message) => {
			const response = JSON.parse(message);
			setStocksLoading(false);
			setStocks(response?.data || []);
		});

		socket.on("bid_offer", (message) => {
			const response = JSON.parse(message);
			const parsedResponse = JSON.parse(response);
			setBidOfferTradesLoading(false);
			setBidLevels(parsedResponse?.bidLevels || []);
			setOfferLevels(parsedResponse?.offerLevels || []);
			setTrades(parsedResponse?.trades || []);
		});

		socket.on("stock_recommendations", (message) => {
			const response = JSON.parse(message);
			setStockRecommendationsLoading(false);
			setStockRecommendations(response?.data || []);
		});

		socket.on("stock_recommendations_price", (message) => {
			const response = JSON.parse(message);
			setStockRecommendationsPriceLoading(false);
			setStockRecommendationsPrice(response?.data || []);
		});

		const activeChatInterval = setInterval(() => {
			fetchActiveChat();
		}, 1000);

		const transferQueueInterval = setInterval(() => {
			supportChatTransferQueue();
		}, 1000);

		updateStockPrices();
		updateStockRecommendations();
		updateStockRecommendationsPrice();

		return () => {
			socket.off("connect");
			socket.off("connected");
			socket.off("support_queue");
			socket.off("join_room");
			socket.off("send_message");
			socket.off("end_session");
			socket.off("typing");
			socket.off("admin_transfer_chat");
			socket.off("stock_prices");
			socket.off("bid_offer");
			socket.off("stock_recommendations");
			socket.off("stock_recommendations_price");
			clearInterval(activeChatInterval);
			clearInterval(transferQueueInterval);
			if (userTyping) {
				clearTimeout(userTyping);
			}
		};
	}, [socket]);

	return (
		<SocketContext.Provider
			value={{
				socket,
				clientId,
				activeQueue,
				supportQueue,
				transferQueue,
				messages,
				setMessages,
				currentRoom,
				setCurrentRoom,
				chatBoxRef,
				isChatEnded,
				setIsChatEnded,
				isOtherUserTyping,
				userTypingMessage,
				stocks,
				stocksLoading,
				bidlevels,
				offerLevels,
				trades,
				bidOfferTradesLoading,
				setBidOfferTradesLoading,
				stockRecommendations,
				stockRecommendationsPrice,
				stockRecommendationsLoading,
				stockRecommendationsPriceLoading,
			}}
		>
			{props.children}
		</SocketContext.Provider>
	);
};

export default SupportSocketProvider;

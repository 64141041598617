import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { currencySymbolMap } from "../../utils/constants";
import { numberFormatter } from "../../utils/helpers/functions";

// const getAvertStatus = (advert) => {
// 	const { status, paid } = advert;
// 	if (Number(status) === 1 && Number(paid) === 1) {
// 		return "active";
// 	}
// 	if (Number(status) === 1 && Number(paid) === 0) {
// 		return "unpaid";
// 	}
// 	if (Number(status) === 1) {
// 		return "approved";
// 	}
// 	if (Number(status) === 2) {
// 		return "rejected";
// 	}
// 	if (Number(status) === 3) {
// 		return "expired";
// 	}
// 	return "pending";
// };

// const getAdvertStatusColor = (advert) => {
// 	const advertStatus = getAvertStatus(advert);
// 	if (advertStatus === "active") {
// 		return "success.main";
// 	}
// 	if (advertStatus === "unpaid") {
// 		return "warning.main";
// 	}
// 	if (advertStatus === "approved") {
// 		return "info.main";
// 	}
// 	if (advertStatus === "rejected") {
// 		return "error.main";
// 	}
// 	if (advertStatus === "expired") {
// 		return "error.main";
// 	}
// 	return "info.main";
// };

const displayExpirationDate = (activeTab) => {
	if (activeTab === 0 || activeTab === 4) {
		return true;
	}
	return false;
};

export default function StickyHeadTable({
	loading,
	data,
	setSelectedItem,
	handleView,
	handleApprove,
	handleReject,
	handleDelete,
	disableOtherActions = false,
	currentTab,
}) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const { region } = useSelector((state) => state.auth);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const firstPageElement = page * rowsPerPage + 1;

	useEffect(() => {
		if (firstPageElement > data?.length) {
			setPage(0);
		}
	}, [data, firstPageElement]);

	const columns = displayExpirationDate(currentTab)
		? [
				{ id: "S/N", label: "S/N", minWidth: 10 },
				{ id: "title", label: "Title", minWidth: 170 },
				{ id: "advertImage", label: "Image", minWidth: 80 },
				{ id: "username", label: "Username", minWidth: 80 },
				// { id: "emailAddress", label: "Email Address", minWidth: 170 },
				{ id: "position", label: "Position", minWidth: 80 },
				{
					id: "unitAmount",
					label: `Unit Amount (${currencySymbolMap[region.region]})`,
					minWidth: 80,
				},
				{
					id: "amount",
					label: `Amount (${currencySymbolMap[region.region]})`,
					minWidth: 80,
				},
				{ id: "days", label: "Duration (Days)", minWidth: 80 },
				{
					id: "expiryDate",
					label: "Expiry Date",
					minWidth: 80,
				},
				{ id: "action", label: "Action", minWidth: 80 },
		  ]
		: [
				{ id: "S/N", label: "S/N", minWidth: 10 },
				{ id: "title", label: "Title", minWidth: 170 },
				{ id: "advertImage", label: "Image", minWidth: 80 },
				{ id: "username", label: "Username", minWidth: 80 },
				// { id: "emailAddress", label: "Email Address", minWidth: 170 },
				{ id: "position", label: "Position", minWidth: 80 },
				{
					id: "unitAmount",
					label: `Unit Amount (${currencySymbolMap[region.region]})`,
					minWidth: 80,
				},
				{
					id: "amount",
					label: `Amount (${currencySymbolMap[region.region]})`,
					minWidth: 80,
				},
				{ id: "days", label: "Duration (Days)", minWidth: 80 },
				{ id: "action", label: "Action", minWidth: 80 },
		  ];

	return (
		<Paper
			sx={{
				width: "100%",
				overflow: "hidden",
				marginBottom: "3rem",
			}}
		>
			<TableContainer sx={{ maxHeight: 440 }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
									sx={{
										backgroundColor: "primary.main",
										color: "#fff",
									}}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{!loading &&
							data
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((item, index) => (
									<TableRow key={item.id} hover role="checkbox" tabIndex={-1}>
										<TableCell>{page * rowsPerPage + index + 1}</TableCell>
										<TableCell>{item.title || ""}</TableCell>
										<TableCell>
											<Box sx={{ height: "3rem", width: "3rem" }}>
												<img
													src={item.file}
													alt={item.productName}
													height="100%"
													width="100%"
												/>
											</Box>
										</TableCell>
										<TableCell>{item.username || ""}</TableCell>
										{/* <TableCell>{item.emailAddress || ""}</TableCell> */}
										{/* <TableCell>{item.emailAddress || ""}</TableCell>
										<TableCell>{item.phoneNumber || ""}</TableCell>
										<TableCell>{item.productID || ""}</TableCell>
										<TableCell>{item.dealID || ""}</TableCell>
										<TableCell>
											{numberFormatter(item.pointsUsed) || ""}
										</TableCell>
										<TableCell>{item.transactionRef || ""}</TableCell>
										<TableCell>
											{numberFormatter(item.shippingFee) || ""}
										</TableCell>
										<TableCell>
											{numberFormatter(item.retailPrice) || ""}
										</TableCell> */}
										<TableCell>
											{numberFormatter(item.position) || ""}
										</TableCell>
										<TableCell>
											{numberFormatter(item.unitAmount) || ""}
										</TableCell>
										<TableCell>{numberFormatter(item.amount) || ""}</TableCell>
										{/* <TableCell>{numberFormatter(item.paid) || ""}</TableCell> */}
										{/* <TableCell
											sx={{
												color: getAdvertStatusColor(item),
											}}
										>
											{getAvertStatus(item)}
										</TableCell> */}
										<TableCell>{numberFormatter(item.days) || ""}</TableCell>
										{displayExpirationDate(currentTab) && (
											<TableCell>{item.expiryDate || ""}</TableCell>
										)}
										<TableCell>
											<IconButton
												onClick={(event) => {
													setSelectedItem(item);
													handleClick(event);
												}}
											>
												<MoreHorizIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
					</TableBody>
				</Table>
			</TableContainer>
			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						marginTop: "2rem",
					}}
				>
					<CircularProgress size="2rem" />
				</Box>
			)}
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={data.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Menu
				id="handle-activate-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "handle-activate-button",
				}}
			>
				<MenuItem
					onClick={() => {
						handleClose();
						handleView();
					}}
				>
					<VisibilityOutlinedIcon sx={{ mr: "0.5rem", fontSize: "1rem" }} />
					<Typography variant="body2">View</Typography>
				</MenuItem>
				{disableOtherActions && (
					<MenuItem
						onClick={() => {
							handleApprove();
							handleClose();
						}}
						sx={{ color: "#00C96C" }}
					>
						<CheckIcon sx={{ mr: "0.5rem", fontSize: "1rem" }} />
						<Typography variant="body2">Approve</Typography>
					</MenuItem>
				)}
				{disableOtherActions && (
					<MenuItem
						onClick={() => {
							handleReject();
							handleClose();
						}}
						sx={{ color: "#f00" }}
					>
						<CloseIcon sx={{ mr: "0.5rem", fontSize: "1rem" }} />
						<Typography variant="body2">Reject</Typography>
					</MenuItem>
				)}
				{disableOtherActions && (
					<MenuItem
						onClick={() => {
							handleDelete();
							handleClose();
						}}
						sx={{ color: "#f00" }}
					>
						<DeleteForeverIcon sx={{ mr: "0.5rem", fontSize: "1rem" }} />
						<Typography variant="body2">Delete</Typography>
					</MenuItem>
				)}
			</Menu>
		</Paper>
	);
}

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import { currencySymbolMap } from "../../utils/constants";
import { numberFormatter } from "../../utils/helpers/functions";

export default function StickyHeadTable({
	loading,
	data,
	setSelectedItem,
	handleView,
}) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const { region } = useSelector((state) => state.auth);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const firstPageElement = page * rowsPerPage + 1;

	useEffect(() => {
		if (firstPageElement > data?.length) {
			setPage(0);
		}
	}, [data, firstPageElement]);

	const columns = [
		{ id: "S/N", label: "S/N", minWidth: 10 },
		{ id: "productImage", label: "Product Image", minWidth: 80 },
		{ id: "currentDealer", label: "Current Dealer", minWidth: 80 },
		{ id: "productName", label: "Product Name", minWidth: 170 },
		{
			id: "retailPrice",
			label: `Retail Price (${currencySymbolMap[region.region]})`,
			minWidth: 80,
		},
		{
			id: "dealPrice",
			label: `Deal Price (${currencySymbolMap[region.region]})`,
			minWidth: 80,
		},
		// { id: "dealID", label: "Deal ID", minWidth: 80 },
		{ id: "pointsRequired", label: "Points Required", minWidth: 80 },
		{ id: "txttimeLeft", label: "Time Left", minWidth: 80 },
		// { id: "dealStartTime", label: "Start timestamp", minWidth: 80 },
		{ id: "action", label: "Action", minWidth: 80 },
	];

	return (
		<Paper
			sx={{
				width: "100%",
				overflow: "hidden",
				marginBottom: "3rem",
			}}
		>
			<TableContainer sx={{ maxHeight: 440 }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
									sx={{
										backgroundColor: "primary.main",
										color: "#fff",
									}}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{!loading &&
							data
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((item, index) => (
									<TableRow key={index} hover role="checkbox" tabIndex={-1}>
										<TableCell>{page * rowsPerPage + index + 1}</TableCell>
										<TableCell>
											<Box sx={{ height: "3rem", width: "3rem" }}>
												<img
													src={item.productImage}
													alt={item.productName}
													height="100%"
													width="100%"
												/>
											</Box>
										</TableCell>
										<TableCell>{item.currentDealer || ""}</TableCell>
										<TableCell>{item.productName || ""}</TableCell>
										<TableCell>
											{numberFormatter(item.retailPrice) || ""}
										</TableCell>
										<TableCell>
											{numberFormatter(item.dealPrice) || ""}
										</TableCell>
										<TableCell>
											{numberFormatter(item.pointsRequired) || ""}
										</TableCell>
										<TableCell>{item.txttimeLeft || ""}</TableCell>
										<TableCell>
											<IconButton
												onClick={(event) => {
													setSelectedItem(item);
													handleClick(event);
												}}
											>
												<MoreHorizIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
					</TableBody>
				</Table>
			</TableContainer>
			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						marginTop: "2rem",
					}}
				>
					<CircularProgress size="2rem" />
				</Box>
			)}
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={data.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Menu
				id="handle-activate-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "handle-activate-button",
				}}
			>
				<MenuItem
					onClick={() => {
						handleClose();
						handleView();
					}}
				>
					<VisibilityOutlinedIcon sx={{ mr: "0.5rem", fontSize: "1rem" }} />
					<Typography variant="body2">View</Typography>
				</MenuItem>
			</Menu>
		</Paper>
	);
}

import { useState, useEffect } from "react";
import { format } from "date-fns";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import ViewFailedWithdrawalsModal from "../../../components/common/ViewFailedWithdrawalsModal";
import Table from "../../../components/Admin/FailedWithdrawalTable";
import DateComponent from "../../../components/common/DateComponent";
import { failedWithdrawal } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const datesContainerStyle = {
	display: "flex",
	justifyContent: { xs: "flex-start", md: "flex-end" },
	alignItems: "center",
	mb: "2rem",
};

const dateStyle = {
	mr: "1rem",
	"&:last-child": {
		mr: 0,
	},
};

const dateComponentWidth = "13rem";

const FailedWithdrawals = () => {
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);

	const { region } = useSelector((state) => state.auth);

	const {
		data: failedWithdrawalsData = [],
		isLoading: failedWithdrawalsLoading,
		isError: failedWithdrawalsError,
		error: failedWithdrawalsErrorMessage,
	} = useQuery(
		["get-failed-withdrawals", region?.region, startDate, endDate],
		async () =>
			failedWithdrawal(
				region?.region,
				format(startDate, "yyyy-MM-d"),
				format(endDate, "yyyy-MM-d")
			),
		{
			select: (data) => data,
			staleTime: 4 * 60 * 1000,
			enabled: !!region?.region && !!startDate && !!endDate,
		}
	);

	useEffect(() => {
		if (failedWithdrawalsError) {
			if (failedWithdrawalsErrorMessage.response) {
				toast.error(failedWithdrawalsErrorMessage.response.data?.message);
			} else if (failedWithdrawalsErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", failedWithdrawalsErrorMessage.message);
			}
		}
	}, [failedWithdrawalsError, failedWithdrawalsErrorMessage]);

	useEffect(() => {
		if (failedWithdrawalsData.length && !failedWithdrawalsLoading) {
			setDisplayed([...failedWithdrawalsData]);
			setAllData([...failedWithdrawalsData]);
		}
	}, [failedWithdrawalsData, failedWithdrawalsLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.transactionAmount
							?.toLowerCase()
							.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Failed Withdrawals"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Failed Withdrawals"
				/>
			</Box>

			<Box sx={datesContainerStyle}>
				<Box sx={dateStyle}>
					<DateComponent
						width={dateComponentWidth}
						value={startDate}
						onChange={(newValue) => setStartDate(newValue)}
						label="Start Date"
						id="StartDate"
						name="StartDate"
					/>
				</Box>
				<Box sx={dateStyle}>
					<DateComponent
						width={dateComponentWidth}
						value={endDate}
						onChange={(newValue) => setEndDate(newValue)}
						label="End Date"
						id="endDate"
						name="endDate"
					/>
				</Box>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table
					data={displayed}
					loading={failedWithdrawalsLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</Box>

			<ViewFailedWithdrawalsModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Failed Withdrawal Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default FailedWithdrawals;

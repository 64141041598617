import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";

import NavBar from "../../components/Home/NavBar";
import FullPageAdvert from "../../components/common/FullPageAdvert";
import Footer from "../../components/common/Footer";
import clubIcon from "../../assets/logos/club-icon.svg";
import discountIcon from "../../assets/logos/discount-icon.svg";

const HowItWorks = () => {
	return (
		<Box>
			<Box
				sx={{
					margin: {
						md: "0 3rem",
						xs: "0 1rem",
					},
				}}
			>
				<CssBaseline />
				<NavBar />
				<Toolbar />
				<FullPageAdvert />

				<Box>
					<Typography
						variant="h5"
						gutterBottom
						component="div"
						sx={{ fontWeight: 700, mb: "3rem", textAlign: "center" }}
					>
						How it works
					</Typography>

					<Box sx={{ mb: "3rem" }}>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ fontWeight: 700, mb: "1rem" }}
						>
							SMARTDEALS
						</Typography>
						<Typography
							component="div"
							variant="body1"
							sx={{
								mb: "1rem",
							}}
							gutterBottom
						>
							We are Nigeria&apos;s foremost efficient and interactive on-demand
							discount club and deals platform. SmartDeals is a new innovative
							business-to-consumer driven Deals and Discount club solution
							created to enhance how businesses and consumers interact mutually.
						</Typography>
					</Box>

					<Box sx={{ mb: "2rem" }}>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ fontWeight: 700, mb: "1rem" }}
						>
							SMARTDEALS “DEAL” SESSIONS
						</Typography>
						<Typography
							component="div"
							variant="body1"
							sx={{
								mb: "1rem",
							}}
							gutterBottom
						>
							Active Smartdeals discount club members can use their accumulated
							free Smartdeals points to participate in deals sessions generated
							and managed on Smartdeals discount club members' portal.
						</Typography>
						<Typography
							component="div"
							variant="body1"
							sx={{
								mb: "1rem",
							}}
							gutterBottom
						>
							How to participate in a deal session?
						</Typography>
						<ol>
							<li>
								<Typography component="div" variant="body1">
									Consumers sign up on Smartdeals on web or app
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									{" "}
									Consumers subscribe to peferred discount club membership plan
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Each membership package has free reward points, which
									consumers can use when they participate in deal sessions{" "}
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Users are notified when an item is available for a deal on
									their Smartdeals Discount App
								</Typography>
							</li>{" "}
							<li>
								<Typography component="div" variant="body1">
									Users click on the deal button for preferred items and
									required points are deducted per click
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									The deal will run for 24 hours and the user whose username is
									displayed on the item as current dealer when the deal is over,
									wins the deal session
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Deal winner simply checks out and pays 1% of the actual
									product price.
								</Typography>
							</li>
						</ol>
					</Box>

					<Box sx={{ mb: "2rem" }}>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ fontWeight: 700, mb: "1rem" }}
						>
							SMARTDEALS DISCOUNT CLUB SERVICES/OFFERINGS
						</Typography>
						<Typography
							component="div"
							variant="body1"
							sx={{
								mb: "1rem",
								textTrabsform: "capitalize",
							}}
							gutterBottom
						>
							Are you a business owner?
						</Typography>
						<Typography
							component="div"
							variant="body1"
							sx={{
								mb: "1rem",
							}}
							gutterBottom
						>
							Businesses can register at no cost to Offer Exclusive Discounts
							that attracts more consumers and as such grow its sales revenue.
						</Typography>
						<Typography component="div" variant="body1">
							Simply
						</Typography>
						<ol>
							<li>
								<Typography component="div" variant="body1">
									Download Smartdeals Discount Partners App on Google and Apple
									Store.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Sign up to become a discount partner.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Read and accept Terms &amp; Conditions.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Login after account activation.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Provide Business Details.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Provide Certificate of incorporation.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Provide Company Logo.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Provide discount offered (5% - 50%).
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Provide Terms and Conditions for discount e.g Minimum spend of
									5,000 naira.
								</Typography>
							</li>
						</ol>
					</Box>

					<Box sx={{ mb: "2rem" }}>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ fontWeight: 700, mb: "1rem" }}
						>
							Want to be a Member?
						</Typography>
						<Typography
							component="div"
							variant="body1"
							sx={{
								mb: "1rem",
							}}
							gutterBottom
						>
							Active Smartdeals subscribers can access Exclusive Discounts in
							real time at thousands of registered business partners across the
							country.
						</Typography>
						<Typography component="div" variant="body1">
							Simply
						</Typography>
						<ol>
							<li>
								<Typography component="div" variant="body1">
									Download the Smartdeals Discount App from Google and Apple
									stores.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Sign Up to Smartdeals discount club.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Subscribe to any of our Discount Club Membership plan.
								</Typography>
							</li>
							<li>
								<Typography component="div" variant="body1">
									Each Membership Plan comes with free reward points that are
									used to participate in DEAL sessions on Smartdeals Discount
									Members Portal and stand a chance to get 99% discount on
									luxury items.
								</Typography>
							</li>
						</ol>
					</Box>

					<Box sx={{ mb: { md: "3rem", xs: "1rem" } }}>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ fontWeight: 700, mb: "1rem" }}
						>
							How?
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								flexDirection: { md: "row", xs: "column" },
								textAlign: "center",
							}}
						>
							<Box
								sx={{
									width: { md: "30rem", xs: "100%" },
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									mr: {
										md: "1rem",
										xs: 0,
									},
								}}
							>
								<Box sx={{ height: "3.75rem", width: "3.75rem", mb: "1rem" }}>
									<img
										src={clubIcon}
										alt=""
										height="100%"
										width="100%"
										style={{ objectFit: "cover" }}
									/>
								</Box>
								<Typography
									variant="h6"
									gutterBottom
									component="div"
									sx={{ mb: "1rem", textTransform: "uppercase" }}
								>
									club members
								</Typography>
								<Typography component="div" variant="body1">
									Active Smartdeals subscribers can access Exclusive Discounts
									in real time at thousands of registered business partners
									across the country.
								</Typography>
							</Box>

							<Box
								sx={{
									width: { md: "30rem", xs: "100%" },
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Box sx={{ height: "3.75rem", width: "3.75rem", mb: "1rem" }}>
									<img
										src={discountIcon}
										alt=""
										height="100%"
										width="100%"
										style={{ objectFit: "cover" }}
									/>
								</Box>
								<Typography
									variant="h6"
									gutterBottom
									component="div"
									sx={{ mb: "1rem", textTransform: "uppercase" }}
								>
									discount partners
								</Typography>
								<Typography component="div" variant="body1">
									Smartdeals registered discount partners offer Exclusive
									Discounts on Products and Services on the platform to Active
									Smartdeals discount club Suscribers to promote their everyday
									lifestyle.
								</Typography>
							</Box>
						</Box>
					</Box>

					<Box
						sx={{
							mb: {
								md: "3rem",
								xs: "1rem",
							},
							backgroundColor: "#E41F2650",
							padding: "1rem",
						}}
					>
						<Typography component="div" variant="body1">
							<span style={{ color: "#E41F26", fontWeight: "bold" }}>
								NOTE:
							</span>{" "}
							To take advantage of Smartdeals discount club offers, simply visit
							any of our registered discount partners shops or websites,
							patronize them and present your Smartdeals discount App to claim
							your discount.
						</Typography>
					</Box>
				</Box>
			</Box>
			<Footer />
		</Box>
	);
};

export default HowItWorks;

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import SelectFieldComponent from "../common/SelectFieldComponent";
import closeIcon from "../../assets/icons/close-icon.svg";

export default function ResponsiveDialog({
	open,
	handleClose,
	loading,
	handleSubmit,
	selectedServiceAgent,
	setSelectedServiceAgent,
	transferList,
	transferListLoading,
}) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<div>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							borderBottom: "1px solid #6F6F6F",
						}}
					>
						<Typography variant="h6" component="div" sx={{ fontWeight: 700 }}>
							Transfer Requests
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						textAlign: "center",
						display: "flex",
						justifyContent: "center",
					}}
				>
					{!transferListLoading && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								marginBottom: "2rem",
								width: "30rem",
							}}
						>
							<Box sx={{ mb: "2rem" }}>
								<Typography
									variant="body2"
									gutterBottom
									sx={{ marginBottom: "1rem", color: "#2B2B2B" }}
								>
									Please select the person to transfer the chat to
								</Typography>

								<Box sx={{ mb: "1rem" }}>
									<SelectFieldComponent
										width="100%"
										value={selectedServiceAgent}
										values={transferList}
										onChange={(e) => {
											setSelectedServiceAgent(e.target.value);
										}}
										label="Select service agent"
										id="serviceAgent"
										name="serviceAgent"
										menuValue="username"
										displayValue="fullName"
									/>
								</Box>
							</Box>

							<Button
								sx={{
									paddingLeft: "3rem",
									paddingRight: "3rem",
								}}
								variant="contained"
								color="success"
								onClick={() => {
									handleSubmit();
								}}
							>
								{loading ? (
									<CircularProgress sx={{ color: "#fff" }} size="1.5rem" />
								) : (
									"transfer"
								)}
							</Button>
						</Box>
					)}
					{transferListLoading && (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								width: "100%",
								marginTop: "2rem",
							}}
						>
							<CircularProgress size="2rem" />
						</Box>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
}

import { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/SuggestedPartnerstable";
import OnboardedPartnersTable from "../../../components/Admin/OnboardedPartnersTable";
import ViewSuggestedPartnersDetailsModal from "../../../components/common/ViewSuggestedPartnersDetailsModal";
import {
	fetchSuggestedPartner,
	onboardSuggestedPartner,
} from "../../../api/admin/dashboard";

function a11yProps(index) {
	return {
		id: `suggested-partner-tab-${index}`,
		"aria-controls": `suggested-partner-tabpanel-${index}`,
	};
}

const SuggestedPartners = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);
	const [displayedSuggestedPartners, setDisplayedSuggestedPartners] = useState(
		[]
	);
	const [displayedOnboardedPartners, setDisplayedOnboardedPartners] = useState(
		[]
	);

	const { region, bizFrom, role, user } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: partnersData = [],
		isLoading: partnersLoading,
		isError: partnersError,
		error: partnersErrorMessage,
	} = useQuery(
		["get-admin-suggested-partners", region?.region],
		async () => fetchSuggestedPartner(region?.region, bizFrom, role),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region),
		}
	);

	useEffect(() => {
		if (partnersError) {
			if (partnersErrorMessage.response) {
				toast.error(partnersErrorMessage.response.data?.message);
			} else if (partnersErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", partnersErrorMessage.message);
			}
		}
	}, [partnersError, partnersErrorMessage]);

	const pendingPartners = useMemo(() => {
		return partnersData.filter(
			(partner) => partner.status?.toLowerCase() === "pending"
		);
	}, [partnersData]);

	const onboardedPartners = useMemo(() => {
		return partnersData.filter(
			(partner) => partner.status?.toLowerCase() !== "pending"
		);
	}, [partnersData]);

	useEffect(() => {
		if (pendingPartners.length) {
			if (currentTab === 0 && searchTerm) {
				const filteredPartners = pendingPartners.filter((partner) => {
					return (
						partner.partnerName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.username
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.emailAddress
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.phoneNumber
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
				setDisplayedSuggestedPartners(filteredPartners);
				return;
			}
			setDisplayedSuggestedPartners(pendingPartners);
		}
	}, [pendingPartners, searchTerm, currentTab]);

	useEffect(() => {
		if (onboardedPartners.length) {
			if (currentTab === 1 && searchTerm) {
				const filteredPartners = onboardedPartners.filter((partner) => {
					return (
						partner.partnerName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.username
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.emailAddress
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.phoneNumber
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
				setDisplayedOnboardedPartners(filteredPartners);
				return;
			}
			setDisplayedOnboardedPartners(onboardedPartners);
		}
	}, [onboardedPartners, searchTerm, currentTab]);

	const {
		mutate: onboardSuggestedPartnerMutation,
		// isLoading: onboardSuggestedPartnerLoading,
	} = useMutation("onboard-partner", onboardSuggestedPartner, {
		onSuccess: (dataSuccess) => {
			toast.success(dataSuccess?.message);
		},
		onError: (errors) => {
			if (errors.response) {
				toast.error(errors.response.data.message);
			} else if (errors.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", errors.message);
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries("get-admin-suggested-partners");
		},
	});

	const handleOnboard = () => {
		const payload = {
			region: region?.region,
			id: selectedItem.id,
			username: user.username,
			partnerName: selectedItem?.partnerName,
			bizFrom,
			role,
		};
		onboardSuggestedPartnerMutation(payload);
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
				width: "100%",
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="suggested partners"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={() => {}}
					label="Search Partners"
				/>
			</Box>

			<Box sx={{ width: "100%", mb: "1rem" }}>
				<Box>
					<Tabs
						value={currentTab}
						onChange={(event, newValue) => {
							setCurrentTab(newValue);
						}}
						aria-label="advert tabs"
						variant="scrollable"
						scrollButtons
						allowScrollButtonsMobile
					>
						<Tab label="suggested partners" {...a11yProps(0)} />
						<Tab label="onboarded partners" {...a11yProps(1)} />
					</Tabs>
				</Box>
			</Box>

			{currentTab === 0 && (
				<Box
					sx={{
						// width: { md: "78vw", xs: "95vw" },
						width: "100%",
						overflow: "auto",
						maxWidth: "100%",
						boxSizing: "border-box",
					}}
				>
					<Table
						data={displayedSuggestedPartners}
						loading={partnersLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setViewDetailsModalOpen(true)}
						handleOnboard={handleOnboard}
					/>
				</Box>
			)}

			{currentTab === 1 && (
				<Box
					sx={{
						// width: { md: "78vw", xs: "95vw" },
						width: "100%",
						overflow: "auto",
						maxWidth: "100%",
						boxSizing: "border-box",
					}}
				>
					<OnboardedPartnersTable
						data={displayedOnboardedPartners}
						loading={partnersLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setViewDetailsModalOpen(true)}
					/>
				</Box>
			)}

			<ViewSuggestedPartnersDetailsModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Suggested Partners Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default SuggestedPartners;

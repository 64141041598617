import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import ViewWalletModal from "../../../components/common/ViewWalletModal";
import Table from "../../../components/Admin/WalletTable";
import { getAllWalletReport } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const WalletReport = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);

	const { region } = useSelector((state) => state.auth);

	const {
		data: walletReportData = [],
		isLoading: walletReportLoading,
		isError: walletReportError,
		error: walletReportErrorMessage,
	} = useQuery(
		["get-wallet-report", region?.region],
		async () => getAllWalletReport(region?.region),
		{
			select: (data) => data,
			staleTime: 4 * 60 * 1000,
			enabled: !!region?.region,
		}
	);

	useEffect(() => {
		if (walletReportError) {
			if (walletReportErrorMessage.response) {
				toast.error(walletReportErrorMessage.response.data?.message);
			} else if (walletReportErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", walletReportErrorMessage.message);
			}
		}
	}, [walletReportError, walletReportErrorMessage]);

	useEffect(() => {
		if (walletReportData.length && !walletReportLoading) {
			setDisplayed([...walletReportData]);
			setAllData([...walletReportData]);
		}
	}, [walletReportData, walletReportLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.transactionAmount
							?.toLowerCase()
							.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Wallet Report"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search wallet report"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table
					data={displayed}
					loading={walletReportLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</Box>

			<ViewWalletModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Wallet Report Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default WalletReport;

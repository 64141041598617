import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/DealsWonTable";
import ViewDealsWonModal from "../../../components/common/ViewDealsWonModal";
import { getAdminDealsWon } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const DealsWon = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);

	const { region, bizFrom, role } = useSelector((state) => state.auth);

	const {
		data: dealsWonData = [],
		isLoading: dealsWonLoading,
		isError: dealsWonError,
		error: dealsWonErrorMessage,
	} = useQuery(
		["get-deals-won", region.region],
		async () => getAdminDealsWon(region.region, bizFrom, role),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (dealsWonError) {
			if (dealsWonErrorMessage.response) {
				toast.error(dealsWonErrorMessage.response.data?.message);
			} else if (dealsWonErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", dealsWonErrorMessage.message);
			}
		}
	}, [dealsWonError, dealsWonErrorMessage]);

	useEffect(() => {
		if (dealsWonData.length && !dealsWonLoading) {
			setDisplayed([...dealsWonData]);
			setAllData([...dealsWonData]);
		}
	}, [dealsWonData, dealsWonLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.username?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.fullName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						`${item.dealPrice}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="deals won"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Deals"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table
					data={displayed}
					loading={dealsWonLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</Box>
			<ViewDealsWonModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Won Deal Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default DealsWon;

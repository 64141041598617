import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Box,
	Typography,
	Stack,
	CircularProgress,
} from "../common/muiComponents";
import TextFieldComponent from "../common/TextFieldComponent";
import SelectFieldComponent from "../common/SelectFieldComponent";
import { ACCOUNT_TYPES } from "../../utils/constants";
import closeIcon from "../../assets/icons/close-icon.svg";

const accountTypes = Object.values(ACCOUNT_TYPES);

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	data,
	setData,
	loading,
	handleFormSubmit,
}) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography
							variant="h6"
							component="div"
							sx={{
								fontWeight: 700,
								textTransform: "capitalize",
							}}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						textAlign: "center",
						overflowX: "hidden",
					}}
				>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						noValidate
						autoComplete="off"
						onSubmit={handleFormSubmit}
					>
						<TextFieldComponent
							id="role"
							label="Role"
							variant="outlined"
							value={data.role}
							onChange={(e) =>
								setData({
									...data,
									role: e.target.value,
								})
							}
						/>
						<TextFieldComponent
							id="roleID"
							label="Role ID"
							variant="outlined"
							value={data.roleID}
							onChange={(e) =>
								setData({
									...data,
									roleID: e.target.value,
								})
							}
						/>

						<Box sx={{ m: 1, width: "25ch" }}>
							<SelectFieldComponent
								id="accountType"
								label="Account Type"
								variant="outlined"
								value={data.accountType}
								values={accountTypes}
								onChange={(e) =>
									setData({
										...data,
										accountType: e.target.value,
									})
								}
							/>
						</Box>
						<Stack
							direction="row"
							spacing={2}
							sx={{
								marginTop: "1rem",
								marginBottom: "1rem",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								sx={{
									paddingLeft: "2rem",
									paddingRight: "2rem",
								}}
								variant="contained"
								type="submit"
							>
								{loading ? (
									<CircularProgress size="1.5rem" sx={{ color: "#fff" }} />
								) : (
									"save"
								)}
							</Button>
						</Stack>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

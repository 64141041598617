import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Table from "../../../components/Admin/ExecutiveSummaryTable";
import DateComponent from "../../../components/common/DateComponent";
import SummaryCard from "../../../components/common/ExecutiveSummaryCard";
import { executiveSummary } from "../../../api/admin/dashboard";
import { newDateFormatter } from "../../../utils/helpers/functions";

const ExecutiveSummary = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);

	const { region, bizFrom, role, user } = useSelector((state) => state.auth);

	const {
		data: executiveSummaryData = [],
		isLoading: executiveSummaryLoading,
		isError: executiveSummaryError,
		error: executiveSummaryErrorMessage,
	} = useQuery(
		["get-executive summary", region.region, selectedDate],
		async () =>
			executiveSummary({
				region: region.region,
				bizFrom,
				role,
				username: user?.username,
				period: newDateFormatter(selectedDate, "MMM yyyy"),
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	console.log({
		executiveSummaryData,
	});

	useEffect(() => {
		if (executiveSummaryError) {
			if (executiveSummaryErrorMessage.response) {
				toast.error(executiveSummaryErrorMessage.response.data?.message);
			} else if (executiveSummaryErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", executiveSummaryErrorMessage.message);
			}
		}
	}, [executiveSummaryError, executiveSummaryErrorMessage]);

	useEffect(() => {
		if (
			executiveSummaryData?.agencyCommission?.length &&
			!executiveSummaryLoading
		) {
			setDisplayed(executiveSummaryData?.agencyCommission);
			setAllData(executiveSummaryData?.agencyCommission);
		}
	}, [executiveSummaryData?.agencyCommission, executiveSummaryLoading]);

	useEffect(() => {
		if (allData?.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.Agency?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						`${item?.["Agency Commission"]}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						`${item.Revenue}`?.toLowerCase()?.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleReset = () => {
		setSearchTerm("");
		setSelectedDate(null);
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: { sm: "row", xs: "column" },
					}}
				>
					<Typography
						variant="h6"
						sx={{ fontWeight: 700, textTransform: "capitalize", mr: "auto" }}
						gutterBottom
					>
						Executive Summary
					</Typography>

					<Box
						sx={{
							display: "flex",
							// justifyContent: { xs: "flex-start", sm: "flex-end" },
							alignItems: "center",
							width: { xs: "100%", sm: "auto" },
							gap: "1rem",
						}}
					>
						<Box sx={{ ml: { md: "auto", xs: 0 } }}>
							<TextField
								id="table-search-box"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								label="Search agency commision"
								variant="outlined"
								size="small"
								sx={{
									label: {
										color: "#594E4E !important",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										borderColor: "#594E4E !important",
									},
									width: "13rem",
									maxWidth: "100%",
								}}
							/>
						</Box>

						<Box>
							<DateComponent
								width="13rem"
								value={selectedDate}
								onChange={(newValue) => setSelectedDate(newValue)}
								label="Period"
								id="selectedDate"
								name="selectedDate"
								views={["year", "month"]}
							/>
						</Box>

						<Button
							sx={{
								textTransform: "capitalize",
							}}
							color="primary"
							variant="contained"
							onClick={handleReset}
						>
							reset
						</Button>
					</Box>
				</Box>
			</Box>

			<Box sx={{ mb: "2rem" }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<SummaryCard
							label="Card Wallet Transactions"
							value={
								executiveSummaryData?.cardWalletTxn?.[
									"Card Wallet Transactions"
								] || 0
							}
							loading={executiveSummaryLoading}
							backgroundColor="#D3EFFF"
							isCurrency
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<SummaryCard
							label="Providus Wallet Transactions"
							value={
								executiveSummaryData?.providusWalletTxn?.[
									"Providus Wallet Transactions"
								] || 0
							}
							loading={executiveSummaryLoading}
							backgroundColor="#E5FAF0"
							isCurrency
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<SummaryCard
							label="Total Discount Partners"
							value={
								executiveSummaryData?.totalDiscountPartners?.[
									"Total Discount Partners"
								] || 0
							}
							loading={executiveSummaryLoading}
							backgroundColor="#F7C5FF"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<SummaryCard
							label="Total Members"
							value={executiveSummaryData?.totalMembers?.Total_Members || 0}
							loading={executiveSummaryLoading}
							backgroundColor="#FFDDDD"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<SummaryCard
							label="Total Partners"
							value={
								executiveSummaryData?.totalPartners?.["Total Partners"] || 0
							}
							loading={executiveSummaryLoading}
							backgroundColor="#FAE4D2"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<SummaryCard
							label="Total Subscribed Members"
							value={
								executiveSummaryData?.totalSubscribedMembers?.[
									"Total Subscribed Members"
								] || 0
							}
							loading={executiveSummaryLoading}
							backgroundColor="#D3EFFF"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<SummaryCard
							label="Total Subscription Revenue"
							value={
								executiveSummaryData?.totalSubscriptionRevenue?.[
									"Total Subscription Revenue"
								] || 0
							}
							loading={executiveSummaryLoading}
							backgroundColor="#E5FAF0"
							isCurrency
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<SummaryCard
							label="Total subscriptions"
							value={
								executiveSummaryData?.totalSubscriptionRevenue?.[
									"Total subscriptions"
								] || 0
							}
							loading={executiveSummaryLoading}
							backgroundColor="#F7C5FF"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<SummaryCard
							label="Total Unsubscribed Members
"
							value={
								executiveSummaryData?.totalUnsubscribedMembers?.[
									"Total Unsubscribed Members"
								] || 0
							}
							loading={executiveSummaryLoading}
							backgroundColor="#FFDDDD"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<SummaryCard
							label="Total Wallet Users"
							value={
								executiveSummaryData?.totalWalletUsers?.[
									"Total Wallet Users"
								] || 0
							}
							loading={executiveSummaryLoading}
							backgroundColor="#FAE4D2"
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<SummaryCard
							label="Wallet Balance"
							value={
								executiveSummaryData?.walletBalance?.["Wallet Balance"] || 0
							}
							loading={executiveSummaryLoading}
							backgroundColor="#D3EFFF"
							isCurrency
						/>
					</Grid>
				</Grid>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table data={displayed} loading={executiveSummaryLoading} />
			</Box>
		</Box>
	);
};

export default ExecutiveSummary;

import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/MailingListTable";
import { downloadExcel } from "../../../utils/helpers/functions";
import { getMailingList } from "../../../api/admin/dashboard";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const MailingList = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);

	const { region } = useSelector((state) => state.auth);

	const {
		data: mailingListData = [],
		isLoading: mailingListLoading,
		isError: mailingListError,
		error: mailingListErrorMessage,
	} = useQuery(
		["get-mailing-list", region.region],
		async () => getMailingList(region.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (mailingListError) {
			if (mailingListErrorMessage.response) {
				toast.error(mailingListErrorMessage.response.data?.message);
			} else if (mailingListErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", mailingListErrorMessage.message);
			}
		}
	}, [mailingListError, mailingListErrorMessage]);

	useEffect(() => {
		if (mailingListData.length && !mailingListLoading) {
			setDisplayed(mailingListData);
			setAllData(mailingListData);
		}
	}, [mailingListData, mailingListLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.emailAddress?.toLowerCase().includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="mailing list"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Mailing List"
				/>
			</Box>

			<Box>
				<Table data={displayed} loading={mailingListLoading} />
			</Box>
		</Box>
	);
};

export default MailingList;

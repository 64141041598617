import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import TextFieldComponent from "../../../components/common/TextFieldComponent";
import NumberTextField from "../../../components/common/NumberTextField";
import SubmitButton from "../../../components/common/SubmitButton";
import { extendSubscriptionValidity } from "../../../api/admin/dashboard";

// beneficiary: ["benecifiary (member username or all) is required"];
// comment: ["comment is required"];
// days: ["number of days extension is required"];
// username: ["username is required"];

const fields = {
	beneficiary: "",
	comment: "",
	days: "",
};

const validationSchema = yup.object({
	beneficiary: yup
		.string("Please enter beneficiary")
		.required("Beneficiary is required"),
	comment: yup
		.string("Please enter comment")
		.min(3, "Comment should be of minimum 3 characters length")
		.max(200, "Comment should be of maximum 200 characters length")
		.required("Comment is required"),
	days: yup
		.number("Please enter number of days extension")
		.required("Number of days extension is required"),
});

const AppVersion = () => {
	const { user } = useSelector((state) => state.auth);

	const handleSubmit = async (values, stopSubmitLoading, resetForm) => {
		try {
			const payload = {
				beneficiary: values.beneficiary,
				comment: values.comment,
				days: values.days,
				username: user?.username,
			};

			const result = await extendSubscriptionValidity(payload);
			toast.success(result?.message);
			stopSubmitLoading();
			resetForm();
		} catch (err) {
			stopSubmitLoading();
			if (err.response) {
				toast.error(err.response.data.message);
			} else if (err.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", err.message);
			}
		}
	};

	const formik = useFormik({
		initialValues: fields,
		validationSchema,
		onSubmit: (values) => {
			handleSubmit(
				values,
				() => formik.setSubmitting(false),
				() => formik.resetForm()
			);
		},
	});

	return (
		<Box
			sx={{
				minHeight: "100vh",
				backgroundColor: "#fff",
				position: "relative",
			}}
		>
			<Box
				sx={{
					padding: { md: "3rem", sm: "1rem", xs: 0 },
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							width: "33rem",
							border: "1px solid #E6E6E6",
							borderRadius: "8px",
							pb: { md: "2rem", xs: "1rem" },
						}}
					>
						<Typography
							variant="h6"
							sx={{
								textAlign: "center",
								fontWeight: 700,
								textTransform: "capitalize",
								color: "#303030",
								p: { md: "2rem", xs: "1rem" },
							}}
						>
							extend subscription validity
						</Typography>
						<Box
							component="form"
							sx={{
								p: { md: "1rem 3rem", xs: "1rem" },
								// border: "1px solid red",
							}}
							noValidate
							autoComplete="off"
							onSubmit={formik.handleSubmit}
						>
							<Box sx={{ mb: "1rem" }}>
								<TextFieldComponent
									value={formik.values.beneficiary}
									onChange={formik.handleChange}
									label="Enter beneficiary / all"
									name="beneficiary"
									error={
										formik.touched.beneficiary &&
										Boolean(formik.errors.beneficiary)
									}
									helperText={
										formik.touched.beneficiary && formik.errors.beneficiary
									}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<TextField
									name="comment"
									label="Comment"
									multiline
									rows={4}
									value={formik.values.comment}
									onChange={formik.handleChange}
									error={
										formik.touched.comment && Boolean(formik.errors.comment)
									}
									helperText={formik.touched.comment && formik.errors.comment}
									fullWidth
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<NumberTextField
									value={formik.values.days}
									onChange={formik.handleChange}
									required
									label="Enter days"
									name="days"
									error={formik.touched.days && Boolean(formik.errors.days)}
									helperText={formik.touched.days && formik.errors.days}
								/>
							</Box>

							<SubmitButton
								text="extend validity"
								loading={formik.isSubmitting}
								disabled={!formik.dirty || formik.isSubmitting}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default AppVersion;

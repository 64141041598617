import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { numberFormatter } from "../../utils/helpers/functions";
import { currencySymbolMap } from "../../utils/constants";

const cardStyles = {
	width: 230,
	height: "8rem",
	backgroundColor: "#002564",
	color: "#fff",
	margin: "0.5rem",
	mb: "1rem",
};

const cardContentStyles = {
	padding: "0.5rem",
	height: "100% !important",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	color: "#000",
	"&:last-child": {
		padding: "0.5rem",
	},
};

const WalletBalncSummaryCard = ({
	label,
	value,
	loading,
	backgroundColor,
	isCurrency,
}) => {
	const { region } = useSelector((state) => state.auth);

	return (
		<Card
			sx={{
				...cardStyles,
				backgroundColor,
				width: 230,
			}}
		>
			<CardContent
				sx={{
					...cardContentStyles,
				}}
			>
				<Typography
					variant="p"
					sx={{ mb: 1.5, width: "70%", textTransform: "capitalize" }}
					component="div"
				>
					{label}
				</Typography>
				<Typography align="right" variant="h6">
					{/* eslint-disable-next-line no-nested-ternary */}
					{loading ? (
						<CircularProgress size="1.5rem" sx={{ color: "#000" }} />
					) : isCurrency ? (
						`${currencySymbolMap[region.region]} ${numberFormatter(value || 0)}`
					) : (
						`${numberFormatter(value || 0)}`
					)}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default WalletBalncSummaryCard;

import { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import SelectFieldComponent from "../../../components/common/SelectFieldComponent";
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import SubmitButton from "../../../components/common/SubmitButton";
import {
	pushNotification,
	getActionScreen,
	getAccountType,
	fetchNotificationGroup,
} from "../../../api/admin/dashboard";
import { getCountry } from "../../../api/common/location";

const fields = {
	title: "",
	message: "",
	type: "",
	region: "",
	accountType: "",
	group: "",
	recipient: "",
	actionScreen: "dashboard",
};

const validationSchema = yup.object({
	title: yup
		.string("Please choose title")
		.min(3, "Title must be at least 3 characters")
		.required("Title is required"),
	message: yup
		.string("Please choose message")
		.min(3, "Message must be at least 3 characters")
		.max(200, "Message must be at most 200 characters")
		.required("Message is required"),
	type: yup
		.string("Please select notification type")
		.required("Notification type number is required"),
	region: yup
		.string("Please select region")
		.required("Region number is required"),
	accountType: yup.string("Please select account type").when("type", {
		is: (type) => type?.toLowerCase() === "personal",
		then: yup.string().required("Account type is required"),
	}),
	group: yup.string("Please choose group"),
	recipient: yup
		.string("Please choose recipient")
		.min(3, "Recipient must be at least 3 characters")
		.when("type", {
			is: (type) => type?.toLowerCase() === "personal",
			then: yup.string().required("Recipient is required"),
		}),
	actionScreen: yup
		.string("Please select action screen")
		.required("Action screen number is required"),
});

const PushNotification = () => {
	const { region, bizFrom, role, user } = useSelector((state) => state.auth);

	const {
		data: actionScreensData = [],
		// isLoading: actionScreensLoading,
		isError: actionScreensError,
		error: actionScreensErrorMessage,
	} = useQuery(
		["get-app-version-android", region.region],
		async () =>
			getActionScreen({
				region: region.region,
				bizFrom,
				role,
				username: user?.username,
				platform: "android",
			}),
		{
			select: (data) => data?.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	const {
		data: countriesData = [],
		// isLoading: countriesLoading,
		isError: countriesError,
		error: countriesErrorMessage,
	} = useQuery(["get-country"], async () => getCountry(), {
		select: (data) => data.filter((country) => country.list),
		staleTime: Infinity,
	});

	const {
		data: accountTypeData = [],
		// isLoading: accountTypeLoading,
		isError: accountTypeError,
		error: accountTypeErrorMessage,
	} = useQuery(["get-account-type"], async () => getAccountType(), {
		select: (data) => data?.data,
		staleTime: Infinity,
	});

	const {
		data: notificationGroupData = [],
		// isLoading: notificationGroupLoading,
		isError: notificationGroupError,
		error: notificationGroupErrorMessage,
	} = useQuery(
		["get-notification-group"],
		async () => fetchNotificationGroup({ username: user?.username }),
		{
			select: (data) => data?.data?.filter((item) => !!item?.referrer),
			staleTime: Infinity,
		}
	);

	useEffect(() => {
		if (actionScreensError) {
			if (actionScreensErrorMessage.response) {
				toast.error(actionScreensErrorMessage.response.data?.message);
			} else if (actionScreensErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", actionScreensErrorMessage.message);
			}
		}
		if (countriesError) {
			if (countriesErrorMessage.response) {
				toast.error(countriesErrorMessage.response.data?.message);
			} else if (countriesErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", countriesErrorMessage.message);
			}
		}
		if (accountTypeError) {
			if (accountTypeErrorMessage.response) {
				toast.error(accountTypeErrorMessage.response.data?.message);
			} else if (accountTypeErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", accountTypeErrorMessage.message);
			}
		}
		if (notificationGroupError) {
			if (notificationGroupErrorMessage.response) {
				toast.error(notificationGroupErrorMessage.response.data?.message);
			} else if (notificationGroupErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", notificationGroupErrorMessage.message);
			}
		}
	}, [
		actionScreensError,
		actionScreensErrorMessage,
		countriesError,
		countriesErrorMessage,
		accountTypeError,
		accountTypeErrorMessage,
		notificationGroupError,
		notificationGroupErrorMessage,
	]);

	const handleSubmit = async (values, stopSubmitLoading, resetForm) => {
		try {
			const payload = {
				title: values.title,
				message: values.message,
				type: values.type,
				region: values.region,
				accountType_: values.accountType,
				group: values.group,
				recipient: values.recipient,
				actionScreen: values.actionScreen,
				username: user?.username,
			};

			if (values.type?.toLowerCase() === "general") {
				delete payload.recipient;
			}

			if (!values.group) {
				delete payload.group;
			}

			const result = await pushNotification(payload);
			toast.success(result?.message);
			stopSubmitLoading();
			resetForm();
		} catch (err) {
			stopSubmitLoading();
			if (err.response) {
				toast.error(err.response.data.message);
			} else if (err.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", err.message);
			}
		}
	};

	const formik = useFormik({
		initialValues: fields,
		validationSchema,
		onSubmit: (values) => {
			handleSubmit(
				values,
				() => formik.setSubmitting(false),
				() => formik.resetForm()
			);
		},
	});

	return (
		<Box
			sx={{
				minHeight: "100vh",
				backgroundColor: "#fff",
				position: "relative",
			}}
		>
			<Box
				sx={{
					padding: { md: "3rem", sm: "1rem", xs: 0 },
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							width: "33rem",
							border: "1px solid #E6E6E6",
							borderRadius: "8px",
							pb: { md: "2rem", xs: "1rem" },
						}}
					>
						<Typography
							variant="h6"
							sx={{
								textAlign: "center",
								fontWeight: 700,
								textTransform: "capitalize",
								color: "#303030",
								p: { md: "2rem", xs: "1rem" },
							}}
						>
							push notification
						</Typography>
						<Box
							component="form"
							sx={{
								p: { md: "1rem 3rem", xs: "1rem" },
								// border: "1px solid red",
							}}
							noValidate
							autoComplete="off"
							onSubmit={formik.handleSubmit}
						>
							<Box sx={{ mb: "1rem" }}>
								<Box sx={{ mb: "1rem" }}>
									<TextFieldComponent
										value={formik.values.title}
										onChange={formik.handleChange}
										label="Title"
										name="title"
										error={formik.touched.title && Boolean(formik.errors.title)}
										helperText={formik.touched.title && formik.errors.title}
									/>
								</Box>
								<Box sx={{ mb: "1rem" }}>
									<TextField
										name="message"
										label="Message"
										multiline
										rows={4}
										value={formik.values.message}
										onChange={formik.handleChange}
										error={
											formik.touched.message && Boolean(formik.errors.message)
										}
										helperText={formik.touched.message && formik.errors.message}
										fullWidth
									/>
								</Box>
								<SelectFieldComponent
									width="100%"
									values={["Personal", "General"]}
									value={formik.values.type}
									onChange={formik.handleChange}
									label="Notification type"
									id="type"
									name="type"
									// menuValue="type"
									// displayValue="type"
									error={formik.touched.type && Boolean(formik.errors.type)}
									helperText={formik.touched.type && formik.errors.type}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<SelectFieldComponent
									width="100%"
									values={countriesData}
									value={formik.values.region}
									onChange={formik.handleChange}
									label="Region"
									id="region"
									name="region"
									menuValue="short_name"
									displayValue="name"
									error={formik.touched.region && Boolean(formik.errors.region)}
									helperText={formik.touched.region && formik.errors.region}
								/>
							</Box>

							<Box sx={{ mb: "1rem" }}>
								<SelectFieldComponent
									width="100%"
									values={accountTypeData}
									value={formik.values.accountType}
									onChange={formik.handleChange}
									label="Account Type"
									id="accountType"
									name="accountType"
									menuValue="code"
									displayValue="name"
									error={
										formik.touched.accountType &&
										Boolean(formik.errors.accountType)
									}
									helperText={
										formik.touched.accountType && formik.errors.accountType
									}
								/>
							</Box>

							<Box sx={{ mb: "1rem" }}>
								<SelectFieldComponent
									width="100%"
									values={notificationGroupData}
									value={formik.values.group}
									onChange={formik.handleChange}
									label="Group"
									id="group"
									name="group"
									menuValue="referrer"
									displayValue="referrer"
									error={formik.touched.group && Boolean(formik.errors.group)}
									helperText={formik.touched.group && formik.errors.group}
								/>
							</Box>

							{formik.values.type?.toLowerCase() === "personal" && (
								<Box sx={{ mb: "1rem" }}>
									<TextFieldComponent
										value={formik.values.recipient}
										onChange={formik.handleChange}
										label="Recipient"
										name="recipient"
										error={
											formik.touched.recipient &&
											Boolean(formik.errors.recipient)
										}
										helperText={
											formik.touched.recipient && formik.errors.recipient
										}
									/>
								</Box>
							)}

							<Box sx={{ mb: "1rem" }}>
								<SelectFieldComponent
									width="100%"
									values={actionScreensData}
									value={formik.values.actionScreen}
									onChange={formik.handleChange}
									label="Action Screen"
									id="actionScreen"
									name="actionScreen"
									menuValue="code"
									displayValue="name"
									error={
										formik.touched.actionScreen &&
										Boolean(formik.errors.actionScreen)
									}
									helperText={
										formik.touched.actionScreen && formik.errors.actionScreen
									}
								/>
							</Box>

							<SubmitButton
								text="push notification"
								loading={formik.isSubmitting}
								disabled={!formik.dirty || formik.isSubmitting}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default PushNotification;

import { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import ApproveModal from "../../../components/common/ApproveModal";
import RejectModal from "../../../components/common/RejectModal";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/PartnerDiscountTable";
import ViewPartnerModal from "../../../components/common/ViewPartnerDiscountModal";
import {
	fetchDiscounts,
	enableDiscount,
	disableDiscount,
} from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const DISCOUNT_STATUSES = Object.freeze({
	INACTIVE: 0,
	ACTIVE: 1,
	PENDING: 3,
});

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const PartnersDiscounts = () => {
	const [selectedItem, setSelectedItem] = useState({});
	const [searchTerm, setSearchTerm] = useState("");
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);
	const [comment, setComment] = useState("");
	const [openApproveModal, setOpenApproveModal] = useState(false);
	const [openRejectModal, setOpenRejectModal] = useState(false);

	const { user, region, bizFrom, role } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: partnerDiscountData = [],
		isLoading: partnerDiscountLoading,
		isError: partnerDiscountError,
		error: partnerDiscountErrorMessage,
	} = useQuery(
		["get-admin-partner-discount", region?.region],
		async () =>
			fetchDiscounts({ region: region?.region, username: user?.username }),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region),
		}
	);

	// console.log({ partnerDiscountData });

	useEffect(() => {
		if (partnerDiscountError) {
			if (partnerDiscountErrorMessage.response) {
				toast.error(partnerDiscountErrorMessage.response.data?.message);
			} else if (partnerDiscountErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", partnerDiscountErrorMessage.message);
			}
		}
	}, [partnerDiscountError, partnerDiscountErrorMessage]);

	const { mutate: enableDiscountMutation, isLoading: enableDiscountLoading } =
		useMutation("enable-discount", enableDiscount, {
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setSelectedItem({});
				setOpenApproveModal(false);
				setComment("");
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-partner-discount");
			},
		});

	const { mutate: disableDiscountMutation, isLoading: disableDiscountLoading } =
		useMutation("disable-discount", disableDiscount, {
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setSelectedItem({});
				setOpenRejectModal(false);
				setComment("");
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-partner-discount");
			},
		});

	const handleEnabaleDiscount = async () => {
		const payload = {
			partnerID: selectedItem.partnerID,
			username: user.username,
			admin: user.fullName,
			region: region?.region,
			bizFrom,
			role,
			comment: comment || null,
		};

		await enableDiscountMutation(payload);
	};

	const handleDisableDiscount = async () => {
		const payload = {
			partnerID: selectedItem.partnerID,
			username: user.username,
			admin: user.fullName,
			region: region?.region,
			bizFrom,
			role,
			comment,
		};

		await disableDiscountMutation(payload);
	};

	const displayed = useMemo(() => {
		if (partnerDiscountData.length) {
			let filteredPartners = [...partnerDiscountData];
			if (searchTerm) {
				filteredPartners = filteredPartners.filter((partner) => {
					return (
						partner.partnerName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.username
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.emailAddress
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.phoneNumber
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredPartners;
		}
		return partnerDiscountData;
	}, [partnerDiscountData, searchTerm]);

	const activePartnersDiscounts = useMemo(() => {
		if (partnerDiscountData.length) {
			let filteredPartners = [
				...partnerDiscountData?.filter(
					(item) => Number(item.discountStatus) === DISCOUNT_STATUSES.ACTIVE
				),
			];
			if (searchTerm) {
				filteredPartners = filteredPartners.filter((partner) => {
					return (
						partner.partnerName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.username
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.emailAddress
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.phoneNumber
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredPartners;
		}
		return partnerDiscountData;
	}, [partnerDiscountData, searchTerm]);

	const inacctivePartnersDiscounts = useMemo(() => {
		if (partnerDiscountData.length) {
			let filteredPartners = [
				...partnerDiscountData?.filter(
					(item) => Number(item.discountStatus) === DISCOUNT_STATUSES.INACTIVE
				),
			];
			if (searchTerm) {
				filteredPartners = filteredPartners.filter((partner) => {
					return (
						partner.partnerName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.username
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.emailAddress
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.phoneNumber
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredPartners;
		}
		return partnerDiscountData;
	}, [partnerDiscountData, searchTerm]);

	const pendingPartnersDiscounts = useMemo(() => {
		if (partnerDiscountData.length) {
			let filteredPartners = [
				...partnerDiscountData?.filter(
					(item) => Number(item.discountStatus) === DISCOUNT_STATUSES.PENDING
				),
			];
			if (searchTerm) {
				filteredPartners = filteredPartners.filter((partner) => {
					return (
						partner.partnerName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.username
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.emailAddress
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.phoneNumber
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredPartners;
		}
		return partnerDiscountData;
	}, [partnerDiscountData, searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
				width: "100%",
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="partner discounts"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Pending Discounts"
				/>
			</Box>

			<Box sx={{ mb: "2rem" }}>
				<Tabs
					value={currentTab}
					onChange={(event, newValue) => {
						setCurrentTab(newValue);
					}}
					aria-label="partners discount status tab"
				>
					<Tab label="All" {...a11yProps(0)} />
					<Tab label="Active" {...a11yProps(1)} />
					<Tab label="Inactive" {...a11yProps(2)} />
					<Tab label="Pending" {...a11yProps(3)} />
				</Tabs>
			</Box>

			<Box
				sx={{
					// width: { md: "78vw", xs: "95vw" },
					width: "100%",
					overflow: "auto",
					maxWidth: "100%",
					boxSizing: "border-box",
				}}
			>
				{currentTab === 0 && (
					<Table
						data={displayed}
						loading={partnerDiscountLoading}
						setSelectedItem={setSelectedItem}
						handleEnabaleDiscount={() => setOpenApproveModal(true)}
						handleDeactivatePartner={() => setOpenRejectModal(true)}
						handleView={() => setViewDetailsModalOpen(true)}
					/>
				)}
				{currentTab === 1 && (
					<Table
						data={activePartnersDiscounts}
						loading={partnerDiscountLoading}
						setSelectedItem={setSelectedItem}
						handleEnabaleDiscount={() => setOpenApproveModal(true)}
						handleDeactivatePartner={() => setOpenRejectModal(true)}
						handleView={() => setViewDetailsModalOpen(true)}
					/>
				)}
				{currentTab === 2 && (
					<Table
						data={inacctivePartnersDiscounts}
						loading={partnerDiscountLoading}
						setSelectedItem={setSelectedItem}
						handleEnabaleDiscount={() => setOpenApproveModal(true)}
						handleDeactivatePartner={() => setOpenRejectModal(true)}
						handleView={() => setViewDetailsModalOpen(true)}
					/>
				)}
				{currentTab === 3 && (
					<Table
						data={pendingPartnersDiscounts}
						loading={partnerDiscountLoading}
						setSelectedItem={setSelectedItem}
						handleEnabaleDiscount={() => setOpenApproveModal(true)}
						handleDeactivatePartner={() => setOpenRejectModal(true)}
						handleView={() => setViewDetailsModalOpen(true)}
					/>
				)}
			</Box>

			<ViewPartnerModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Partner's Discount Details"
				data={selectedItem}
			/>

			<ApproveModal
				open={openApproveModal}
				handleClose={() => {
					setOpenApproveModal(false);
					setComment("");
				}}
				title="Approve Partner Discount"
				subtitle=""
				handleApprove={handleEnabaleDiscount}
				loading={enableDiscountLoading}
				comment={comment}
				setComment={setComment}
			/>

			<RejectModal
				open={openRejectModal}
				handleClose={() => {
					setOpenRejectModal(false);
					setComment("");
				}}
				title="Reject Partner Discount"
				subtitle=""
				handleReject={handleDisableDiscount}
				loading={disableDiscountLoading}
				comment={comment}
				setComment={setComment}
			/>
		</Box>
	);
};

export default PartnersDiscounts;

import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/EventsTable";
import ApproveModal from "../../../components/common/ApproveModal";
import RejectModal from "../../../components/common/RejectModal";
import ViewEventDetailsModal from "../../../components/common/ViewEventDetailsModal";
import {
	fetchAdminEvents,
	approveEvent,
	rejectEvent,
} from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const Events = () => {
	const [selectedItem, setSelectedItem] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [comment, setComment] = useState("");
	const [openApproveModal, setOpenApproveModal] = useState(false);
	const [openRejectModal, setOpenRejectModal] = useState(false);

	const { region, user, bizFrom, role } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: eventsData = [],
		isLoading: eventsLoading,
		isError: eventsError,
		error: eventsErrorMessage,
	} = useQuery(
		["get-admin-events", region.region],
		async () =>
			fetchAdminEvents({
				region: region.region,
				bizFrom,
				role,
				username: user?.username,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (eventsError) {
			if (eventsErrorMessage.response) {
				toast.error(eventsErrorMessage.response.data?.message);
			} else if (eventsErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", eventsErrorMessage.message);
			}
		}
	}, [eventsError, eventsErrorMessage]);

	const { mutate: approveEventMutation, isLoading: approvetLoading } =
		useMutation("approve-event", approveEvent, {
			onSuccess: (dataSuccess) => {
				setOpenApproveModal(false);
				toast.success(dataSuccess?.message);
				setComment("");
				setSelectedItem({});
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-events");
			},
		});

	const { mutate: rejectEventMutation, isLoading: rejectLoading } = useMutation(
		"reject-event",
		rejectEvent,
		{
			onSuccess: (dataSuccess) => {
				setOpenRejectModal(false);
				toast.success(dataSuccess?.message);
				setComment("");
				setSelectedItem({});
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-events");
			},
		}
	);

	const handleApproveEvents = () => {
		const payload = {
			username: user?.username,
			admin: user.fullName,
			region: region?.region,
			bizFrom,
			role,
			eventId: selectedItem?.eventId,
			comment,
			ID: selectedItem?.id,
		};

		approveEventMutation(payload);
	};

	const handleRejectEvents = () => {
		const payload = {
			username: user?.username,
			admin: user.fullName,
			region: region?.region,
			bizFrom,
			role,
			eventId: selectedItem?.eventId,
			comment,
			ID: selectedItem?.id,
		};

		rejectEventMutation(payload);
	};

	useEffect(() => {
		if (eventsData.length && !eventsLoading) {
			setDisplayed(eventsData);
			setAllData(eventsData);
		}
	}, [eventsData, eventsLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.partnerName
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						item.eventName?.toLowerCase()?.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="events"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Events"
				/>
			</Box>
			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table
					data={displayed}
					loading={eventsLoading}
					setSelectedItem={setSelectedItem}
					handleApproveEvents={() => setOpenApproveModal(true)}
					handleRejectEvents={() => setOpenRejectModal(true)}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</Box>

			<ApproveModal
				open={openApproveModal}
				handleClose={() => {
					setOpenApproveModal(false);
					setComment("");
				}}
				title="Approve Event"
				subtitle=""
				handleApprove={handleApproveEvents}
				loading={approvetLoading}
				comment={comment}
				setComment={setComment}
			/>

			<RejectModal
				open={openRejectModal}
				handleClose={() => {
					setOpenRejectModal(false);
					setComment("");
				}}
				title="Reject Event"
				subtitle=""
				handleReject={handleRejectEvents}
				loading={rejectLoading}
				comment={comment}
				setComment={setComment}
			/>

			<ViewEventDetailsModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Event's Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default Events;

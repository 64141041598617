import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/DeliveryTable";
import ViewDetailsModal from "../../../components/common/ViewDeliveryModal";
import { kwikGetTasks, kwikCreateTask } from "../../../api/admin/dashboard";

const Delivery = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);

	const { region, user } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: tasksData = [],
		isLoading: tasksLoading,
		isError: tasksError,
		error: tasksErrorMessage,
	} = useQuery(
		["kwik-get-tasks", region?.region],
		async () => kwikGetTasks(region?.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (tasksError) {
			if (tasksErrorMessage.response) {
				toast.error(tasksErrorMessage.response.data?.message);
			} else if (tasksErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", tasksErrorMessage.message);
			}
		}
	}, [tasksError, tasksErrorMessage]);

	useEffect(() => {
		if (tasksData.length && !tasksLoading) {
			setDisplayed([...tasksData]);
			setAllData([...tasksData]);
		}
	}, [tasksData, tasksLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.delivery_fullName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.unique_order_id
							?.toLowerCase()
							?.includes(searchTerm?.toLowerCase()) ||
						item.delivery_status
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const {
		mutate: kwikCreateTaskMutation,
		//  isLoading: kwikCreateTaskLoading
	} = useMutation("kwik-create-task", kwikCreateTask, {
		onSuccess: (dataSuccess) => {
			toast.success(dataSuccess?.message);
			setSelectedItem({});
		},
		onError: (errors) => {
			if (errors.response) {
				toast.error(errors.response.data.message);
			} else if (errors.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", errors.message);
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries("kwik-get-tasks");
		},
	});

	const handleCreateTask = async () => {
		const payload = {
			region: region?.region,
			username: user?.username,
			dealID: selectedItem?.dealID,
		};
		await kwikCreateTaskMutation(payload);
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Delivery"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={() => {}}
					label="Search Deliveries"
					textFieldWidth="15rem"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table
					data={displayed}
					loading={tasksLoading}
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
					handleCreateTask={() => handleCreateTask()}
				/>
			</Box>

			<ViewDetailsModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Delivery Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default Delivery;

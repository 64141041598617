import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import closeIcon from "../../assets/icons/close-icon.svg";
import successIcon from "../../assets/icons/deal-success-icon.svg";

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	message,
	handleSubmit,
	loading,
}) {
	return (
		<div style={{ fontSize: "0.8rem" }}>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "2rem", width: "2rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography variant="h6" component="div" sx={{ fontWeight: 700 }}>
							{title}
						</Typography>
					</Box>
					<Divider />
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						textAlign: "center",
						width: "30rem",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "1rem",
							width: "100%",
						}}
					>
						<Box sx={{ height: "7.25rem", width: "7.25rem", mb: "1rem" }}>
							<img src={successIcon} alt="" height="100%" width="100%" />
						</Box>
						<Typography variant="body2" gutterBottom>
							{message}
						</Typography>
					</Box>

					<Stack
						direction="row"
						spacing={2}
						sx={{
							marginBottom: "1rem",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Button
							sx={{
								paddingLeft: "3rem",
								paddingRight: "3rem",
							}}
							variant="contained"
							onClick={() => {
								handleSubmit();
							}}
						>
							{loading ? (
								<CircularProgress size="1.5rem" sx={{ color: "#fff" }} />
							) : (
								"yes, confirm"
							)}
						</Button>
						<Button
							sx={{
								paddingLeft: "3rem",
								paddingRight: "3rem",
							}}
							variant="outlined"
							onClick={handleClose}
						>
							back
						</Button>
					</Stack>
				</DialogContent>
			</Dialog>
		</div>
	);
}

import { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import SelectFieldComponent from "../../../components/common/SelectFieldComponent";
import NumberTextField from "../../../components/common/NumberTextField";
import SubmitButton from "../../../components/common/SubmitButton";
import { updateAppVersion, getAppVersion } from "../../../api/admin/dashboard";

const fields = {
	platform: "",
	version: "",
};

const validationSchema = yup.object({
	platform: yup
		.string("Please choose platform")
		.required("Platform is required"),
	version: yup
		.number("Please enter app version number")
		.required("Version number is required"),
});

const AppVersion = () => {
	const { region, bizFrom, role, user } = useSelector((state) => state.auth);

	const {
		data: appVersionAndroidData = [],
		// isLoading: appVersionAndroidLoading,
		isError: appVersionAndroidError,
		error: appVersionAndroidErrorMessage,
	} = useQuery(
		["get-app-version-android", region.region],
		async () =>
			getAppVersion({
				region: region.region,
				bizFrom,
				role,
				username: user?.username,
				platform: "android",
			}),
		{
			select: (data) => data?.app_info?.version,
			staleTime: 4 * 60 * 1000,
		}
	);

	const {
		data: appVersionIosData = [],
		// isLoading: appVersionIosLoading,
		isError: appVersionIosError,
		error: appVersionIosErrorMessage,
	} = useQuery(
		["get-app-version-ios", region.region],
		async () =>
			getAppVersion({
				region: region.region,
				bizFrom,
				role,
				username: user?.username,
				platform: "ios",
			}),
		{
			select: (data) => data?.app_info?.version,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (appVersionAndroidError) {
			if (appVersionAndroidErrorMessage.response) {
				toast.error(appVersionAndroidErrorMessage.response.data?.message);
			} else if (appVersionAndroidErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", appVersionAndroidErrorMessage.message);
			}
		}
		if (appVersionIosError) {
			if (appVersionIosErrorMessage.response) {
				toast.error(appVersionIosErrorMessage.response.data?.message);
			} else if (appVersionIosErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", appVersionIosErrorMessage.message);
			}
		}
	}, [
		appVersionAndroidError,
		appVersionAndroidErrorMessage,
		appVersionIosError,
		appVersionIosErrorMessage,
	]);

	const handleSubmit = async (values, stopSubmitLoading, resetForm) => {
		try {
			const payload = {
				platform: values.platform,
				version: Number(values.version),
			};

			const result = await updateAppVersion(payload);
			toast.success(result?.message);
			stopSubmitLoading();
			resetForm();
		} catch (err) {
			stopSubmitLoading();
			if (err.response) {
				toast.error(err.response.data.message);
			} else if (err.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", err.message);
			}
		}
	};

	const formik = useFormik({
		initialValues: fields,
		validationSchema,
		onSubmit: (values) => {
			handleSubmit(
				values,
				() => formik.setSubmitting(false),
				() => formik.resetForm()
			);
		},
	});

	return (
		<Box
			sx={{
				minHeight: "100vh",
				backgroundColor: "#fff",
				position: "relative",
			}}
		>
			<Box
				sx={{
					padding: { md: "3rem", sm: "1rem", xs: 0 },
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							width: "33rem",
							border: "1px solid #E6E6E6",
							borderRadius: "8px",
							pb: { md: "2rem", xs: "1rem" },
						}}
					>
						<Typography
							variant="h6"
							sx={{
								textAlign: "center",
								fontWeight: 700,
								textTransform: "capitalize",
								color: "#303030",
								p: { md: "2rem", xs: "1rem" },
							}}
						>
							update app version
						</Typography>
						<Box
							component="form"
							sx={{
								p: { md: "1rem 3rem", xs: "1rem" },
								// border: "1px solid red",
							}}
							noValidate
							autoComplete="off"
							onSubmit={formik.handleSubmit}
						>
							<Box sx={{ mb: "1rem" }}>
								<SelectFieldComponent
									width="100%"
									values={["ios", "android"]}
									value={formik.values.platform}
									onChange={formik.handleChange}
									label="Choose Platform"
									id="platform"
									name="platform"
									// menuValue="platform"
									// displayValue="platform"
									required
									error={
										formik.touched.platform && Boolean(formik.errors.platform)
									}
									helperText={formik.touched.platform && formik.errors.platform}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								{formik.values.platform && (
									<Typography variant="body1">
										Current version:{" "}
										{formik.values.platform === "ios"
											? appVersionIosData
											: appVersionAndroidData}
									</Typography>
								)}
								<NumberTextField
									value={formik.values.version}
									onChange={formik.handleChange}
									required
									label="App Version"
									name="version"
									error={
										formik.touched.version && Boolean(formik.errors.version)
									}
									helperText={formik.touched.version && formik.errors.version}
								/>
							</Box>

							<SubmitButton
								text="update version"
								loading={formik.isSubmitting}
								disabled={!formik.dirty || formik.isSubmitting}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default AppVersion;

import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import { logout as adminLogout } from "../../slices/authSlice";
// import { ROLES } from "../../utils/constants";

// eslint-disable-next-line no-unused-vars
const DesktopProfileMenu = ({ anchorEl, open, handleClose, role }) => {
	const dispatch = useDispatch();

	return (
		<Menu
			id="deskotp-profile-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				"aria-labelledby": "basic-button",
			}}
			sx={{ textAlign: "right" }}
		>
			{/* <MenuItem onClick={handleClose} sx={{ textAlign: "right" }}>
				Profile
			</MenuItem>
			<MenuItem onClick={handleClose} sx={{ textAlign: "right" }}>
				My account
			</MenuItem> */}

			{/* {role === ROLES.ADMIN && ( */}
			<MenuItem onClick={() => dispatch(adminLogout())}>logout</MenuItem>
			{/* )} */}
		</Menu>
	);
};

export default DesktopProfileMenu;

import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Table from "../../../components/Admin/PayWithSmartDealsTable";
import SuccessModal from "../../../components/common/SuccessModal";
import ViewPayWithSmartDealsModal from "../../../components/common/ViewPayWithSmartDealsModal";
import CreatePayPartnerModal from "../../../components/common/CreatePayPartnerModal";
import EditPayPartnerModal from "../../../components/common/EditPayPartnerModal";
import DeleteModal from "../../../components/common/Deletemodal";
import {
	fetchPayPartners,
	deletePayPartner,
	getBanks,
} from "../../../api/admin/dashboard";

const PayWithSmartDeals = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [openSuccessModal, setOpenSuccessModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [createPartnerModalOpen, setCreatePartnerModalOpen] = useState(false);
	const [editPartnerModalOpen, setEditPartnerModalOpen] = useState(false);
	const [createPartnerSuccessModalOpen, setCreatePartnerSuccessModalOpen] =
		useState(false);
	const [editPartnerSuccessModalOpen, setEditPartnerSuccessModalOpen] =
		useState(false);
	const [createMessage, setCreateMessage] = useState("");
	const [editMessage, setEditMessage] = useState("");
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const { user, bizFrom, region, role } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: productData = [],
		isLoading: productLoading,
		isError: productError,
		error: productErrorMessage,
	} = useQuery(
		["fetch-pay-partners", region?.region, bizFrom, role],
		async () =>
			fetchPayPartners({
				region: region?.region,
				bizFrom,
				role,
				username: user?.username,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region) && Boolean(bizFrom) && Boolean(role),
		}
	);

	const {
		data: banksData = [],
		// isLoading: banksLoading,
		isError: banksError,
		error: banksErrorMessage,
	} = useQuery(
		["get-banks", region.region],
		async () => getBanks(region.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (productError) {
			if (productErrorMessage.response) {
				toast.error(productErrorMessage.response.data?.message);
			} else if (productErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", productErrorMessage.message);
			}
		}
		if (banksError) {
			if (banksErrorMessage.response) {
				toast.error(banksErrorMessage.response.data?.message);
			} else if (banksErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", banksErrorMessage.message);
			}
		}
	}, [productError, productErrorMessage, banksError, banksErrorMessage]);

	const { mutate: deletePayPartnerMutation, isLoading: deleteLoading } =
		useMutation("delete-pay-partner", deletePayPartner, {
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setSelectedItem({});
				setDeleteModalOpen(false);
				setViewDetailsModalOpen(false);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("fetch-pay-partners");
			},
		});

	const handleDeletePartner = () => {
		const payload = {
			ID: selectedItem?.id,
			username: user?.username,
		};

		deletePayPartnerMutation(payload);
	};

	useEffect(() => {
		if (productData.length && !productLoading) {
			setDisplayed(productData);
			setAllData(productData);
		}
	}, [productData, productLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.partnerName
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						item.emailAddress?.toLowerCase().includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			{/* header */}
			<Box sx={{ mb: "2rem" }}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: { sm: "row", xs: "column" },
						mb: "1rem",
					}}
				>
					<Typography
						variant="h6"
						sx={{ fontWeight: 700, textTransform: "capitalize", mr: "auto" }}
						gutterBottom
					>
						Pay with SmartDeals
					</Typography>

					<Box
						sx={{
							display: "flex",
							// justifyContent: { xs: "flex-start", sm: "flex-end" },
							width: { xs: "100%", sm: "auto" },
						}}
					>
						<Box sx={{ ml: { md: "auto", xs: 0 }, mr: "1rem" }}>
							<TextField
								id="table-search-box"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								label="Search Partners"
								variant="outlined"
								size="small"
								sx={{
									label: {
										color: "#594E4E !important",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										borderColor: "#594E4E !important",
									},
								}}
							/>
						</Box>

						<Box sx={{ ml: { md: "auto", xs: 0 } }}>
							<Button
								variant="contained"
								color="primary"
								sx={{ textTransform: "capitalize" }}
								onClick={() => setCreatePartnerModalOpen(true)}
							>
								add new partner
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>

			{/* table */}
			<Box
				sx={{
					// width: { md: "78vw", xs: "95vw" },
					width: "100%",
					overflow: "auto",
					maxWidth: "100%",
					boxSizing: "border-box",
				}}
			>
				<Table
					data={displayed}
					loading={productLoading}
					setSelectedItem={setSelectedItem}
					handleDelete={() => setDeleteModalOpen(true)}
					handleView={() => setViewDetailsModalOpen(true)}
					handleEdit={() => setEditPartnerModalOpen(true)}
				/>
			</Box>
			<SuccessModal
				open={openSuccessModal}
				handleClose={() => setOpenSuccessModal(false)}
				text="Deal Activated"
			/>
			<ViewPayWithSmartDealsModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Pay Partner Details"
				data={selectedItem}
			/>
			<CreatePayPartnerModal
				open={createPartnerModalOpen}
				handleClose={() => {
					setCreatePartnerModalOpen(false);
				}}
				title="Create New Pay Partner"
				setCreatePartnerSuccessModalOpen={setCreatePartnerSuccessModalOpen}
				setCreateMessage={setCreateMessage}
				banksData={banksData}
			/>
			<EditPayPartnerModal
				open={editPartnerModalOpen}
				handleClose={() => {
					setSelectedItem({});
					setEditPartnerModalOpen(false);
				}}
				title="Edit Pay Partner"
				setEditPartnerSuccessModalOpen={setEditPartnerSuccessModalOpen}
				setEditMessage={setEditMessage}
				selectedPayPartner={selectedItem}
				banksData={banksData}
			/>

			<DeleteModal
				open={deleteModalOpen}
				handleClose={() => setDeleteModalOpen(false)}
				title="Delete Pay Partner"
				subtitle=""
				handleDelete={handleDeletePartner}
				loading={deleteLoading}
			/>
			<SuccessModal
				open={createPartnerSuccessModalOpen}
				handleClose={() => setCreatePartnerSuccessModalOpen(false)}
				text={createMessage}
			/>
			<SuccessModal
				open={editPartnerSuccessModalOpen}
				handleClose={() => setEditPartnerSuccessModalOpen(false)}
				text={editMessage}
			/>
		</Box>
	);
};

export default PayWithSmartDeals;

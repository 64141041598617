import { Switch, Route } from "react-router-dom";
import AdminUsers from "../../views/Admin/AdminUsers";
import ChangePassword from "../../views/Admin/ChangePassword";
import AdminRoles from "../../views/Admin/AdminRoles";
import SubscriptionPlans from "../../views/Admin/SubscriptionPlans";
import AppVersion from "../../views/Admin/AppVersion";
import ExtendSubscription from "../../views/Admin/ExtendSubscription";
import ProctectedRoute from "../../../components/common/ProtectedRoutes";
import { ROLES } from "../../../utils/constants";

const MainRoutes = () => {
	return (
		<div>
			<Switch>
				<ProctectedRoute
					path="/settings/admin-users"
					component={AdminUsers}
					roles={[ROLES.EXECUTIVE, ROLES.ADMIN]}
				/>
				<Route path="/settings/change-password" component={ChangePassword} />
				<ProctectedRoute
					path="/settings/user-roles"
					roles={[ROLES.EXECUTIVE, ROLES.ADMIN]}
					component={AdminRoles}
				/>
				<ProctectedRoute
					path="/settings/subscription-plans"
					roles={[ROLES.EXECUTIVE, ROLES.ADMIN]}
					component={SubscriptionPlans}
				/>
				<ProctectedRoute
					path="/settings/update-app-version"
					roles={[ROLES.EXECUTIVE, ROLES.ADMIN]}
					component={AppVersion}
				/>
				<ProctectedRoute
					path="/settings/extend-subscription-validity"
					roles={[ROLES.EXECUTIVE]}
					component={ExtendSubscription}
				/>
			</Switch>
		</div>
	);
};

export default MainRoutes;

import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Box, Tabs, Tab } from "../../../components/common/muiComponents";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/CardCreationTable";
import ViewVirtualCardDetails from "../../../components/common/ViewVirtualCardDetails";
import { fetchVirtualCardsData } from "../../../api/admin/dashboard";
import {
	downloadExcel,
	filterArrayBySearchTerm,
} from "../../../utils/helpers/functions";

const CATEGORIES = {
	CREATE_CARD: "create_card",
	FUND_CARD: "fund_card",
};

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
	width: "100%",
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const VirtualCards = () => {
	const [selectedItem, setSelectedItem] = useState({});
	const [searchTerm, setSearchTerm] = useState("");
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);

	const { user, region } = useSelector((state) => state.auth);

	const {
		data: cardCreationData = [],
		isLoading: cardCreationLoading,
		isError: cardCreationError,
		error: cardCreationErrorMessage,
	} = useQuery(
		["virtual-cards-creation", region?.region],
		async () =>
			fetchVirtualCardsData({
				region: region?.region,
				username: user?.username,
				admin: user?.fullName,
				category: CATEGORIES.CREATE_CARD,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region),
		}
	);

	const {
		data: cardFundingData = [],
		isLoading: cardFundingLoading,
		isError: cardFundingError,
		error: cardFundingErrorMessage,
	} = useQuery(
		["virtual-cards-funding", region?.region],
		async () =>
			fetchVirtualCardsData({
				region: region?.region,
				username: user?.username,
				admin: user?.fullName,
				category: CATEGORIES.FUND_CARD,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region),
		}
	);

	useEffect(() => {
		if (cardCreationError) {
			if (cardCreationErrorMessage.response) {
				toast.error(cardCreationErrorMessage.response.data?.message);
			} else if (cardCreationErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", cardCreationErrorMessage.message);
			}
		}
		if (cardFundingError) {
			if (cardFundingErrorMessage.response) {
				toast.error(cardFundingErrorMessage.response.data?.message);
			} else if (cardFundingErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", cardFundingErrorMessage.message);
			}
		}
	}, [
		cardCreationError,
		cardCreationErrorMessage,
		cardFundingError,
		cardFundingErrorMessage,
	]);

	const displayedCardCreationData = useMemo(() => {
		if (cardCreationData.length) {
			let filteredData = [...cardCreationData];
			if (searchTerm) {
				filteredData = filterArrayBySearchTerm(filteredData, searchTerm);
			}
			return filteredData;
		}
		return cardCreationData;
	}, [cardCreationData, searchTerm]);

	const displayedCardFundingData = useMemo(() => {
		if (cardFundingData.length) {
			let filteredData = [...cardFundingData];
			if (searchTerm) {
				filteredData = filterArrayBySearchTerm(filteredData, searchTerm);
			}
			return filteredData;
		}
		return cardFundingData;
	}, [cardFundingData, searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayedCardCreationData, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "1rem" }}>
				<TableHeader
					text="Virtual cards"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search records"
				/>
			</Box>

			<Box sx={{ mb: "2rem" }}>
				<Tabs
					value={currentTab}
					onChange={(event, newValue) => {
						setCurrentTab(newValue);
					}}
					aria-label="virtual cards tabs"
				>
					<Tab label="Card Creation" {...a11yProps(0)} />
					<Tab label="Card Funding" {...a11yProps(1)} />
				</Tabs>
			</Box>

			{currentTab === 0 && (
				<Table
					data={displayedCardCreationData}
					loading={cardCreationLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			)}

			{currentTab === 1 && (
				<Table
					data={displayedCardFundingData}
					loading={cardFundingLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			)}

			<ViewVirtualCardDetails
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title={
					currentTab === 0
						? "View Card Creation Details"
						: "View Card Funding Details"
				}
				data={selectedItem}
				setData={setSelectedItem}
			/>
		</Box>
	);
};

export default VirtualCards;

import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Table from "../../../components/Admin/ActivityLogTable";
import ViewActivityLogDetails from "../../../components/common/ViewActivityLogDetails";
import { activityLog } from "../../../api/admin/dashboard";

const ACtivityLog = () => {
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);

	const { region } = useSelector((state) => state.auth);

	const {
		data: activityLogData = [],
		isLoading: activityLogLoading,
		isError: activityLogError,
		error: activityLogErrorMessage,
	} = useQuery(
		["get-activity-log", region?.region],
		async () => activityLog(region?.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (activityLogError) {
			if (activityLogErrorMessage.response) {
				toast.error(activityLogErrorMessage.response.data?.message);
			} else if (activityLogErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", activityLogErrorMessage.message);
			}
		}
	}, [activityLogError, activityLogErrorMessage]);

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					mb: "1rem",
				}}
			>
				<Typography
					variant="h6"
					sx={{ fontWeight: 700, textTransform: "capitalize", mr: "auto" }}
					gutterBottom
				>
					Activity Log
				</Typography>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table
					data={activityLogData}
					loading={activityLogLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</Box>
			<ViewActivityLogDetails
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Activity Log Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default ACtivityLog;

import { useState, useMemo, useContext } from "react";
import { useSelector } from "react-redux";

import {
	Box,
	Typography,
	Drawer,
	Toolbar,
	Tabs,
	Tab,
} from "../../../components/common/muiComponents";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/MarketDataTable";
import BidOfferTable from "../../../components/Admin/BidOfferTable";
import NGXTradesTables from "../../../components/Admin/NGXTradesTables";
import TabPanel from "../../../components/common/TabPanel";
import ViewMoreStockDetails from "../../../components/common/ViewMoreStockDetails";
import { SocketContext } from "../../../context/chatSocket";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const NGX = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedRow, setSelectedRow] = useState({});
	const [openViewModal, setOpenViewModal] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [currentTabValue, setCurrentTabValue] = useState(0);

	const { user } = useSelector((state) => state.auth);
	const {
		socket,
		// clientId,
		stockRecommendations,
		stockRecommendationsPrice,
		stockRecommendationsLoading,
		stockRecommendationsPriceLoading,
		bidlevels,
		offerLevels,
		trades,
		bidOfferTradesLoading,
		setBidOfferTradesLoading,
	} = useContext(SocketContext);

	const displayedRecommemdationByBidsAndOffers = useMemo(() => {
		if (searchTerm) {
			return stockRecommendations.filter((item) => {
				return (
					item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item?.symbol?.toLowerCase().includes(searchTerm.toLowerCase())
				);
			});
		}
		return stockRecommendations;
	}, [stockRecommendations, searchTerm]);

	const displayedRecommemdationByPrice = useMemo(() => {
		if (searchTerm) {
			return stockRecommendationsPrice.filter((item) => {
				return (
					item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item?.symbol?.toLowerCase().includes(searchTerm.toLowerCase())
				);
			});
		}
		return stockRecommendationsPrice;
	}, [stockRecommendationsPrice, searchTerm]);

	const handleStockSelect = (data) => {
		const payload = {
			method: "bid_offer",
			symbol: data?.symbol,
			accountType: user?.accountType,
			username: user?.username,
		};

		socket.emit("bid_offer", payload);
		setBidOfferTradesLoading(true);
	};

	const handleExcelExport = () => {};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Stock Recommendations"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search report"
				/>
			</Box>

			<Box sx={{ mb: "2rem" }}>
				<Tabs
					value={currentTabValue}
					onChange={(event, newValue) => {
						setCurrentTabValue(newValue);
					}}
					aria-label="stock recommendations tabs"
				>
					<Tab
						label="Stock Recommendation by Bid and Offer"
						{...a11yProps(0)}
					/>
					<Tab
						label="Stock Recommendation by Price Movement."
						{...a11yProps(1)}
					/>
				</Tabs>
			</Box>

			<TabPanel value={currentTabValue} index={0}>
				<Box
					sx={{
						width: "100%",
						overflow: "auto",
					}}
				>
					<Table
						data={displayedRecommemdationByBidsAndOffers}
						loading={stockRecommendationsLoading}
						selectedRow={selectedRow}
						setSelectedRow={(data) => {
							setSelectedRow(data);
						}}
						handleStockSelect={(item) => {
							handleStockSelect(item);
							setOpenDrawer(true);
						}}
						handleViewDetails={() => {
							setOpenViewModal(true);
						}}
					/>
				</Box>
			</TabPanel>

			<TabPanel value={currentTabValue} index={1}>
				<Box
					sx={{
						width: "100%",
						overflow: "auto",
					}}
				>
					<Table
						data={displayedRecommemdationByPrice}
						loading={stockRecommendationsPriceLoading}
						selectedRow={selectedRow}
						setSelectedRow={(data) => {
							setSelectedRow(data);
						}}
						handleStockSelect={(item) => {
							handleStockSelect(item);
							setOpenDrawer(true);
						}}
						handleViewDetails={() => {
							setOpenViewModal(true);
						}}
					/>
				</Box>
			</TabPanel>

			<Drawer
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
				anchor="right"
				sx={{
					scrollbarWidth: "none",
					msOverflowStyle: "none",
					"&::-webkit-scrollbar": {
						display: "none",
					},
				}}
			>
				<Box
					sx={{
						width: "70vw",
						scrollbarWidth: "none",
						msOverflowStyle: "none",
						"&::-webkit-scrollbar": {
							display: "none",
						},
						padding: ".5rem",
					}}
				>
					<Toolbar />
					<Box
						sx={{
							width: "100%",
							display: "flex",
							gap: "1rem",
							justifyContent: "space-between",
						}}
					>
						<Box>
							<Typography variant="h6">Bid Prices</Typography>
							<BidOfferTable
								data={bidlevels}
								loading={bidOfferTradesLoading}
								symbol={selectedRow?.symbol}
							/>
						</Box>
						<Box>
							<Typography variant="h6">Offer Prices</Typography>
							<BidOfferTable
								data={offerLevels}
								loading={bidOfferTradesLoading}
								symbol={selectedRow?.symbol}
							/>
						</Box>
					</Box>
					<Box>
						<Typography variant="h6">Trades</Typography>
						<NGXTradesTables
							data={trades}
							loading={bidOfferTradesLoading}
							symbol={selectedRow?.symbol}
						/>
					</Box>
				</Box>
			</Drawer>

			<ViewMoreStockDetails
				open={openViewModal}
				handleClose={() => {
					setOpenViewModal(false);
				}}
				title="View Stock Details"
				data={selectedRow}
			/>
		</Box>
	);
};

export default NGX;

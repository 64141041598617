import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/CustomersTable";
import ViewClubMembersModal from "../../../components/common/ViewClubMembersModal";
import {
	fetchCustomers,
	disableCustomer,
	enableCustomer,
} from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const ClubMembers = () => {
	const [selectedItem, setSelectedItem] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);

	const { region, user, bizFrom, role } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: membersData = [],
		isLoading: membersLoading,
		isError: membersError,
		error: membersErrorMessage,
	} = useQuery(
		["get-admin-members", region.region],
		async () => fetchCustomers(region.region, bizFrom, role),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (membersError) {
			if (membersErrorMessage.response) {
				toast.error(membersErrorMessage.response.data?.message);
			} else if (membersErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", membersErrorMessage.message);
			}
		}
	}, [membersError, membersErrorMessage]);

	const { mutate: activateMembers } = useMutation(
		"activate-members",
		enableCustomer,
		{
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-members");
			},
		}
	);

	const { mutate: deactivateMembers } = useMutation(
		"deactivate-members",
		disableCustomer,
		{
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-members");
			},
		}
	);

	const handleActivateMembers = () => {
		const payload = {
			username: user.username,
			admin: user.fullName,
			region: region?.region,
			bizFrom,
			role,
		};

		activateMembers(payload);
	};

	const handleDeactivateMembers = () => {
		const payload = {
			username: user.username,
			admin: user.fullName,
			region: region?.region,
			bizFrom,
			role,
		};

		deactivateMembers(payload);
	};

	useEffect(() => {
		if (membersData.length && !membersLoading) {
			setDisplayed(membersData);
			setAllData(membersData);
		}
	}, [membersData, membersLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.fullName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.emailAddress
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.phoneNumber?.toLowerCase()?.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="club members"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Members"
				/>
			</Box>
			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table
					data={displayed}
					loading={membersLoading}
					setSelectedItem={setSelectedItem}
					handleActivatePartner={handleActivateMembers}
					handleDeactivatePartner={handleDeactivateMembers}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</Box>
			<ViewClubMembersModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Club Member's Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default ClubMembers;

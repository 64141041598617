import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import { dateFormatter, numberFormatter } from "../../utils/helpers/functions";

export default function StickyHeadTable({
	loading,
	data,
	setSelectedItem,
	handleView,
	selected,
	setSelected,
}) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const isSelected = (id) => selected.indexOf(id) !== -1;

	const handleSelectClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const onSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = data?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const firstPageElement = page * rowsPerPage + 1;

	useEffect(() => {
		if (firstPageElement > data?.length) {
			setPage(0);
		}
	}, [data, firstPageElement]);

	const columns = [
		{ id: "S/N", label: "S/N", minWidth: 10 },
		{ id: "amount", label: "Amount", minWidth: 80 },
		{ id: "requester", label: "Requester", minWidth: 170 },
		{ id: "accountType", label: "Account Type", minWidth: 170 },
		{ id: "bizFrom", label: "Business", minWidth: 80 },
		{ id: "status", label: "Status", minWidth: 80 },
		{ id: "requestDate", label: "Request Date", minWidth: 170 },
		{ id: "action", label: "Action", minWidth: 80 },
	];

	return (
		<Paper
			sx={{
				width: "100%",
				overflow: "hidden",
				marginBottom: "3rem",
			}}
		>
			<TableContainer sx={{ maxHeight: 440 }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									backgroundColor: "primary.main",
									color: "#fff !important",
									borderColor: "#fff !important",
									"& .MuiSvgIcon-root": {
										color: "#fff !important",
										borderColor: "#fff !important",
									},
								}}
							>
								<Checkbox
									color="primary"
									indeterminate={
										selected?.length > 0 && selected?.length < data?.length
									}
									checked={
										data?.length > 0 && selected?.length === data?.length
									}
									onChange={onSelectAllClick}
									inputProps={{
										"aria-label": "select all requests",
									}}
								/>
							</TableCell>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
									sx={{
										backgroundColor: "primary.main",
										color: "#fff",
									}}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{!loading &&
							data
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((item, index) => {
									const isItemSelected = isSelected(item.id);
									const labelId = `enhanced-table-checkbox-${index}`;
									return (
										<TableRow
											key={item.id}
											hover
											role="checkbox"
											onClick={(event) => handleSelectClick(event, item.id)}
											tabIndex={-1}
											aria-checked={isItemSelected}
											selected={isItemSelected}
											sx={{ cursor: "pointer" }}
										>
											<TableCell padding="checkbox" sx={{ p: "1rem" }}>
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														"aria-labelledby": labelId,
													}}
												/>
											</TableCell>
											<TableCell>{page * rowsPerPage + index + 1}</TableCell>
											<TableCell>
												{numberFormatter(item.amount) || ""}
											</TableCell>
											<TableCell>{item.requester || ""}</TableCell>
											<TableCell>{item.accountType || ""}</TableCell>
											<TableCell>{item.bizFrom || ""}</TableCell>
											<TableCell>{item?.status}</TableCell>
											<TableCell>{dateFormatter(item?.requestDate)}</TableCell>
											<TableCell>
												<IconButton
													onClick={(event) => {
														setSelectedItem(item);
														handleClick(event);
													}}
												>
													<MoreHorizIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									);
								})}
					</TableBody>
				</Table>
			</TableContainer>
			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						marginTop: "2rem",
					}}
				>
					<CircularProgress size="2rem" />
				</Box>
			)}
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={data.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Menu
				id="handle-activate-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "handle-activate-button",
				}}
			>
				<MenuItem
					onClick={() => {
						handleClose();
						handleView();
					}}
				>
					<VisibilityOutlinedIcon sx={{ mr: "0.5rem", fontSize: "1rem" }} />
					<Typography variant="body2">View</Typography>
				</MenuItem>
			</Menu>
		</Paper>
	);
}

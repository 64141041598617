import { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";

import Layout from "../../../components/Admin/SupportLayout";
import { SocketContext } from "../../../context/chatSocket";
import ConfirmModal from "../../../components/common/ConfirmModal";
import TransferModal from "../../../components/SupportChat/TransferModal";
import { fetchCustomerServiceAgent } from "../../../api/admin/dashboard";
import {
	drawerWidth,
	chatTimeDateFnsFormat,
	ROLES,
} from "../../../utils/constants";
import { newDateFormatter } from "../../../utils/helpers/functions";

// const buttonStyle = {
// 	textTransform: "none",
// };

const containerStyle = {
	display: "flex",
	flexDirection: "column",
	height: "100%",
	gap: "1rem",
	// border: "1px solid blue",
	py: "1rem",
};

const entryBoxStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	gap: "1rem",
};

const myMessageStyles = {
	marginLeft: "auto",
	// display: "flex",
	// flexDirection: "column",
	// alignItems: "flex-end",
};

const myMessageBodyStyles = {
	backgroundColor: "primary.main",
	// border: "1px solid #0048aa",
	borderRadius: "14px 14px 0 14px",
	color: "#fff",
	textAlign: "end",
};

const noActiveChatContainerStyle = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "100%",
	width: "100%",
};

const sendMessageButtonStyle = {
	borderRadisu: "50%",
	backgroundColor: "green",
	color: "#fff",
	border: "1px solid rgba(0,0,0,0.3)",
	"&:hover": {
		backgroundColor: "#013220",
	},
};

const eendSessionButtonStyle = {
	borderRadisu: "50%",
	backgroundColor: "primary.main",
	color: "#fff",
	border: "1px solid rgba(0,0,0,0.3)",
	"&:hover": {
		backgroundColor: "primary.dark",
	},
};

const transferButtonStyle = {
	borderRadisu: "50%",
	backgroundColor: "blue",
	color: "#fff",
	border: "1px solid rgba(0,0,0,0.3)",
	"&:hover": {
		backgroundColor: "#002564",
	},
};

const SupportChat = () => {
	const [text, setText] = useState("");
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [isTyping, setIsTyping] = useState(false);
	const [openTransferModal, setOpenTransferModal] = useState(false);
	const [selectedServiceAgent, setSelectedServiceAgent] = useState("");

	const { user, region } = useSelector((state) => state.auth);
	const {
		socket,
		clientId,
		messages,
		setMessages,
		currentRoom,
		setCurrentRoom,
		chatBoxRef,
		isChatEnded,
		setIsChatEnded,
		isOtherUserTyping,
		userTypingMessage,
	} = useContext(SocketContext);

	const {
		data: customerServiceAgentsData = [],
		isLoading: customerServiceAgentsLoading,
		isError: customerServiceAgentsError,
		error: customerServiceAgentsErrorMessage,
		refetch: customerServiceAgentsRefetch,
	} = useQuery(
		["fetch-custoner-service-agents", region?.region],
		async () =>
			fetchCustomerServiceAgent({
				region: region?.region,
				username: user?.username,
				admin: user?.username,
			}),
		{
			select: (data) => data?.data,
			staleTime: Infinity,
			enabled: !!region?.region,
		}
	);

	useEffect(() => {
		if (customerServiceAgentsError) {
			if (customerServiceAgentsErrorMessage.response) {
				toast.error(customerServiceAgentsErrorMessage.response.data?.message);
			} else if (customerServiceAgentsErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", customerServiceAgentsErrorMessage.message);
			}
		}
	}, [customerServiceAgentsError, customerServiceAgentsErrorMessage]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!currentRoom || isChatEnded) {
			return;
		}
		const payload = {
			method: "send_message",
			clientId,
			chat: text,
			username: user?.username,
			room: currentRoom,
			accountType: "admin",
			time: newDateFormatter(new Date(), chatTimeDateFnsFormat),
		};
		setMessages((prev) => ({
			...prev,
			[currentRoom]: [
				...(prev[currentRoom] || []),
				{ ...payload, sender: "self" },
			],
		}));
		socket.emit("send_message", payload);
		setTimeout(() => {
			chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
		}, 0);
		setText("");
	};

	const handleEndSession = () => {
		const payload = {
			method: "end_session",
			username: user?.username,
			fullName: user?.fullName,
			accountType: ROLES.ADMIN,
			room: currentRoom,
		};

		socket.emit("end_session", payload);
		setCurrentRoom(null);
		setIsChatEnded(true);
		setOpenConfirmModal(false);
	};

	const handleTransferChat = () => {
		const currentAgent = customerServiceAgentsData?.find(
			(item) => item?.username === selectedServiceAgent
		);
		const payload = {
			method: "transfer_chat",
			username: user?.username,
			fullName: user?.fullName,
			recipient: selectedServiceAgent,
			accountType: ROLES.ADMIN,
			clientId,
			room: currentRoom,
			recipient_fullName: currentAgent?.fullName,
		};

		setIsChatEnded(true);

		socket.emit("transfer_chat", payload);
		setSelectedServiceAgent("");
		setOpenTransferModal(false);
		customerServiceAgentsRefetch();
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault(); // Prevent new line
			// You can add additional logic here before form submission
			handleSubmit(event);
		}
	};

	const handleTyping = () => {
		if (!isTyping) {
			setIsTyping(true);
		}
		const payload = {
			method: "typing",
			username: user?.username,
			fullName: user?.fullName,
			accountType: ROLES.ADMIn,
			room: currentRoom,
		};
		socket.emit("typing", payload);
	};

	const handleInputChange = (e) => {
		if (isChatEnded) {
			return;
		}
		setText(e.target.value);
		handleTyping();
	};

	return (
		<Box
			sx={{
				display: "flex",
				width: "100vw !important",
				maxWidth: "100%",
				minHeight: "100vh",
			}}
		>
			<Layout />
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					minHeight: "100%",
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					maxWidth: "100vw",
					padding: {
						xs: "1rem",
						md: "2rem",
					},
				}}
			>
				<Toolbar />
				{!!currentRoom && (
					<Box sx={containerStyle}>
						<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
							<IconButton
								sx={eendSessionButtonStyle}
								onClick={() => setOpenConfirmModal(true)}
								disabled={isChatEnded}
							>
								<PowerSettingsNewIcon />
							</IconButton>
						</Box>

						<Box
							ref={chatBoxRef}
							sx={{
								// flex: 1,
								// border: "1px solid red",
								overflowY: "auto",
								p: 2,
								display: "flex",
								flexDirection: "column",
								height: 400,
								borderRadius: 4,
								border: "1px solid rgba(0,0,0,0.3)",
							}}
						>
							{messages?.[currentRoom]?.map((message, index) => (
								<Box
									key={index}
									sx={{
										maxWidth: "70%",
										margin: ".8rem",
										...(message.sender === "self" && myMessageStyles),
									}}
								>
									{!!message.sender &&
										message.sender !== "system" &&
										message.sender !== "self" && (
											<Box sx={{ mb: ".4rem" }}>
												<Typography variant="p">{message.sender}</Typography>
											</Box>
										)}
									<Box
										sx={{
											...(message.sender === "self" && {
												display: "flex",
												justifyContent: "flex-end",
											}),
										}}
									>
										<Typography
											variant="p"
											sx={{
												backgroundColor: "#f0f0f0",
												color: "#000",
												border: "1px solid #cccccc",
												borderRadius: "14px 14px 14px 0",
												padding: "0.5rem",
												...(message.sender === "self" && myMessageBodyStyles),
											}}
										>
											{message.chat}
										</Typography>
									</Box>
									{message.time && (
										<Box
											sx={{
												mt: ".4rem",
												...(message.sender === "self" && {
													display: "flex",
													justifyContent: "flex-end",
												}),
											}}
										>
											<Typography
												variant="caption"
												color="GrayText"
												sx={{
													...(message.sender === "self" && {
														textAlign: "end",
													}),
												}}
											>
												{message.time}
											</Typography>
										</Box>
									)}
								</Box>
							))}
							{isOtherUserTyping && (
								<Box sx={{ mt: ".4rem" }}>
									<Typography variant="caption" color="textSecondary">
										{userTypingMessage}
									</Typography>
								</Box>
							)}
						</Box>

						<Box
							component="form"
							sx={entryBoxStyle}
							noValidate
							autoComplete="off"
							onSubmit={handleSubmit}
						>
							<TextField
								id="chat-input-textfield"
								label="Type a message"
								variant="outlined"
								value={text}
								onChange={handleInputChange}
								error={!currentRoom}
								multiline
								maxRows={4}
								fullWidth
								onKeyDown={handleKeyDown}
								helperText={
									!currentRoom &&
									"You are not in any room. Please join a room to start chatting."
								}
							/>
							<IconButton
								type="submit"
								disabled={isChatEnded}
								sx={sendMessageButtonStyle}
							>
								<SendIcon />
							</IconButton>
							<IconButton
								type="button"
								onClick={() => setOpenTransferModal(true)}
								disabled={isChatEnded}
								sx={transferButtonStyle}
							>
								<MultipleStopIcon />
							</IconButton>
						</Box>
					</Box>
				)}
				{!currentRoom && (
					<Box sx={noActiveChatContainerStyle}>
						<Box>
							<Typography variant="h5" align="center">
								You are not in any room.
							</Typography>
							<Typography variant="subtitle1" align="center">
								Please join a room to start chatting.
							</Typography>
						</Box>
					</Box>
				)}
			</Box>

			<ConfirmModal
				open={openConfirmModal}
				handleClose={() => setOpenConfirmModal(false)}
				title="End Session"
				message="Are you sure?"
				handleSubmit={handleEndSession}
				loading={false}
			/>

			<TransferModal
				open={openTransferModal}
				handleClose={() => {
					setOpenTransferModal(false);
				}}
				transferListLoading={customerServiceAgentsLoading}
				transferList={customerServiceAgentsData}
				selectedServiceAgent={selectedServiceAgent}
				setSelectedServiceAgent={setSelectedServiceAgent}
				handleSubmit={handleTransferChat}
			/>
		</Box>
	);
};

export default SupportChat;

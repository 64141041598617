import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Box } from "../../../components/common/muiComponents";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Coop/TransactionsLogTable";
import { fetchCoopTransactionsLog } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const CoopTransactionsLog = () => {
	const [searchTerm, setSearchTerm] = useState("");

	const { region, user } = useSelector((state) => state.auth);

	const {
		data: reportData = [],
		isLoading: reportLoading,
		isError: reportError,
		error: reportErrorMessage,
	} = useQuery(
		[`fetch-coop-transactions-log`, region?.region],
		async () =>
			fetchCoopTransactionsLog({
				region: region?.region,
				roleID: user?.role,
				username: user?.username,
			}),
		{
			select: (data) => data?.data,
			staleTime: 4 * 60 * 1000,
			enabled: !!region?.region,
		}
	);

	useEffect(() => {
		if (reportError) {
			if (reportErrorMessage.response) {
				toast.error(reportErrorMessage.response.data?.message);
			} else if (reportErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", reportErrorMessage.message);
			}
		}
	}, [reportError, reportErrorMessage]);

	const displayed = useMemo(() => {
		if (reportData.length) {
			let filtered = [...reportData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item?.username
							?.toLowerCase()
							?.includes(searchTerm?.toLowerCase()) ||
						item?.period?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						`${item?.transactionAmount}`
							?.toLowerCase()
							?.includes(searchTerm?.toLowerCase())
					);
				});
			}
			return filtered;
		}
		return [];
	}, [reportData, searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Transaction Log"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search report"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table data={displayed} loading={reportLoading} />
			</Box>
		</Box>
	);
};

export default CoopTransactionsLog;

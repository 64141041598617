const popperLinks = {
	home: {
		title: "Home",
		sections: {
			"Home & Kitchen": [
				{ link: "/test", label: "Heating, Cooling & Air Quality" },
				{ link: "/test", label: "Furniture" },
				{ link: "/test", label: "Home Decor" },
				{ link: "/test", label: "Bedding" },
				{ link: "/test", label: "Bath" },
				{ link: "/test", label: "Event & Party Supplies" },
				{ link: "/test", label: "Lighting & Ceiling Fans" },
				{ link: "/test", label: "Storage & Organization" },
				{ link: "/test", label: "Wall Art" },
				{ link: "/test", label: "Vacuums & Floor Care" },
				{ link: "/test", label: "Login" },
				{ link: "/test", label: "Cleaning Supplies" },
			],
			"office products": [
				{ link: "/test", label: "Office & School Supplies" },
				{ link: "/test", label: "Office Electronics" },
				{ link: "/test", label: "Office Furniture & Lighting" },
			],
			"arts, craft & sewing": [
				{ link: "/test", label: "Sewing" },
				{ link: "/test", label: "Painting, Drawing & Art Supplies" },
				{ link: "/test", label: "Gift Wrapping Supplies" },
			],
			"kitchen & dining": [
				{ link: "/test", label: "Small Appliances" },
				{ link: "/test", label: "Dining & Entertaining" },
				{ link: "/test", label: "Kitchen Utensils & Gadgets" },
				{ link: "/test", label: "Cookware" },
				{ link: "/test", label: "Coffee, Tea & Espresso" },
				{ link: "/test", label: "Storage & Organization" },
				{ link: "/test", label: "Bakeware" },
				{ link: "/test", label: "Cutlery & Knife Accessories" },
				{ link: "/test", label: "Food Service Equipment & Supplies" },
				{ link: "/test", label: "Water Coolers & Filters" },
				{ link: "/test", label: "Travel & To-Go Drinkware" },
			],
			"tools & home improvement": [
				{ link: "/test", label: "Power & Hand Tools" },
				{ link: "/test", label: "Painting Supplies & Wall Treatments" },
				{ link: "/test", label: "Electrical" },
				{ link: "/test", label: "Appliances" },
				{ link: "/test", label: "Building Supplies" },
				{ link: "/test", label: "Safety & Security" },
				{ link: "/test", label: "Hardware" },
				{ link: "/test", label: "Kitchen & Bath Fixtures" },
				{ link: "/test", label: "Measuring & Layout Tools" },
				{ link: "/test", label: "Light Bulbs" },
				{ link: "/test", label: "Storage & Home Organization" },
				{ link: "/test", label: "Welding & Soldering" },
				{ link: "/test", label: "Rough Plumbing" },
				{ link: "/test", label: "Stationery" },
				{ link: "/test", label: "Lighting" },
			],
		},
	},
	family: {
		title: "Family",
		sections: {
			babyGirls: [
				{ link: "/test", label: "Clothing" },
				{ link: "/test", label: "Shoes" },
			],
			babyNursery: [
				{ link: "/test", label: "Beds, Cribs & Bedding" },
				{ link: "/test", label: "Nursery Decor" },
				{ link: "/test", label: "Furniture" },
			],
			babyToddlerToys: [
				{ link: "/test", label: "Sewing" },
				{ link: "/test", label: "Painting, Drawing & Art Supplies" },
				{ link: "/test", label: "Gift Wrapping Supplies" },
			],
			bathingSkincare: [
				{ link: "/test", label: "Washcloths & Towels" },
				{ link: "/test", label: "Grooming & Healthcare Kits" },
				{ link: "/test", label: "Skin Care" },
				{ link: "/test", label: "Bathing Tubs & Seats" },
				{ link: "/test", label: "Bathroom Safety" },
				{ link: "/test", label: "Shampoo" },
			],
			diapering: [
				{ link: "/test", label: "Diaper Bags" },
				{ link: "/test", label: "Cloth Diapers" },
				{ link: "/test", label: "Wipes & Holders" },
				{ link: "/test", label: "Diaper Pails & Refills" },
				{ link: "/test", label: "Portable Changing Pads" },
				{ link: "/test", label: "Changing Tables" },
			],
			feeding: [
				{ link: "/test", label: "Bibs & Burp Cloths" },
				{ link: "/test", label: "Breastfeeding" },
				{ link: "/test", label: "Bottle-Feeding" },
				{ link: "/test", label: "Pacifiers & Accessories" },
				{ link: "/test", label: "Food Storage" },
				{ link: "/test", label: "Highchairs & Booster Seats" },
				{ link: "/test", label: "Solid Feeding" },
				{ link: "/test", label: "Baby Food" },
				{ link: "/test", label: "Baby Gear" },
				{ link: "/test", label: "Baby Safety" },
				{ link: "/test", label: "Baby Stationery" },
				{ link: "/test", label: "Pregnancy & Maternity" },
				{ link: "/test", label: "Car Seats & Accessories" },
				{ link: "/test", label: "Baby Gifts" },
				{ link: "/test", label: "Potty Training" },
				{ link: "/test", label: "Health & Baby Care" },
				{ link: "/test", label: "Strollers & Accessories" },
			],
		},
	},
	gadgets: {
		title: "Gadgets",
		sections: {
			sportingGoods: [
				{ link: "/test", label: "Sporting Goods" },
				{ link: "/test", label: "Shoes" },
			],
			officialStores: [
				{ link: "/test", label: "Nestle" },
				{ link: "/test", label: "Xiaomi" },
				{ link: "/test", label: "Huawei" },
				{ link: "/test", label: "Maybelline" },
				{ link: "/test", label: "MAC" },
				{ link: "/test", label: "Apple" },
				{ link: "/test", label: "Intel" },
				{ link: "/test", label: "Reckitt Benckiser" },
				{ link: "/test", label: "Binatone" },
				{ link: "/test", label: "Nexus" },
			],
			automobiles: [],
			toysGames: [
				{ link: "/test", label: "Games" },
				{ link: "/test", label: "Dress Up & Pretend Play" },
				{ link: "/test", label: "Sports & Outdoor Play" },
				{ link: "/test", label: "Top Toys & Games" },
			],
		},
	},
	fashion: {
		title: "Fashion",
		sections: {
			womenFashion: [
				{ link: "/test", label: "Clothing" },
				{ link: "/test", label: "Shoes" },
				{ link: "/test", label: "Accessories" },
				{ link: "/test", label: "Jewelry" },
				{ link: "/test", label: "Handbags & Wallets" },
				{ link: "/test", label: "Plus-Size" },
				{ link: "/test", label: "Underwear & Sleepwear" },
				{ link: "/test", label: "Maternity" },
				{ link: "/test", label: "Costumes & Accessories" },
				{ link: "/test", label: "Traditional" },
				{ link: "/test", label: "Beach & Swimwear" },
				{ link: "/test", label: "Uniforms, Work & Safety" },
				{ link: "/test", label: "Ankara Bags" },
				{ link: "/test", label: "View All" },
			],
			boyFashion: [
				{ link: "/test", label: "Accessories" },
				{ link: "/test", label: "Clothing" },
				{ link: "/test", label: "Shoes" },
				{ link: "/test", label: "Watches" },
				{ link: "/test", label: "Shops" },
			],
			girlFashion: [
				{ link: "/test", label: "Accessories" },
				{ link: "/test", label: "Clothing" },
				{ link: "/test", label: "Shoes" },
				{ link: "/test", label: "School Uniforms" },
				{ link: "/test", label: "Watches" },
				{ link: "/test", label: "Jewelry" },
			],
			menFashion: [
				{ link: "/test", label: "Clothing" },
				{ link: "/test", label: "Shoes" },
				{ link: "/test", label: "Accessories" },
				{ link: "/test", label: "Jewelry" },
				{ link: "/test", label: "Costumes & Accessories" },
				{ link: "/test", label: "Traditional & Cultural Wear" },
				{ link: "/test", label: "ShUnderwear & Sleepwearops" },
				{ link: "/test", label: "Jerseys" },
				{ link: "/test", label: "View All" },
			],
			more: [
				{ link: "/test", label: "Watches" },
				{ link: "/test", label: "Weddings" },
				{ link: "/test", label: "Luggage & Travel Gear" },
				{ link: "/test", label: "Fabrics" },
				{ link: "/test", label: "Baby Fashion" },
			],
		},
	},
	lifestyle: {
		title: "Lifestyle",
		sections: {
			healthBeauty: [
				{ link: "/test", label: "Oral Care" },
				{ link: "/test", label: "Wellness & Relaxation" },
				{ link: "/test", label: "Fragrances" },
				{ link: "/test", label: "Baby & Child Care" },
				{ link: "/test", label: "vision Care" },
				{ link: "/test", label: "Men's Grooming" },
				{ link: "/test", label: "Sports Nutrition" },
				{ link: "/test", label: "Kid's Beauty" },
			],
			vitamnins: [
				{ link: "/test", label: "Supplements" },
				{ link: "/test", label: "Herbal Supplements" },
				{ link: "/test", label: "Weight Loss" },
				{ link: "/test", label: "Vitamins" },
				{ link: "/test", label: "Minerals" },
			],
			beautyCare: [
				{ link: "/test", label: "Makeup" },
				{ link: "/test", label: "Tools & Accessories" },
				{ link: "/test", label: "Fragrance" },
				{ link: "/test", label: "Personal Care" },
				{ link: "/test", label: "Hair Care" },
				{ link: "/test", label: "Oral Care" },
				{ link: "/test", label: "Skin Care" },
				{ link: "/test", label: "Shave & Hair Removal" },
				{ link: "/test", label: "Deodorants & Antiperspirants" },
			],
			medicalEquipment: [
				{ link: "/test", label: "Health Monitors" },
				{ link: "/test", label: "Braces, Splints & Supports" },
				{ link: "/test", label: "Mobility & Daily Living Aids" },
				{
					link: "/test",
					label: "FraOccupational & Physical Therapy Aidsgrance",
				},
			],
			healthCare: [
				{ link: "/test", label: "First Aid" },
				{ link: "/test", label: "Ear Care" },
				{ link: "/test", label: "Diabetes Care" },
				{ link: "/test", label: "Feminine Care" },
				{ link: "/test", label: "Medications & Treatments" },
				{ link: "/test", label: "Alternative Medicine" },
				{ link: "/test", label: "Sleep & Snoring" },
				{ link: "/test", label: "Foot Health" },
				{ link: "/test", label: "Respiratory Aids & Accessories" },
				{ link: "/test", label: "Incontinence & Ostomy" },
				{ link: "/test", label: "Women's Health" },
			],
			sexualWellness: [
				{ link: "/test", label: "Adult Toys & Games" },
				{ link: "/test", label: "Fetish Wear" },
				{ link: "/test", label: "Safer Sex" },
				{ link: "/test", label: "Temptations" },
			],
		},
	},
	electronics: {
		title: "Electronics",
		sections: {
			television: [
				{ link: "/test", label: "Televisions" },
				{ link: "/test", label: "Smart TVs" },
				{ link: "/test", label: "LED TVs" },
				{ link: "/test", label: "Curved TVs" },
				{ link: "/test", label: "OLED TVs" },
				{ link: "/test", label: "DVD Players" },
			],
			generators: [
				{ link: "/test", label: "Generators" },
				{ link: "/test", label: "Inverters" },
				{ link: "/test", label: "Stablizers" },
				{ link: "/test", label: "Solar & Wind Power" },
			],
			cameras: [
				{ link: "/test", label: "Digital Cameras" },
				{ link: "/test", label: "Projectors" },
				{ link: "/test", label: "Video Surveillance" },
				{ link: "/test", label: "Camcoders" },
			],
			audio: [
				{ link: "/test", label: "Home Theatre Systems" },
				{ link: "/test", label: "Bluetooth Speakers" },
				{ link: "/test", label: "Receivers & Amplifiers" },
				{
					link: "/test",
					label: "Sound Bars",
				},
			],
			instruments: [
				{ link: "/test", label: "Guitars" },
				{ link: "/test", label: "Keyboards & MIDI" },
				{ link: "/test", label: "Drums & Percussion" },
				{ link: "/test", label: "Studio Recording Equipment" },
			],
			brands: [
				{ link: "/test", label: "LG" },
				{ link: "/test", label: "Samsung" },
				{ link: "/test", label: "Sony" },
				{ link: "/test", label: "TCL" },
				{ link: "/test", label: "Hisense" },
				{ link: "/test", label: "Canon" },
				{ link: "/test", label: "DStv" },
			],
		},
	},
	computers: {
		title: "Computers",
		sections: {
			accesories: [
				{ link: "/test", label: "Keyboards & Mice" },
				{ link: "/test", label: "Printer Ink & Toner" },
				{ link: "/test", label: "UPS" },
				{ link: "/test", label: "Flash Drives" },
				{ link: "/test", label: "External Hard Drives" },
				{ link: "/test", label: "Laptop Acessories" },
				{ link: "/test", label: "Computer Components" },
				{ link: "/test", label: "Printer" },
				{ link: "/test", label: "Scanner" },
				{ link: "/test", label: "Monitors" },
			],
			laptops: [
				{ link: "/test", label: "HP Laptops" },
				{ link: "/test", label: "Dell Laptops" },
				{ link: "/test", label: "Macbooks" },
				{ link: "/test", label: "Lenovo Laptops" },
				{ link: "/test", label: "ASUS Laptops" },
				{ link: "/test", label: "Acer Laptops" },
				{ link: "/test", label: "Microsoft Laptops" },
				{ link: "/test", label: "Huawei Laptops" },
				{ link: "/test", label: "HP Pavilion 15" },
				{ link: "/test", label: "Dell Inspiron 15" },
			],
			brands: [
				{ link: "/test", label: "HP" },
				{ link: "/test", label: "Logitech" },
				{ link: "/test", label: "Dell" },
				{ link: "/test", label: "Lenovo" },
				{ link: "/test", label: "Apple" },
				{ link: "/test", label: "ASUS" },
				{ link: "/test", label: "Huawei" },
				{ link: "/test", label: "Microsoft" },
				{ link: "/test", label: "Kingston" },
				{ link: "/test", label: "Seagate" },
				{ link: "/test", label: "Samsung" },
				{ link: "/test", label: "Sandisk" },
			],
		},
	},
	phones: {
		title: "Phones",
		sections: {
			phones: [
				{ link: "/test", label: "Smartphones" },
				{ link: "/test", label: "Andriod Phones" },
				{ link: "/test", label: "iOS Phones" },
				{ link: "/test", label: "Basic Phones" },
				{ link: "/test", label: "Phone Cases" },
				{ link: "/test", label: "Screen Protection" },
				{ link: "/test", label: "Batteries" },
				{ link: "/test", label: "Portable Power Banks" },
				{ link: "/test", label: "Headsets" },
				{ link: "/test", label: "Smart Watches" },
			],
			brands: [
				{ link: "/test", label: "Xiaomi" },
				{ link: "/test", label: "Gionee" },
				{ link: "/test", label: "Infinix" },
				{ link: "/test", label: "Nokia" },
				{ link: "/test", label: "Tecno" },
				{ link: "/test", label: "Apple" },
				{ link: "/test", label: "Huawei" },
				{ link: "/test", label: "Cubot" },
				{ link: "/test", label: "Oukitel" },
				{ link: "/test", label: "Itel" },
				{ link: "/test", label: "Bontel" },
				{ link: "/test", label: "OPPO" },
			],
			smartPhones: [
				{ link: "/test", label: "HP" },
				{ link: "/test", label: "Logitech" },
				{ link: "/test", label: "Dell" },
				{ link: "/test", label: "Lenovo" },
				{ link: "/test", label: "Apple" },
				{ link: "/test", label: "ASUS" },
				{ link: "/test", label: "Huawei" },
				{ link: "/test", label: "Microsoft" },
				{ link: "/test", label: "Kingston" },
				{ link: "/test", label: "Seagate" },
				{ link: "/test", label: "Samsung" },
				{ link: "/test", label: "Sandisk" },
			],
			tablets: [
				{ link: "/test", label: "iPads" },
				{ link: "/test", label: "Tablet Acessories" },
			],
		},
	},
};

export default popperLinks;

import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
	Box,
	Typography,
	TextField,
	Button,
} from "../../../components/common/muiComponents";
import Table from "../../../components/Admin/ProductsTable";
import SuccessModal from "../../../components/common/SuccessModal";
import DealDurationModal from "../../../components/Admin/DealDurationModal";
import ViewProductModal from "../../../components/common/ViewProductDetails";
import CreateProductModal from "../../../components/common/CreateProductModal";
import {
	fetchProducts,
	deleteProduct,
	activateDeal,
	createProduct,
	editProduct,
	getRedemptionMode,
} from "../../../api/admin/dashboard";
import { getCountry } from "../../../api/common/location";
import {
	getProductMedium,
	getProductCategory,
} from "../../../api/common/request";
import { ROLES } from "../../../utils/constants";

const defaultDealFields = {
	dealHours: "",
	group: "",
	promo: "",
	region: "",
};

const defaultProductFields = {
	productName: "",
	productCategoryID: "",
	productLongDesc: "",
	productShortDesc: "",
	retailPrice: "",
	files: "",
	productMedium: "",
	redemptionMode: "",
	region: "",
};

const Products = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [openSuccessModal, setOpenSuccessModal] = useState(false);
	const [openDealDurationModal, setOpenDealDurationModal] = useState(false);
	const [dealFields, setDealFields] = useState(defaultDealFields);
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [productFields, setProductFields] = useState(defaultProductFields);
	const [createProductModalOpen, setCreateProductModalOpen] = useState(false);
	const [editProductFields, setEditProductFields] = useState({});
	const [editProductModalOpen, setEditProductModalOpen] = useState(false);
	const [createProductSuccessModalOpen, setCreateProductSuccessModalOpen] =
		useState(false);
	const [editProductSuccessModalOpen, setEditProductSuccessModalOpen] =
		useState(false);

	const { user, bizFrom, region } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: productData = [],
		isLoading: productLoading,
		isError: productError,
		error: productErrorMessage,
	} = useQuery(
		["get-admin-products", region?.region, bizFrom, user?.accountType],
		async () => fetchProducts(region?.region, bizFrom, user?.accountType),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled:
				Boolean(region?.region) &&
				Boolean(bizFrom) &&
				Boolean(user?.accountType),
		}
	);

	const {
		data: productmediumData = [],
		isError: productmediumError,
		error: productmediumErrorMessage,
	} = useQuery(
		["get-product-medium", user?.bizFrom, user?.accountType],
		async () => getProductMedium(user?.bizFrom, user?.accountType),
		{
			select: (data) => data,
			staleTime: Infinity,
		}
	);

	const {
		data: productCategoryData = [],
		isError: productCategoryError,
		error: productCategoryErrorMessage,
	} = useQuery(
		["get-productCategory", user.bizFrom, region?.region, user?.accountType],
		async () =>
			getProductCategory(user.bizFrom, region?.region, user?.accountType),
		{
			select: (data) => data,
			staleTime: Infinity,
			enabled: Boolean(user.bizFrom) && Boolean(region?.region),
		}
	);

	const {
		data: redemptionModeData = [],
		isError: redemptionModeError,
		error: redemptionModeErrorMessage,
	} = useQuery(
		["get-redemptionMode", region?.region, bizFrom, user?.fullName],
		async () => getRedemptionMode(region?.region, bizFrom, user?.fullName),
		{
			select: (data) => data?.data?.flat(2),
			staleTime: Infinity,
			enabled: !!region?.region && !!bizFrom && !!user?.fullName,
		}
	);

	const {
		data: countriesData = [],
		// isLoading: countriesLoading,
		isError: fetchCountriesError,
		error: fetchCountriesErrorMessage,
	} = useQuery(["get-smartdeals-regions"], async () => getCountry(), {
		select: (data) => data.filter((country) => country.list),
		staleTime: 4 * 60 * 1000,
	});

	useEffect(() => {
		if (productError) {
			if (productErrorMessage.response) {
				toast.error(productErrorMessage.response.data?.message);
			} else if (productErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", productErrorMessage.message);
			}
		}
		if (productmediumError) {
			if (productmediumErrorMessage.response) {
				toast.error(productmediumErrorMessage.response.data?.message);
			} else if (productmediumErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", productmediumErrorMessage.message);
			}
		}
		if (productCategoryError) {
			if (productCategoryErrorMessage.response) {
				toast.error(productCategoryErrorMessage.response.data?.message);
			} else if (productCategoryErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", productCategoryErrorMessage.message);
			}
		}
		if (redemptionModeError) {
			if (redemptionModeErrorMessage.response) {
				toast.error(redemptionModeErrorMessage.response.data?.message);
			} else if (redemptionModeErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", redemptionModeErrorMessage.message);
			}
		}
		if (fetchCountriesError) {
			if (fetchCountriesErrorMessage.response) {
				toast.error(fetchCountriesErrorMessage.response.data?.message);
			} else if (fetchCountriesErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", fetchCountriesErrorMessage.message);
			}
		}
	}, [
		productError,
		productErrorMessage,
		productmediumError,
		productmediumErrorMessage,
		productCategoryError,
		productCategoryErrorMessage,
		redemptionModeError,
		redemptionModeErrorMessage,
		fetchCountriesError,
		fetchCountriesErrorMessage,
	]);

	const { mutate: dealProduct, isLoading: dealProductLoading } = useMutation(
		"activate-deal",
		activateDeal,
		{
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setSelectedItem({});
				setOpenSuccessModal(true);
				setOpenDealDurationModal(false);
				setDealFields(defaultDealFields);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-products");
			},
		}
	);

	const { mutate: destroyProduct } = useMutation(
		"delete-product",
		deleteProduct,
		{
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setSelectedItem({});
				setViewDetailsModalOpen(false);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-products");
			},
		}
	);

	const handleProductDeal = async (e) => {
		e.preventDefault();
		if (!Number(dealFields.dealHours)) {
			toast.error("Please enter a valid deal end time");
			return;
		}
		if (!dealFields.region) {
			toast.error("Please select a region for the deal");
			return;
		}
		if (!dealFields.promo) {
			toast.error("Please select if this deal is a promo or not");
			return;
		}
		const payload = {
			admin: user.fullName,
			productID: selectedItem.productID,
			duration: dealFields.dealHours,
			interestRequired: selectedItem.interestRequired,
			dealGoal: selectedItem.dealGoal,
			pointsRequired: selectedItem.pointsRequired,
			retailPrice: selectedItem.retailPrice,
			dealPrice: selectedItem.dealPrice,
			productName: selectedItem.productName,
			productMedium: selectedItem.productMedium,
			region: region?.region,
			group: dealFields?.group,
			promo: dealFields?.promo === "yes" ? 1 : 0,
		};

		if (!dealFields.group) {
			delete payload.group;
		}

		await dealProduct(payload);
	};

	const handleDeleteProduct = async () => {
		const payload = {
			admin: user.fullName,
			productID: selectedItem.productID,
			region: region?.region,
			bizFrom,
			accountType: user?.accountType,
			productName: selectedItem.productName,
		};
		await destroyProduct(payload);
	};

	const { mutate: addProduct, isLoading: addProductLoading } = useMutation(
		"add-product",
		createProduct,
		{
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setProductFields(defaultProductFields);
				setCreateProductModalOpen(false);
				setCreateProductSuccessModalOpen(true);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-products");
			},
		}
	);

	const handleProductCreation = async () => {
		if (!productFields.productName) {
			toast.error("Please enter a product name");
			return;
		}
		if (!productFields.files) {
			toast.error("Please select an image to upload");
			return;
		}
		if (!productFields.productCategoryID) {
			toast.error("Please select a product category");
			return;
		}
		if (!productFields.productMedium) {
			toast.error("Please select a product medium");
			return;
		}
		if (!productFields.productLongDesc) {
			toast.error("Please enter product description");
			return;
		}
		if (!productFields.productShortDesc) {
			toast.error("Please enter a short product description");
			return;
		}
		if (!productFields.retailPrice) {
			toast.error("Please enter a retail price");
			return;
		}
		if (
			productFields.productMedium?.toLowerCase() === "digital" &&
			!productFields.redemptionMode
		) {
			toast.error("Please choose redemption mode");
			return;
		}

		const formData = new FormData();
		formData.append("productName", productFields.productName);
		formData.append("files", productFields.files);
		formData.append("productCategoryID", productFields.productCategoryID);
		formData.append("productLongDesc", productFields.productLongDesc);
		formData.append("productShortDesc", productFields.productShortDesc);
		formData.append("retailPrice", productFields.retailPrice);
		formData.append("username", user.username);
		formData.append("region", productFields?.region);
		formData.append("bizFrom", bizFrom);
		formData.append("accountType", user?.accountType);
		formData.append("productMedium", productFields.productMedium);
		formData.append("admin", user.fullName);
		// eslint-disable-next-line no-unused-expressions
		productFields.productMedium?.toLowerCase() === "digital"
			? formData.append("redemptionMode", productFields?.redemptionMode)
			: formData.append("redemptionMode", "NA");

		await addProduct(formData);
	};

	const { mutate: updateProduct, isLoading: updateProductLoading } =
		useMutation("edit-product", editProduct, {
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setProductFields(defaultProductFields);
				setEditProductModalOpen(false);
				setEditProductSuccessModalOpen(true);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-products");
			},
		});

	const handleProductEdit = async () => {
		if (!editProductFields.productName) {
			toast.error("Please enter a product name");
			return;
		}
		if (!editProductFields.productCategoryID) {
			toast.error("Please select a product category");
			return;
		}
		if (!editProductFields.productLongDesc) {
			toast.error("Please enter product description");
			return;
		}
		if (!editProductFields.productShortDesc) {
			toast.error("Please enter a short product description");
			return;
		}
		if (!editProductFields.retailPrice) {
			toast.error("Please enter a retail price");
			return;
		}
		if (
			editProductFields.productMedium?.toLowerCase() === "digital" &&
			!editProductFields.redemptionMode
		) {
			toast.error("Please choose redemption mode");
			return;
		}

		const formData = new FormData();
		formData.append("productName", editProductFields.productName);
		formData.append("files", editProductFields.files);
		formData.append("productCategoryID", editProductFields.productCategoryID);
		formData.append("productLongDesc", editProductFields.productLongDesc);
		formData.append("productShortDesc", editProductFields.productShortDesc);
		formData.append("retailPrice", editProductFields.retailPrice);
		formData.append("productID", selectedItem.productID);
		formData.append("username", user.username);
		formData.append("region", editProductFields?.region);
		formData.append("bizFrom", bizFrom);
		formData.append("admin", user.fullName);
		// eslint-disable-next-line no-unused-expressions
		editProductFields.productMedium?.toLowerCase() === "digital"
			? formData.append("redemptionMode", editProductFields?.redemptionMode)
			: formData.append("redemptionMode", "NA");

		await updateProduct(formData);
	};

	useEffect(() => {
		if (productData.length && !productLoading) {
			setDisplayed(productData);
			setAllData(productData);
		}
	}, [productData, productLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.productName
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						item.categoryName
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						`${item.productID}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						`${item.dealGoal}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						`${item.dealPrice}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	useEffect(() => {
		if (Object.keys(selectedItem).length && editProductModalOpen) {
			setEditProductFields({
				productName: selectedItem.productName,
				productCategoryID: selectedItem.productCategoryID,
				productLongDesc: selectedItem.productLongDesc,
				productShortDesc: selectedItem.productShortDesc,
				retailPrice: selectedItem.retailPrice,
				productMedium: selectedItem.productMedium,
				redemptionMode: selectedItem.redemptionMode,
				region: selectedItem.region || "",
			});
		}
	}, [selectedItem, editProductModalOpen]);

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			{/* header */}
			<Box sx={{ mb: "2rem" }}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: { sm: "row", xs: "column" },
						mb: "1rem",
					}}
				>
					<Typography
						variant="h6"
						sx={{ fontWeight: 700, textTransform: "capitalize", mr: "auto" }}
						gutterBottom
					>
						Products
					</Typography>

					<Box
						sx={{
							display: "flex",
							// justifyContent: { xs: "flex-start", sm: "flex-end" },
							width: { xs: "100%", sm: "auto" },
						}}
					>
						<Box sx={{ ml: { md: "auto", xs: 0 }, mr: "1rem" }}>
							<TextField
								id="table-search-box"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								label="Search Products"
								variant="outlined"
								size="small"
								sx={{
									label: {
										color: "#594E4E !important",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										borderColor: "#594E4E !important",
									},
								}}
							/>
						</Box>

						{(user?.role?.toLowerCase() === ROLES.ADMIN ||
							user?.role?.toLowerCase() === ROLES.EXECUTIVE) && (
							<Box sx={{ ml: { md: "auto", xs: 0 } }}>
								<Button
									variant="contained"
									color="primary"
									sx={{ textTransform: "capitalize" }}
									onClick={() => setCreateProductModalOpen(true)}
								>
									add new product
								</Button>
							</Box>
						)}
					</Box>
				</Box>
			</Box>

			{/* table */}
			<Box
				sx={{
					// width: { md: "78vw", xs: "95vw" },
					width: "100%",
					overflow: "auto",
					maxWidth: "100%",
					boxSizing: "border-box",
				}}
			>
				<Table
					data={displayed}
					loading={productLoading}
					setSelectedItem={setSelectedItem}
					handleDelete={handleDeleteProduct}
					handleProductDeal={() => setOpenDealDurationModal(true)}
					handleView={() => setViewDetailsModalOpen(true)}
					handleEdit={() => setEditProductModalOpen(true)}
				/>
			</Box>
			<SuccessModal
				open={openSuccessModal}
				handleClose={() => setOpenSuccessModal(false)}
				text="Deal Activated"
			/>
			<DealDurationModal
				open={openDealDurationModal}
				handleClose={() => setOpenDealDurationModal(false)}
				data={dealFields}
				setData={setDealFields}
				title="Deal Duration"
				loading={dealProductLoading}
				handleFormSubmit={handleProductDeal}
				countriesData={countriesData}
			/>
			<ViewProductModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Product Details"
				data={selectedItem}
				user={user}
				activateDeal={() => setOpenDealDurationModal(true)}
			/>
			<CreateProductModal
				open={createProductModalOpen}
				handleClose={() => {
					setCreateProductModalOpen(false);
				}}
				title="Create new Product"
				productFields={productFields}
				setProductFields={setProductFields}
				productmediumData={productmediumData}
				productCategoryData={productCategoryData}
				loading={addProductLoading}
				handleSubmit={() => handleProductCreation()}
				redemptionModeData={redemptionModeData}
				countriesData={countriesData}
			/>
			<CreateProductModal
				open={editProductModalOpen}
				handleClose={() => {
					setEditProductModalOpen(false);
				}}
				title="Edit Product"
				productFields={editProductFields}
				setProductFields={setEditProductFields}
				productmediumData={productmediumData}
				productCategoryData={productCategoryData}
				loading={updateProductLoading}
				handleSubmit={() => handleProductEdit()}
				redemptionModeData={redemptionModeData}
				countriesData={countriesData}
			/>
			<SuccessModal
				open={createProductSuccessModalOpen}
				handleClose={() => setCreateProductSuccessModalOpen(false)}
				text="Product created successfully!"
			/>
			<SuccessModal
				open={editProductSuccessModalOpen}
				handleClose={() => setEditProductSuccessModalOpen(false)}
				text="Product edited successfully!"
			/>
		</Box>
	);
};

export default Products;

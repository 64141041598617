import { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import { SocketContext } from "../../../context/socket";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/OngoingDealsTable";
import ViewOngoingDealsModal from "../../../components/common/ViewOngoingDealsModal";
import { getLiveDeals } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const OngoingDeals = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);

	const { region, bizFrom, role } = useSelector((state) => state.auth);
	const socket = useContext(SocketContext);

	const {
		data: ongoingDealsData = [],
		isLoading: ongoingDealsLoading,
		isError: ongoingDealsError,
		error: ongoingDealsErrorMessage,
	} = useQuery(
		["get-ongoing-deals", region.region],
		async () => getLiveDeals(region.region, bizFrom, role),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (ongoingDealsError) {
			if (ongoingDealsErrorMessage.response) {
				toast.error(ongoingDealsErrorMessage.response.data?.message);
			} else if (ongoingDealsErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", ongoingDealsErrorMessage.message);
			}
		}
	}, [ongoingDealsError, ongoingDealsErrorMessage]);

	useEffect(() => {
		socket.on("live", (response) => {
			const data = JSON.parse(response);
			const newDeals = data?.liveDeals?.filter((deal) => deal.dealStatus === 1);
			setAllData([...newDeals]);
		});

		socket.on("update", (response) => {
			const data = JSON.parse(response);
			if (data.method === "update") {
				const productToUpdate = allData.find(
					(deal) => deal?.dealID === data?.deal?.dealId
				);
				if (productToUpdate) {
					productToUpdate.txttimeLeft = data?.deal?.txttimeLeft;
					productToUpdate.currentDealer = data?.deal?.currentDealer;
				}
				setAllData([...allData]);
			}
		});

		return () => {
			socket.off("live");
			socket.off("update");
		};
	}, [socket, allData]);

	useEffect(() => {
		if (ongoingDealsData.length && !ongoingDealsLoading) {
			setDisplayed([...ongoingDealsData]);
			setAllData([...ongoingDealsData]);
		}
	}, [ongoingDealsData, ongoingDealsLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.productName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.currentDealer
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.productName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						`${item.retailPrice}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						`${item.dealPrice}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						`${item.pointsRequired}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="live deals"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Deals"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table
					data={displayed}
					loading={ongoingDealsLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</Box>
			<ViewOngoingDealsModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Ongoing Deals Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default OngoingDeals;

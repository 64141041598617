import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import DownloadIcon from "@mui/icons-material/Download";

const TableHeader = ({
	text,
	searchTerm,
	setSearchTerm,
	// handleExcelExport,
	label,
	textFieldWidth = "13rem",
}) => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				flexDirection: { sm: "row", xs: "column" },
			}}
		>
			<Typography
				variant="h6"
				sx={{ fontWeight: 700, textTransform: "capitalize", mr: "auto" }}
				gutterBottom
			>
				{text}
			</Typography>

			<Box
				sx={{
					display: "flex",
					// justifyContent: { xs: "flex-start", sm: "flex-end" },
					width: { xs: "100%", sm: "auto" },
				}}
			>
				<Box sx={{ ml: { md: "auto", xs: 0 } }}>
					<TextField
						id="table-search-box"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						label={label}
						variant="outlined"
						size="small"
						sx={{
							label: {
								color: "#594E4E !important",
							},
							"& .MuiOutlinedInput-notchedOutline": {
								borderColor: "#594E4E !important",
							},
							width: textFieldWidth,
							maxWidth: "100%",
						}}
					/>
				</Box>

				{/* <Button
					sx={{
						textTransform: "capitalize",
						color: "#594E4E",
						border: "1px solid #E6E6E6 !important",
					}}
					variant="outlined"
					startIcon={<DownloadIcon />}
					onClick={handleExcelExport}
				>
					export
				</Button> */}
			</Box>
		</Box>
	);
};

export default TableHeader;

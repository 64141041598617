import { useState, useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import SelectFieldComponent from "../../../components/common/SelectFieldComponent";
import PasswordComponent from "../../../components/common/PasswordComponent";

import {
	specialCharacterCheck,
	digitCheck,
	uppercaseCheck,
	DEVICETYPE,
} from "../../../utils/constants";
import {
	fetchRoles,
	checkUsername,
	checkEmailAddress,
	checkPhoneNumber,
	createAccount,
} from "../../../api/admin/dashboard";
import { hashString } from "../../../utils/helpers/functions";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const mainContainerStyle = {
	display: "flex",
	justifyContent: "center",
};

const mainStyle = {
	width: "33rem",
	mb: "2rem",
	p: "2rem",
	border: "1px solid #E6E6E6",
};

const titleStyle = {
	fontWeight: 700,
	textTransform: "capitalize",
	mb: "2rem",
};

const validationSchema = yup.object({
	username: yup
		.string("Please enter your username")
		.min(3, "Username should be of minimum 3 characters length")
		.required("Username is required"),
	firstname: yup
		.string("Please enter your first name")
		.min(3, "First name should be of minimum 3 characters length")
		.required("First name is required"),
	lastname: yup
		.string("Please enter your last name")
		.min(3, "Last name should be of minimum 3 characters length")
		.required("Last name is required"),
	email: yup
		.string("Please enter your email")
		.email("Please enter a valid email")
		.required("Email is required"),
	phoneNumber: yup
		.string("Please enter your phone number")
		.min(10, "Phone number should be of minimum 10 characters length")
		.required("Phone number is required"),
	password: yup
		.string("Please enter your password")
		.min(7, "Password should be of minimum 7 characters length")
		.matches(
			specialCharacterCheck,
			"Password should contain at least one special character"
		)
		.matches(digitCheck, "Password should contain at least one digit")
		.matches(
			uppercaseCheck,
			"Password should contain at least one uppercase character"
		)
		.required("Password is required"),
	role: yup.string("Please choose a role").required("Role is required"),
});

const initialValues = {
	username: "",
	firstname: "",
	lastname: "",
	email: "",
	phoneNumber: "",
	password: "",
	role: "",
	isChecked: false,
};

const SignUp = () => {
	const { region, bizFrom, user } = useSelector((state) => state.auth);

	const [existUsernameMessage, setExistUsernameMessage] = useState("");
	const [existEmailMessage, setExistEmailMessage] = useState("");
	const [existPhoneNumber, setExistPhoneNumber] = useState("");

	const {
		data: userRolesData = [],
		// isLoading: userRolesLoading,
		isError: userRolesError,
		error: userRolesErrorMessage,
	} = useQuery(
		["get-admin-roles", region?.region],
		async () => fetchRoles(region?.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region),
		}
	);

	useEffect(() => {
		if (userRolesError) {
			if (userRolesErrorMessage.response) {
				toast.error(userRolesErrorMessage.response.data?.message);
			} else if (userRolesErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", userRolesErrorMessage.message);
			}
		}
	}, [userRolesError, userRolesErrorMessage]);

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			handleSubmit(
				values,
				() => formik.setSubmitting(false),
				() => formik.resetForm()
			);
		},
	});

	const handleSubmit = async (values, stopSubmiting, resetForm) => {
		try {
			const selectedRole = userRolesData?.find(
				(item) => item?.role === values.role
			);

			const payload = {
				username: values?.username,
				firstName: values?.firstname,
				lastName: values?.lastname,
				emailAddress: values?.email,
				phoneNumber: values?.phoneNumber,
				password: hashString(values?.password),
				role: values?.role,
				roleID: selectedRole?.roleID,
				bizFrom,
				deviceType: DEVICETYPE,
				accountType: selectedRole?.accountType,
				region: region?.region,
				loggedInRoleID: user?.role,
				loggedInUsername: user?.username,
			};
			const result = await createAccount(payload);
			toast.success(result.message);
			stopSubmiting();
			resetForm();
			// setOtp("");
			// setOpenSuccessModal(true);
		} catch (error) {
			stopSubmiting();
			if (error.response) {
				toast.error(error.response.data?.message);
			} else if (error.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", error.message);
			}
		}
	};

	const checkUsernameExist = useCallback(
		debounce(async (query) => {
			try {
				if (region) {
					const payload = {
						username: query,
						region: region.region,
					};
					await checkUsername(payload);
					setExistUsernameMessage("");
				}
			} catch (error) {
				if (error.response) {
					setExistUsernameMessage(error.response?.data?.message);
				} else if (error.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", error.message);
				}
			}
		}, 500),
		[]
	);

	const checkEmailExist = useCallback(
		debounce(async (query) => {
			try {
				if (region) {
					const payload = {
						emailAddress: query,
						region: region.region,
					};
					await checkEmailAddress(payload);
					setExistEmailMessage("");
				}
			} catch (error) {
				if (error.response) {
					setExistEmailMessage(error.response?.data?.message);
				} else if (error.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", error.message);
				}
			}
		}, 500),
		[]
	);

	const checkPhoneNumberExist = useCallback(
		debounce(async (query) => {
			try {
				if (region) {
					const payload = {
						phoneNumber: query,
						region: region.region,
					};
					await checkPhoneNumber(payload);
					setExistPhoneNumber("");
				}
			} catch (error) {
				if (error.response) {
					setExistPhoneNumber(error.response?.data?.message);
				} else if (error.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", error.message);
				}
			}
		}, 500),
		[]
	);

	useEffect(() => {
		if (formik.values.username && !formik.errors.username) {
			checkUsernameExist(formik.values.username);
		}
		if (formik.values.email && !formik.errors.email) {
			checkEmailExist(formik.values.email);
		}
		if (formik.values.phoneNumber && !formik.errors.phoneNumber) {
			checkPhoneNumberExist(formik.values.phoneNumber);
		}
	}, [
		formik.values.username,
		formik.errors.username,
		formik.values.email,
		formik.errors.email,
		formik.values.phoneNumber,
		formik.errors.phoneNumber,
	]);

	return (
		<Box sx={style}>
			<Typography variant="h6" sx={titleStyle}>
				Create Admin User
			</Typography>

			<Box sx={mainContainerStyle}>
				<Box sx={mainStyle}>
					<Typography
						variant="body1"
						sx={{ textAlign: "center", fontWeight: 500, mb: "2rem" }}
						color="primary"
					>
						Create an account
					</Typography>

					<Box
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={formik.handleSubmit}
					>
						<Box sx={{ mb: "2rem" }}>
							<Box sx={{ mb: "1rem" }}>
								<TextFieldComponent
									value={formik.values.username}
									onChange={formik.handleChange}
									label="Username"
									id="username"
									name="username"
									required
									error={
										Boolean(existUsernameMessage) ||
										(formik.touched.username && Boolean(formik.errors.username))
									}
									helperText={
										existUsernameMessage ||
										(formik.touched.username && formik.errors.username)
									}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<TextFieldComponent
									value={formik.values.firstname}
									onChange={formik.handleChange}
									label="Firstname"
									id="firstname"
									name="firstname"
									required
									error={
										formik.touched.firstname && Boolean(formik.errors.firstname)
									}
									helperText={
										formik.touched.firstname && formik.errors.firstname
									}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<TextFieldComponent
									value={formik.values.lastname}
									onChange={formik.handleChange}
									label="Lastname"
									id="lastname"
									name="lastname"
									required
									error={
										formik.touched.lastname && Boolean(formik.errors.lastname)
									}
									helperText={formik.touched.lastname && formik.errors.lastname}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<TextFieldComponent
									value={formik.values.email}
									onChange={formik.handleChange}
									label="Email"
									id="email"
									name="email"
									required
									error={
										Boolean(existEmailMessage) ||
										(formik.touched.email && Boolean(formik.errors.email))
									}
									helperText={
										existEmailMessage ||
										(formik.touched.email && formik.errors.email)
									}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<TextFieldComponent
									value={formik.values.phoneNumber}
									onChange={formik.handleChange}
									label="Phone Number"
									id="phoneNumber"
									name="phoneNumber"
									required
									error={
										Boolean(existPhoneNumber) ||
										(formik.touched.phoneNumber &&
											Boolean(formik.errors.phoneNumber))
									}
									helperText={
										existPhoneNumber ||
										(formik.touched.phoneNumber && formik.errors.phoneNumber)
									}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<PasswordComponent
									value={formik.values.password}
									onChange={formik.handleChange}
									required
									error={
										formik.touched.password && Boolean(formik.errors.password)
									}
									helperText={formik.touched.password && formik.errors.password}
								/>
							</Box>
							<Box sx={{ mb: "1rem" }}>
								<SelectFieldComponent
									width="100%"
									values={userRolesData}
									value={formik.values.role}
									onChange={formik.handleChange}
									label="Choose Role"
									id="role"
									name="role"
									menuValue="role"
									displayValue="role"
									required
									error={formik.touched.role && Boolean(formik.errors.role)}
									helperText={formik.touched.role && formik.errors.role}
								/>
							</Box>
							<FormControlLabel
								control={
									<Checkbox
										checked={formik.values.isChecked}
										onChange={() =>
											formik.setFieldValue(
												"isChecked",
												!formik.values.isChecked
											)
										}
										name="terms"
									/>
								}
								label={
									<p>
										I accept Smart Deal's{" "}
										<span style={{ color: "#002564" }}>
											Terms and conditions
										</span>{" "}
										and Privacy policy
									</p>
								}
							/>
						</Box>

						<Button
							type="submit"
							variant="contained"
							color="primary"
							fullWidth
							sx={{ padding: "1rem" }}
							disabled={!formik.dirty || formik.isSubmitting}
						>
							{formik.isSubmitting ? (
								<CircularProgress size="1.5rem" />
							) : (
								"create account"
							)}
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default SignUp;

import { useState, useMemo, useContext } from "react";
import { useSelector } from "react-redux";

import {
	Box,
	Typography,
	Drawer,
	Toolbar,
} from "../../../components/common/muiComponents";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/MarketDataTable";
import BidOfferTable from "../../../components/Admin/BidOfferTable";
import NGXTradesTables from "../../../components/Admin/NGXTradesTables";
import ViewMoreStockDetails from "../../../components/common/ViewMoreStockDetails";
import { SocketContext } from "../../../context/chatSocket";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const NGX = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedRow, setSelectedRow] = useState({});
	const [openViewModal, setOpenViewModal] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	// cosnt [stock]

	const { user } = useSelector((state) => state.auth);
	const {
		socket,
		// clientId,
		stocks,
		bidlevels,
		offerLevels,
		trades,
		stocksLoading,
		bidOfferTradesLoading,
		setBidOfferTradesLoading,
	} = useContext(SocketContext);

	// useEffect(() => {
	// 	if (
	// 		(bidlevels.length || offerLevels.length || trades.length) &&
	// 		!openDrawer
	// 	) {
	// 		setOpenDrawer(true);
	// 	}
	// }, [bidlevels, offerLevels, trades]);

	const displayed = useMemo(() => {
		if (searchTerm) {
			return stocks.filter((item) => {
				return (
					item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item?.symbol?.toLowerCase().includes(searchTerm.toLowerCase())
				);
			});
		}
		return stocks;
	}, [stocks, searchTerm]);

	const handleStockSelect = (data) => {
		const payload = {
			method: "bid_offer",
			symbol: data?.symbol,
			accountType: user?.accountType,
			username: user?.username,
		};

		socket.emit("bid_offer", payload);
		setBidOfferTradesLoading(true);
	};

	const handleExcelExport = () => {};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="NGX Price Monitor"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search report"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table
					data={displayed}
					loading={stocksLoading}
					selectedRow={selectedRow}
					setSelectedRow={(data) => {
						setSelectedRow(data);
					}}
					handleStockSelect={(item) => {
						handleStockSelect(item);
						setOpenDrawer(true);
					}}
					handleViewDetails={() => {
						setOpenViewModal(true);
					}}
				/>
			</Box>

			<Drawer
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
				anchor="right"
				sx={{
					scrollbarWidth: "none",
					msOverflowStyle: "none",
					"&::-webkit-scrollbar": {
						display: "none",
					},
				}}
			>
				<Box
					sx={{
						width: "70vw",
						scrollbarWidth: "none",
						msOverflowStyle: "none",
						"&::-webkit-scrollbar": {
							display: "none",
						},
						padding: ".5rem",
					}}
				>
					<Toolbar />
					<Box
						sx={{
							width: "100%",
							display: "flex",
							gap: "1rem",
							justifyContent: "space-between",
						}}
					>
						<Box>
							<Typography variant="h6">Bid Prices</Typography>
							<BidOfferTable
								data={bidlevels}
								loading={bidOfferTradesLoading}
								symbol={selectedRow?.symbol}
							/>
						</Box>
						<Box>
							<Typography variant="h6">Offer Prices</Typography>
							<BidOfferTable
								data={offerLevels}
								loading={bidOfferTradesLoading}
								symbol={selectedRow?.symbol}
							/>
						</Box>
					</Box>
					<Box>
						<Typography variant="h6">Trades</Typography>
						<NGXTradesTables
							data={trades}
							loading={bidOfferTradesLoading}
							symbol={selectedRow?.symbol}
						/>
					</Box>
				</Box>
			</Drawer>

			<ViewMoreStockDetails
				open={openViewModal}
				handleClose={() => {
					setOpenViewModal(false);
				}}
				title="View Stock Details"
				data={selectedRow}
			/>
		</Box>
	);
};

export default NGX;

import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
	// CircularProgress,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Box,
	Typography,
	// Stack,
	// Radio,
	// RadioGroup,
	// FormControlLabel,
	// FormControl,
	// FormLabel,
} from "../common/muiComponents";
import SelectFieldComponent from "../common/SelectFieldComponent";
import { fetchRoles } from "../../api/admin/dashboard";
import closeIcon from "../../assets/icons/close-icon.svg";

const formFieldContainer = {
	mb: "1rem",
	with: "15rem",
};

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	selectedRole,
	setSelectedRole,
}) {
	const { region } = useSelector((state) => state.auth);

	const {
		data: userRolesData = [],
		// isLoading: userRolesLoading,
		isError: userRolesError,
		error: userRolesErrorMessage,
	} = useQuery(
		["get-admin-roles", region?.region],
		async () => fetchRoles(region?.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region),
		}
	);

	const collabRoles = useMemo(() => {
		return userRolesData.filter(
			(role) => role?.accountType?.toLowerCase() === "collab"
		);
	}, [userRolesData]);

	useEffect(() => {
		if (userRolesError) {
			if (userRolesErrorMessage.response) {
				toast.error(userRolesErrorMessage.response.data?.message);
			} else if (userRolesErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", userRolesErrorMessage.message);
			}
		}
	}, [userRolesError, userRolesErrorMessage]);

	const handleModalClose = () => {
		if (!selectedRole) {
			return;
		}

		handleClose();
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={(_, reason) => {
					if (reason !== "backdropClick") {
						handleModalClose();
					}
				}}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
						width: "30rem",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleModalClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography
							variant="h6"
							component="div"
							sx={{
								fontWeight: 700,
								textTransform: "capitalize",
							}}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						textAlign: "center",
						overflowX: "hidden",
					}}
				>
					<Box
						component="form"
						// sx={{
						// 	// "& .MuiTextField-root": { width: "25ch" },
						// }}
						noValidate
						autoComplete="off"
					>
						<Box sx={formFieldContainer}>
							<SelectFieldComponent
								value={selectedRole}
								values={collabRoles}
								onChange={(e) => {
									setSelectedRole(e.target.value);
									handleClose();
								}}
								label="Choose Role"
								id="selectedRole"
								name="selectedRole"
								displayValue="role"
								menuValue="roleID"
							/>
						</Box>

						{/* <Stack
							direction="row"
							spacing={2}
							sx={{
								marginTop: "1rem",
								marginBottom: "1rem",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								sx={{
									paddingLeft: "2rem",
									paddingRight: "2rem",
									textTransform: "none",
								}}
								variant="contained"
								type="submit"
							>
								{loading ? (
									<CircularProgress size="1.5rem" sx={{ color: "#fff" }} />
								) : (
									"submit"
								)}
							</Button>
						</Stack> */}
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
	numberFormatter,
	newDateFormatter,
} from "../../utils/helpers/functions";
import closeIcon from "../../assets/icons/close-icon.svg";
import logo from "../../assets/logos/smart-deals-logo.svg";

const STATUS_COLORS = {
	active: "green",
	pending: "orange",
	failed: "red",
};

const columns = [
	{ id: "Record Info", label: "Record Info", minWidth: 80 },
	{ id: "Details", label: "Details", minWidth: 80 },
];

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	data,
}) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={() => {
					handleClose();
				}}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
						maxWidth: "60rem",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={() => {
								handleClose();
							}}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box sx={{ height: "5.25rem" }}>
							<img
								src={logo}
								height="100%"
								width="auto"
								alt="Cardinalstone logo"
							/>
						</Box>
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						textAlign: "center",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "2rem",
							pb: "2rem",
							width: "30rem",
						}}
					>
						<Box sx={{ mb: "1rem", width: "100%", display: "flex" }}>
							<Paper sx={{ width: "100%", overflow: "hidden" }}>
								<TableContainer sx={{ maxHeight: 440 }}>
									<Table stickyHeader aria-label="sticky table">
										<TableHead>
											<TableRow>
												{columns.map((column) => (
													<TableCell
														key={column.id}
														style={{
															minWidth: column.minWidth,
															backgroundColor: "#e41f26",
															color: "#fff",
														}}
													>
														{column.label}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Fullname</TableCell>
												<TableCell>{data?.fullName || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Username</TableCell>
												<TableCell>{data?.username || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Currency</TableCell>
												<TableCell>{data?.currency || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Amount</TableCell>
												<TableCell>{numberFormatter(data.amount)}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Status</TableCell>
												<TableCell
													sx={{
														color:
															STATUS_COLORS[(data.status || "")?.toLowerCase()],
													}}
												>
													{data?.status}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Transaction Reference</TableCell>
												<TableCell>{data?.transactionRef}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Request ID</TableCell>
												<TableCell>{data?.requestId}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Request Date</TableCell>
												<TableCell>
													{newDateFormatter(
														data?.requestDate,
														"do MMM yyyy h:mma"
													)}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</Box>
						<Stack
							direction="row"
							spacing={2}
							sx={{
								marginBottom: "1rem",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								sx={{
									paddingLeft: "3rem",
									paddingRight: "3rem",
								}}
								variant="contained"
								onClick={() => {
									handleClose();
								}}
							>
								close
							</Button>
						</Stack>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

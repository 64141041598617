import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import TextFieldComponent from "./TextFieldComponent";
import SelectFieldComponent from "./SelectFieldComponent";
import { editPayPartner } from "../../api/admin/dashboard";
import closeIcon from "../../assets/icons/close-icon.svg";
import logo from "../../assets/logos/smart-deals-logo.svg";

const validationSchema = yup.object({
	agency: yup
		.string("Please enter agency")
		.min(3, "Agency should be minimum 3 characters long")
		.required("Agency is required"),
	partnerName: yup
		.string("Please enter partner name")
		.min(3, "Partner name should be minimum 3 characters long")
		.required("Partner name is required"),
	emailAddress: yup
		.string("Please enter email address")
		.min(3, "Email address should be minimum 3 characters long")
		.required("Email address is required"),
	phoneNumber: yup
		.string("Please enter phone number")
		.min(3, "Phone number should be minimum 3 characters long")
		.required("Phone number is required"),
	bankName: yup
		.string("Please enter bank name")
		.min(3, "Bank name should be minimum 3 characters long")
		.required("Bank name is required"),
	bankCode: yup
		.string("Please enter bank code")
		.min(3, "Bank code should be minimum 3 characters long")
		.required("Bank code is required"),
	accountName: yup
		.string("Please enter account name")
		.min(3, "Account name should be minimum 3 characters long")
		.required("Account name is required"),
	accountNumber: yup
		.string("Please enter account number")
		.min(3, "Account number should be minimum 3 characters long")
		.required("Account number is required"),
	whitelist_ip: yup
		.string()
		.min(3, "White list should be minimum 3 characters long"),
	webhook: yup
		.string("Please enter webhook")
		.url("Invalid URL format")
		.required("Webhook is required"),
});

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	setEditPartnerSuccessModalOpen,
	setEditMessage,
	selectedPayPartner,
	banksData,
}) {
	const queryClient = useQueryClient();
	const { user, role } = useSelector((state) => state.auth);

	console.log({ selectedPayPartner });

	const formik = useFormik({
		initialValues: {
			agency: selectedPayPartner?.agency || "",
			partnerName: selectedPayPartner?.partnerName || "",
			emailAddress: selectedPayPartner?.emailAddress || "",
			phoneNumber: selectedPayPartner?.phoneNumber || "",
			bankName: selectedPayPartner?.bankName || "",
			bankCode: selectedPayPartner?.bankCode || "",
			accountName: selectedPayPartner?.accountName || "",
			accountNumber: selectedPayPartner?.accountNumber || "",
			whitelist_ip: selectedPayPartner?.whitelist_ip || "",
			webhook: selectedPayPartner?.webhook || "",
			webhook_2: selectedPayPartner?.webhook_2 || "",
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});

	const { mutate: editPayPartnerMutation } = useMutation(
		"edit-pay-partner",
		editPayPartner,
		{
			onSuccess: (dataSuccess) => {
				setEditMessage(dataSuccess?.message);
				formik.setSubmitting(false);
				formik.resetForm();
				setEditPartnerSuccessModalOpen(true);
				handleClose();
			},
			onError: (errors) => {
				formik.setSubmitting(false);
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("fetch-pay-partners");
			},
		}
	);

	const handleSubmit = (values) => {
		const payload = {
			role,
			username: user?.username,
			agency: values.agency,
			partnerName: values.partnerName,
			emailAddress: values.emailAddress,
			phoneNumber: values.phoneNumber,
			bankName: values.bankName,
			bankCode: values.bankCode,
			accountName: values.accountName,
			accountNumber: values.accountNumber,
			whitelist_ip: values.whitelist_ip,
			webhook: values.webhook,
			webhook_2: values.webhook_2,
			ID: selectedPayPartner?.id,
		};

		if (values.whitelist_ip) {
			delete payload.whitelist_ip;
		}

		editPayPartnerMutation(payload);
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
						maxWidth: "60rem",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box sx={{ height: "5.25rem" }}>
							<img
								src={logo}
								height="100%"
								width="auto"
								alt="Cardinalstone logo"
							/>
						</Box>
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						display: "flex",
						justifyContent: "center",
						mb: "3rem",
					}}
				>
					<Box
						sx={{
							width: { md: "40rem", xs: "100%" },
							p: { md: "0 2rem", xs: "0 1rem" },
							mt: "1rem",
						}}
					>
						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.agency}
								onChange={formik.handleChange}
								label="Agency"
								id="agency"
								name="agency"
								required
								error={formik.touched.agency && Boolean(formik.errors.agency)}
								helperText={formik.touched.agency && formik.errors.agency}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.partnerName}
								onChange={formik.handleChange}
								label="Partner name"
								id="partnerName"
								name="partnerName"
								required
								error={
									formik.touched.partnerName &&
									Boolean(formik.errors.partnerName)
								}
								helperText={
									formik.touched.partnerName && formik.errors.partnerName
								}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.emailAddress}
								onChange={formik.handleChange}
								label="Email address"
								id="emailAddress"
								name="emailAddress"
								required
								error={
									formik.touched.emailAddress &&
									Boolean(formik.errors.emailAddress)
								}
								helperText={
									formik.touched.emailAddress && formik.errors.emailAddress
								}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.phoneNumber}
								onChange={formik.handleChange}
								label="Phone Number"
								id="phoneNumber"
								name="phoneNumber"
								required
								error={
									formik.touched.phoneNumber &&
									Boolean(formik.errors.phoneNumber)
								}
								helperText={
									formik.touched.phoneNumber && formik.errors.phoneNumber
								}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<SelectFieldComponent
								width="100%"
								value={formik.values.bankName}
								values={banksData}
								onChange={(e) => {
									formik.handleChange(e);
									const selectedBank = banksData?.find(
										(item) => item?.bankName === e.target.value
									);
									formik.setFieldValue(`bankCode`, selectedBank.iCode || "");
								}}
								label="Bank Name"
								id="bankName"
								name="bankName"
								menuValue="bankName"
								displayValue="bankName"
								required
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.accountName}
								onChange={formik.handleChange}
								label="Account Name"
								id="accountName"
								name="accountName"
								required
								error={
									formik.touched.accountName &&
									Boolean(formik.errors.accountName)
								}
								helperText={
									formik.touched.accountName && formik.errors.accountName
								}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.accountNumber}
								onChange={formik.handleChange}
								label="Account Number"
								id="accountNumber"
								name="accountNumber"
								required
								error={
									formik.touched.accountNumber &&
									Boolean(formik.errors.accountNumber)
								}
								helperText={
									formik.touched.accountNumber && formik.errors.accountNumber
								}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.whitelist_ip}
								onChange={formik.handleChange}
								label="Whitelist IP"
								id="whitelist_ip"
								name="whitelist_ip"
								error={
									formik.touched.whitelist_ip &&
									Boolean(formik.errors.whitelist_ip)
								}
								helperText={
									formik.touched.whitelist_ip && formik.errors.whitelist_ip
								}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.webhook}
								onChange={formik.handleChange}
								label="Webhook"
								id="webhook"
								name="webhook"
								error={formik.touched.webhook && Boolean(formik.errors.webhook)}
								helperText={formik.touched.webhook && formik.errors.webhook}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.webhook_2}
								onChange={formik.handleChange}
								label="Webhook 2"
								id="webhook_2"
								name="webhook_2"
								error={
									formik.touched.webhook_2 && Boolean(formik.errors.webhook_2)
								}
								helperText={formik.touched.webhook_2 && formik.errors.webhook_2}
							/>
						</TextFieldContainer>

						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Button
								color="primary"
								variant="contained"
								onClick={formik.handleSubmit}
								sx={{ p: "0.5rem 3rem" }}
								disabled={!formik.dirty || formik.isSubmitting}
							>
								{formik.isSubmitting ? (
									<CircularProgress size="1.5rem" />
								) : (
									"update"
								)}
							</Button>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

const TextFieldContainer = styled.div`
	margin-bottom: 1rem;
`;

import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import { currencySymbolMap } from "../../utils/constants";
import { numberFormatter } from "../../utils/helpers/functions";
import closeIcon from "../../assets/icons/close-icon.svg";
import logo from "../../assets/logos/smart-deals-logo.svg";

const columns = [
	{ id: "Record Info", label: "Record Info", minWidth: 80 },
	{ id: "Details", label: "Details", minWidth: 80 },
];

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	data,
}) {
	const { region } = useSelector((state) => state.auth);
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
						maxWidth: "60rem",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box sx={{ height: "5.25rem" }}>
							<img
								src={logo}
								height="100%"
								width="auto"
								alt="Cardinalstone logo"
							/>
						</Box>
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						textAlign: "center",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "2rem",
							pb: "2rem",
							width: "30rem",
						}}
					>
						<Box sx={{ mb: "1rem", width: "100%", display: "flex" }}>
							<Paper sx={{ width: "100%", overflow: "hidden" }}>
								<TableContainer sx={{ maxHeight: 440 }}>
									<Table stickyHeader aria-label="sticky table">
										<TableHead>
											<TableRow>
												{columns.map((column) => (
													<TableCell
														key={column.id}
														style={{
															minWidth: column.minWidth,
															backgroundColor: "#e41f26",
															color: "#fff",
														}}
													>
														{column.label}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery Fullname</TableCell>
												<TableCell>{data?.delivery_fullName || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Username</TableCell>
												<TableCell>{data?.username || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Unique Order ID</TableCell>
												<TableCell>{data?.unique_order_id || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Deal ID</TableCell>
												<TableCell>{data?.dealID || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>{`Shipping Fee (${
													currencySymbolMap[region?.region]
												})`}</TableCell>
												<TableCell>
													{numberFormatter(data?.shippingFee)}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>isPaid</TableCell>
												<TableCell>
													{Number(data?.paid) === 1 ? "Paid" : "Unpaid"}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery Option</TableCell>
												<TableCell>{data?.deliveryOption}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery EmailAddress</TableCell>
												<TableCell>{data?.delivery_emailAddress}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery Phone Number</TableCell>
												<TableCell>{data?.delivery_phoneNumber}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery Address</TableCell>
												<TableCell>{data?.delivery_address}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery City</TableCell>
												<TableCell>{data?.delivery_city}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery State</TableCell>
												<TableCell>{data?.delivery_state}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery Country</TableCell>
												<TableCell>{data?.delivery_country}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery Longitude</TableCell>
												<TableCell>{data?.delivery_longitude}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery Latitude</TableCell>
												<TableCell>{data?.delivery_latitude}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery Date</TableCell>
												<TableCell>{data?.delivery_date}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery Status</TableCell>
												<TableCell>{data?.delivery_status}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Delivery Tracking</TableCell>
												<TableCell>
													<Link href={data?.delivery_tracking} target="_blank">
														{data?.delivery_tracking}
													</Link>
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Order ID</TableCell>
												<TableCell>{data?.orderID}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Pickup Fullname</TableCell>
												<TableCell>{data?.pickup_fullName}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Pickup Email Address</TableCell>
												<TableCell>{data?.pickup_emailAddress}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Pickup Phone Number</TableCell>
												<TableCell>{data?.pickup_phoneNumber}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Pickup Address</TableCell>
												<TableCell>{data?.pickup_address}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Pickup City</TableCell>
												<TableCell>{data?.pickup_city}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Pickup State</TableCell>
												<TableCell>{data?.pickup_state}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Pickup Country</TableCell>
												<TableCell>{data?.pickup_country}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Pickup Date</TableCell>
												<TableCell>{data?.pickup_date}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Pickup Longitude</TableCell>
												<TableCell>{data?.pickup_longitude}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Pickup Latitude</TableCell>
												<TableCell>{data?.pickup_latitude}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Pickup Status</TableCell>
												<TableCell>{data?.pickup_status}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Pickup Tracking</TableCell>
												<TableCell>
													<Link href={data?.delivery_tracking} target="_blank">
														{data?.pickup_tracking}
													</Link>
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>State ID</TableCell>
												<TableCell>{data?.stateID}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Status</TableCell>
												<TableCell>{data?.status}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Date Created</TableCell>
												<TableCell>{data?.createDate}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</Box>
						<Stack
							direction="row"
							spacing={2}
							sx={{
								marginBottom: "1rem",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								sx={{
									paddingLeft: "3rem",
									paddingRight: "3rem",
								}}
								variant="contained"
								onClick={() => {
									handleClose();
								}}
							>
								close
							</Button>
						</Stack>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

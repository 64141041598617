import { useRef } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import EventIcon from "@mui/icons-material/Event";
import AddCardIcon from "@mui/icons-material/AddCard";

import {
	ArrowBackIosNewSharpIcon,
	ArrowForwardIosSharpIcon,
	IconButton,
	Box,
} from "../common/muiComponents";
import SummaryCard from "../common/SummaryCard";
import { numberFormatter } from "../../utils/helpers/functions";
import { currencySymbolMap, ROLES } from "../../utils/constants";

const SummaryContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexWrap: "nowrap",
	overflowX: "auto",
	position: "relative",
	[theme.breakpoints.down("lg")]: {
		marginLeft: 0,
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
	},
	scrollbarWidth: "none",
	msOverflowStyle: "none",
	"&::-webkit-scrollbar": {
		display: "none",
	},
}));

const ScrollButton = styled(IconButton)({
	position: "absolute",
	top: "50%",
	transform: "translateY(-50%)",
	zIndex: 999,
	// border: "1px solid red",
	backgroundColor: "rgba(255, 255, 255, 0.5)",
	"&:hover": {
		backgroundColor: "rgba(255, 255, 255, 0.8)",
	},
});

const SummaryCards = ({ summary, loading }) => {
	const containerRef = useRef(null);
	const { region, user } = useSelector((state) => state.auth);

	const handleScroll = (scrollOffset) => {
		if (containerRef.current) {
			containerRef.current.scrollTo({
				left: containerRef.current.scrollLeft + scrollOffset,
				behavior: "smooth",
			});
		}
	};
	return (
		<Box sx={{ position: "relative" }}>
			<ScrollButton
				aria-label="Scroll Left"
				onClick={() => handleScroll(-640)}
				sx={{ left: -16 }}
			>
				<ArrowBackIosNewSharpIcon />
			</ScrollButton>
			<SummaryContainer ref={containerRef}>
				{/* {(user.role === ROLES.EXECUTIVE || user.role === ROLES.FINCON) && (
					<SummaryCard
						value={`${numberFormatter(summary?.smartdeals_balance)}`}
						text="smartdeals"
						Icon={
							<CreditCardIcon sx={{ fontSize: "2rem", color: "#FF0000" }} />
						}
						backgroundColor="#F6E0B8"
						loading={loading}
					/>
				)} */}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.FINCON) && (
					<SummaryCard
						value={`${numberFormatter(summary?.subscription)} (${
							currencySymbolMap[region.region]
						}${numberFormatter(summary?.subscription_amount)})`}
						text="subscription"
						Icon={<AddCardIcon sx={{ fontSize: "2rem", color: "#DB00FF" }} />}
						backgroundColor="#F7C5FF"
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.FINCON) && (
					<SummaryCard
						value={`${numberFormatter(summary?.bill_payment)} (${
							currencySymbolMap[region.region]
						}${numberFormatter(summary?.bill_payment_amount)})`}
						text="bill payment"
						Icon={
							<CreditCardIcon sx={{ fontSize: "2rem", color: "#00B38C" }} />
						}
						backgroundColor="#C5FFE3"
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.FINCON) && (
					<SummaryCard
						value={`${numberFormatter(summary?.wallet_balance)}`}
						text="wallet"
						Icon={
							<CreditCardIcon sx={{ fontSize: "2rem", color: "#00A3FF" }} />
						}
						backgroundColor="#D3EFFF"
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.FINCON) && (
					<SummaryCard
						value={`${numberFormatter(summary?.paystack_balance)}`}
						text="paystack"
						Icon={
							<CreditCardIcon sx={{ fontSize: "2rem", color: "#00C96C" }} />
						}
						backgroundColor="#E5FAF0"
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.FINCON) && (
					<SummaryCard
						value={`${numberFormatter(summary?.providus_balance)}`}
						text="providus"
						backgroundColor="#F7C5FF"
						Icon={
							<CreditCardIcon sx={{ fontSize: "2rem", color: "#DB00FF" }} />
						}
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.FINCON) && (
					<SummaryCard
						value={`${numberFormatter(summary?.revenue)}`}
						text="revenue"
						backgroundColor="#F6E0B8"
						Icon={
							<CreditCardIcon sx={{ fontSize: "2rem", color: "#FF0000" }} />
						}
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.FINCON) && (
					<SummaryCard
						value={`${numberFormatter(summary?.baxi_remote_balance)}`}
						text="Baxi"
						Icon={
							<CreditCardIcon sx={{ fontSize: "2rem", color: "#906E7B" }} />
						}
						backgroundColor="#FFD9E6"
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.FINCON) && (
					<SummaryCard
						value={`${numberFormatter(summary?.webpay_remote_balance)}`}
						text="Webpay"
						Icon={
							<CreditCardIcon sx={{ fontSize: "2rem", color: "#FF0000" }} />
						}
						backgroundColor="#F6E0B8"
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.FINCON) && (
					<SummaryCard
						value={`${numberFormatter(summary?.thepeer_remote_balance)}`}
						text="ThePeer"
						Icon={
							<CreditCardIcon sx={{ fontSize: "2rem", color: "#00B38C" }} />
						}
						backgroundColor="#C5FFE3"
						loading={loading}
					/>
				)}
				{/* {(user.role === ROLES.EXECUTIVE || user.role === ROLES.FINCON) && (
					<SummaryCard
						value={`${numberFormatter(summary?.interswitch_vcard_balance)}`}
						text="Interswitch"
						Icon={
							<CreditCardIcon sx={{ fontSize: "2rem", color: "#ccae91" }} />
						}
						backgroundColor="#FFD9C3"
						loading={loading}
					/>
				)} */}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.CSU) && (
					<SummaryCard
						value={numberFormatter(summary?.members || 0)}
						text="members"
						Icon={
							<PersonAddAlt1OutlinedIcon
								sx={{ fontSize: "2rem", color: "#00A3FF" }}
							/>
						}
						backgroundColor="#D3EFFF"
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.CSU) && (
					<SummaryCard
						value={numberFormatter(summary?.partner || 0)}
						text="discount partners"
						Icon={
							<HandshakeOutlinedIcon
								sx={{ fontSize: "2rem", color: "#00C96C" }}
							/>
						}
						backgroundColor="#E5FAF0"
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.CSU) && (
					<SummaryCard
						value={numberFormatter(summary?.event || 0)}
						text="event managers"
						Icon={<EventIcon sx={{ fontSize: "2rem", color: "#0044B3" }} />}
						backgroundColor="#8AB3FF"
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.CSU) && (
					<SummaryCard
						value={numberFormatter(summary?.deals || 0)}
						text="number of deals"
						Icon={
							<ShoppingCartOutlinedIcon
								sx={{ fontSize: "2rem", color: "#DB00FF" }}
							/>
						}
						backgroundColor="#F7C5FF"
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.CSU) && (
					<SummaryCard
						value={numberFormatter(summary?.discount || 0)}
						text="discounts claimed"
						Icon={
							<LocalOfferOutlinedIcon
								sx={{ fontSize: "2rem", color: "#FF0000" }}
							/>
						}
						backgroundColor="#FFDDDD"
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.CSU) && (
					<SummaryCard
						value={summary?.mvp}
						text="Partner of the Month"
						subtext="By discount claimed"
						Icon={
							<EmojiEventsOutlinedIcon
								sx={{ fontSize: "2rem", color: "#E6771F" }}
							/>
						}
						isNumber={false}
						backgroundColor="#FAE4D2"
						loading={loading}
					/>
				)}
				{(user.role === ROLES.EXECUTIVE || user.role === ROLES.CSU) && (
					<SummaryCard
						value={summary?.rating}
						text="Partner of the Month"
						subtext="By rating"
						Icon={
							<EmojiEventsOutlinedIcon
								sx={{ fontSize: "2rem", color: "#906E7B" }}
							/>
						}
						isNumber={false}
						backgroundColor="#FFD9E6"
						loading={loading}
					/>
				)}
			</SummaryContainer>
			<ScrollButton
				aria-label="Scroll Right"
				onClick={() => handleScroll(260)}
				sx={{ right: -16 }}
			>
				<ArrowForwardIosSharpIcon />
			</ScrollButton>
		</Box>
	);
};

export default SummaryCards;

import request from "../httpService";

export async function signup(payload) {
	const url = `/api/customer/new-account`;
	const { data } = await request.post(url, payload);
	return data;
}

export async function sendOtp(payload) {
	const url = "/api/customer/send-otp";
	const { data } = await request.post(url, payload);
	return data;
}

export async function resendOtp(payload) {
	const url = "/api/customer/resend-otp";
	const { data } = await request.post(url, payload);
	return data;
}

export async function verifyOtp(payload) {
	const url = "/api/customer/verify-otp";
	const { data } = await request.post(url, payload);
	return data;
}

export async function forgotPassword(payload) {
	const url = "/api/forgotPassword";
	const { data } = await request.post(url, payload);
	return data;
}

export async function changePassword(payload) {
	const url = "/api/changePassword";
	const { data } = await request.post(url, payload);
	return data;
}

import { Switch, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

import Login from "../views/Admin/AdminLogin";
import MainRoutes from "./admin/MainRoutes";
import SupportChat from "../views/Admin/SupportChat";
import ProctectedRoute from "../../components/common/ProtectedRoutes";
import { ROLES } from "../../utils/constants";

const AdminRoutes = () => {
	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					backgroundColor: "#fff",
				}}
			>
				<CssBaseline />
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						backgroundColor: "#fff",
						minHeight: "100vh",
					}}
				>
					<Box>
						<Switch>
							<ProctectedRoute
								path="/support-chat"
								roles={[ROLES.CSU, ROLES.EXECUTIVE]}
								component={SupportChat}
							/>
							<Route path="/" exact component={Login} />
							<Route path="/" component={MainRoutes} />
						</Switch>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default AdminRoutes;

import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/BillPaymentTable";
import CustomTabPanel from "../../../components/common/CustomTabPanel";
import ViewBillPaymentDetailsModal from "../../../components/common/ViewBillPaymentDetailsModal";
import {
	getSuccessfulBillPayment,
	getPendingBillPayment,
	getFailedBillPayment,
} from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const BillPayment = () => {
	const [selectedItem, setSelectedItem] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);

	const { region, user, bizFrom, role } = useSelector((state) => state.auth);

	const handleChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const {
		data: successfulBillPaymentData = [],
		isLoading: successfulBillPaymentLoading,
		isError: successfulBillPaymentError,
		error: successfulBillPaymentErrorMessage,
	} = useQuery(
		["get-successful-bill-payment", region.region],
		async () =>
			getSuccessfulBillPayment({
				region: region.region,
				bizFrom,
				role,
				username: user?.username,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	const {
		data: pendingBillPaymentData = [],
		isLoading: pendingBillPaymentLoading,
		isError: pendingBillPaymentError,
		error: pendingBillPaymentErrorMessage,
	} = useQuery(
		["get-pending-bill-payment", region.region],
		async () =>
			getPendingBillPayment({
				region: region.region,
				bizFrom,
				role,
				username: user?.username,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	const {
		data: failedBillPaymentData = [],
		isLoading: failedBillPaymentLoading,
		isError: failedBillPaymentError,
		error: failedBillPaymentErrorMessage,
	} = useQuery(
		["get-failed-bill-payment", region.region],
		async () =>
			getFailedBillPayment({
				region: region.region,
				bizFrom,
				role,
				username: user?.username,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	const displayedSuccessfulBillPaymentData = useMemo(() => {
		let filtered = [...successfulBillPaymentData];
		if (searchTerm) {
			filtered = filtered.filter((item) => {
				return (
					item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item.billerName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.product?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					`${item.amount}`?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.comment?.toLowerCase()?.includes(searchTerm.toLowerCase())
				);
			});
		}
		return filtered;
	}, [successfulBillPaymentData, searchTerm]);

	const displayedPendingBillPaymentData = useMemo(() => {
		let filtered = [...pendingBillPaymentData];
		if (searchTerm) {
			filtered = filtered.filter((item) => {
				return (
					item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item.billerName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.product?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					`${item.amount}`?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.comment?.toLowerCase()?.includes(searchTerm.toLowerCase())
				);
			});
		}
		return filtered;
	}, [pendingBillPaymentData, searchTerm]);

	const displayedFailedBillPaymentData = useMemo(() => {
		let filtered = [...failedBillPaymentData];
		if (searchTerm) {
			filtered = filtered.filter((item) => {
				return (
					item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item.billerName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.product?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					`${item.amount}`?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
					item.comment?.toLowerCase()?.includes(searchTerm.toLowerCase())
				);
			});
		}
		return filtered;
	}, [failedBillPaymentData, searchTerm]);

	useEffect(() => {
		if (successfulBillPaymentError) {
			if (successfulBillPaymentErrorMessage.response) {
				toast.error(successfulBillPaymentErrorMessage.response.data?.message);
			} else if (successfulBillPaymentErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", successfulBillPaymentErrorMessage.message);
			}
		}
		if (pendingBillPaymentError) {
			if (pendingBillPaymentErrorMessage.response) {
				toast.error(pendingBillPaymentErrorMessage.response.data?.message);
			} else if (pendingBillPaymentErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", pendingBillPaymentErrorMessage.message);
			}
		}
		if (failedBillPaymentError) {
			if (failedBillPaymentErrorMessage.response) {
				toast.error(failedBillPaymentErrorMessage.response.data?.message);
			} else if (failedBillPaymentErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", failedBillPaymentErrorMessage.message);
			}
		}
	}, [
		successfulBillPaymentError,
		successfulBillPaymentErrorMessage,
		pendingBillPaymentError,
		pendingBillPaymentErrorMessage,
		failedBillPaymentError,
		failedBillPaymentErrorMessage,
	]);

	const handleExcelExport = () => {
		downloadExcel([], "Partners", "partners");
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="bill payment"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search bill payment"
				/>
			</Box>

			<Box>
				<Tabs
					value={currentTab}
					onChange={handleChange}
					aria-label="bill payment tabs"
				>
					<Tab label="Successful Payments" {...a11yProps(0)} />
					<Tab label="Pending Payments" {...a11yProps(1)} />
					<Tab label="Failed Payments" {...a11yProps(2)} />
				</Tabs>
			</Box>
			<CustomTabPanel value={currentTab} index={0}>
				<Table
					data={displayedSuccessfulBillPaymentData}
					loading={successfulBillPaymentLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</CustomTabPanel>
			<CustomTabPanel value={currentTab} index={1}>
				<Table
					data={displayedPendingBillPaymentData}
					loading={pendingBillPaymentLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</CustomTabPanel>
			<CustomTabPanel value={currentTab} index={2}>
				<Table
					data={displayedFailedBillPaymentData}
					loading={failedBillPaymentLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</CustomTabPanel>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<ViewBillPaymentDetailsModal
					open={viewDetailsModalOpen}
					handleClose={() => {
						setViewDetailsModalOpen(false);
					}}
					title="View Bill Payment Details"
					data={selectedItem}
				/>
			</Box>
		</Box>
	);
};

export default BillPayment;

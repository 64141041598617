import { useState, useEffect, useLayoutEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Box, Button, styled } from "../../../components/common/muiComponents";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Collab/MemberReportTable";
import ChooseRoleModal from "../../../components/Admin/ChooseCollabeRoleModal";
import { fetchCollabMembers } from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";
import { ROLES } from "../../../utils/constants";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const SummaryFilterContainer = styled(Box)({
	display: "flex",
	justifyContent: "flex-end",
	marginBottom: ".5rem",
	alignItems: "center",
});

const CollabMembersReport = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedRole, setSelectedRole] = useState("");
	const [openChooseRoleModal, setOpenChooseRoleModal] = useState(false);

	const { region, user } = useSelector((state) => state.auth);

	useLayoutEffect(() => {
		if (user?.accountType?.toLowerCase() === "collab") {
			setSelectedRole(user?.role);
		}
	}, []);

	const {
		data: reportData = [],
		isLoading: reportLoading,
		isError: reportError,
		error: reportErrorMessage,
	} = useQuery(
		[`fetch-collab-members-${selectedRole}`, region?.region, selectedRole],
		async () =>
			fetchCollabMembers({
				region: region?.region,
				roleID: selectedRole,
				username: user?.username,
			}),
		{
			select: (data) => data?.data,
			staleTime: 4 * 60 * 1000,
			enabled: !!region?.region && !!selectedRole,
		}
	);

	useEffect(() => {
		if (reportError) {
			if (reportErrorMessage.response) {
				toast.error(reportErrorMessage.response.data?.message);
			} else if (reportErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", reportErrorMessage.message);
			}
		}
	}, [reportError, reportErrorMessage]);

	useEffect(() => {
		if (reportData.length && !reportLoading) {
			setDisplayed([...reportData]);
			setAllData([...reportData]);
		}
	}, [reportData, reportLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item?.username
							?.toLowerCase()
							?.includes(searchTerm?.toLowerCase()) ||
						item?.firstName
							?.toLowerCase()
							?.includes(searchTerm?.toLowerCase()) ||
						item?.lastName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text={`${selectedRole?.toUpperCase()} Members Report`}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search report"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				{(user?.role === ROLES.EXECUTIVE || user?.role === ROLES.FINCON) && (
					<SummaryFilterContainer>
						<Button
							variant="contained"
							sx={{ textTransform: "capitalize" }}
							onClick={() => setOpenChooseRoleModal(true)}
						>
							build report
						</Button>
					</SummaryFilterContainer>
				)}
				<Table data={displayed} loading={reportLoading} />
			</Box>

			<ChooseRoleModal
				open={openChooseRoleModal}
				handleClose={() => setOpenChooseRoleModal(false)}
				selectedRole={selectedRole}
				setSelectedRole={setSelectedRole}
				title="Choose Collab Role"
			/>
		</Box>
	);
};

export default CollabMembersReport;

import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
	Box,
	Button,
	Tabs,
	Tab,
	Typography,
} from "../../../components/common/muiComponents";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/UserReferralsTable";
import UserReferralsSummaryTable from "../../../components/Admin/UserReferralsSummaryTable";
import AddRecipientModal from "../../../components/Admin/UserReferralRecipientModal";
import TabPanel from "../../../components/common/TabPanel";
import {
	fetchUserReferrals,
	userReferralSummary,
} from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
	width: "100%",
};

const openRecipientButtonContainerStyle = {
	display: "flex",
	alignItems: "center",
	mb: "1rem",
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const UserReferrals = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [recipient, setRecipient] = useState("");
	const [openAddRecipientModal, setOpenAddRecipientModal] = useState(false);
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const { user, region } = useSelector((state) => state.auth);

	const {
		data: userReferralsData = [],
		isLoading: userReferralsLoading,
		isError: userReferralsError,
		error: userReferralsErrorMessage,
	} = useQuery(
		["fetch-user-referrals", region?.region, recipient, openAddRecipientModal],
		async () =>
			fetchUserReferrals({
				region: region?.region,
				username: user?.username,
				recipient,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: !!region?.region && !!recipient && !openAddRecipientModal,
		}
	);

	const {
		data: userReferralsSummaryData = [],
		isLoading: userReferralsSummaryLoading,
		isError: userReferralsSummaryError,
		error: userReferralsSummaryErrorMessage,
	} = useQuery(
		["fetch-user-referrals-summary", region?.region, openAddRecipientModal],
		async () =>
			userReferralSummary({
				region: region?.region,
				username: user?.username,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: !!region?.region && !openAddRecipientModal,
		}
	);

	useEffect(() => {
		if (userReferralsError) {
			if (userReferralsErrorMessage.response) {
				toast.error(userReferralsErrorMessage.response.data?.message);
			} else if (userReferralsErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", userReferralsErrorMessage.message);
			}
		}
		if (userReferralsSummaryError) {
			if (userReferralsSummaryErrorMessage.response) {
				toast.error(userReferralsSummaryErrorMessage.response.data?.message);
			} else if (userReferralsSummaryErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", userReferralsSummaryErrorMessage.message);
			}
		}
	}, [
		userReferralsError,
		userReferralsErrorMessage,
		userReferralsSummaryError,
		userReferralsSummaryErrorMessage,
	]);

	const displayed = useMemo(() => {
		if (userReferralsData.length) {
			let filteredData = [...userReferralsData];
			if (searchTerm) {
				filteredData = filteredData.filter((itemValue) => {
					return (
						itemValue.username
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						itemValue.accountType
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredData;
		}
		return userReferralsData;
	}, [userReferralsData, searchTerm]);

	const displayedSummary = useMemo(() => {
		if (userReferralsSummaryData.length) {
			let filteredData = [...userReferralsSummaryData];
			if (searchTerm) {
				filteredData = filteredData.filter((itemValue) => {
					return (
						itemValue.Referrer?.toLowerCase()?.includes(
							searchTerm.toLowerCase()
						) ||
						`${itemValue.Members}`
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredData;
		}
		return userReferralsSummaryData;
	}, [userReferralsSummaryData, searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};
	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="user referrals"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search records"
				/>
			</Box>

			<Box sx={{ mb: "2rem" }}>
				<Tabs value={value} onChange={handleChange} aria-label="referral tabs">
					<Tab label="Summary" {...a11yProps(0)} />
					<Tab label="Details" {...a11yProps(1)} />
				</Tabs>
			</Box>

			<TabPanel value={value} index={0}>
				<UserReferralsSummaryTable
					data={displayedSummary}
					loading={userReferralsSummaryLoading}
				/>
			</TabPanel>

			<TabPanel value={value} index={1}>
				<Box sx={openRecipientButtonContainerStyle}>
					{!!recipient && (
						<Typography
							textAlign="center"
							variant="body1"
							sx={{ width: "100%" }}
						>{`Referral report for ${recipient?.toUpperCase()}`}</Typography>
					)}
					<Button
						variant="contained"
						onClick={() => setOpenAddRecipientModal(true)}
						sx={{
							textTransform: "capitalize",
							ml: "auto",
							whiteSpace: "nowrap",
						}}
					>
						Enter Referrer
					</Button>
				</Box>
				<Table data={displayed} loading={userReferralsLoading} />
			</TabPanel>

			<AddRecipientModal
				open={openAddRecipientModal}
				handleClose={() => {
					setOpenAddRecipientModal(false);
				}}
				title="Enter Referrer"
				recipient={recipient}
				setRecipient={setRecipient}
			/>
		</Box>
	);
};

export default UserReferrals;

import { Switch, Route } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Layout from "../../../components/Admin/Layout";
import Dashboard from "../../views/Admin/Dashboard";
import Partners from "../../views/Admin/Partners";
import PartnerDiscount from "../../views/Admin/PartnerDiscount";
import ClubMembers from "../../views/Admin/ClubMembers";
import Products from "../../views/Admin/Products";
import OngoingDeals from "../../views/Admin/OngoingDeals";
import DealsWon from "../../views/Admin/DealsWon";
import DiscountsClaimed from "../../views/Admin/DiscountsClaimed";
import AdminSettings from "./AdminSettings";
// import ViewProduct from "../../views/Admin/ViewProduct";
// import CreateProduct from "../../views/Admin/CreateProduct";
// import EditProduct from "../../views/Admin/EditProduct";
import Checkout from "../../views/Admin/Checkout";
import SuggestedPartners from "../../views/Admin/SuggestedPartners";
import Notifications from "../../views/Admin/Notifications";
import ActivityLog from "../../views/Admin/ActivityLog";
import NotFound from "../../views/NotFoundPage/NotFoundPage";
import Adverts from "../../views/Admin/Adverts";
import Delivery from "../../views/Admin/Delivery";
import MailingList from "../../views/Admin/MailingList";
import FailedWithdrawals from "../../views/Admin/FailedWithdrawals";
import WalletReport from "../../views/Admin/WalletReport";
import Subscriptions from "../../views/Admin/Subscriptions";
import Banks from "../../views/Admin/Banks";
import VerifyAccount from "../../views/Admin/VerifyAccount";
import FlutterLog from "../../views/Admin/FlutterLog";
import PaystackLog from "../../views/Admin/PaystackLog";
import ProvidusLog from "../../views/Admin/ProvidusLog";
import SignUp from "../../views/Admin/SignUp";
import InitiateTransfer from "../../views/Admin/InitiateTransfer";
import CompleteTransfer from "../../views/Admin/CompleteTransfer";
import WalletBalnce from "../../views/Admin/WalletBalance";
import Events from "../../views/Admin/Events";
import ContactUs from "../../views/Admin/ContactUs";
import Users from "../../views/Admin/Users";
import ExecutiveSummary from "../../views/Admin/ExecutiveSummary";
import BillPayment from "../../views/Admin/BillPayment";
import MavidTransfer from "../../views/Admin/MakvidTransfer";
import PushNotification from "../../views/Admin/PushNotification";
import PayWithSmartDeals from "../../views/Admin/PayWithSmartDeals";
import DelayedTransactions from "../../views/Admin/DelayedTransactions";
import PendingInflow from "../../views/Admin/PendingInflow";
import UserReferrals from "../../views/Admin/UserReferrals";
import PendingProvidusNotifications from "../../views/Admin/PendingProvidusNotifications";
import NoticeBoard from "../../views/Admin/NoticeBoard";
import CollabMembersReport from "../../views/Admin/CollabMembersReport";
import CollabSubscriptionReport from "../../views/Admin/CollabSubscriptionReport";
import BaxiServiceStatus from "../../views/Admin/BaxiServiceStatus";
import CoopMembersReport from "../../views/Admin/CoopMembersReport";
import CoopTransactionsLog from "../../views/Admin/CoopTransactionsLog";
import CoopTransactionsSummary from "../../views/Admin/CoopTransactionsSummary";
import CoopWalletBalances from "../../views/Admin/CoopWalletBalances";
import CoopSubscriptionReport from "../../views/Admin/CoopSubscriptionReport";
import ScumlReport from "../../views/Admin/ScumlReport";
import BvnReport from "../../views/Admin/BvnReport";
import NinReport from "../../views/Admin/NinReport";
import FetchInterest from "../../views/Admin/FetchInterest";
import NGX from "../../views/Admin/NGX";
import StockRecommendations from "../../views/Admin/StockRecommendations";
import EventManagers from "../../views/Admin/EventManagers";
import AMC from "../../views/Admin/AMC";
import VirtualCardRequest from "../../views/Admin/VirtualCardRequest";
import VirtualCards from "../../views/Admin/VirtualCards";
// import FundPaystackBalance from "../../views/Admin/FundPaystackBalance";
import ProctectedRoute from "../../../components/common/ProtectedRoutes";
import { drawerWidth, ROLES, ACCOUNT_TYPES } from "../../../utils/constants";

const MainRoutes = () => {
	return (
		<div>
			<Box
				sx={{
					display: "flex",
					width: "100vw !important",
					maxWidth: "100%",
				}}
			>
				<CssBaseline />
				<Layout />
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						minHeight: "100vh",
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						maxWidth: "100vw",
					}}
				>
					<Toolbar />
					<Switch>
						<ProctectedRoute
							path="/dashboard"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.FINCON, ROLES.EXECUTIVE]}
							component={Dashboard}
						/>
						<ProctectedRoute
							path="/partners"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							component={Partners}
						/>
						<ProctectedRoute
							path="/event-managers"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							component={EventManagers}
						/>
						<ProctectedRoute
							path="/events"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							component={Events}
						/>
						<ProctectedRoute
							path="/suggested-partners"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							component={SuggestedPartners}
						/>
						<ProctectedRoute
							path="/partners-discounts"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							component={PartnerDiscount}
						/>
						<ProctectedRoute
							path="/members"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							component={ClubMembers}
						/>
						{/* <ProctectedRoute path="/products/new" componen roles={[ROLES.ADMIN]}t={CreateProduct} /> */}
						{/* <ProctectedRoute path="/products/view/:productID" componen roles={[ROLES.ADMIN]}t={ViewProduct} /> */}
						{/* <ProctectedRoute path="/products/edit/:productID" componen roles={[ROLES.ADMIN]}t={EditProduct} /> */}
						<ProctectedRoute
							path="/products"
							exact
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							component={Products}
						/>
						<ProctectedRoute
							path="/live-deals"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							component={OngoingDeals}
						/>
						<ProctectedRoute
							path="/deals-won"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.FINCON, ROLES.EXECUTIVE]}
							component={DealsWon}
						/>
						<ProctectedRoute
							path="/checkout"
							roles={[
								ROLES.ADMIN,
								ROLES.CSU,
								ROLES.FINCON,
								ROLES.LOGISTICS,
								ROLES.EXECUTIVE,
							]}
							component={Checkout}
						/>
						<ProctectedRoute
							path="/discount-claimed"
							roles={[ROLES.CSU, ROLES.FINCON, ROLES.EXECUTIVE]}
							component={DiscountsClaimed}
						/>
						<Route path="/settings/" component={AdminSettings} />
						<ProctectedRoute
							path="/notifications"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							component={Notifications}
						/>
						<ProctectedRoute
							path="/activity-log"
							roles={[ROLES.ADMIN, ROLES.EXECUTIVE]}
							component={ActivityLog}
						/>
						<ProctectedRoute
							path="/adverts"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.FINCON, ROLES.EXECUTIVE]}
							component={Adverts}
						/>
						<ProctectedRoute
							path="/mailing-list"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							component={MailingList}
						/>
						<ProctectedRoute
							path="/contact-us"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							component={ContactUs}
						/>
						<ProctectedRoute
							path="/delivery"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							component={Delivery}
						/>
						<ProctectedRoute
							path="/failed-withdrawals"
							roles={[ROLES.FINCON, ROLES.EXECUTIVE]}
							component={FailedWithdrawals}
						/>
						<ProctectedRoute
							path="/wallet-report"
							roles={[ROLES.FINCON, ROLES.EXECUTIVE]}
							component={WalletReport}
						/>
						<ProctectedRoute
							path="/wallet-balances"
							roles={[ROLES.FINCON, ROLES.EXECUTIVE]}
							component={WalletBalnce}
						/>
						<ProctectedRoute
							path="/users"
							roles={[ROLES.FINCON, ROLES.EXECUTIVE]}
							component={Users}
						/>
						<ProctectedRoute
							path="/pending-providus-notifications"
							roles={[ROLES.ADMIN, ROLES.FINCON, ROLES.EXECUTIVE]}
							component={PendingProvidusNotifications}
						/>
						<ProctectedRoute
							path="/executive-summary"
							roles={[ROLES.ADMIN, ROLES.EXECUTIVE]}
							component={ExecutiveSummary}
						/>
						<ProctectedRoute
							path="/bill-payment"
							roles={[ROLES.ADMIN, ROLES.EXECUTIVE, ROLES.CSU]}
							component={BillPayment}
						/>
						<ProctectedRoute
							path="/subscriptions"
							roles={[ROLES.FINCON, ROLES.EXECUTIVE]}
							component={Subscriptions}
						/>
						<ProctectedRoute
							path="/banks"
							roles={[ROLES.ADMIN, ROLES.FINCON, ROLES.EXECUTIVE]}
							component={Banks}
						/>
						<ProctectedRoute
							path="/verify-account"
							roles={[ROLES.ADMIN, ROLES.FINCON, ROLES.EXECUTIVE]}
							component={VerifyAccount}
						/>
						<ProctectedRoute
							path="/flutter-log"
							roles={[ROLES.ADMIN, ROLES.FINCON, ROLES.EXECUTIVE]}
							component={FlutterLog}
						/>
						<ProctectedRoute
							path="/paystack-log"
							roles={[ROLES.ADMIN, ROLES.FINCON, ROLES.EXECUTIVE]}
							component={PaystackLog}
						/>
						<ProctectedRoute
							path="/providus-log"
							roles={[ROLES.ADMIN, ROLES.FINCON, ROLES.EXECUTIVE]}
							component={ProvidusLog}
						/>
						<ProctectedRoute
							path="/failing-baxi-service"
							roles={[ROLES.ADMIN, ROLES.CSU, ROLES.EXECUTIVE]}
							accountTypes={[ACCOUNT_TYPES.COLLAB]}
							component={BaxiServiceStatus}
						/>
						<ProctectedRoute
							path="/create-admin"
							roles={[ROLES.ADMIN, ROLES.EXECUTIVE]}
							component={SignUp}
						/>
						<ProctectedRoute
							path="/payment-requests"
							roles={[ROLES.FINCON, ROLES.EXECUTIVE]}
							component={MavidTransfer}
						/>
						<ProctectedRoute
							path="/submit-transfer"
							roles={[ROLES.FINCON]}
							component={InitiateTransfer}
						/>
						<ProctectedRoute
							path="/complete-transfer"
							roles={[ROLES.EXECUTIVE]}
							component={CompleteTransfer}
						/>
						<ProctectedRoute
							path="/pay-with-smartdeals"
							roles={[ROLES.ADMIN]}
							component={PayWithSmartDeals}
						/>
						<ProctectedRoute
							path="/push-notification"
							roles={[ROLES.EXECUTIVE]}
							component={PushNotification}
						/>
						<ProctectedRoute
							path="/delayed-transactions"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							component={DelayedTransactions}
						/>
						<ProctectedRoute
							path="/pending-inflow"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							component={PendingInflow}
						/>
						<ProctectedRoute
							path="/user-referrals"
							roles={[ROLES.EXECUTIVE, ROLES.CSU]}
							component={UserReferrals}
						/>
						<ProctectedRoute
							path="/notice-board"
							roles={[ROLES.EXECUTIVE, ROLES.CSU]}
							component={NoticeBoard}
						/>
						<ProctectedRoute
							path="/members-report"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							accountTypes={[ACCOUNT_TYPES.COLLAB]}
							component={CollabMembersReport}
						/>
						<ProctectedRoute
							path="/collab-earnings-report"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							accountTypes={[ACCOUNT_TYPES.COLLAB]}
							component={CollabSubscriptionReport}
						/>
						<ProctectedRoute
							path="/coop-members-report"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							accountTypes={[ACCOUNT_TYPES.COOP]}
							component={CoopMembersReport}
						/>
						<ProctectedRoute
							path="/coop-transactions-log"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							accountTypes={[ACCOUNT_TYPES.COOP]}
							component={CoopTransactionsLog}
						/>
						<ProctectedRoute
							path="/coop-transactions-summary"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							accountTypes={[ACCOUNT_TYPES.COOP]}
							component={CoopTransactionsSummary}
						/>
						<ProctectedRoute
							path="/coop-wallet-balances"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							accountTypes={[ACCOUNT_TYPES.COOP]}
							component={CoopWalletBalances}
						/>
						<ProctectedRoute
							path="/coop-earnings-report"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							accountTypes={[ACCOUNT_TYPES.COOP]}
							component={CoopSubscriptionReport}
						/>
						<ProctectedRoute
							path="/scuml-report"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							component={ScumlReport}
						/>
						<ProctectedRoute
							path="/bvn-report"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON, ROLES.CSU]}
							component={BvnReport}
						/>
						<ProctectedRoute
							path="/nin-report"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON, ROLES.CSU]}
							component={NinReport}
						/>
						<ProctectedRoute
							path="/fetch-interest"
							roles={[ROLES.EXECUTIVE, ROLES.CSU]}
							component={FetchInterest}
						/>
						<ProctectedRoute
							path="/ngx-monitor"
							roles={[ROLES.EXECUTIVE]}
							component={NGX}
						/>
						<ProctectedRoute
							path="/ngx-recommendations"
							roles={[ROLES.EXECUTIVE]}
							component={StockRecommendations}
						/>
						<ProctectedRoute
							path="/amc"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							component={AMC}
						/>
						<ProctectedRoute
							path="/virtual-card-request"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							component={VirtualCardRequest}
						/>
						<ProctectedRoute
							path="/virtual-cards"
							roles={[ROLES.EXECUTIVE, ROLES.FINCON]}
							component={VirtualCards}
						/>
						<Route path="*" exact component={NotFound} />
					</Switch>
				</Box>
			</Box>
		</div>
	);
};

export default MainRoutes;

import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/BanksTable";
import { downloadExcel } from "../../../utils/helpers/functions";
import { getBanks } from "../../../api/admin/dashboard";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const Banks = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);

	const { region } = useSelector((state) => state.auth);

	const {
		data: banksData = [],
		isLoading: banksLoading,
		isError: banksError,
		error: banksErrorMessage,
	} = useQuery(
		["get-banks", region.region],
		async () => getBanks(region.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (banksError) {
			if (banksErrorMessage.response) {
				toast.error(banksErrorMessage.response.data?.message);
			} else if (banksErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", banksErrorMessage.message);
			}
		}
	}, [banksError, banksErrorMessage]);

	useEffect(() => {
		if (banksData.length && !banksLoading) {
			setDisplayed(banksData);
			setAllData(banksData);
		}
	}, [banksData, banksLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.bankName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.bankCode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.iCode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.sortCode?.toLowerCase().includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Banks"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Banks"
				/>
			</Box>

			<Box>
				<Table data={displayed} loading={banksLoading} />
			</Box>
		</Box>
	);
};

export default Banks;

import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Button,
	Stack,
	Dialog,
	DialogContent,
	DialogTitle,
} from "./muiComponents";
import {
	formatNumberToDecimalPlace,
	newDateFormatter,
} from "../../utils/helpers/functions";
import closeIcon from "../../assets/icons/close-icon.svg";
import logo from "../../assets/logos/smart-deals-logo.svg";

const columns = [
	{ id: "Record Info", label: "Record Info", minWidth: 80 },
	{ id: "Details", label: "Details", minWidth: 80 },
];

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	data,
}) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
						maxWidth: "60rem",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box sx={{ height: "5.25rem" }}>
							<img
								src={logo}
								height="100%"
								width="auto"
								alt="Cardinalstone logo"
							/>
						</Box>
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						textAlign: "center",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "2rem",
							pb: "2rem",
							width: "30rem",
						}}
					>
						<Box sx={{ mb: "1rem", width: "100%", display: "flex" }}>
							<Paper sx={{ width: "100%", overflow: "hidden" }}>
								<TableContainer sx={{ maxHeight: 440 }}>
									<Table stickyHeader aria-label="sticky table">
										<TableHead>
											<TableRow>
												{columns.map((column) => (
													<TableCell
														key={column.id}
														style={{
															minWidth: column.minWidth,
															backgroundColor: "#e41f26",
															color: "#fff",
														}}
													>
														{column.label}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Name</TableCell>
												<TableCell>{data?.name || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Symbol</TableCell>
												<TableCell>{data?.symbol || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Prev Close (₦)</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(
														Number(data?.pclose),
														4
													) || ""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Average Price (₦)</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(
														Number(data?.average_price),
														4
													) || ""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Open</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(Number(data?.open), 4) ||
														""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>High (₦)</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(Number(data?.high), 4) ||
														""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Low (₦)</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(Number(data?.low), 4) ||
														""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Close (₦)</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(Number(data?.close), 4) ||
														""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Change (₦)</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(
														Number(data?.change),
														4
													) || ""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Change (%)</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(
														Number(data?.percent_change),
														4
													) || ""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Spread (%)</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(
														Number(data?.percent_spread),
														4
													) || ""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Volume</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(
														Number(data?.volume),
														4
													) || ""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Value</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(Number(data?.value), 4) ||
														""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>52 Week Low</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(
														Number(data?.["52wklow"]),
														4
													) || ""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>52 Week High</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(
														Number(data?.["52wkhigh"]),
														4
													) || ""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Segment</TableCell>
												<TableCell>{(data?.segment, 4) || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Sector</TableCell>
												<TableCell>{(data?.sector, 4) || ""}</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Value</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(Number(data?.value), 4) ||
														""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Value</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(Number(data?.value), 4) ||
														""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Value</TableCell>
												<TableCell>
													{formatNumberToDecimalPlace(Number(data?.value), 4) ||
														""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Date Created</TableCell>
												<TableCell>
													{newDateFormatter(
														data?.createDate,
														"do MMM yyyy h:mma",
														4
													) || ""}
												</TableCell>
											</TableRow>
											<TableRow hover role="checkbox" tabIndex={-1}>
												<TableCell>Date Modified</TableCell>
												<TableCell>
													{newDateFormatter(
														data?.modifyDate,
														"do MMM yyyy h:mma",
														4
													) || ""}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						</Box>
						<Stack
							direction="row"
							spacing={2}
							sx={{
								marginBottom: "1rem",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								sx={{
									paddingLeft: "3rem",
									paddingRight: "3rem",
								}}
								variant="contained"
								onClick={() => {
									handleClose();
								}}
							>
								close
							</Button>
						</Stack>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/FlutterTable";
import ViewDetailsModal from "../../../components/common/ViewFlutterLogDetailsModal";
import { downloadExcel } from "../../../utils/helpers/functions";
import { getFlutterLog } from "../../../api/admin/dashboard";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const FlutterLog = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);

	const { region } = useSelector((state) => state.auth);

	const {
		data: flutterLogData = [],
		isLoading: flutterLogLoading,
		isError: flutterLogError,
		error: flutterLogErrorMessage,
	} = useQuery(
		["get-flutter-log", region.region],
		async () => getFlutterLog(region.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (flutterLogError) {
			if (flutterLogErrorMessage.response) {
				toast.error(flutterLogErrorMessage.response.data?.message);
			} else if (flutterLogErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", flutterLogErrorMessage.message);
			}
		}
	}, [flutterLogError, flutterLogErrorMessage]);

	useEffect(() => {
		if (flutterLogData.length && !flutterLogLoading) {
			setDisplayed(flutterLogData);
			setAllData(flutterLogData);
		}
	}, [flutterLogData, flutterLogLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.emailAddress
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						item.channel?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.transactionRef
							?.toLowerCase()
							.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};
	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Flutter log"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Log"
				/>
			</Box>

			<Box>
				<Table
					data={displayed}
					loading={flutterLogLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
				/>
			</Box>

			<ViewDetailsModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Flutter Log Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default FlutterLog;

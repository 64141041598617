import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import TextFieldComponent from "./TextFieldComponent";
import NumberTextField from "./NumberTextField";
import SelectFieldComponent from "./SelectFieldComponent";
import closeIcon from "../../assets/icons/close-icon.svg";
import logo from "../../assets/logos/smart-deals-logo.svg";

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	productFields,
	setProductFields,
	productmediumData,
	productCategoryData,
	loading,
	handleSubmit,
	redemptionModeData,
	countriesData,
}) {
	const handleChange = (e) => {
		const { name, value } = e.target;
		const currentValues = { ...productFields };
		currentValues[name] = value;
		setProductFields(currentValues);
	};

	const handleImageChange = (e) => {
		const { name, files } = e.target;
		const reader = new FileReader();
		const file = files[0];
		// if (file.size > 500000) {
		// 	toast.error("file is too large");
		// 	return;
		// }
		if (
			file.type !== "image/jpg" &&
			file.type !== "image/png" &&
			file.type !== "image/jpeg"
		) {
			toast.error("Image must be of format jpg, png or jpeg");
			return;
		}
		reader.onloadend = () => {
			if (name === "files") {
				setProductFields({
					...productFields,
					files: file,
				});
			}
		};

		reader.readAsDataURL(file);
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
						maxWidth: "60rem",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box sx={{ height: "5.25rem" }}>
							<img
								src={logo}
								height="100%"
								width="auto"
								alt="Cardinalstone logo"
							/>
						</Box>
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						display: "flex",
						justifyContent: "center",
						mb: "3rem",
					}}
				>
					<Box
						sx={{
							width: { md: "40rem", xs: "100%" },
							p: { md: "0 2rem", xs: "0 1rem" },
							mt: "1rem",
						}}
					>
						<Box sx={{ mb: "1rem" }}>
							<TextFieldComponent
								value={productFields.productName}
								onChange={handleChange}
								label="Product Name"
								id="productName"
								name="productName"
							/>
						</Box>
						<Box sx={{ mb: "1rem" }}>
							<TextField
								type="file"
								onChange={handleImageChange}
								label={productFields.files?.name || "Click to upload image"}
								id="files"
								name="files"
								required
								InputLabelProps={{
									shrink: false,
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<FileUploadOutlinedIcon />
										</InputAdornment>
									),
									accept: "application/pdf",
								}}
								// eslint-disable-next-line react/jsx-no-duplicate-props
								inputProps={{ accept: "image/*" }}
								sx={{
									input: {
										opacity: 0,
									},
									label: {
										color: "#594E4E !important",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										border: "1px solid rgba(0, 0, 0, 0.23) !important",
									},
								}}
								fullWidth
								helperText="Image must be of format jpg, png or jpeg"
							/>
						</Box>

						<Box sx={{ mb: "1rem" }}>
							<SelectFieldComponent
								width="100%"
								value={productFields.productCategoryID}
								values={productCategoryData?.data}
								onChange={handleChange}
								label="Choose Product Category"
								id="productCategoryID"
								name="productCategoryID"
								menuValue="categoryID"
								displayValue="categoryName"
								required
							/>
						</Box>

						<Box sx={{ mb: "1rem" }}>
							<SelectFieldComponent
								width="100%"
								value={productFields.productMedium}
								values={productmediumData}
								onChange={handleChange}
								label="Choose Product Medium"
								id="productMedium"
								name="productMedium"
								menuValue="medium"
								displayValue="medium"
								required
							/>
						</Box>

						{productFields.productMedium?.toLowerCase() === "digital" && (
							<Box sx={{ mb: "1rem" }}>
								<SelectFieldComponent
									width="100%"
									value={productFields.redemptionMode}
									values={redemptionModeData}
									onChange={handleChange}
									label="Choose Redemption Mode"
									id="redemptionMode"
									name="redemptionMode"
									required
								/>
							</Box>
						)}

						<Box sx={{ mb: "1rem" }}>
							<TextFieldComponent
								value={productFields.productShortDesc}
								onChange={handleChange}
								label="Short Description"
								id="productShortDesc"
								name="productShortDesc"
							/>
						</Box>

						<Box sx={{ mb: "1rem" }}>
							<TextField
								id="productLongDesc"
								name="productLongDesc"
								label="Description"
								multiline
								rows={4}
								value={productFields.productLongDesc}
								onChange={handleChange}
								fullWidth
							/>
						</Box>
						<Box sx={{ mb: "2rem" }}>
							<NumberTextField
								value={productFields.retailPrice}
								onChange={handleChange}
								label="Retail Price"
								id="retailPrice"
								name="retailPrice"
								required
							/>
						</Box>
						<Box sx={{ mb: "2rem" }}>
							<SelectFieldComponent
								value={productFields.region}
								values={countriesData}
								onChange={handleChange}
								label="Choose Region"
								id="short_name"
								name="region"
								displayValue="name"
								menuValue="short_name"
							/>
						</Box>

						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Button
								color="primary"
								variant="contained"
								onClick={handleSubmit}
								sx={{ p: "0.5rem 3rem" }}
								disabled={loading}
							>
								{loading ? (
									<CircularProgress sx={{ color: "#fff" }} size="1.5rem" />
								) : (
									"save"
								)}
							</Button>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

import { useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { CircularProgress } from "./muiComponents";
import { fundPaystackBalance } from "../../api/admin/dashboard";

const sideBarItemActiveStyles = {
	color: "primary.main",
	borderRight: "0.25rem solid #E41F26",
};

const sideBarItemStyles = {
	color: "#594E4E",
	"&:hover": { ...sideBarItemActiveStyles },
};

const sidebarIconActiveStyles = {
	color: "primary.main",
};

const sideBarIconStyles = {
	color: "#594E4E",
	fontSize: "0.875rem !important",
	"&:hover": { ...sidebarIconActiveStyles },
};

const DrawerItem = ({ text, open, Icon }) => {
	const { region, user } = useSelector((state) => state.auth);

	const {
		// data: paystackBalanceData = {},
		isLoading: paystackBalanceLoading,
		isError: paystackBalanceError,
		error: paystackBalanceErrorMessage,
		refetch: paystackBalanceRefetch,
	} = useQuery(
		["fund-paystack-balance", region.region],
		async () =>
			fundPaystackBalance({
				region: region.region,
				username: user?.username,
			}),
		{
			select: (data) => data,
			cacheTime: 0,
			staleTime: 0,
			onSuccess: (data) => toast.success(data?.message),
			enabled: false,
		}
	);

	useEffect(() => {
		if (paystackBalanceError) {
			if (paystackBalanceErrorMessage.response) {
				toast.error(paystackBalanceErrorMessage.response.data?.message);
			} else if (paystackBalanceErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", paystackBalanceErrorMessage.message);
			}
		}
	}, [paystackBalanceError, paystackBalanceErrorMessage]);

	return (
		<ListItem
			key={text}
			disablePadding
			sx={{
				display: "block",
				borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
				...sideBarItemStyles,
			}}
			onClick={paystackBalanceRefetch}
		>
			<ListItemButton
				sx={{
					minHeight: 48,
					justifyContent: open ? "initial" : "center",
					px: 2.5,
				}}
			>
				<ListItemIcon
					sx={{
						minWidth: 0,
						mr: open ? 3 : "auto",
						justifyContent: "center",
						...sideBarIconStyles,
					}}
				>
					{paystackBalanceLoading ? <CircularProgress /> : <Icon />}
				</ListItemIcon>
				<ListItemText
					primary={text}
					sx={{ opacity: open ? 1 : 0, fontSize: "0.875rem !important" }}
				/>
			</ListItemButton>
		</ListItem>
	);
};

export default DrawerItem;

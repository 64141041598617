import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { getBizFrom } from "../../../api/admin/dashboard";
import { setBizfrom } from "../../../slices/authSlice";
import backgroundImage from "../../../assets/images/smart-deals-wall-logo.jpg";
import logo from "../../../assets/logos/smart-deals-logo.svg";

const Wrapper = styled("div")({
	backgroundImage: `url(${backgroundImage})`,
	backgroundSize: "cover",
	backgroundPosition: "center center",
	backgroundRepeat: "no-repeat",
	width: "100%",
	height: "100%",
	minHeight: "100vh",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
});

const Section = styled("div")({
	display: "flex",
	padding: "3vh 5vw",
	alignSelf: "center",
	alignContent: "center",
	alignItems: "center",
	height: "inherit",
	minHeight: "100%",
});

const Heading = styled("div")({
	height: "15vh",
	minHeight: "100px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	alignContent: "center",
	width: "fit-content",
	textAlign: "center",
	marginBottom: "1rem",
});

const BrandLogo = styled("img")({
	width: "250px",
	height: "auto",
	marginBottom: "1rem",
});

const titleStyles = {
	color: "#000",
	fontWeight: "bold",
	width: "fit-content",
	textAlign: "center",
	lineHeight: "45px",
	textTransform: "uppercase",
};

const AllCardWrapper = styled("div")({
	width: "100%",
	height: "100%",
	borderRadius: "8px",
});

const CardsWrapper = styled("div")({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	alignContent: "center",
	flexWrap: "wrap",
});

const ProductCard = styled("div")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	alignContent: "center",
	border: "1.5px solid rgb(117, 117, 158, 0.5)",
	width: "240px",
	height: "140px",
	margin: "10px",
	borderRadius: "10px",
	justifyContent: "center",
	cursor: "pointer",
	"&:hover": {
		backgroundColor: "#00256447",
	},
	"&.active": {
		backgroundColor: "#00256447 !important",
	},
});

const CardImage = styled("img")({
	height: "70px",
	marginBottom: "0.9rem",
});

const CardText = styled("p")({
	color: "#002564",
	// fontSize: "14px",
	fontWeight: 400,
	textAlign: "center",
	margin: 0,
});

const buttonContainerStyles = {
	display: "flex",
	justifyContent: "center",
	marginTop: "1rem",
};

const right = {
	flex: 1,
	display: "flex",
	width: "100%",
	flexDirection: "column",
	alignItems: "center",
	backgroundColor: "white",
	padding: "20px",
	justifyContent: "center",
};

const LandingPage = ({ location }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { region } = useSelector((state) => state.auth);

	const {
		data: bizFromData = [],
		isLoading: bizFromLoading,
		isError: bizFromError,
		error: bizFromErrorMessage,
	} = useQuery(
		["get-biz-from", region?.region],
		async () => getBizFrom(region?.region),
		{
			select: (data) => data?.data,
			staleTime: 4 * 60 * 1000,
			enabled: !!region?.region,
		}
	);

	const handleBizSelect = async (bizFrom) => {
		localStorage.setItem("bizFrom", bizFrom);
		dispatch(setBizfrom(bizFrom));
		history.push({
			pathname: "/otp-verification",
			state: {
				accessToken: location.state.accessToken,
			},
		});
	};

	useEffect(() => {
		if (bizFromError) {
			if (bizFromErrorMessage.response) {
				toast.error(bizFromErrorMessage.response.data?.message);
			} else if (bizFromErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", bizFromErrorMessage.message);
			}
		}
	}, [bizFromError, bizFromErrorMessage]);

	return (
		<Wrapper>
			<Section>
				<div style={right}>
					<Heading>
						<BrandLogo src={logo} alt="Smart Deals logo" />
						<Typography
							variant="body1"
							sx={titleStyles}
							gutterBottom
							component="div"
						>
							Tap on App name to make a selection
						</Typography>
					</Heading>
					<AllCardWrapper>
						<CardsWrapper>
							{!bizFromLoading && (
								<>
									{bizFromData.map((biz) => (
										<ProductCard
											key={biz.id}
											onClick={() => {
												handleBizSelect(biz?.bizFrom);
											}}
										>
											<CardImage src={biz?.logo} alt={biz?.bizFrom} />
											<CardText>{biz?.bizFrom}</CardText>
										</ProductCard>
									))}
								</>
							)}
						</CardsWrapper>

						<Box sx={buttonContainerStyles}>
							<Button component={Link} to="/logout" variant="contained">
								Logout
							</Button>
						</Box>
					</AllCardWrapper>
					{bizFromLoading && (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								width: "100%",
								marginTop: "2rem",
							}}
						>
							<CircularProgress size="2rem" />
						</Box>
					)}
				</div>
			</Section>
		</Wrapper>
	);
};

export default LandingPage;

import { useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { currencySymbolMap } from "../../utils/constants";
import {
	numberFormatter,
	discountCalculator,
} from "../../utils/helpers/functions";
import closeIcon from "../../assets/icons/close-icon.svg";
import logo from "../../assets/logos/smart-deals-logo.svg";

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	data,
	activateDeal,
}) {
	const { region } = useSelector((state) => state.auth);
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
						maxWidth: "60rem",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box sx={{ height: "5.25rem" }}>
							<img
								src={logo}
								height="100%"
								width="auto"
								alt="Cardinalstone logo"
							/>
						</Box>
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							margin: {
								md: "1rem 2rem",
								xs: "1rem",
							},
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: {
									md: "row",
									xs: "column",
								},
								mb: { md: "3rem", xs: "1rem" },
							}}
						>
							<Box
								sx={{
									width: { md: "50%", xs: "100%" },
									marginRight: {
										md: "3rem",
										xs: "1rem",
									},
								}}
							>
								<Carousel infiniteLoop showStatus={false}>
									{[data?.productImage].map((image, index) => (
										<Box
											key={index}
											sx={{ height: { md: "13rem", xs: "13rem" } }}
										>
											<img src={image} alt="" height="100%" width="auto" />
										</Box>
									))}
									{/* <Box>
									<img src={productImage} alt="" height="100%" width="100%" />
								</Box> */}
								</Carousel>
							</Box>
							<Box
								sx={{
									width: { md: "50%", xs: "100%" },
									color: "#594E4E",
								}}
							>
								{/* <Typography variant="body1" gutterBottom>
								Bags
							</Typography> */}
								<Typography
									variant="body1"
									sx={{ fontWeight: "bold" }}
									gutterBottom
								>
									{data?.productName}
								</Typography>
								<Box sx={{ mb: "1rem" }}>
									<Typography
										variant="body2"
										sx={{
											color: "#00C96C",
											fontStyle: "italic",
											fontWeight: "bold",
										}}
									>
										{`${
											numberFormatter(
												discountCalculator(data?.retailPrice, data?.dealPrice)
											) || 0
										}% off`}
									</Typography>
									<Box
										sx={{
											width: "1.5rem",
											height: "1px",
											color: "#000",
											borderStyle: "solid",
										}}
									/>
								</Box>

								<Box sx={{ display: "flex", mb: "1rem" }}>
									<Box
										sx={{
											padding: "0.5rem",
											"&:hover": { backgroundColor: "#eee" },
										}}
									>
										<Typography sx={{ fontSize: "0.8rem" }} variant="body2">
											Retail Price
										</Typography>
										<Typography
											variant="body1"
											sx={{
												fontSize: "0.9rem",
												textDecoration: "line-through",
											}}
										>
											{`${currencySymbolMap[region?.region]} ${numberFormatter(
												data?.retailPrice
											)}`}
										</Typography>
									</Box>
									<Box
										sx={{
											padding: "0.5rem",
											"&:hover": { backgroundColor: "#eee" },
										}}
									>
										<Typography sx={{ fontSize: "0.8rem" }} variant="body2">
											Deal Price
										</Typography>
										<Typography
											variant="body1"
											color="secondary"
											sx={{ fontSize: "0.9rem", fontWeight: 700 }}
										>
											{`${currencySymbolMap[region?.region]} ${numberFormatter(
												data?.dealPrice
											)}`}
										</Typography>
									</Box>
								</Box>

								<Box sx={{ mb: "2rem" }}>
									<Typography
										sx={{ fontSize: "0.8rem", color: "#5F5F5F" }}
										variant="body2"
									>
										{data?.productLongDesc}
									</Typography>
								</Box>

								<Box>
									<Button
										variant="contained"
										sx={{
											textTransform: "none",
											padding: "0.7rem 3rem",
											borderRadius: "0.5rem",
										}}
										onClick={() => {
											activateDeal();
										}}
										disabled={Boolean(data?.dealActive)}
									>
										Activate Deal
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

import { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import {
	Box,
	Tabs,
	Tab,
	Typography,
} from "../../../components/common/muiComponents";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/BvnReportTable";
import BvnLogTable from "../../../components/Admin/BvnLogTable";
import ConfirmModal from "../../../components/common/ConfirmModal";
import RejectModal from "../../../components/common/RejectModal";
import TabPanel from "../../../components/common/TabPanel";
import ViewBvnDetailsModal from "../../../components/common/ViewBvnDetailsModal";
import ViewBvnLogDetails from "../../../components/common/ViewBvnLogDetails";
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import SubmitButton from "../../../components/common/SubmitButton";
import {
	fetchBVNReport,
	fetchBVNInfo,
	processBVN,
	compareFaces,
	recheckBVN,
} from "../../../api/admin/dashboard";
import { REPORT_CATEGORIES } from "../../../utils/constants";
import { filterArrayBySearchTerm } from "../../../utils/helpers/functions";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const handleExcelExport = () => {};

const BVN_REPORT_APPROVAL = {
	APPROVAL: 1,
	REJECTION: 0,
};

const validationSchema = yup.object({
	username: yup
		.string("Please enter your BVN / username")
		.required("BVN / username is required"),
});

const BvnReport = () => {
	const [selectedItem, setSelectedItem] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [comment, setComment] = useState("");
	const [openApproveModal, setOpenApproveModal] = useState(false);
	const [openRejectModal, setOpenRejectModal] = useState(false);
	const [compareFacesLoading, setCompareFacesLoading] = useState(false);
	const [recheckBvnFacesLoading, setRecheckBvnFacesLoading] = useState(false);
	const [openViewLogDetailsModal, setOpenViewLogDetailsModal] = useState(false);
	const [value, setValue] = useState(0);

	const { region, user, bizFrom, role } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const formik = useFormik({
		initialValues: { username: "" },
		validationSchema,
		onSubmit: () => {
			formik.setSubmitting(false);
			handleSearchDBRecords();
		},
	});

	const handleChange = (event, newValue) => {
		setValue(newValue);
		formik.setFieldValue(`username`, "");
	};

	const {
		data: bvnReportData = [],
		isLoading: bvnReportLoading,
		isError: bvnReportError,
		error: bvnReportErrorMessage,
	} = useQuery(
		["get-bvn-report", region.region],
		async () =>
			fetchBVNReport({
				region: region.region,
				bizFrom,
				role,
				roleID: role,
				username: user?.username,
				category: REPORT_CATEGORIES.BVN,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	const {
		data: bvnLogData = [],
		isLoading: bvnLogLoading,
		isError: bvnLogError,
		error: bvnLogErrorMessage,
	} = useQuery(
		["get-bvn-report", region.region],
		async () =>
			fetchBVNReport({
				region: region.region,
				bizFrom,
				role,
				roleID: role,
				username: user?.username,
				category: REPORT_CATEGORIES.BVN,
			}),
		{
			select: (data) => data.bvnLog,
			staleTime: 4 * 60 * 1000,
		}
	);

	const {
		data: selectedBvnReportData = {},
		isLoading: selectedBvnReportLoading,
		isError: selectedBvnReportError,
		error: selectedBvnReportErrorMessage,
		refetch: refetchSelectedBvnReport,
	} = useQuery(
		[
			"get-single-bvn-report",
			region.region,
			selectedItem?.username,
			formik.values.username,
		],
		async () =>
			fetchBVNInfo({
				region: region.region,
				bizFrom,
				role,
				roleID: role,
				username: user?.username,
				recipient: selectedItem?.username || formik.values.username,
				category: REPORT_CATEGORIES.BVN,
			}),
		{
			select: (data) => data.data,
			staleTime: 0,
			cacheTime: 0,
			enabled: !!selectedItem?.username && value !== 4,
			onSuccess: () => {
				if (formik.values.username) {
					setViewDetailsModalOpen(true);
				}
			},
		}
	);

	const handleSearchDBRecords = () => {
		refetchSelectedBvnReport();
	};

	useEffect(() => {
		if (bvnReportError) {
			if (bvnReportErrorMessage.response) {
				toast.error(bvnReportErrorMessage.response.data?.message);
			} else if (bvnReportErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", bvnReportErrorMessage.message);
			}
		}
		if (selectedBvnReportError) {
			if (selectedBvnReportErrorMessage.response) {
				toast.error(selectedBvnReportErrorMessage.response.data?.message);
			} else if (selectedBvnReportErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", selectedBvnReportErrorMessage.message);
			}
		}
		if (bvnLogError) {
			if (bvnLogErrorMessage.response) {
				toast.error(bvnLogErrorMessage.response.data?.message);
			} else if (bvnLogErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", bvnLogErrorMessage.message);
			}
		}
	}, [
		bvnReportError,
		bvnReportErrorMessage,
		selectedBvnReportError,
		selectedBvnReportErrorMessage,
		bvnLogError,
		bvnLogErrorMessage,
	]);

	const pendingBvnData = useMemo(() => {
		return bvnReportData?.filter((item) => Number(item?.bvn_verify) === 2);
	}, [bvnReportData]);

	const activeBvnData = useMemo(() => {
		return bvnReportData?.filter((item) => Number(item?.bvn_verify) === 1);
	}, [bvnReportData]);

	const rejectedBvnData = useMemo(() => {
		return bvnReportData?.filter((item) => Number(item?.bvn_verify) === 3);
	}, [bvnReportData]);

	const displayed = useMemo(() => {
		if (bvnReportData.length) {
			let filteredData = [...bvnReportData];
			if (searchTerm) {
				filteredData = filteredData.filter((item) => {
					return (
						item.bvn?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.bvn_firstname
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.username?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.bvn_middlename
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.bvn_lastname?.toLowerCase()?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredData;
		}
		return bvnReportData;
	}, [bvnReportData, searchTerm]);

	const displayedPending = useMemo(() => {
		if (pendingBvnData.length) {
			let filteredData = [...pendingBvnData];
			if (searchTerm) {
				filteredData = filteredData.filter((item) => {
					return (
						item.bvn?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.bvn_firstname
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.username?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.bvn_middlename
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.bvn_lastname?.toLowerCase()?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredData;
		}
		return pendingBvnData;
	}, [pendingBvnData, searchTerm]);

	const displayedApproved = useMemo(() => {
		if (activeBvnData.length) {
			let filteredData = [...activeBvnData];
			if (searchTerm) {
				filteredData = filteredData.filter((item) => {
					return (
						item.bvn?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.bvn_firstname
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.username?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
						item.bvn_middlename
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						item.bvn_lastname?.toLowerCase()?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredData;
		}
		return activeBvnData;
	}, [activeBvnData, searchTerm]);

	const displayedCardCreationData = useMemo(() => {
		if (bvnLogData.length) {
			let filteredData = [...bvnLogData];
			if (searchTerm) {
				filteredData = filterArrayBySearchTerm(filteredData, searchTerm);
			}
			return filteredData;
		}
		return bvnLogData;
	}, [bvnLogData, searchTerm]);

	const { mutate: approveBvnMutation, isLoading: approveLoading } = useMutation(
		"approve-bvn",
		processBVN,
		{
			onSuccess: (dataSuccess) => {
				setOpenApproveModal(false);
				setViewDetailsModalOpen(false);
				toast.success(dataSuccess?.message);
				setComment("");
				setSelectedItem({});
				formik.setFieldValue(`username`, "");
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-bvn-report");
			},
		}
	);

	const { mutate: rejectBvnMutation, isLoading: rejectLoading } = useMutation(
		"reject-bvn",
		processBVN,
		{
			onSuccess: (dataSuccess) => {
				setOpenRejectModal(false);
				setViewDetailsModalOpen(false);
				toast.success(dataSuccess?.message);
				setComment("");
				setSelectedItem({});
				formik.setFieldValue(`username`, "");
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-bvn-report");
			},
		}
	);

	const handleApproval = () => {
		const payload = {
			username: user?.username,
			recipient: selectedBvnReportData?.username,
			approval: BVN_REPORT_APPROVAL.APPROVAL,
			roleID: role,
			role,
			region: region?.region,
			bizFrom,
			category: REPORT_CATEGORIES.BVN,
		};

		approveBvnMutation(payload);
	};

	const handleRejection = () => {
		const payload = {
			username: user?.username,
			recipient: selectedBvnReportData?.username,
			approval: BVN_REPORT_APPROVAL.REJECTION,
			roleID: role,
			role,
			region: region?.region,
			bizFrom,
			comment,
			category: REPORT_CATEGORIES.BVN,
		};

		rejectBvnMutation(payload);
	};

	const handleCompareFaces = async () => {
		try {
			setCompareFacesLoading(true);
			const payload = {
				url1: selectedBvnReportData?.bvn_image,
				url2: selectedBvnReportData?.bvn_selfie,
				bvn_firstname: selectedBvnReportData?.bvn_firstname,
				bvn_lastname: selectedBvnReportData?.bvn_lastname,
				bvn_middlename: selectedBvnReportData?.bvn_middlename,
				username: user?.username,
				recipient: selectedBvnReportData?.username,
				category: REPORT_CATEGORIES.BVN,
			};
			const result = await compareFaces(payload);
			toast.success(result?.message);
			refetchSelectedBvnReport();
			setCompareFacesLoading(false);
		} catch (err) {
			setCompareFacesLoading(false);
			if (err.response) {
				toast.error(err?.response?.data?.message);
			} else if (err.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", err.message);
			}
		}
	};

	const handleRecheckBVN = async () => {
		try {
			setRecheckBvnFacesLoading(true);
			const payload = {
				recipient: selectedBvnReportData?.username,
				username: user?.username,
				bvn: selectedBvnReportData?.bvn,
				bvn_selfie: selectedBvnReportData?.bvn_selfie,
				roleID: user?.role,
				category: REPORT_CATEGORIES.BVN,
			};
			const result = await recheckBVN(payload);
			toast.success(result?.message);
			refetchSelectedBvnReport();
			setRecheckBvnFacesLoading(false);
		} catch (err) {
			setRecheckBvnFacesLoading(false);
			if (err.response) {
				toast.error(err?.response?.data?.message);
			} else if (err.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", err.message);
			}
		}
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="BVN Report"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search report"
				/>
			</Box>

			<Box sx={{ mb: "2rem" }}>
				<Tabs value={value} onChange={handleChange} aria-label="referral tabs">
					<Tab label="All" {...a11yProps(0)} />
					<Tab label="Approved" {...a11yProps(1)} />
					<Tab label="Pending" {...a11yProps(2)} />
					<Tab label="Rejected" {...a11yProps(3)} />
					<Tab label="Log" {...a11yProps(4)} />
					<Tab label="Search" {...a11yProps(5)} />
				</Tabs>
			</Box>

			<TabPanel value={value} index={0}>
				<Box
					sx={{
						width: "100%",
						overflow: "auto",
					}}
				>
					<Table
						data={displayed}
						loading={bvnReportLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setViewDetailsModalOpen(true)}
					/>
				</Box>
			</TabPanel>

			<TabPanel value={value} index={1}>
				<Box
					sx={{
						width: "100%",
						overflow: "auto",
					}}
				>
					<Table
						data={displayedApproved}
						loading={bvnReportLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setViewDetailsModalOpen(true)}
					/>
				</Box>
			</TabPanel>

			<TabPanel value={value} index={2}>
				<Box
					sx={{
						width: "100%",
						overflow: "auto",
					}}
				>
					<Table
						data={displayedPending}
						loading={bvnReportLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setViewDetailsModalOpen(true)}
					/>
				</Box>
			</TabPanel>

			<TabPanel value={value} index={3}>
				<Box
					sx={{
						width: "100%",
						overflow: "auto",
					}}
				>
					<Table
						data={rejectedBvnData}
						loading={bvnReportLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setViewDetailsModalOpen(true)}
						displayComment
					/>
				</Box>
			</TabPanel>

			<TabPanel value={value} index={4}>
				<Box
					sx={{
						width: "100%",
						overflow: "auto",
					}}
				>
					<BvnLogTable
						data={displayedCardCreationData}
						loading={bvnLogLoading}
						setSelectedItem={setSelectedItem}
						handleView={() => setOpenViewLogDetailsModal(true)}
						displayComment
					/>
				</Box>
			</TabPanel>

			<TabPanel value={value} index={5}>
				<Box
					sx={{
						width: "33rem",
						maxWidth: "100%",
						boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
						borderRadius: "1rem",
						p: "1rem",
					}}
				>
					<Box
						sx={{
							m: "1rem 0",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Typography variant="h6" textAlign="center">
							Please enter BVN or Username
						</Typography>
					</Box>

					<Box
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={formik.handleSubmit}
					>
						<Box sx={{ mb: "1rem" }}>
							<TextFieldComponent
								value={formik.values.username}
								onChange={formik.handleChange}
								label="BVN /Username"
								id="username"
								name="username"
								required
								error={
									formik.touched.username && Boolean(formik.errors.username)
								}
								helperText={formik.touched.username && formik.errors.username}
							/>
						</Box>

						<SubmitButton
							text="Search"
							loading={selectedBvnReportLoading}
							disabled={!formik.dirty || selectedBvnReportLoading}
						/>
					</Box>
				</Box>
			</TabPanel>

			<ConfirmModal
				open={openApproveModal}
				handleClose={() => {
					setOpenApproveModal(false);
				}}
				title="Approve BVN"
				subtitle=""
				message="Are you sure?"
				handleSubmit={handleApproval}
				loading={approveLoading}
			/>

			<RejectModal
				open={openRejectModal}
				handleClose={() => {
					setOpenRejectModal(false);
					setComment("");
				}}
				title="Reject BVN"
				subtitle=""
				handleReject={handleRejection}
				loading={rejectLoading}
				comment={comment}
				setComment={setComment}
			/>

			<ViewBvnDetailsModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
					setSelectedItem({});
					formik.setFieldValue(`username`, "");
				}}
				title=""
				data={selectedBvnReportData}
				loading={selectedBvnReportLoading}
				handleApprove={() => setOpenApproveModal(true)}
				handleReject={() => setOpenRejectModal(true)}
				handleCompareFaces={handleCompareFaces}
				compareFacesLoading={compareFacesLoading}
				handleRecheckBVN={handleRecheckBVN}
				recheckBvnFacesLoading={recheckBvnFacesLoading}
			/>

			<ViewBvnLogDetails
				open={openViewLogDetailsModal}
				handleClose={() => {
					setOpenViewLogDetailsModal(false);
					setSelectedItem({});
				}}
				title="View BVN Log Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default BvnReport;

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import CircularProgress from "@mui/material/CircularProgress";
import SelectFieldComponent from "../common/SelectFieldComponent";
import closeIcon from "../../assets/icons/close-icon.svg";

const deliveryStatuses = ["processing", "shipped", "delivered"];

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	data,
	setData,
	loading,
	handleFormSubmit,
}) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography
							variant="h6"
							component="div"
							sx={{
								fontWeight: 700,
								textTransform: "capitalize",
							}}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						textAlign: "center",
						overflowX: "hidden",
					}}
				>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { width: "25ch" },
						}}
						noValidate
						autoComplete="off"
						onSubmit={handleFormSubmit}
					>
						<Box sx={{ mt: "1rem", mb: "1rem", width: "25ch" }}>
							<SelectFieldComponent
								width="100%"
								value={data.deliveryStatus}
								values={deliveryStatuses}
								onChange={(e) => {
									setData({
										...data,
										deliveryStatus: e.target.value,
									});
								}}
								label="Choose Delivery Status"
								id="deliveryStatus"
								name="deliveryStatus"
								required
							/>
						</Box>

						<Stack
							direction="row"
							spacing={2}
							sx={{
								marginTop: "1rem",
								marginBottom: "1rem",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								sx={{
									paddingLeft: "2rem",
									paddingRight: "2rem",
									textTransform: "none",
								}}
								variant="contained"
								type="submit"
							>
								{loading ? (
									<CircularProgress size="1.5rem" sx={{ color: "#fff" }} />
								) : (
									"Update"
								)}
							</Button>
						</Stack>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

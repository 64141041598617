import { styled } from "@mui/material/styles";

import SummaryCard from "../common/WalletBalanceSummaryCard";

const SummaryContainer = styled("div")(({ theme }) => ({
	display: "flex",
	marginBottom: "2rem",
	flexWrap: "nowrap",
	overflowX: "auto",
	[theme.breakpoints.down("lg")]: {
		marginLeft: 0,
	},
	scrollbarWidth: "none",
	msOverflowStyle: "none",
	"&::-webkit-scrollbar": {
		display: "none",
	},
}));

const SummaryCards = ({ summary, loading }) => {
	return (
		<SummaryContainer>
			<SummaryCard
				value={summary?.balance}
				label="wallet"
				backgroundColor="#D3EFFF"
				loading={loading}
			/>
			<SummaryCard
				value={summary?.paystack_balance}
				label="paystack"
				backgroundColor="#E5FAF0"
				loading={loading}
			/>
			<SummaryCard
				value={summary?.providus_balance}
				label="providus"
				backgroundColor="#F7C5FF"
				loading={loading}
			/>
			{/* <SummaryCard
				value={summary?.interswitch_balance}
				label="Interswitch"
				backgroundColor="#FFD9C3"
				loading={loading}
			/> */}
			<SummaryCard
				value={summary?.webpay_balance}
				label="Webpay"
				backgroundColor="#F6E0B8"
				loading={loading}
			/>
			<SummaryCard
				value={summary?.thepeer_balance}
				label="ThePeer"
				backgroundColor="#C5FFE3"
				loading={loading}
			/>
			<SummaryCard
				value={summary?.baxi_balance}
				label="Baxi"
				backgroundColor="#FFD9E6"
				loading={loading}
			/>
		</SummaryContainer>
	);
};

export default SummaryCards;

import { forwardRef } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const DateComponent = forwardRef(
	(
		{
			value,
			onChange,
			width,
			label,
			required,
			disablePast = false,
			disableFuture = false,
			helperText = "",
			error = false,
			views = ["day", "month", "year"],
			...props
		},
		ref
	) => {
		return (
			<Box
				sx={{
					width: {
						xs: "100%",
						md: width,
					},
				}}
			>
				<DatePicker
					label={label}
					value={value}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							required={required}
							helperText={helperText}
							error={error}
						/>
					)}
					onChange={onChange}
					disablePast={disablePast}
					disableFuture={disableFuture}
					views={views}
					ref={ref}
					{...props}
				/>
			</Box>
		);
	}
);

export default DateComponent;

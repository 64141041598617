import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/CheckoutTable";
import ViewCheckoutDetailsModal from "../../../components/common/ViewCheckoutDetailsModal";
import UpdateCheckoutModal from "../../../components/Admin/UpdateCheckoutModal";
import SelectFieldComponent from "../../../components/common/SelectFieldComponent";
import {
	getAdminCheckout,
	adminUpdateCheckout,
} from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const defaultFields = {
	deliveryStatus: "",
};

const filterContainerStyle = {
	display: "flex",
	justifyContent: { md: "flex-end", xs: "flex-start" },
	mb: "2rem",
};

const selectFieldWidth = "13rem";

const filterStatuses = ["all", "processing", "shipped", "delivered"];

const Checkout = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [openUodateModal, setOpenUpdateModal] = useState(false);
	const [updateCheckoutFields, setUpdateCheckoutFields] =
		useState(defaultFields);
	const [filterStatus, setFilterStatus] = useState("all");

	const { user, region } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: adminCheckoutData = [],
		isLoading: adminCheckoutLoading,
		isError: adminCheckoutError,
		error: adminCheckoutErrorMessage,
	} = useQuery(
		["get-admin-checkout", region?.region],
		async () => getAdminCheckout(region?.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (adminCheckoutError) {
			if (adminCheckoutErrorMessage.response) {
				toast.error(adminCheckoutErrorMessage.response.data?.message);
			} else if (adminCheckoutErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", adminCheckoutErrorMessage.message);
			}
		}
	}, [adminCheckoutError, adminCheckoutErrorMessage]);

	useEffect(() => {
		if (adminCheckoutData.length && !adminCheckoutLoading) {
			setDisplayed([...adminCheckoutData]);
			setAllData([...adminCheckoutData]);
		}
	}, [adminCheckoutData, adminCheckoutLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.dealPrice?.toLowerCase().includes(searchTerm.toLowerCase())
					);
				});
			}
			if (filterStatus !== "all") {
				filtered = filtered.filter(
					(item) => item.deliveryStatus === filterStatus
				);
			}
			setDisplayed(filtered);
		}
	}, [searchTerm, filterStatus, allData]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	const { mutate: updateCheckout, isLoading: updateCheckoutLoading } =
		useMutation("update-checkout", adminUpdateCheckout, {
			onSuccess: (dataSuccess) => {
				setOpenUpdateModal(false);
				toast.success(dataSuccess?.message);
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-admin-checkout");
			},
		});

	const handleUpdateCheckout = async (e) => {
		e.preventDefault();
		if (!updateCheckoutFields.deliveryStatus) {
			toast.error("Please choose delivery status");
			return;
		}
		const payload = {
			ID: selectedItem.id,
			dealID: selectedItem.dealID,
			username: user.username,
			deliveryStatus: updateCheckoutFields.deliveryStatus,
			admin: user.fullName,
			region: region?.region,
		};
		await updateCheckout(payload);
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Checkout"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Customers"
				/>
			</Box>

			<Box sx={filterContainerStyle}>
				<SelectFieldComponent
					width={selectFieldWidth}
					values={filterStatuses}
					value={filterStatus}
					onChange={(e) => setFilterStatus(e.target.value)}
					label="Filter by delivery status"
					id="filterStatus"
					name="filterStatus"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<Table
					data={displayed}
					loading={adminCheckoutLoading}
					setSelectedItem={setSelectedItem}
					handleView={() => setViewDetailsModalOpen(true)}
					handleCheckoutUpdate={() => setOpenUpdateModal(true)}
				/>
			</Box>
			<ViewCheckoutDetailsModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Checkout Details"
				data={selectedItem}
			/>
			<UpdateCheckoutModal
				open={openUodateModal}
				handleClose={() => setOpenUpdateModal(false)}
				data={updateCheckoutFields}
				setData={setUpdateCheckoutFields}
				title="Update Checkout"
				loading={updateCheckoutLoading}
				handleFormSubmit={handleUpdateCheckout}
			/>
		</Box>
	);
};

export default Checkout;

import { useState, useEffect, useMemo, useLayoutEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
	Box,
	Button,
	Typography,
	styled,
} from "../../../components/common/muiComponents";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Collab/SubscriptionReportTable";
import DateComponent from "../../../components/common/DateComponent";
import ChooseRoleModal from "../../../components/Admin/ChooseCollabeRoleModal";
import { fetchCollabSubscriptions } from "../../../api/admin/dashboard";
import {
	downloadExcel,
	numberFormatter,
	newDateFormatter,
} from "../../../utils/helpers/functions";
import { ROLES } from "../../../utils/constants";

const SummaryFilterContainer = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: ".5rem",
	alignItems: "center",
});

const DateFilterContainer = styled(Box)({
	display: "flex",
	alignItems: "center",
	gap: "1rem",
});

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const CollabSubscriptionReport = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedRole, setSelectedRole] = useState("");
	const [openChooseRoleModal, setOpenChooseRoleModal] = useState(false);
	const [open, setOpen] = useState(false);

	const { region, user } = useSelector((state) => state.auth);

	useLayoutEffect(() => {
		if (user?.accountType?.toLowerCase() === "collab") {
			setSelectedRole(user?.role);
		}
	}, []);

	const {
		data: subscriptionReportData = [],
		isLoading: subscriptionReportLoading,
		isError: subscriptionReportError,
		error: subscriptionReportErrorMessage,
	} = useQuery(
		[
			`fetch-collab-subscriptions-${selectedRole}-${
				selectedDate ? newDateFormatter(selectedDate, "MMM yyyy") : ""
			}`,
			region?.region,
			selectedDate,
			selectedRole,
		],
		async () =>
			fetchCollabSubscriptions({
				region: region?.region,
				roleID: selectedRole,
				username: user?.username,
				period: selectedDate ? newDateFormatter(selectedDate, "MMM yyyy") : "",
			}),
		{
			select: (data) => data?.data,
			staleTime: 4 * 60 * 1000,
			enabled: !!region?.region && !!selectedRole,
		}
	);

	useEffect(() => {
		if (subscriptionReportError) {
			if (subscriptionReportErrorMessage.response) {
				toast.error(subscriptionReportErrorMessage.response.data?.message);
			} else if (subscriptionReportErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", subscriptionReportErrorMessage.message);
			}
		}
	}, [subscriptionReportError, subscriptionReportErrorMessage]);

	const displayed = useMemo(() => {
		if (subscriptionReportData.length) {
			let filtered = [...subscriptionReportData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item?.username
							?.toLowerCase()
							?.includes(searchTerm?.toLowerCase()) ||
						item?.subscriptionClass
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						item?.referrer
							?.toLowerCase()
							?.includes(searchTerm?.toLowerCase()) ||
						`${item?.amount}`
							?.toLowerCase()
							?.includes(searchTerm?.toLowerCase()) ||
						item?.period?.toLowerCase()?.includes(searchTerm?.toLowerCase())
					);
				});
			}
			return filtered;
		}
		return [];
	}, [subscriptionReportData, searchTerm]);

	const totalAmount = useMemo(() => {
		return displayed.reduce((acc, item) => acc + Number(item.amount), 0);
	}, [displayed]);

	const handlePickerClick = () => {
		setOpen(true);
	};

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text={`${selectedRole?.toUpperCase()} Earnings Report`}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search report"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<SummaryFilterContainer>
					<Typography variant="h6">{`Total Amount: ${numberFormatter(
						totalAmount
					)}`}</Typography>

					<DateFilterContainer>
						<Box
							sx={{ width: "12rem", cursor: "pointer" }}
							onClick={handlePickerClick}
						>
							<Box
								onClick={(e) => {
									if (open) {
										e.stopPropagation();
									}
								}}
							>
								<DateComponent
									label="Filter by date"
									value={selectedDate}
									onChange={(newValue) => setSelectedDate(newValue)}
									views={["year", "month"]}
									inputProps={{
										readOnly: true,
									}}
									open={open}
									onClose={() => setOpen(false)}
									onAccept={() => setOpen(false)}
								/>
							</Box>
						</Box>
						<Box>
							<Button
								variant="contained"
								sx={{ textTransform: "capitalize" }}
								onClick={() => setSelectedDate(null)}
							>
								show all
							</Button>
						</Box>
						{(user?.role === ROLES.EXECUTIVE ||
							user?.role === ROLES.FINCON) && (
							<Box>
								<Button
									variant="contained"
									sx={{ textTransform: "capitalize" }}
									onClick={() => setOpenChooseRoleModal(true)}
								>
									build report
								</Button>
							</Box>
						)}
					</DateFilterContainer>
				</SummaryFilterContainer>
				<Table data={displayed} loading={subscriptionReportLoading} />
			</Box>

			<ChooseRoleModal
				open={openChooseRoleModal}
				handleClose={() => setOpenChooseRoleModal(false)}
				selectedRole={selectedRole}
				setSelectedRole={setSelectedRole}
				title="Choose Collab Role"
			/>
		</Box>
	);
};

export default CollabSubscriptionReport;

import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import debounce from "lodash.debounce";
import styled from "styled-components";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import TextFieldComponent from "../common/TextFieldComponent";
import NumberTextField from "../common/NumberTextField";
import Loader from "../common/Loader";
import { fetchUserProfile, getAccountType } from "../../api/admin/dashboard";

const UserFieldSet = ({
	index,
	removeFieldSet,
	formik,
	keyword,
	setKeyword,
}) => {
	const [usersDetails, setUserDetails] = useState([]);
	const [usersDetailsLoading, setUserDetailsLoading] = useState(false);

	const { region, bizFrom, role } = useSelector((state) => state.auth);

	const {
		data: accountTypeData = [],
		// isLoading: accountTypeLoading,
		isError: accountTypeError,
		error: accountTypeErrorMessage,
	} = useQuery(["get-account-type"], async () => getAccountType(), {
		select: (data) => data?.data,
		staleTime: Infinity,
	});

	useEffect(() => {
		if (accountTypeError) {
			if (accountTypeErrorMessage.response) {
				toast.error(accountTypeErrorMessage.response.data?.message);
			} else if (accountTypeErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", accountTypeErrorMessage.message);
			}
		}
	}, [accountTypeError, accountTypeErrorMessage]);

	const updateFullNameField = useCallback(
		debounce(async (enteredKeyword) => {
			if (enteredKeyword) {
				try {
					setUserDetailsLoading(true);
					const { data } = await fetchUserProfile({
						keyword: enteredKeyword,
						region: region?.region,
						bizFrom,
						role,
					});
					setUserDetails(data);
					setUserDetailsLoading(false);
				} catch (error) {
					setUserDetailsLoading(false);
					if (error.response) {
						toast.error(error.response.data?.message);
					} else if (error.request) {
						toast.error("Network Error");
					} else {
						toast.error("Error", error.message);
					}
				}
			}
		}, 1000),
		[index]
	);

	console.log({ usersDetails, accountTypeData });

	return (
		<div key={index}>
			<HeaderContainer>
				<Typography variant="body1">{`Recipient ${index + 1}`}</Typography>
			</HeaderContainer>
			<TextFieldWrapper>
				<TextFieldComponent
					value={keyword}
					onChange={(e) => {
						setKeyword(e.target.value);
						updateFullNameField(e.target.value);
					}}
					label="Enter keyword to fetch user details"
					id="keyword"
					required
				/>
			</TextFieldWrapper>

			{usersDetailsLoading && (
				<LoaderWrapper>
					<Loader />
				</LoaderWrapper>
			)}
			{!usersDetailsLoading && usersDetails?.length > 0 && (
				<>
					<TextFieldWrapper>
						<FormControl
							fullWidth
							required
							error={Boolean(formik.errors?.usernames?.[index]?.username)}
						>
							<InputLabel id="username-label">Username</InputLabel>
							<Select
								labelId="username-label"
								id="username"
								name="username"
								value={formik.values.usernames[index].username}
								label="Username"
								onChange={(e) => {
									const { value } = e.target;
									const selectedUser = usersDetails?.find(
										(item) => item?.username === value
									);
									const fullName = selectedUser?.fullName;
									const accountType = selectedUser?.accountType;
									formik.setFieldValue(
										`usernames[${index}].username`,
										value || ""
									);
									formik.setFieldValue(
										`usernames[${index}].fullname`,
										fullName || ""
									);
									formik.setFieldValue(
										`usernames[${index}].accountType`,
										accountType || ""
									);
								}}
								sx={{ textTransform: "none" }}
							>
								{usersDetails?.map((item) => (
									<MenuItem
										value={item?.username}
										key={item.username}
										sx={{ textTransform: "capitalize" }}
									>
										{`${item.username} - ${item.fullName}`}
									</MenuItem>
								))}
							</Select>
							{formik.touched?.usernames?.[index]?.username &&
								formik.errors?.usernames?.[index]?.username && (
									<FormHelperText
										error={Boolean(formik.errors?.usernames?.[index]?.username)}
									>
										{formik.touched?.usernames?.[index]?.username &&
											formik.errors?.usernames?.[index]?.username}
									</FormHelperText>
								)}
						</FormControl>
					</TextFieldWrapper>
					<TextFieldWrapper>
						<NumberTextField
							value={formik.values.usernames[index].amount}
							onChange={formik.handleChange}
							label="Amount"
							id="amount"
							name={`usernames[${index}].amount`}
							required
							error={
								formik.touched?.usernames?.[index]?.amount &&
								Boolean(formik.errors?.usernames?.[index]?.amount)
							}
							helperText={
								formik.touched?.usernames?.[index]?.amount &&
								formik.errors?.usernames?.[index]?.amount
							}
						/>
					</TextFieldWrapper>
					<TextFieldWrapper>
						<TextField
							value={formik.values.usernames[index].comment}
							onChange={formik.handleChange}
							name={`usernames[${index}].comment`}
							label="Comment"
							fullWidth
							multiline
							rows={4}
							error={
								formik.touched?.usernames?.[index]?.comment &&
								Boolean(formik.errors?.usernames?.[index]?.comment)
							}
							helperText={
								formik.touched?.usernames?.[index]?.comment &&
								formik.errors?.usernames?.[index]?.comment
							}
						/>
					</TextFieldWrapper>
				</>
			)}
			{formik.values.usernames.length > 1 && (
				<RemoveButtonContainer>
					<IconButton onClick={() => removeFieldSet(index)} color="error">
						<DeleteForeverIcon />
					</IconButton>
				</RemoveButtonContainer>
			)}
		</div>
	);
};

export default UserFieldSet;

const TextFieldWrapper = styled.div`
	margin-bottom: 1rem;
`;

const RemoveButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const LoaderWrapper = styled.div`
	margin-bottom: 1rem;
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;
`;

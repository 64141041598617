import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Box, Button, styled } from "../../../components/common/muiComponents";
import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/BaxiServiceStatusTable";
import DateComponent from "../../../components/common/DateComponent";
import { baxiServiceStatusLog } from "../../../api/admin/dashboard";
import {
	downloadExcel,
	newDateFormatter,
} from "../../../utils/helpers/functions";

const SummaryFilterContainer = styled(Box)({
	display: "flex",
	justifyContent: "flex-end",
	marginBottom: ".5rem",
	gap: "1rem",
	alignItems: "center",
});

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const BaxiServiceStatus = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedDate, setSelectedDate] = useState(null);
	const [open, setOpen] = useState(false);

	const { region, user } = useSelector((state) => state.auth);

	const {
		data: summaryData = [],
		isLoading: summaryLoading,
		isError: summaryError,
		error: summaryErrorMessage,
	} = useQuery(
		[
			`get-baxi-service-status-${
				selectedDate ? newDateFormatter(selectedDate, "MMM yyyy") : ""
			}`,
			region.region,
			selectedDate,
		],
		async () =>
			baxiServiceStatusLog({
				region: region.region,
				bizFrom: user?.bizFrom,
				accountType: user?.accountType,
				period: selectedDate ? newDateFormatter(selectedDate, "MMM yyyy") : "",
				username: user?.username,
			}),
		{
			select: (data) => data?.baxi_service,
		}
	);

	useEffect(() => {
		if (summaryError) {
			if (summaryErrorMessage.response) {
				toast.error(summaryErrorMessage.response.data?.message);
			} else if (summaryErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", summaryErrorMessage.message);
			}
		}
	}, [summaryError, summaryErrorMessage]);

	const displayed = useMemo(() => {
		if (searchTerm) {
			return summaryData.filter((item) => {
				return (
					item.event?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item.service?.toLowerCase().includes(searchTerm.toLowerCase())
				);
			});
		}
		return summaryData;
	}, [summaryData, searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};

	const handlePickerClick = () => {
		setOpen(true);
	};

	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Failing Baxi Service"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search report"
				/>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<SummaryFilterContainer>
					<Box
						sx={{ width: "12rem", cursor: "pointer" }}
						onClick={handlePickerClick}
					>
						<Box
							onClick={(e) => {
								if (open) {
									e.stopPropagation();
								}
							}}
						>
							<DateComponent
								label="Filter by date"
								value={selectedDate}
								onChange={(newValue) => {
									setSelectedDate(newValue);
								}}
								views={["year", "month"]}
								inputProps={{
									readOnly: true,
								}}
								open={open}
								onClose={() => setOpen(false)}
								onAccept={() => setOpen(false)}
							/>
						</Box>
					</Box>

					<Box>
						<Button
							variant="contained"
							sx={{ textTransform: "capitalize" }}
							onClick={() => setSelectedDate(null)}
						>
							show all
						</Button>
					</Box>
				</SummaryFilterContainer>
				<Table data={displayed} loading={summaryLoading} />
			</Box>
		</Box>
	);
};

export default BaxiServiceStatus;

import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/PendingProvidusNotificationsTable";
import ViewDetailsModal from "../../../components/common/ViewPendingProvidusNotifications";
import DateComponent from "../../../components/common/DateComponent";
import { downloadExcel } from "../../../utils/helpers/functions";
import { fetchPendingProvidusNotifications } from "../../../api/admin/dashboard";

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const filterContainerStyle = {
	display: "flex",
	justifyContent: "flex-end",
	mb: "1rem",
};

const dateContainerStyle = {
	display: "flex",
	gap: "1rem",
};

const ProvidusLog = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [displayed, setDisplayed] = useState([]);
	const [allData, setAllData] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const { user, region } = useSelector((state) => state.auth);

	const {
		data: pendingProvidusNotificationsData = [],
		isLoading: pendingProvidusNotificationsLoading,
		isError: pendingProvidusNotificationsError,
		error: pendingProvidusNotificationsErrorMessage,
	} = useQuery(
		["get-pending-providus-notifications", region.region],
		async () =>
			fetchPendingProvidusNotifications({
				region: region.region,
				sdate: startDate.toISOString(),
				edate: endDate.toISOString(),
				username: user?.username,
			}),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
		}
	);

	useEffect(() => {
		if (pendingProvidusNotificationsError) {
			if (pendingProvidusNotificationsErrorMessage.response) {
				toast.error(
					pendingProvidusNotificationsErrorMessage.response.data?.message
				);
			} else if (pendingProvidusNotificationsErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", pendingProvidusNotificationsErrorMessage.message);
			}
		}
	}, [
		pendingProvidusNotificationsError,
		pendingProvidusNotificationsErrorMessage,
	]);

	useEffect(() => {
		if (
			pendingProvidusNotificationsData.length &&
			!pendingProvidusNotificationsLoading
		) {
			setDisplayed(pendingProvidusNotificationsData);
			setAllData(pendingProvidusNotificationsData);
		}
	}, [pendingProvidusNotificationsData, pendingProvidusNotificationsLoading]);

	useEffect(() => {
		if (allData.length) {
			let filtered = [...allData];
			if (searchTerm) {
				filtered = filtered.filter((item) => {
					return (
						item.sessionID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.settlementID
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						`${item.transactionAmount}`
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						item.virtualAccountNumber
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						item.postedToSettlementAccount
							?.toLowerCase()
							.includes(searchTerm.toLowerCase())
					);
				});
			}
			setDisplayed(filtered);
		}
	}, [searchTerm]);

	// const handleDateFilterChange = (e

	const handleExcelExport = () => {
		downloadExcel(displayed, "Partners", "partners");
	};
	return (
		<Box sx={style}>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Pending Providus Notifications"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Notifications"
				/>
			</Box>

			<Box sx={filterContainerStyle}>
				<Box sx={dateContainerStyle}>
					<DateComponent
						value={startDate}
						onChange={(newValue) => setStartDate(newValue)}
						label="Start Date"
						id="StartDate"
						name="StartDate"
					/>
					<DateComponent
						value={endDate}
						onChange={(newValue) => setEndDate(newValue)}
						label="End Date"
						id="endDate"
						name="endDate"
					/>
				</Box>
			</Box>
			<Table
				data={displayed}
				loading={pendingProvidusNotificationsLoading}
				setSelectedItem={setSelectedItem}
				handleView={() => setViewDetailsModalOpen(true)}
			/>

			<ViewDetailsModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Pending Providus Notification Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default ProvidusLog;

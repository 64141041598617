import { ResponsiveBar } from "@nivo/bar";
import { numberFormatter } from "../../utils/helpers/functions";

const MyResponsiveBar = ({ data }) => (
	<ResponsiveBar
		data={data}
		valueFormat={(v) => numberFormatter(v)}
		keys={["total"]}
		indexBy="period"
		margin={{ top: 20, right: 30, bottom: 110, left: 50 }}
		padding={0.3}
		valueScale={{ type: "linear" }}
		indexScale={{ type: "band", round: true }}
		colors={{ scheme: "nivo" }}
		defs={[
			{
				id: "dots",
				type: "patternDots",
				background: "inherit",
				color: "#38bcb2",
				size: 4,
				padding: 1,
				stagger: true,
			},
			{
				id: "lines",
				type: "patternLines",
				background: "inherit",
				color: "#eed312",
				rotation: -45,
				lineWidth: 6,
				spacing: 10,
			},
		]}
		borderColor={{
			from: "color",
			modifiers: [["darker", 1.6]],
		}}
		axisTop={null}
		axisRight={null}
		axisBottom={{
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 90,
			legend: "",
			legendPosition: "middle",
			legendOffset: 32,
		}}
		axisLeft={{
			tickSize: 5,
			tickPadding: 5,
			tickRotation: 0,
			legend: "",
			legendPosition: "middle",
			legendOffset: -40,
		}}
		enableLabel={false}
		labelSkipWidth={12}
		labelSkipHeight={12}
		labelTextColor={{
			from: "color",
			modifiers: [["darker", 1.6]],
		}}
		legends={[]}
		role="application"
		ariaLabel="bar chart"
	/>
);

export default MyResponsiveBar;

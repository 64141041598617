import { useState } from "react";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import Navbar from "../Home/NavBar";
import Drawer from "./SupportChatDrawer";

const Layout = () => {
	const [open, setOpen] = useState(true);

	// const theme = useTheme();
	// const showDrawer = useMediaQuery(theme.breakpoints.up("md"));

	return (
		<div>
			<Navbar openDrawer={open} toggleDrawer={setOpen} />
			{open && <Drawer open={open} setOpen={setOpen} />}
		</div>
	);
};

export default Layout;

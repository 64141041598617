import { useState, useEffect } from "react";

import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TablePagination,
	CircularProgress,
} from "../common/muiComponents";
import { formatNumberToDecimalPlace } from "../../utils/helpers/functions";

export default function StickyHeadTable({ loading, data, symbol }) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const firstPageElement = page * rowsPerPage + 1;

	useEffect(() => {
		if (firstPageElement > data?.length) {
			setPage(0);
		}
	}, [data, firstPageElement]);

	useEffect(() => {
		setPage(0);
	}, [symbol]);

	const columns = [
		{ id: "S/N", label: "S/N", minWidth: 10 },
		{ id: "OrderCount", label: "Order Count", minWidth: 130 },
		{ id: "Price", label: "Price", minWidth: 130 },
		{ id: "Volume", label: "Volume", minWidth: 130 },
	];

	return (
		<Paper
			sx={{
				width: "100%",
				overflow: "hidden",
			}}
		>
			<TableContainer sx={{ maxHeight: 260, minHeight: 260 }}>
				<Table
					stickyHeader
					aria-label="sticky table"
					sx={{
						scrollbarWidth: "none",
						msOverflowStyle: "none",
						"&::-webkit-scrollbar": {
							display: "none",
						},
					}}
				>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
									sx={{
										backgroundColor: "primary.main",
										color: "#fff",
									}}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody
						sx={{
							scrollbarWidth: "none",
							msOverflowStyle: "none",
							"&::-webkit-scrollbar": {
								display: "none",
							},
						}}
					>
						{!loading &&
							data
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((item, index) => (
									<TableRow key={index} hover role="checkbox" tabIndex={-1}>
										<TableCell>{page * rowsPerPage + index + 1}</TableCell>
										<TableCell>
											{formatNumberToDecimalPlace(Number(item?.OrderCount), 4)}
										</TableCell>
										<TableCell>
											{formatNumberToDecimalPlace(Number(item?.Price), 4) || ""}
										</TableCell>
										<TableCell>
											{formatNumberToDecimalPlace(Number(item?.Volume), 4) ||
												""}
										</TableCell>
									</TableRow>
								))}
					</TableBody>
				</Table>
			</TableContainer>
			{loading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
					}}
				>
					<CircularProgress size="2rem" />
				</Box>
			)}
			{!loading && data?.length === 0 && (
				<Box sx={{ textAlign: "center", marginTop: "-1.38rem" }}>No data</Box>
			)}
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={data.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
}

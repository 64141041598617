import { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
	Box,
	Typography,
	styled,
	Tabs,
	Tab,
} from "../../../components/common/muiComponents";
import Table from "../../../components/Coop/TransactionsSummaryTable";
import TabPanel from "../../../components/common/TabPanel";
import { fetchCoopTransactionsSummary } from "../../../api/admin/dashboard";
import { numberFormatter } from "../../../utils/helpers/functions";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const SummaryFilterContainer = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: ".5rem",
	alignItems: "center",
});

const style = {
	p: {
		md: "2rem",
		xs: "1rem",
	},
};

const CoopTransactionsSummary = () => {
	const [currentTab, setCurrentTab] = useState(0);

	const { region, user } = useSelector((state) => state.auth);

	const {
		data: reportData = [],
		isLoading: reportLoading,
		isError: reportError,
		error: reportErrorMessage,
	} = useQuery(
		["fetch-coop-transactions-summary", region?.region],
		async () =>
			fetchCoopTransactionsSummary({
				region: region?.region,
				roleID: user?.role,
				username: user?.username,
			}),
		{
			select: (data) => data,
			staleTime: 4 * 60 * 1000,
			enabled: !!region?.region,
		}
	);

	useEffect(() => {
		if (reportError) {
			if (reportErrorMessage.response) {
				toast.error(reportErrorMessage.response.data?.message);
			} else if (reportErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", reportErrorMessage.message);
			}
		}
	}, [reportError, reportErrorMessage]);

	const creditData = useMemo(() => {
		return reportData.credit || [];
	}, [reportData]);

	const debitData = useMemo(() => {
		return reportData.debit || [];
	}, [reportData]);

	const totalCreditAmount = useMemo(() => {
		return creditData.reduce((acc, item) => acc + Number(item.Total), 0);
	}, [creditData]);

	const totalDebitAmount = useMemo(() => {
		return debitData.reduce((acc, item) => acc + Number(item.Total), 0);
	}, [debitData]);

	return (
		<Box sx={style}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					flexDirection: { sm: "row", xs: "column" },
					mb: "2rem",
				}}
			>
				<Typography
					variant="h6"
					sx={{ fontWeight: 700, textTransform: "capitalize", mr: "auto" }}
					gutterBottom
				>
					Cashflow Analysis
				</Typography>
			</Box>

			<Box sx={{ mb: "2rem" }}>
				<Tabs
					value={currentTab}
					onChange={(event, newValue) => {
						setCurrentTab(newValue);
					}}
					aria-label="COOP Cashflow Analysis"
				>
					<Tab label="Credit Report" {...a11yProps(0)} />
					<Tab label="Debit Report" {...a11yProps(1)} />
				</Tabs>
			</Box>

			<Box
				sx={{
					width: "100%",
					overflow: "auto",
				}}
			>
				<TabPanel value={currentTab} index={0}>
					<SummaryFilterContainer>
						<Typography variant="h6">{`Total Amount: ₦ ${numberFormatter(
							totalCreditAmount
						)}`}</Typography>
					</SummaryFilterContainer>
					<Table data={creditData} loading={reportLoading} />
				</TabPanel>

				<TabPanel value={currentTab} index={1}>
					<SummaryFilterContainer>
						<Typography variant="h6">{`Total Amount: ₦ ${numberFormatter(
							totalDebitAmount
						)}`}</Typography>
					</SummaryFilterContainer>
					<Table data={debitData} loading={reportLoading} />
				</TabPanel>
			</Box>
		</Box>
	);
};

export default CoopTransactionsSummary;

import { useSelector } from "react-redux";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableContainer,
	TableRow,
	Typography,
	Paper,
} from "./muiComponents";
import { ROLES } from "../../utils/constants";
import closeIcon from "../../assets/icons/close-icon.svg";
import approvedImage from "../../assets/images/approved.png";
import pendingImage from "../../assets/images/pending.png";
import rejectedImage from "../../assets/images/rejected.png";

const displayStatusImage = (status) => {
	switch (status) {
		case 1:
			return approvedImage;
		case 2:
			return pendingImage;
		case 3:
			return rejectedImage;
		default:
			return pendingImage;
	}
};

const columns = [
	{ id: "Record Info", label: "Record Info", minWidth: 80 },
	{ id: "BVN Details", label: "BVN Details", minWidth: 80 },
	{ id: "SmartDeals Details", label: "SmartDeals Details", minWidth: 80 },
	{ id: "status", label: "", minWidth: 30 },
];

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	data,
	loading,
	handleApprove,
	handleReject,
	handleCompareFaces,
	compareFacesLoading,
	handleRecheckBVN,
	recheckBvnFacesLoading,
}) {
	const { user } = useSelector((state) => state.auth);

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					// width: "90vw",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
						width: "90vw",
						maxWidth: "90vw",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						{/* <Box sx={{ height: "5.25rem" }}>
							<img
								src={logo}
								height="100%"
								width="auto"
								alt="Cardinalstone logo"
							/>
						</Box> */}
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						textAlign: "center",
					}}
				>
					{loading && (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								width: "100%",
								marginTop: "2rem",
							}}
						>
							<CircularProgress size="2rem" />
						</Box>
					)}
					{!loading && (
						<>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									marginBottom: "2rem",
									pb: "2rem",
									// width: "30rem",
								}}
							>
								<Box sx={{ mb: "1rem", width: "100%", display: "flex" }}>
									<Paper sx={{ width: "100%", overflow: "hidden" }}>
										<TableContainer sx={{ maxHeight: 440 }}>
											<Table stickyHeader aria-label="sticky table">
												<TableHead>
													<TableRow>
														{columns.map((column) => (
															<TableCell
																key={column.id}
																style={{
																	minWidth: column.minWidth,
																	backgroundColor: "#e41f26",
																	color: "#fff",
																}}
															>
																{column.label}
															</TableCell>
														))}
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow hover role="checkbox" tabIndex={-1}>
														<TableCell>Image</TableCell>
														<TableCell>
															<Box sx={{ height: "13rem", width: "13rem" }}>
																<img
																	src={data?.bvn_image}
																	alt=""
																	height="100%"
																	width="auto"
																/>
															</Box>
														</TableCell>
														<TableCell>
															<Box sx={{ height: "13rem", width: "13rem" }}>
																<img
																	src={data?.bvn_selfie}
																	alt=""
																	height="100%"
																	width="auto"
																/>
															</Box>
														</TableCell>
														<TableCell>
															<Box sx={{ height: "13rem", width: "13rem" }}>
																<img
																	src={displayStatusImage(
																		Number(data?.bvn_verify)
																	)}
																	alt=""
																	height="100%"
																	width="auto"
																/>
															</Box>
														</TableCell>
													</TableRow>
													<TableRow hover role="checkbox" tabIndex={-1}>
														<TableCell>BVN</TableCell>
														<TableCell>{data?.bvn || ""}</TableCell>
														<TableCell>{data?.bvn_2 || ""}</TableCell>
													</TableRow>
													<TableRow hover role="checkbox" tabIndex={-1}>
														<TableCell>First Name</TableCell>
														<TableCell>{data?.bvn_firstname || ""}</TableCell>
														<TableCell>{data?.firstName || ""}</TableCell>
													</TableRow>
													<TableRow hover role="checkbox" tabIndex={-1}>
														<TableCell>Middle Name</TableCell>
														<TableCell>{data?.bvn_middlename || ""}</TableCell>
														<TableCell>{data?.middlename || ""}</TableCell>
													</TableRow>
													<TableRow hover role="checkbox" tabIndex={-1}>
														<TableCell>Last Name</TableCell>
														<TableCell>{data?.bvn_lastname || ""}</TableCell>
														<TableCell>{data?.lastName || ""}</TableCell>
													</TableRow>
													<TableRow hover role="checkbox" tabIndex={-1}>
														<TableCell>Phone Number</TableCell>
														<TableCell>{data?.bvn_phonenumber || ""}</TableCell>
														<TableCell>{data?.phoneNumber || ""}</TableCell>
													</TableRow>
													<TableRow hover role="checkbox" tabIndex={-1}>
														<TableCell>Email Address</TableCell>
														<TableCell>
															{data?.bvn_emailAddress || ""}
														</TableCell>
														<TableCell>{data?.emailAddress || ""}</TableCell>
													</TableRow>
													<TableRow hover role="checkbox" tabIndex={-1}>
														<TableCell>Gender</TableCell>
														<TableCell>{data?.bvn_gender || ""}</TableCell>
														<TableCell>{data?.gender || ""}</TableCell>
													</TableRow>
													<TableRow hover role="checkbox" tabIndex={-1}>
														<TableCell>Comment</TableCell>
														<TableCell colSpan="2">
															{data?.bvn_comment || ""}
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</Paper>
								</Box>
								{!!data?.bvn_image === true && (
									<Stack
										direction="row"
										spacing={2}
										sx={{
											marginBottom: "1rem",
											display: "flex",
											justifyContent: "center",
										}}
									>
										{Number(data?.bvn_verify) === 2 &&
											Number(user?.compare_face) === 1 && (
												<Button
													sx={{
														paddingLeft: "3rem",
														paddingRight: "3rem",
														color: "#fff",
													}}
													variant="contained"
													color="secondary"
													onClick={() => {
														handleCompareFaces();
													}}
												>
													{compareFacesLoading ? (
														<CircularProgress
															size="1.5rem"
															sx={{ color: "#fff" }}
														/>
													) : (
														"compare faces"
													)}
												</Button>
											)}
										{Number(data?.bvn_verify) === 2 && (
											<Button
												sx={{
													paddingLeft: "3rem",
													paddingRight: "3rem",
												}}
												variant="contained"
												color="success"
												onClick={() => {
													handleApprove();
												}}
											>
												{Number(user?.compare_face) === 1
													? "approve"
													: "process BVN"}
											</Button>
										)}
										{(Number(data?.bvn_verify) === 2 ||
											(Number(data?.bvn_verify) === 3 &&
												user?.role?.toLowerCase() === ROLES.EXECUTIVE)) && (
											<Button
												sx={{
													paddingLeft: "3rem",
													paddingRight: "3rem",
												}}
												color="error"
												variant="contained"
												onClick={() => {
													handleReject();
												}}
											>
												reject
											</Button>
										)}
										<Button
											sx={{
												paddingLeft: "3rem",
												paddingRight: "3rem",
											}}
											variant="contained"
											color="info"
											onClick={() => {
												handleClose();
											}}
										>
											close
										</Button>
									</Stack>
								)}
								{!!data?.bvn_image === false && (
									<Stack
										direction="row"
										spacing={2}
										sx={{
											marginBottom: "1rem",
											display: "flex",
											justifyContent: "center",
										}}
									>
										<Button
											sx={{
												paddingLeft: "3rem",
												paddingRight: "3rem",
												color: "#fff",
											}}
											variant="contained"
											color="secondary"
											onClick={() => {
												handleRecheckBVN();
											}}
										>
											{recheckBvnFacesLoading ? (
												<CircularProgress
													size="1.5rem"
													sx={{ color: "#fff" }}
												/>
											) : (
												"recheck BVN"
											)}
										</Button>
										<Button
											sx={{
												paddingLeft: "3rem",
												paddingRight: "3rem",
											}}
											variant="contained"
											color="info"
											onClick={() => {
												handleClose();
											}}
										>
											close
										</Button>
									</Stack>
								)}
							</Box>
						</>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
}

/* eslint-disable no-param-reassign */
import axios from "axios";
import { TOKENKEY, REGION_KEY } from "../utils/constants";
import config from "../config";
import { encryption, decryption } from "../utils/helpers/functions";

const { apiHost } = config().secrets;

const customAxios = axios.create({
	baseURL: `${apiHost}`,
	timeout: 600000,
});

const role = localStorage.getItem("role");
const accountType = localStorage.getItem("accountType");
const bizFrom = localStorage.getItem("bizFrom");

const getRegion = () => {
	try {
		return JSON.parse(localStorage.getItem(REGION_KEY));
	} catch (err) {
		return null;
	}
};
const region = getRegion();

const defaultParams = {
	region: region?.region || region?.short_name,
	bizFrom,
	accountType,
	deviceType: "WEB",
	role,
};

const defaultData = {
	region: region?.region || region?.short_name,
	bizFrom,
	accountType,
	deviceType: "WEB",
	role,
};

const mergeObjects = (obj1, obj2) => {
	return {
		...obj1,
		...obj2,
	};
};

const requestHandler = (request) => {
	request.headers.Authorization = localStorage.getItem(TOKENKEY);

	if (request.method === "get") {
		const mergeParams = mergeObjects(defaultParams, request.params);
		request.params = {
			payload: encryption(mergeParams),
		};
	} else if (request.method === "post") {
		if (request.data instanceof FormData) {
			// Handle FormData directly
			request.data.append("region", defaultParams.region);
			request.data.append("bizFrom", defaultParams.bizFrom);
			request.data.append("accountType", defaultParams.accountType);
			request.data.append("deviceType", defaultParams.deviceType);
			request.data.append("role", role);
			request.headers["Content-Type"] = "multipart/form-data";
		} else {
			// Merge default data with request data
			const mergedData = mergeObjects(defaultData, request.data);
			request.data = encryption(mergedData);
			request.headers["Content-Type"] = "text/plain";
		}
	}

	return request;
};

const responseHandler = (response) => {
	if (response?.status === 403) {
		localStorage.clear();
		window.location = "/";
	}
	let reponseData;
	try {
		reponseData = decryption(response?.data);
	} catch (err) {
		reponseData = response?.data;
	}
	return { ...response, data: reponseData };
};

const errorHandler = (error) => {
	if (error.response.status === 422) {
		const formattedErrors = Object.entries(error.response.data)
			.map(([field, errors]) => `${field}: ${errors.join(", ")}`)
			.join("\n");
		error.response.data = { message: formattedErrors };
	} else {
		if (error.response?.status === 403) {
			localStorage.clear();
			window.location = "/";
		}
		if (error.response?.data) {
			const decryptedData = decryption(error.response.data);
			if (decryptedData?.message?.toLowerCase() === "invalid credentials") {
				localStorage.clear();
				window.location = "/";
			}
			// eslint-disable-next-line no-param-reassign
			error.response.data = decryptedData;
		}
	}

	// eslint-disable-next-line prefer-promise-reject-errors
	return Promise.reject(error);
};

customAxios.interceptors.request.use(
	(request) => requestHandler(request),
	(error) => errorHandler(error)
);

customAxios.interceptors.response.use(
	(response) => responseHandler(response),
	(error) => errorHandler(error)
);

export default customAxios;

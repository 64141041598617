import { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import TableHeader from "../../../components/Admin/TableHeader";
import Table from "../../../components/Admin/EventManagersTable";
import ViewEventManagerModal from "../../../components/common/ViewEventManagerModal";
import {
	fetchAdminEventsManagers,
	enableEventManager,
	disableEventManager,
} from "../../../api/admin/dashboard";
import { downloadExcel } from "../../../utils/helpers/functions";

const PARTNER_STATUSES = Object.freeze({
	INACTIVE: 0,
	ACTIVE: 1,
});

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const EventManagers = () => {
	const [selectedItem, setSelectedItem] = useState({});
	const [searchTerm, setSearchTerm] = useState("");
	const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);

	const { user, region, bizFrom, role } = useSelector((state) => state.auth);
	const queryClient = useQueryClient();

	const {
		data: partnersData = [],
		isLoading: partnersLoading,
		isError: partnersError,
		error: partnersErrorMessage,
	} = useQuery(
		["get-event-managers", region?.region],
		async () => fetchAdminEventsManagers(region?.region),
		{
			select: (data) => data.data,
			staleTime: 4 * 60 * 1000,
			enabled: Boolean(region?.region),
		}
	);

	useEffect(() => {
		if (partnersError) {
			if (partnersErrorMessage.response) {
				toast.error(partnersErrorMessage.response.data?.message);
			} else if (partnersErrorMessage.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", partnersErrorMessage.message);
			}
		}
	}, [partnersError, partnersErrorMessage]);

	const { mutate: activateEventManager } = useMutation(
		"activate-event-manager",
		enableEventManager,
		{
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setSelectedItem({});
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-event-managers");
			},
		}
	);

	const { mutate: deactivateEventManager } = useMutation(
		"deactivate-event-manager",
		disableEventManager,
		{
			onSuccess: (dataSuccess) => {
				toast.success(dataSuccess?.message);
				setSelectedItem({});
			},
			onError: (errors) => {
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("get-event-managers");
			},
		}
	);

	const handleActivatePartner = async () => {
		const payload = {
			partnerID: selectedItem.partnerID,
			username: user.username,
			admin: user.fullName,
			region: region?.region,
			bizFrom,
			role,
		};

		await activateEventManager(payload);
	};

	const handleDeactivatePartner = async () => {
		const payload = {
			partnerID: selectedItem.partnerID,
			username: user.username,
			admin: user.fullName,
			region: region?.region,
			bizFrom,
			role,
		};

		await deactivateEventManager(payload);
	};

	const displayed = useMemo(() => {
		if (partnersData.length) {
			let filteredPartners = [...partnersData];
			if (searchTerm) {
				filteredPartners = filteredPartners.filter((partner) => {
					return (
						partner.partnerName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.username
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.emailAddress
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.phoneNumber
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredPartners;
		}
		return partnersData;
	}, [partnersData, searchTerm]);

	const activePartners = useMemo(() => {
		if (partnersData.length) {
			let filteredPartners = [
				...partnersData?.filter(
					(item) => Number(item.status) === PARTNER_STATUSES.ACTIVE
				),
			];
			if (searchTerm) {
				filteredPartners = filteredPartners.filter((partner) => {
					return (
						partner.partnerName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.username
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.emailAddress
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.phoneNumber
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredPartners;
		}
		return partnersData;
	}, [partnersData, searchTerm]);

	const inacctivePartners = useMemo(() => {
		if (partnersData.length) {
			let filteredPartners = [
				...partnersData?.filter(
					(item) => Number(item.status) === PARTNER_STATUSES.INACTIVE
				),
			];
			if (searchTerm) {
				filteredPartners = filteredPartners.filter((partner) => {
					return (
						partner.partnerName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.username
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.emailAddress
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						partner.phoneNumber
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
					);
				});
			}
			return filteredPartners;
		}
		return partnersData;
	}, [partnersData, searchTerm]);

	const handleExcelExport = () => {
		downloadExcel(displayed, "Event Managers", "event-managers");
	};

	return (
		<Box
			sx={{
				p: {
					md: "2rem",
					xs: "1rem",
				},
				width: "100%",
			}}
		>
			<Box sx={{ mb: "2rem" }}>
				<TableHeader
					text="Event Managers"
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleExcelExport={handleExcelExport}
					label="Search Managers"
				/>
			</Box>

			<Box sx={{ mb: "2rem" }}>
				<Tabs
					value={currentTab}
					onChange={(event, newValue) => {
						setCurrentTab(newValue);
					}}
					aria-label="event managers status tab"
				>
					<Tab label="All" {...a11yProps(0)} />
					<Tab label="Active" {...a11yProps(1)} />
					<Tab label="Inactive" {...a11yProps(2)} />
				</Tabs>
			</Box>

			<Box
				sx={{
					// width: { md: "78vw", xs: "95vw" },
					width: "100%",
					overflow: "auto",
					maxWidth: "100%",
					boxSizing: "border-box",
				}}
			>
				{currentTab === 0 && (
					<Table
						data={displayed}
						loading={partnersLoading}
						setSelectedItem={setSelectedItem}
						handleActivatePartner={handleActivatePartner}
						handleDeactivatePartner={handleDeactivatePartner}
						handleView={() => setViewDetailsModalOpen(true)}
					/>
				)}
				{currentTab === 1 && (
					<Table
						data={activePartners}
						loading={partnersLoading}
						setSelectedItem={setSelectedItem}
						handleActivatePartner={handleActivatePartner}
						handleDeactivatePartner={handleDeactivatePartner}
						handleView={() => setViewDetailsModalOpen(true)}
					/>
				)}
				{currentTab === 2 && (
					<Table
						data={inacctivePartners}
						loading={partnersLoading}
						setSelectedItem={setSelectedItem}
						handleActivatePartner={handleActivatePartner}
						handleDeactivatePartner={handleDeactivatePartner}
						handleView={() => setViewDetailsModalOpen(true)}
					/>
				)}
			</Box>
			<ViewEventManagerModal
				open={viewDetailsModalOpen}
				handleClose={() => {
					setViewDetailsModalOpen(false);
				}}
				title="View Manager's Details"
				data={selectedItem}
			/>
		</Box>
	);
};

export default EventManagers;
